import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  ExternalUrls,
} from '../../constants';

import styles from './UserAvatar.module.scss';

const getImageUrl = (alias) => {
  return `${ExternalUrls.BADGE_PHOTO}${alias}`;
};

const UserAvatar = ({ username, disableShadow }) => {
  if (!username) {
    return null;
  }
  const imageUrl = getImageUrl(username);
  return (
    <div
      className={classnames({
        [styles.avatar]: true,
        [styles.disableShadow]: disableShadow,
      })}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    />
  );
};

UserAvatar.propTypes = {
  username: PropTypes.string.isRequired,
  disableShadow: PropTypes.bool,
};

UserAvatar.defaultProps = {
  disableShadow: false,
};

export default UserAvatar;
