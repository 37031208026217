import { SELECT_TIMEFRAME, SiteWideActions } from '../../constants';
import timeFrameChange from './timeFrameChange';
import reportConnectionError from './reportConnectionError';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;

  if (type === SELECT_TIMEFRAME) {
    return timeFrameChange(dispatch, getState, action, next);
  }
  if (type === SiteWideActions.SET_CONNECTION_ERROR) {
    return reportConnectionError(dispatch, getState, action, next);
  }

  return next(action);
};
