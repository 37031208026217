import React from 'react';
import { isEmptyOrUnfilledArray } from '../helpers/common';

const withPicturedAsinsCustomization = (Component, promotion) => (props) => {
  const { asins } = promotion;
  const isEmpty = isEmptyOrUnfilledArray(asins);
  const hasMultipleAsins = !isEmpty && asins.length > 1;
  return (
    <Component
      {...props}
      overrideOptions={asins}
      showSelectAll={hasMultipleAsins}
      autoOpen={!isEmpty}
    />
  );
};

export default withPicturedAsinsCustomization;
