import { connect } from 'react-redux';
import TemplateActionBar from './TemplateActionBar';
import { onRemoveRow } from '../../../actionCreators';

const mapDispatchToProps = (dispatch, { promotion: { id } }) => {
  return {
    onRemoveRow() {
      dispatch(onRemoveRow(id));
    },
  };
};

export default connect(null, mapDispatchToProps)(TemplateActionBar);
