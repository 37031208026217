import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import styles from './PromoAuditLogModal.module.scss';
import GridView from '../GridView';
import { campaignColumnProps } from '../../proptypes';

const PromoAuditLogModal = (props) => {
  const {
    isOpen,
    onCloseModal,
    promoAuditLogs,
    columnOrder,
  } = props;

  if (!isOpen) {
    return null;
  }

  const getVersionHistoryList = () => {
    const versionsList = [...promoAuditLogs].reverse().map((promoAuditLog) => {
      const {
        publishedPromotion: {
          version,
          promotions,
        },
        auditLogMetadata: {
          modificationTimestamp,
        },
      } = promoAuditLog;

      const formattedTime = moment(modificationTimestamp).format('MMMM Do YYYY, h:mm:ss a');

      return (
        <li key={version} className={styles.item}>
          <div className={styles.description}>
            <h3 className={styles.version}>Version {version}</h3>
            <sup className={styles.dateTime}>{formattedTime}</sup>
          </div>
          <GridView
            promotions={promotions}
            columnOrder={columnOrder}
            isReadOnly
          />
        </li>
      );
    });

    return (
      <ul className={styles.list}>
        {versionsList}
      </ul>
    );
  };

  const versionsHistory = getVersionHistoryList();

  return (
    <Modal open={isOpen} scrollContainer="modal" title="Version history">
      {versionsHistory}
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button size="small" onClick={onCloseModal}>Close</Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

PromoAuditLogModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  columnOrder: campaignColumnProps.isRequired,
};

export default PromoAuditLogModal;
