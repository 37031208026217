import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './TemplateBuilderContainer.module.scss';
import AuditLogPage from '../AuditLogPage';
import TemplateBuilder from '../TemplateBuilder';
import Tabs from '../Tabs';
import {
  TemplateBuilderTabsList,
  AuditLogTypes,
} from '../../constants';

const TemplateBuilderContainer = (props) => {
  const {
    activeTemplate,
    currentTab,
    onSwitchTab,
    onReset,
    onLoadTemplate,
    templateId,
    templates,
    shouldShow,
  } = props;

  useEffect(() => {
    return () => {
      onReset();
    };
  }, []);

  useEffect(() => {
    if (templateId) {
      onLoadTemplate(templateId);
    }
  }, [templates.length, templateId]);

  const getAuditLog = () => {
    if (!activeTemplate) {
      return null;
    }

    return (
      <AuditLogPage
        entity={activeTemplate}
        logType={AuditLogTypes.TEMPLATE_BUILDER}
        showRollback={false}
      />
    );
  };

  const getTemplateBuilder = () => <TemplateBuilder />;

  const getComponentByTab = () => {
    switch (currentTab) {
      case TemplateBuilderTabsList.TEMPLATE_BUILDER.name:
        return getTemplateBuilder();
      case TemplateBuilderTabsList.AUDIT_LOG.name:
        return getAuditLog();
      default:
        return getTemplateBuilder();
    }
  };

  const getTabs = () => {
    const tabsList = Object.values(TemplateBuilderTabsList);

    return (
      <Tabs
        onSwitchTab={onSwitchTab}
        currentTab={currentTab}
        tabs={tabsList}
      />
    );
  };

  const getContent = () => {
    if (!shouldShow) {
      return null;
    }

    return getComponentByTab();
  };

  const tabs = getTabs();
  const content = getContent();

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          {tabs}
          <div className={styles.scrollableContent}>
            {content}
          </div>
        </div>
      </div>
    </>
  );
};

TemplateBuilderContainer.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
  onLoadTemplate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  templates: PropTypes.array,
  templateId: PropTypes.string,
  shouldShow: PropTypes.bool,
};
TemplateBuilderContainer.propTypes = {
  templates: [],
  templateId: '',
  shouldShow: false,
}

export default TemplateBuilderContainer;
