import {
  writeClipboardContent
} from '../../helpers/clipboardCopyPaste';
import { onOpenToast } from '../../actionCreators';
import { Language } from '../../constants';
import {
  getFormattedForClipboardPromotions,
  getFormattedPromotions
} from '../../helpers/promotionRowsCopyPaste';
import { getTemplateString } from '../../helpers/common';

export default (dispatch, getState, action, next) => {
  const formattedPromotions = getFormattedPromotions(getState());
  if (!formattedPromotions) {
    return next(action);
  }
  const formattedForClipboardPromotions = getFormattedForClipboardPromotions(formattedPromotions);

  writeClipboardContent(formattedForClipboardPromotions)
    .then(() => {
      dispatch(onOpenToast(getTemplateString(
        Language.PROMOTION_SUCCESS_COPIED_TO_CLIPBOARD,
        { count: formattedPromotions.length }
      )));
    })
    .catch(() => {
      dispatch(onOpenToast(Language.CLIPBOARD_COPY_ERROR));
    });

  return next(action);
}