import { connect } from 'react-redux';
import GridViewHeader from './GridViewHeader';
import { onClearSearch, onSetContentWrapping, onSetMustFillHighlight } from '../../actionCreators';
import { isCurrentUserAdmin } from '../../selectors/user';

export const mapStateToProps = (state) => {
  return {
    isAdmin: isCurrentUserAdmin(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClearSearchButtonClick: () => dispatch(onClearSearch()),
    onContentWrapButtonClick: (value) => dispatch(onSetContentWrapping(value)),
    onHighlightMustFillButtonClick: (value) => dispatch(onSetMustFillHighlight(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewHeader);
