import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { getYearQuarter, getSelectedDates } from '../../selectors/promotions';
import { getUsername } from '../../selectors/user';
import { onSetBannerModalOpen } from '../../actionCreators/banner';
import { parseArrayField } from '../../helpers/translatePromotions';
import { BannerColumns } from '../../constants/banner';

const getBannersToCreate = (banners) => {
  return banners.filter(banner => {
    if (banner.id) {
      return false;
    }

    for (const key of Object.keys(banner)) {
      if (banner[key]) {
        return true;
      }
    }

    return false;
  });
};

const getBannersToDelete = (existingBanners, currentBanners) => {
  const existingBannerIds = existingBanners.map(existingBanner => existingBanner.id);
  const currentBannerIds = currentBanners.map(currentBanner => currentBanner.id);

  const bannerIdsToDelete = _.difference(existingBannerIds, currentBannerIds);

  return existingBanners.filter(existingBanner => bannerIdsToDelete.includes(existingBanner.id));
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  const yearQuarter = getYearQuarter(state);
  const [startDate, endDate] = getSelectedDates(state);

  if (!yearQuarter || !startDate || !endDate) {
    return next(action);
  }

  const { payload: { banners: currentBanners } } = action;
  if (!currentBanners) {
    return next(currentBanners);
  }

  const { Banner: { banners: existingBanners } } = state;

  for (const currentBanner of currentBanners) {
    if (_.isEmpty(currentBanner.storeIds)) {
      delete currentBanner.storeIds;
    }
    if (_.isEmpty(currentBanner.storeRegions)) {
      delete currentBanner.storeRegions;
    }
  }

  const bannersToCreate = getBannersToCreate(currentBanners);
  const bannersToUpdate = currentBanners.filter(banner => banner.id);
  const bannersToDelete = getBannersToDelete(existingBanners, currentBanners);

  const onResponse = (response) => {
    dispatch(onSetBannerModalOpen(false));
  };

  const getBannerInput = (bannerProps) => {
    const baseBannerProps = {
      yearQuarter_startDate_endDate: `${yearQuarter}_${startDate}_${endDate}`,
      version: 1,
    };
    const username = getUsername(state);
    const banner = {
      ...baseBannerProps,
      ...bannerProps,
      ...parseArrayField(BannerColumns.STORE_IDS.name, bannerProps.storeIds),
      ...parseArrayField(BannerColumns.STORE_REGIONS.name, bannerProps.storeRegions),
    }
    if (banner.version) {
      banner.version = banner.version + 1;
    } else {
      banner.version = 1;
    }


    return {
      banner,
      id: banner.id,
      username,
    };
  }

  const sendRequest = (api, input) => {
    API
    .graphql(graphqlOperation(api, {
      input,
    }))
    .then(onResponse)
    .catch((error) => console.error(error));
  };

  if (bannersToCreate.length) {
    bannersToCreate.forEach(bannerToCreate => {
      sendRequest(mutations.createBanner, getBannerInput(bannerToCreate));
    });
  }

  if (bannersToUpdate.length) {
    bannersToUpdate.forEach(bannerToUpdate => {
      sendRequest(mutations.updateBanner, getBannerInput(bannerToUpdate));
    });
  }

  if (bannersToDelete.length) {
    bannersToDelete.forEach(bannerToDelete => {
      sendRequest(mutations.deleteBanner, getBannerInput(bannerToDelete));
    });
  }

  return next(action);
};
