import {
  onOpenToast,
  onUpdatedPromotionReceived,
} from '../../actionCreators';
import {
  Language, PromotionImageStatuses,
} from '../../constants';
import { localToAppsync } from '../../helpers/translatePromotions';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { parseGraphQLResponse } from '../../helpers/api';
import { updatePromotion } from '../../graphql/mutations';
import { sendRequest } from './helper';

const getRequestParams = (promotion, username) => {
  const translatedPromotion = localToAppsync({
    ...promotion,
    imageStatus: PromotionImageStatuses.MANUALLY_CONFIRMED,
    version: promotion.version + 1,
  });

  delete translatedPromotion.id;

  return {
    promotion: translatedPromotion,
    username,
    updateReason: 'manual image confirm',
    id: promotion.id,
  };
};

const getRequestOptions = (promotion, username) => {
  return {
    graphQlApi: updatePromotion,
    graphQlParams: getRequestParams(promotion, username),
    messages: [
      Language.PROMOTION_IMAGE_APPROVED_SUCCESS_MESSAGE,
      Language.PROMOTION_IMAGE_APPROVED_FAIL_MESSAGE,
    ],
  };
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const {
    GridViewPage,
    User: { currentUser: { username } },
  } = state;

  const {
    payload: { id },
  } = action;

  const promotion = getPromotionById(GridViewPage, id);
  if (!promotion) {
    dispatch(onOpenToast(`Cannot find promotion ${id}!`));

    return next(action);
  }

  const onSuccess = (response, successMessage) => {
    dispatch(onUpdatedPromotionReceived(parseGraphQLResponse(response)));
    dispatch(onOpenToast(successMessage));
  };

  sendRequest(dispatch, getRequestOptions(promotion, username), onSuccess);

  return next(action);
};
