import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import Main from './Sections/Main';
import SubmitButtons from './Sections/SubmitButtons';
import { Language, Urls } from '../../constants';
import sitewideStyles from '../../styles/sitewide.module.scss';
import styles from './PromoWeekForm.module.scss';

const PromoWeekForm = (props) => {
  const history = useHistory();

  const {
    onCreate,
    onUpdate,
    handleSubmit,
    submitting,
    isNew,
    isLoading,
    isNotFound,
    columnsList,
  } = props;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Loader type="circular" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Alert>{Language.PROMOTION_NOT_FOUND}</Alert>
        </div>
      </div>
    );
  }

  const onModalClose = () => {
    history.push(Urls.CALENDAR);
  };

  const process = (data) => {
    if (isNew) {
      onCreate(data);
    } else {
      onUpdate(data);
    }
  };

  const processSubmit = (data) => {
    process(data);

    onModalClose();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <form
          className={styles.form}
          onSubmit={handleSubmit(processSubmit)}
        >
          <div className={styles.formInnerContainer}>
            <Main
              columnsList={columnsList}
              isNew={isNew}
            />
            <SubmitButtons
              isNew={isNew}
              submitting={submitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

PromoWeekForm.propTypes = {
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNotFound: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

PromoWeekForm.defaultProps = {
  isNew: false,
};

export default PromoWeekForm;
