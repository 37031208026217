import React, { useEffect } from 'react';
import Modal from '@amzn/meridian/modal';
import Heading from '@amzn/meridian/heading';
import styles from './BannerModal.module.scss';
import BannerForm from '../BannerForm';

const BannerModal = (props) => {
  const {
    isModalOpen,
    onClose,
    onLoad,
  } = props;

  useEffect(() => {
    onLoad();
  }, [isModalOpen, onClose, onLoad]);

  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <div className={styles.contentContainer}>
        <BannerForm />
      </div>
    </Modal>
  );
};

export default BannerModal;
