const assertCoverageLength = (asinSuggestion, regionFilters) => {
  const flattenedAsinSuggestion = Object.values(asinSuggestion).flat() // convert coverage to a single array

  if (flattenedAsinSuggestion.length === Object.keys(regionFilters).length) { 
    return true;
  }
  return false;
}

export const getRecommendedAsins = (approvedAsinViabilities, partiallyApprovedAsinViabilities, regionFilters) => {
  if (!_.isEmpty(approvedAsinViabilities)) {
    return null;
  }

  const availableAsinsByRegion = {};

  for (const asin in partiallyApprovedAsinViabilities) {
    if (!Object.keys(availableAsinsByRegion).includes(asin)) {
      const coveredRegions = [];
      for (const region in regionFilters) {
        const isUnavailable = partiallyApprovedAsinViabilities[asin][region].unavailableAsinViabilities.length;
        if (isUnavailable && regionFilters[region]) {
          break;
        }
        if (!isUnavailable) {
          coveredRegions.push(region);
        }
      }
      if (coveredRegions.length ) {
        availableAsinsByRegion[asin] = coveredRegions;
      }
    }
  }

  const asinSuggestion = {};
  let regions = Object.keys(regionFilters);

  if (Object.keys(availableAsinsByRegion).length) {
    const sortedAsins = Object.keys(availableAsinsByRegion).sort((a, b) => availableAsinsByRegion[b].length - availableAsinsByRegion[a].length);

    while (regions.length > 0 && sortedAsins.length) {
      const selectedAsin = sortedAsins[0];
      const filteredAsinRegions = availableAsinsByRegion[selectedAsin].filter(region => !Object.values(asinSuggestion).some(values => values.includes(region)));
      asinSuggestion[selectedAsin] = filteredAsinRegions;
      regions = regions.filter(region => !availableAsinsByRegion[selectedAsin].includes(region));
      sortedAsins.shift();
    }
  }

  if (assertCoverageLength(asinSuggestion, regionFilters)) {
    return asinSuggestion;
  }

  return null;
}
