import { Columns } from './index';

export const BulkImportActions = Object.freeze({
  PROCESS_FILE: 'BulkImportActions/BULK_IMPORT_PROCESS_FILE',
  SET_PENDING_PROMOTIONS: 'BulkImportActions/SET_PENDING_PROMOTIONS',
  RESET: 'BulkImportActions/RESET',
  PERFORM_IMPORT: 'BulkImportActions/PERFORM_IMPORT',
  ACTION_COMPLETED: 'BulkImportActions/ACTION_COMPLETED',
  SET_FOR_IMPORT: 'BulkImportActions/SET_FOR_IMPORT',
  SET_SUCCEEDED_PROMOTIONS: 'BulkImportActions/SET_SUCCEEDED_PROMOTIONS',
  SET_FAILED_PROMOTIONS: 'BulkImportActions/SET_FAILED_PROMOTIONS',
  SUCCESS: 'BulkImportActions/SUCCESS',
  ERROR: 'BulkImportActions/ERROR',
  START_IMPORTING: 'BulkImportActions/START_IMPORTING',
  COMPLETE_IMPORTING: 'BulkImportActions/COMPLETE_IMPORTING',
  PERFORM_ASSETS_IMPORT: 'BulkImportActions/PERFORM_ASSETS_IMPORT',
  START_FILE_PARSING: 'BulkImportActions/START_FILE_PARSING',
  COMPLETE_FILE_PARSING: 'BulkImportActions/COMPLETE_FILE_PARSING',
  OPEN_MODAL: 'BulkImportActions/OPEN_MODAL',
  CLOSE_MODAL: 'BulkImportActions/CLOSE_MODAL',
});

export const BulkImportCompareColumns =  [
  Columns.ASINS.name, 
  Columns.PICTURED_ASINS.name, 
  Columns.STORE_IDS.name, 
  Columns.STORE_REGIONS.name
]

