import {
  GridViewSource,
  TemplateActions,
} from '../../constants';
import loadTemplates from './loadTemplates';
import saveTemplate from './saveTemplate';
import deleteTemplate from './deleteTemplate';
import { getTemplateById } from '../../helpers/templates';
import {
  onSetActiveTemplate,
  onSetTemplateName,
  onSetSource,
  onAddRow,
} from '../../actionCreators';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === TemplateActions.LOAD_TEMPLATES) {
    return loadTemplates(dispatch, getState, action, next);
  }

  if (type === TemplateActions.SAVE) {
    return saveTemplate(dispatch, getState, action, next);
  }

  if (type === TemplateActions.DELETE) {
    return deleteTemplate(dispatch, getState, action, next);
  }

  if (type === TemplateActions.SELECT_TEMPLATE_FOR_EDITING) {
    const { payload: { id } } = action;
    const template = getTemplateById(getState(), id);

    if (!template) {
      console.error(`Cannot find template with ID='${id}'`);
      return next(action);
    }

    const { promoConfiguration: { rows }, name } = template;

    dispatch(onSetTemplateName(name));
    dispatch(onSetActiveTemplate(template));
    dispatch(onSetSource(GridViewSource.PREVIEW_ROWS));
    if (rows && rows.length) {
      rows.forEach((row) => {
        dispatch(onAddRow(row));
      });
    }
  }

  return next(action);
};
