import { connect } from 'react-redux';

import { defaultStateToProps, defaultDispatchToProps } from '../helpers';
import { getDependentOptionsForFieldOnCampaign } from '../../../helpers/getMetadataOptions';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import getPromotionById from '../../../reducers/gridViewPage/getPromotionById';

import SingleSelect from '../../StyledInputs/SingleSelect';
import { Columns } from '../../../constants';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      activeCell: { rowId, columnName },
      activeCellValue,
    },
    Meta: { metadata: { fields } },
  } = state;

  const columnData = fields[columnName];
  let options = [];
  if (columnData && columnData.options && Array.isArray(columnData.options)) {
    options = [...columnData.options];
  }
  if (columnData && columnData.dependsOn) {
    const campaign = getPromotionById(state.GridViewPage, rowId);
    options = [...getDependentOptionsForFieldOnCampaign(
      columnData.options,
      campaign,
      columnData.dependsOn,
    )];
  }
  // if an input has an overridden value, add it as an option for styling fixes
  if (activeCellValue && !options.includes(activeCellValue)) {
    options.push(activeCellValue);
  }

  return {
    ...defaultStateToProps(state),
    allowOverride: columnName !== Columns.PRICE_CONDITIONS.name,
    options,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleSelect);
