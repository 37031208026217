import { connect } from 'react-redux';
import {
  onAsinValidatorApproveRegions,
  onAsinValidatorExportResults,
  onAsinValidatorSetOpen,
  onAsinValidatorSetViewMode,
} from '../../actionCreators';
import AsinValidatorModal from './AsinValidatorModal';

export const mapStateToProps = (state) => {
  const {
    AsinValidator: {
      isOpen, asinViabilities, errors, viewMode, selectedRegions,
    },
  } = state;

  return {
    isOpen,
    asinViabilities,
    errors,
    viewMode,
    selectedRegions,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClose() {
      dispatch(onAsinValidatorSetOpen(false));
    },
    onSubmit() {
      dispatch(onAsinValidatorApproveRegions());
    },
    onChangeViewMode(viewMode) {
      dispatch(onAsinValidatorSetViewMode(viewMode));
    },
    onExportResults() {
      dispatch(onAsinValidatorExportResults());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinValidatorModal);
