import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import PopOver from '../PopOver';
import ContextMenu from '../ContextMenu';
import { CellHighlightOverride, COMMA_DELIMETER, NON_BREAKING_SPACE, PopOverDirection } from '../../constants';
import { isEmptyOrUnfilledArray } from '../../helpers/common';
import { campaignInputProps } from '../../proptypes';
import styles from './GridViewCell.module.scss';

const isPopOverWindow = (element) => {
  let result = false;
  Array.from(element.classList).forEach((className) => {
    if (className.indexOf('popOver') > -1) {
      result = true;
    }
  });

  return result;
};

export const isEmpty = (value) => (
  !_.isBoolean(value) && !_.isNumber(value) && (
    typeof value === 'undefined'
    || value === NON_BREAKING_SPACE
    || Array.isArray(value) && isEmptyOrUnfilledArray(value)
    || _.isEmpty(value)
  )
);

const getDerivedChildren = (input, showPercent = false) => {
  if (Array.isArray(input)) {
    return input.join(`${COMMA_DELIMETER} `);
  }
  if (typeof input === 'boolean') {
    return input ? 'True' : 'False';
  }
  if (typeof input === 'number' && showPercent) {
    return `${input}%`;
  }
  return input;
};

export const getCellStyles = (styleOptions, columnName) => {
  const {
    isError,
    isAdd,
    isUpdate,
    isRemove,
    isActive,
    isSticky,
    isAdmin,
    isSelected,
    textAlign,
    isContentWrappingEnabled,
    mustFillHighlight,
    highlightOverride,
  } = styleOptions;

  const directionStyles = {
    [styles.left]: textAlign === 'left',
    [styles.right]: textAlign === 'right',
    [styles.center]: textAlign === 'center',
  };

  const highlightingStyles = () => {
    if (CellHighlightOverride.REMOVED === highlightOverride) {
      return {
        [styles.remove]: true,
      };
    }
    return {
      [styles.add]: isAdd,
      [styles.update]: isUpdate,
      [styles.remove]: isRemove,
    }
  };

  const customColumnWidthStyles = {
    [styles.defaultColumnWidth]: true,
    [styles.adminColumnWidth]: isAdmin,
    [styles.customerColumnWidth]: !isAdmin,
    [styles[`${columnName}ColumnWidth`]]: styles[`${columnName}ColumnWidth`],
  };

  const selectionHighlightingStyles = {
    [styles.selected]: isSelected,
  };

  return {
    [styles.cellContainer]: true,
    [styles.sticky]: isSticky,
    [styles.active]: isActive,
    [styles.error]: isError,
    [styles.contentWrap]: isContentWrappingEnabled,
    [styles.mustFillHighlightCell]: mustFillHighlight,
    ...directionStyles,
    ...highlightingStyles(),
    ...customColumnWidthStyles,
    ...selectionHighlightingStyles,
  };
};

export const getContents = (children, inputComponent, isActive, isEditMode, showPercent) => {
  if (isActive && isEditMode) {
    const ActiveElement = inputComponent;

    return (
      <div className={styles.popOutEditCell}>
        <ActiveElement />
      </div>
    );
  }
  const derivedChildren = getDerivedChildren(children, showPercent);
  if (_.isNumber(derivedChildren)) {
    return derivedChildren;
  }
  return derivedChildren || NON_BREAKING_SPACE;
};

const GridViewCell = forwardRef(({
  children,
  rowId,
  columnName,
  isActive,
  isEditMode,
  link,
  onSelectCell,
  onEditCell,
  inputComponent,
  textAlign,
  errorText,
  isSticky,
  isReadOnly,
  isSelected,
  popOverDirection,
  comparedItem,
  showComparison,
  isPopOverEnabled,
  left,
  enableContextMenu,
  showPercent,
  isAdmin,
  isContentWrappingEnabled,
  applyMustFillHighlight,
  highlightOverride,
}, ref) => {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const cellRef = useRef();
  const onContextMenuInvoke = useCallback(() => {
    setContextMenuOpen(!contextMenuOpen);
  }, [contextMenuOpen]);

  const onClick = (e) => {
    if (!e || isPopOverWindow(e.target)) {
      return;
    }

    if (!isActive) {
      onSelectCell();
    }
  };

  const onDoubleClick = (e) => {
    onClick(e);
    onEditCell();
  };

  const onContextMenuClick = (e) => {
    e.preventDefault();
    onContextMenuInvoke();
  };

  const getLink = () => {
    if (!link) { return null; }
    return (
      <div className={styles.floatingLink}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon tokens={exportSmallTokens} />
        </Link>
      </div>
    );
  };

  const getPopOver = () => {
    const derivedChildren = getDerivedChildren(children, showPercent);
    if (!isPopOverEnabled || isActive || !derivedChildren) {
      return null;
    }

    return (
      <PopOver
        isOpen={isHovered}
        anchorNode={cellRef}
        placement={popOverDirection === PopOverDirection.DOWN ? 'bottom' : 'top'}
      >
        {derivedChildren}
      </PopOver>
    );
  };

  const getErrorPopup = () => {
    if (!errorText) {
      return null;
    }

    const className = popOverDirection === PopOverDirection.UP
      ? styles.bottomErrorText
      : styles.topErrorText;

    return (
      <div className={className}>
        {errorText}
      </div>
    );
  };

  const getErrorIcon = () => {
    if (!errorText) {
      return null;
    }

    return <div className={styles.errorIcon} />;
  };

  const getContextMenu = () => {
    if (!enableContextMenu || !contextMenuOpen) {
      return null;
    }

    return (
      <ContextMenu
        open={contextMenuOpen}
        anchorNode={cellRef}
        rowId={rowId}
        columnName={columnName}
        cellValue={getDerivedChildren(children, showPercent)}
        onClose={() => setContextMenuOpen(false)}
        allowApproveReject={showComparison}
        isReadOnly={isReadOnly}
      />
    );
  };

  const getCellProperties = () => {
    const cellProperties = {
      role: 'gridcell',
      className: classnames(
        getCellStyles(
          {
            isError: errorText,
            isAdd: showComparison && isEmpty(comparedItem) && !isEmpty(children),
            isRemove: showComparison && !isEmpty(comparedItem) && isEmpty(children),
            mustFillHighlight: applyMustFillHighlight,
            isUpdate: (showComparison
              && !isEmpty(comparedItem)
              && !isEmpty(children)
              && !_.isEqual(comparedItem, children)),
            isActive,
            isSticky,
            isSelected,
            isAdmin,
            textAlign,
            isContentWrappingEnabled,
            highlightOverride,
          },
          columnName,
        ),
      ),
      onClick,
      onDoubleClick,
      onContextMenu: onContextMenuClick,
      onMouseEnter: () => {
        setIsHovered(true);
      },
      onMouseLeave: () => {
        setIsHovered(false);
      },
    };
    if (left > 0) {
      cellProperties.style = {
        left: `${left}px`,
      };
    }
    if (ref) {
      cellProperties.ref = ref;
    }

    return cellProperties;
  };

  const cellProperties = getCellProperties();
  const contents = getContents(children, inputComponent, isActive, isEditMode, showPercent);
  const errorIcon = getErrorIcon();
  const popOver = getPopOver();
  const errorPopup = getErrorPopup();
  const contextMenu = getContextMenu();

  return (
    <>
      <td
        ref={cellRef}
        {...cellProperties}
      >
        <div
          className={styles.cell}
        >
          {contents}
          {errorIcon}
        </div>
        {popOver}
        {isHovered && errorPopup}
        {isHovered && !isActive && getLink()}
      </td>
      {contextMenu}
    </>
  );
});

GridViewCell.propTypes = {
  onSelectCell: PropTypes.func.isRequired,
  onEditCell: PropTypes.func.isRequired,
  inputComponent: PropTypes.elementType.isRequired,
  isPopOverEnabled: PropTypes.bool.isRequired,
  children: campaignInputProps,
  comparedItem: campaignInputProps,
  isActive: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  errorText: PropTypes.string,
  isSticky: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isContentWrappingEnabled: PropTypes.bool,
  applyMustFillHighlight: PropTypes.bool,
  enableContextMenu: PropTypes.bool,
  showPercent: PropTypes.bool,
  link: PropTypes.string,
  showComparison: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

GridViewCell.defaultProps = {
  errorText: '',
  isActive: false,
  textAlign: 'left',
  children: NON_BREAKING_SPACE,
  isSticky: false,
  link: null,
  comparedItem: null,
  showComparison: false,
  enableContextMenu: false,
  showPercent: false,
  isAdmin: false,
  isContentWrappingEnabled: false,
  applyMustFillHighlight: false,
};

export default GridViewCell;
