import React from 'react';
import { PageNames } from '../../../constants/metrics';
import WithHistoryTiming from '../../WithHistoryTiming';
import PublishedPromotions from '../../PublishedPromotions';

const PublishedWeeksPage = () => {
  return <PublishedPromotions />;
};

export default WithHistoryTiming(PublishedWeeksPage, { pageName: PageNames.PUBLISHED_PROMOTIONS });
