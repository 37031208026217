import { connect } from 'react-redux';
import PromoReviewList from './PromoReviewList';
import {
  onClearTranslatedAuditLogsForHighlight,
  onLoadPromotionsAuditLogForHighlight,
} from '../../actionCreators';
import { getWeekByStartEndDate } from '../../helpers/promotions';
import { isCurrentUserAdmin } from '../../selectors/user';
import {
  getPromotionsToImageReview,
  getPromotionsToReview,
} from '../../selectors/promotionFiltersSelectors';
import { ImageReviewColumnOrder } from '../../constants';
import { getFormattedDateTime } from '../../helpers/dateTime';

const getFormattedWeekName = (state) => (promotion) => {
  if (!promotion) {
    return '';
  }
  const { startDate, endDate, yearQuarter } = promotion;

  const formattedStartDate = getFormattedDateTime(startDate, { pattern: 'MMM Do' });
  const formattedEndDate = getFormattedDateTime(endDate, { pattern: 'MMM Do' });

  const promotionWeek = getWeekByStartEndDate(
    state,
    promotion,
    { yearQuarter },
  );
  const promotionWeekName = promotionWeek && promotionWeek.name
    ? promotionWeek.name
    : '';

  return `${promotionWeekName} (${formattedStartDate} - ${formattedEndDate})`;
};

const mapStateToProps = (state, { isImageReviewMode = false }) => {
  const {
    GridViewPage: { translatedLogsForHighlight },
    Meta: { metadata: { columnOrder: defaultColumnOrder } },
  } = state;

  const promotionsToReview = isImageReviewMode
    ? getPromotionsToImageReview(state)
    : getPromotionsToReview(state);
  const columnOrder = isImageReviewMode ? ImageReviewColumnOrder: defaultColumnOrder;
  const columnsToCompare = columnOrder.map((column) => column.name);

  return {
    promotionsToReview,
    columnOrder,
    columnsToCompare,
    translatedLogsForHighlight,
    isAdmin: isCurrentUserAdmin(state),
    getFormattedWeekName: getFormattedWeekName(state),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onLoadAuditLogs(promotionsForAuditLog) {
    dispatch(onLoadPromotionsAuditLogForHighlight(promotionsForAuditLog));
  },
  onClearAuditLogs() {
    dispatch(onClearTranslatedAuditLogsForHighlight());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoReviewList);
