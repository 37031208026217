import React from 'react';
import PropTypes from 'prop-types';
import PromoReviewMessage from '../PromoReviewMessage';
import PromoReviewList from '../PromoReviewList';
import { getTemplateString } from '../../helpers/common';
import { Language } from '../../constants';

const PromoReviewContainer = ({
  isAdmin,
  isEditReviewPromotionTableOpen,
  isImageReviewPromotionTableOpen,
  onCloseImageReviewTable,
  onCloseReviewTable,
  onOpenImageReviewTable,
  onOpenReviewTable,
  promotionsToImageReviewCount,
  promotionsToReviewCount,
}) => {
  const getPromoReviewHeader = () => {
    if (!isAdmin || !promotionsToReviewCount) {
      return null;
    }
    const promoReviewMessageTitle = getTemplateString(
      Language.PROMOTION_REVIEW_REQUIRED_MESSAGE_TITLE,
      { promotionsCount: promotionsToReviewCount }
    );

    return (
      <PromoReviewMessage
        isReviewPromotionTableOpen={isEditReviewPromotionTableOpen}
        onOpenReviewTable={onOpenReviewTable}
        onCloseReviewTable={onCloseReviewTable}
        reviewMessage={promoReviewMessageTitle}
      />
    );
  };

  const getPromoReviewTable = () => {
    if (!isEditReviewPromotionTableOpen || !isAdmin) {
      return null;
    }

    return (
      <PromoReviewList />
    );
  };

  const getPromoImageReviewHeader = () => {
    if (!isAdmin || !promotionsToImageReviewCount) {
      return null;
    }
    const promoReviewMessageTitle = getTemplateString(
      Language.PROMOTION_IMAGE_REVIEW_REQUIRED_MESSAGE_TITLE,
      { promotionsCount: promotionsToImageReviewCount }
    );

    return (
      <PromoReviewMessage
        isReviewPromotionTableOpen={isImageReviewPromotionTableOpen}
        onOpenReviewTable={onOpenImageReviewTable}
        onCloseReviewTable={onCloseImageReviewTable}
        reviewMessage={promoReviewMessageTitle}
      />
    );
  };

  const getPromoImageReviewTable = () => {
    if (!isImageReviewPromotionTableOpen || !isAdmin) {
      return null;
    }

    return (
      <PromoReviewList isImageReviewMode />
    );
  };

  return (
    <div>
      {getPromoReviewHeader()}
      {getPromoReviewTable()}
      {getPromoImageReviewHeader()}
      {getPromoImageReviewTable()}
    </div>
  );
};

PromoReviewContainer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default PromoReviewContainer;
