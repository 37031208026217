import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import BannerForm from './BannerForm';
import { Form } from '../../constants';
import {
  onSaveBanners,
} from '../../actionCreators/banner';
import {
  validateBannerInputs,
} from '../../helpers/formValidators';

export const mapStateToProps = (state) => {
  const { Banner: { banners } } = state;

  const initialValues = banners && banners.length
    ? { banners }
    : { banners: [] };

  return {
    initialValues,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onSave: ({ banners }) => {
    dispatch(onSaveBanners(banners));
  },
});

const reduxFormConfig = {
  form: Form.BANNER,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate: validateBannerInputs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(reduxFormConfig)(BannerForm));
