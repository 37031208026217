import React from 'react';
import WebsiteContainer from '../WebsiteContainer';
import {
  isPromotionCloneRoute,
  isPromotionEditRoute,
  isWeekNewRoute,
  isWeekEditRoute,
  isWeekPublishedRoute,
  isCalendarRoute,
  isPromotionWeekRoute,
  isPublishedPromotionsRoute,
} from '../../helpers/router';
import EditPromotionPage from './modals/edit-promotion';
import NewWeekPage from './modals/new-week';
import EditWeekPage from './modals/edit-week';
import PublishedWeekPage from './modals/published-week';
import CalendarPage from './website-container-tabs/calendar';
import PublishedWeeksPage from './website-container-tabs/published-weeks';
import PromotionWeekPage from './website-container-tabs/promotion-week';

const getModalPage = (props) => {
  const { match: { path } } = props;

  if (isPromotionEditRoute(path)) {
    return <EditPromotionPage {...props} />;
  }

  if (isPromotionCloneRoute(path)) {
    return <EditPromotionPage {...props} isClone />;
  }

  if (isWeekNewRoute(path)) {
    return <NewWeekPage {...props} />;
  }

  if (isWeekEditRoute(path)) {
    return <EditWeekPage {...props} />;
  }

  if (isWeekPublishedRoute(path)) {
    return <PublishedWeekPage {...props} />;
  }

  return null;
};

const getWebsiteContainerPage = (props) => {
  const { match: { path } } = props;
  if (isCalendarRoute(path)) {
    return <CalendarPage />;
  }
  if (isPromotionWeekRoute(path)) {
    return <PromotionWeekPage />;
  }
  if (isPublishedPromotionsRoute(path)) {
    return <PublishedWeeksPage />;
  }
  return <PromotionWeekPage />;
};

const WebsiteContainerPage = (props) => {
  const modalPage = getModalPage(props);
  const websiteContainerPage = getWebsiteContainerPage(props);
  return (
    <>
      {modalPage}
      <WebsiteContainer {...props}>
        {websiteContainerPage}
      </WebsiteContainer>
    </>
  );
};

export default WebsiteContainerPage;
