import { connect } from 'react-redux';
import GridViewHeaderCell from './GridViewHeaderCell';
import { onAddSearchColumn } from '../../actionCreators';
import { isCurrentUserAdmin } from '../../selectors/user';

export const mapDispatchToProps = (dispatch, { name }) => {
  return {
    onSearch: (search) => {
      dispatch(onAddSearchColumn(search, name));
    },
  };
};

export const mapStateToProps = (state, { name }) => {
  const {
    GridViewPage: { searchTerms },
  } = state;

  return {
    existingSearchTerm: searchTerms[name],
    isAdmin: isCurrentUserAdmin(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GridViewHeaderCell);
