import { connect } from 'react-redux';
import PromoReviewMessage from './PromoReviewMessage';

export const mapStateToProps = (state) => {
  const { GridViewPage: { isEditReviewPromotionTableOpen, isImageReviewPromotionTableOpen } } = state;

  return {
    isEditReviewPromotionTableOpen,
    isImageReviewPromotionTableOpen,
  };
};

export default connect(mapStateToProps)(PromoReviewMessage);
