import processBulkData from './processBulkData';
import { Columns } from '../../../constants';
import {
  onBulkImportCompleteFileParsing,
  onBulkImportSetPendingPromotions,
} from '../../../actionCreators/bulkImport';
import {
  getAllowedPageZoneCombinationsForCurrentWeek,
  getPromotionsForCurrentWeek,
} from '../../../selectors/promotionFiltersSelectors';
import { getCurrentPromotionWeek } from '../../../selectors/promotions';
import CSVToArray from '../../../helpers/csvToArray';
import { getDelimeterSeparatedStrFromArrayWithoutSpaces } from '../../../helpers/bulkImport';

const getExistingPromotionsWithCompositeKey = (state, keyColumnList) => {
  if (!keyColumnList || !keyColumnList.length) {
    return [];
  }
  const promotions = getPromotionsForCurrentWeek(state);

  const list = {};
  promotions.forEach((promotion) => {
    const compositeKey = keyColumnList.reduce((result, key) => {
      result.push(promotion[key]);

      return result;
    }, []);

    list[getDelimeterSeparatedStrFromArrayWithoutSpaces(compositeKey)] = promotion;
  });

  return list;
};

const processBulkImportData = (dispatch, state, action, columnOrder) => {
  const { payload: { fileData } } = action;

  const parsedRows = CSVToArray(fileData);
  const existingPromotions = getExistingPromotionsWithCompositeKey(state, [
    Columns.PAGE.name,
    Columns.ZONE_NUMBER.name,
    Columns.STORE_REGIONS.name,
  ]);
  const promotionWeek = getCurrentPromotionWeek(state);
  const allowedPageZoneCombinations = getAllowedPageZoneCombinationsForCurrentWeek(state);
  const processedPromotions = processBulkData(
    state,
    parsedRows,
    columnOrder,
    existingPromotions,
    promotionWeek,
    allowedPageZoneCombinations,
  );

  dispatch(onBulkImportSetPendingPromotions(processedPromotions));
  dispatch(onBulkImportCompleteFileParsing());
};

export default processBulkImportData;
