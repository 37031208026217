const updateCellValue = (state, rowId, columnName, newValue) => {
  const { source } = state;

  if (!rowId || !columnName) { return state; }
  const newState = {
    ...state,
    [source]: state[source].map((elem) => {
      if (elem.id !== rowId) { return elem; }
      return { ...elem, [columnName]: newValue };
    }),
  };
  return newState;
};

export default updateCellValue;
