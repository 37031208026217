/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePromotion = /* GraphQL */ `
  subscription OnCreatePromotion {
    onCreatePromotion {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      notes
      page
      pawsId
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const onUpdatePromotion = /* GraphQL */ `
  subscription OnUpdatePromotion {
    onUpdatePromotion {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      notes
      page
      pawsId
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const onDeletePromotion = /* GraphQL */ `
  subscription OnDeletePromotion {
    onDeletePromotion {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      notes
      page
      pawsId
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const onApprovePromotionChange = /* GraphQL */ `
  subscription OnApprovePromotionChange {
    onApprovePromotionChange {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      notes
      page
      pawsId
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const onRejectPromotionChange = /* GraphQL */ `
  subscription OnRejectPromotionChange {
    onRejectPromotionChange {
      asins
      asinImage
      auditedStoreRegions
      circularPrice
      circularPriceDisplay
      description
      discountTag
      eachPriceForF3RS
      id
      imageStatus
      isAplenty
      isFreshBrand
      isCategoryHero
      isCoverPageHero
      isLocal
      isPrimeBenefit
      isPrivateBrand
      isUSDAChoice
      isUSDAOrganic
      isSoftDeleted
      isLocked
      notes
      page
      pawsId
      picturedAsins
      priceConditions
      primeMemberLabel
      primeMemberLabelDisplay
      primeDiscount
      productCategory
      promotionEndDate
      promotionType
      status
      storeIds
      storeRegions
      title
      unitOfMeasure
      version
      vertical
      vendorManager
      yearQuarter_startDate_endDate
      zoneNumber
    }
  }
`;
export const onCreatePromotionWeek = /* GraphQL */ `
  subscription OnCreatePromotionWeek {
    onCreatePromotionWeek {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const onUpdatePromotionWeek = /* GraphQL */ `
  subscription OnUpdatePromotionWeek {
    onUpdatePromotionWeek {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
export const onDeletePromotionWeek = /* GraphQL */ `
  subscription OnDeletePromotionWeek {
    onDeletePromotionWeek {
      name
      startDate_endDate
      editDeadline
      printDeadline
      reviewDeadline
      templateId
      templateName
      template
      version
      yearQuarter
    }
  }
`;
