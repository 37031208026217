import { connect } from 'react-redux';

import { onSetUser, onSetUserUnableToSignIn } from '../../actionCreators';

import CognitoAuth from './CognitoAuth';

export const mapStateToProps = (state) => {
  const { User: { currentUser, cannotAuthenticate } } = state;

  return {
    user: currentUser,
    cannotAuthenticate,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetUser(user) { dispatch(onSetUser(user)); },
    onSetUserUnableToSignIn() { dispatch(onSetUserUnableToSignIn()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CognitoAuth);
