import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '@amzn/meridian/heading';
import readFile from '../../helpers/readFile';
import BulkImportPreview from './BulkImportPreview';
import styles from './BulkImporter.module.scss';

const BulkImportPage = (props) => {
  const {
    isProcessed,
    onProcessFile,
  } = props;

  const [isDragOver, setIsDragOver] = useState(false);

  const handleFile = (file) => {
    const processFile = (fileContents) => onProcessFile(fileContents);

    readFile(file, processFile);
  };

  const handleDrop = (e) => {
    setIsDragOver(false);
    e.preventDefault();
    e.stopPropagation();

    const { dataTransfer: { files } } = e;
    if (files && files.length) {
      handleFile(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  if (isProcessed) {
    return (
      <BulkImportPreview />
    );
  }
  const classes = classnames({
    [styles.dragArea]: true,
    [styles.dragOver]: isDragOver,
  });

  const onInputFileChange = (evt) => {
    handleFile(evt.target.files[0]);
  };

  return (
    <div
      className={classes}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      data-testid="inner"
    >
      <Heading level={3}>Bulk Upload</Heading>
      <p>
        Drag and drop .csv files into this box to perform a bulk upload.
        You will see a preview page before upload happens.
      </p>
      <input type="file" onChange={onInputFileChange} />
    </div>
  );
};

BulkImportPage.propTypes = {
  onProcessFile: PropTypes.func.isRequired,
  isProcessed: PropTypes.bool.isRequired,
};

export default BulkImportPage;
