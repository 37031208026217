import { useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

export default (graphqlMethod, onNext, onError = (error) => console.error(error)) => {
  useEffect(() => {
    const subscription = API
      .graphql(
        graphqlOperation(graphqlMethod),
      ).subscribe({
        next: ({ value: { data } }) => {
          const methodName = Object.keys(data)[0];

          return onNext(data[methodName]);
        },
        error: (error) => onError(error),
      });

    return () => subscription.unsubscribe();
  }, [graphqlMethod, onNext, onError]);
};
