import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {
  onFetchPromotionWeeks,
  onSetWeeksForYearQuarter,
  onOpenToast,
} from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotionWeek';

export default (dispatch, getState, action, next) => {
  if (!action.payload) {
    return next(action);
  }

  const { payload: { yearQuarters, loadPromotions } } = action;

  const onResponse = ({ data }, yearQuarter) => {
    if (!data || !data.getPromotionWeeksForYearQuarter) {
      dispatch(onOpenToast('No promotions week have been create yet for the selected quarter'));
      throw new Error(`API returned no data for getPromotionWeeksForYearQuarter (${yearQuarter})`);
    }
    const { getPromotionWeeksForYearQuarter } = data;
    const weeksList = [];
    getPromotionWeeksForYearQuarter.forEach((promotionWeek) => {
      const translatedPromotionWeek = appsyncToLocal(promotionWeek);
      const { startDate, endDate } = translatedPromotionWeek;

      if (loadPromotions) {
        const yearQuarter_startDate_endDate = `${yearQuarter}_${startDate}_${endDate}`;
        dispatch(onFetchPromotionWeeks(yearQuarter_startDate_endDate));
      }

      weeksList.push(translatedPromotionWeek);
    });
    return weeksList;
  };

  const graphqlRequests = yearQuarters.map((yearQuarter) => API.graphql(
    graphqlOperation(queries.getPromotionWeeksForYearQuarter, { yearQuarter }),
  ));

  Promise.all(graphqlRequests).then((responses) => {
    const weeksByYearQuarter = {};
    responses.forEach((response, i) => {
      const currentYearQuarter = yearQuarters[i];
      weeksByYearQuarter[currentYearQuarter] = onResponse(response, currentYearQuarter);
    });
    Object.keys(weeksByYearQuarter).forEach((yearQuarter) => {
      dispatch(onSetWeeksForYearQuarter(yearQuarter, weeksByYearQuarter[yearQuarter]));
    });
  }).catch((error) => console.error(error));

  return next(action);
};
