import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { Columns } from '../../constants';
import { formatPriceForDisplay } from '../../helpers/promotions';
import { onCellInputUpdate } from '../../actionCreators';

export default (store, next, action) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { GridViewPage } = state;
  const { activeCell } = GridViewPage;

  if (!activeCell) {
    return next(action);
  }

  const { columnName, rowId } = activeCell;
  const promotion = getPromotionById(GridViewPage, rowId);
  if (!promotion) {
    return next(action);
  }

  if([
    Columns.CIRCULAR_PRICE.name,
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRICE_CONDITIONS.name,
    Columns.PRIME_MEMBER_LABEL.name,
    Columns.PROMOTION_TYPE.name,
    Columns.UNIT_OF_MEASURE.name
  ].includes(columnName)) {

    const promotionWithUpdatedActiveCellValue = {
      ...promotion,
      [columnName]: action.data
    };

    const { id, circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure } = promotionWithUpdatedActiveCellValue;

    const {
      circularPriceDisplay,
      primeMemberLabelDisplay
    } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);

    dispatch(onCellInputUpdate(id, Columns.CIRCULAR_PRICE_DISPLAY.name, circularPriceDisplay));
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL_DISPLAY.name, primeMemberLabelDisplay));
  }
  return next(action);
}