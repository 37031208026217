export const getTemplateById = (state, id) => {
  if (!state || !state.Templates) {
    return null;
  }

  const { Templates: { templates } } = state;
  if (!templates || !Array.isArray(templates)) {
    return null;
  }

  return templates.find((template) => template.id === id);
};
