import { connect } from 'react-redux';
import { onCloseAddRowModal } from '../../actionCreators';
import AddRowModal from './AddRowModal';
import { getWeekByStartEndDate, sortByPageZoneNumber } from '../../helpers/promotions';
import { getTemplateById } from '../../helpers/templates';

const mapStateToProps = (state) => {
  const {
    Promo: {
      dates: [startDate, endDate],
    },
    GridViewPage: { isAddRowModalOpen },
  } = state;

  const { name, templateId } = getWeekByStartEndDate(state, { startDate, endDate }) ?? {};
  const { promoConfiguration } = getTemplateById(state, templateId) ?? {};
  const templateRows = promoConfiguration ? promoConfiguration.rows.sort(sortByPageZoneNumber) : [];

  return {
    isOpen: isAddRowModalOpen,
    weekName: name,
    templateRows,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(onCloseAddRowModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRowModal);
