export const getOptions = (options) => {
  if (Array.isArray(options)) {
    return options;
  }
  const childOptions = Object.keys(options).map((key) => {
    return getOptions(options[key]);
  });
  return [].concat(...childOptions);
};

export const removeDuplicates = (arr) => {
  const entries = {};
  arr.forEach((key) => {
    entries[key] = true;
  });
  return Object.keys(entries);
};

const getAllMetadataOptionsForColumn = (state, column) => {
  const { Meta: { metadata: { fields } } } = state;
  if (!fields || !fields[column] || !fields[column].options) {
    return [];
  }
  const unfilteredOptions = getOptions(fields[column].options);
  const unsortedOptions = removeDuplicates(unfilteredOptions);
  const sortedOptions = unsortedOptions.sort();
  return sortedOptions;
};

export default getAllMetadataOptionsForColumn;
