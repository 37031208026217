// TODO: Unify these controls with the ones in helpers/formRenders.js
import AsinInput from './AsinInput';
import AsinTagInput from './AsinTagInput';
import Checkbox from './Checkbox';
import DateTime from './DateTime';
import Input from './Input';
import MultiSelect from './MultiSelect';
import NumberInput from './NumberInput';
import SingleSelect from './SingleSelect';
import TagInput from './TagInput';
import TextAreaInput from './TextAreaInput';
import TimeInput from './TimeInput';
import TreeSelect from './TreeSelect';
import Uneditable from './Uneditable';

export default {
  AsinInput,
  AsinTagInput,
  Checkbox,
  DateTime,
  Input,
  MultiSelect,
  NumberInput,
  SingleSelect,
  TagInput,
  TextAreaInput,
  TimeInput,
  TreeSelect,
  Uneditable,
};
