import React from 'react';
import Modal from '@amzn/meridian/modal';
import PublishedPromotionList from '../../../PublishedPromotionList';
import WithHistoryTiming from '../../../WithHistoryTiming';
import { ModalNames } from '../../../../constants/metrics';

const PublishedWeekPage = (props) => {
  const {
    promotions,
  } = props;

  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={() => window.history.back()}
    >
      <PublishedPromotionList promotions={promotions} />
    </Modal>
  );
};

export default WithHistoryTiming(PublishedWeekPage, { pageName: ModalNames.PUBLISHED_PROMOTION_WEEK });
