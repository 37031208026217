import {
  GridViewActions,
} from '../../constants';
import moveActiveCell from './moveActiveCell';
import loadPromotionsAuditLogsForHighlight from './loadPromotionsAuditLogsForHighlight';
import handleUndo from './handleUndo';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const { type } = action;

  if (!type) {
    return next(action);
  }

  if (type === GridViewActions.MOVE_ACTIVE_CELL) {
    moveActiveCell(store, action.data.direction);
  }
  if (type === GridViewActions.LOAD_PROMOTIONS_AUDIT_LOG_FOR_HIGHLIGHT) {
    return loadPromotionsAuditLogsForHighlight(dispatch, getState, action, next);
  }
  if (type === GridViewActions.PRESS_UNDO) {
    return handleUndo(dispatch, getState, action, next);
  }

  return next(action);
};
