const updateOption = (option) => {
  if (!option || !option.children) {
    return;
  }
  option.checked = option.children.every((child) => child.checked);
};

export const updateOptions = (options, isChecked, value) => {
  for (let i = 0; i < options.length; i++) {
    const hasChildren = options[i].children && options[i].children.length > 0;

    if (!value) {
      options[i].checked = isChecked;
      if (hasChildren) {
        updateOptions(options[i].children, isChecked);
      }
    } else if (options[i].value === value) {
      options[i].checked = isChecked;

      if (hasChildren) {
        updateOptions(options[i].children, isChecked);
      }
    } else if (hasChildren) {
      updateOptions(options[i].children, isChecked, value);
      updateOption(options[i]);
    }
  }

  return options;
};

export const translateDataToControl = (options, selectedOptions = []) => {
  const translatedOptions = [...options];
  for (let i = 0; i < translatedOptions.length; i++) {
    const currentOption = translatedOptions[i];

    const hasChildren = currentOption.children && currentOption.children.length > 0;
    if (hasChildren) {
      translateDataToControl(currentOption.children, selectedOptions);
      updateOption(currentOption);
    } else {
      currentOption.checked = selectedOptions.includes(currentOption.value);
    }
  }

  return translatedOptions;
};

export const translateDataFromControl = (options) => {
  if (!options || !options.length) {
    return [];
  }

  const translatedOptions = [];
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i];
    if (currentOption.children) {
      translatedOptions.push(...translateDataFromControl(currentOption.children));
    } else if (currentOption.checked) {
      translatedOptions.push(currentOption.value);
    }
  }

  return translatedOptions;
};

export const formatStoreRegionInputData = (options) => {
  const optionsGroupedByState = {};
  options.forEach((option) => {
    const stateName = option.value.slice(0, -1);
    if (!optionsGroupedByState[stateName]) {
      optionsGroupedByState[stateName] = [];
    }
    optionsGroupedByState[stateName].push(option);
  });

  const newOptions = [];
  Object.entries(optionsGroupedByState).forEach(([stateName, stateParts]) => {
    newOptions.push({
      label: stateName,
      value: stateName,
      checked: false,
      expanded: true,
      children: stateParts,
    });
  });

  return newOptions;
};

export const isAllOptionsChecked = (optionsList) => {
  for (let i = 0; i < optionsList.length; i++) {
    if (!optionsList[i].checked) {
      return false;
    }
    if (optionsList[i].children && !isAllOptionsChecked(optionsList[i].children)) {
      return false;
    }
  }

  return true;
};
