import {
  onBulkImportSetSucceededPromotions,
  onBulkImportSetFailedPromotions,
  onBulkImportCompleteImporting,
  onBulkImportReset,
  onCloseBulkImportModal,
} from '../../actionCreators/bulkImport';
import {
  onOpenToast,
} from '../../actionCreators';
import { BulkImportActions } from '../../constants/bulkImport';

export default (dispatch, getState, action) => {
  const { payload: { id, responseType } } = action;
  if (responseType === BulkImportActions.SUCCESS) {
    dispatch(onBulkImportSetSucceededPromotions(id));
  } else {
    dispatch(onBulkImportSetFailedPromotions(id));
  }
  const state = getState();
  const { BulkImport: { failedPromotions, succeededPromotions, expectedPromotionCount } } = state;
  const completedEntitiesCount = failedPromotions.length + succeededPromotions.length;

  if (completedEntitiesCount >= expectedPromotionCount) {
    const message = (
      `${succeededPromotions.length} promotions successful. ${failedPromotions.length} promotions failed.`
    );
    dispatch(onOpenToast(message, 0));
    dispatch(onBulkImportCompleteImporting());
    dispatch(onBulkImportReset());
    dispatch(onCloseBulkImportModal());
  }
};
