import {
  onClearAsinValidatorRegionFilters,
  onInitAsinValidatorRegionFilters,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { AsinValidator: { asinViabilities } } = getState();

  const [firstAsin] = asinViabilities;
  const storeRegionList = [];
  
  firstAsin.viability.forEach(({ storeRegions }) => {
    storeRegions.forEach((storeRegion) => {
      storeRegionList.push(storeRegion);
    });
  });

  dispatch(onClearAsinValidatorRegionFilters());
  dispatch(onInitAsinValidatorRegionFilters(storeRegionList))

  return next(action);
};
