import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import checkCircleFilledTokens from '@amzn/meridian-tokens/base/icon/check-circle-filled';
import closeKnockoutTokens from '@amzn/meridian-tokens/base/icon/close-knockout';
import Icon from '@amzn/meridian/icon';
import RadioButton from '@amzn/meridian/radio-button';
import Checkbox from '@amzn/meridian/checkbox';
import Alert from '@amzn/meridian/alert';
import { AsinValidatorViewMode, COMMA_DELIMETER } from '../../../constants';
import { formatToPercentage, getPercentage } from '../../../helpers/common';
import styles from './AsinValidatorTable.module.scss';
import StoreCardList from '../../StoreCardList';

const sortByStoreRegionNameAsc = (a, b) => a.storeRegionName.localeCompare(b.storeRegionName);

const AsinValidatorTable = (props) => {
  const {
    asinViabilities,
    viewMode,
    selectedRegions,
    onRegionCheck,
    onSetAsinViabilities,
  } = props;

  const [notificationMessage, setNotificationMessage] = useState(null);

  const onRegionChange = (asin, region) => () => onRegionCheck(asin, region);

  const onFeaturedAsinChange = (selectedAsin) => () => {
    const newAsinViabilities = asinViabilities.filter(({ asin }) => asin !== selectedAsin);
    const featuredAsinViability = asinViabilities.find(({ asin }) => asin === selectedAsin);
    newAsinViabilities.unshift(featuredAsinViability);
    onSetAsinViabilities(newAsinViabilities);
    setNotificationMessage(`${featuredAsinViability.asin} has been configured as the featured asin`);
  };

  const isFullViewMode = () => viewMode === AsinValidatorViewMode.FULL.name;

  const getStatusIcon = (isAvailable) => {
    const iconType = isAvailable
      ? checkCircleFilledTokens
      : closeKnockoutTokens;

    return <Icon tokens={iconType} />;
  };

  const getHeaders = (asin, isFeatured) => {
    return [
      <TableRow key={asin}>
        <TableCell columnSpan={4}>
          <div>
            <RadioButton
              key={asin}
              name="featuredAsin"
              value={asin}
              checked={isFeatured}
              onChange={onFeaturedAsinChange(asin)}
            >
              <h2>{asin}</h2>
            </RadioButton>
          </div>
        </TableCell>
      </TableRow>,
      <TableRow key={`headers-row-${asin}`}>
        <TableCell>Region</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Available Stores</TableCell>
        <TableCell>Unavailable Stores</TableCell>
      </TableRow>,
    ];
  };

  const getStoresList = (stores, isAvailable = true) => (isFullViewMode()
    ? <StoreCardList storeList={stores} isAvailable={isAvailable} />
    : stores.map((store) => store.store_id).join(`${COMMA_DELIMETER} `));

  const getStatus = (
    acceptablePercentage,
    availableStores,
    unavailableStores,
    isAvailable,
  ) => {
    const totalStores = availableStores + unavailableStores;
    const currentPercentage = getPercentage(availableStores, totalStores);
    const formattedAcceptablePercentage = formatToPercentage(acceptablePercentage);

    const statusIcon = getStatusIcon(isAvailable);
    const extraStatusInfo = isFullViewMode()
      ? (
        <p className={styles.extraStatusInfoText}>
          {currentPercentage}%/{formattedAcceptablePercentage}% ({availableStores}/{totalStores})
        </p>
      )
      : null;

    return (
      <div className={classnames({
        [styles.statusContainer]: true,
        [styles.statusContainerAvailable]: isAvailable,
        [styles.statusContainerUnavailable]: !isAvailable,
      })}
      >
        {statusIcon}
        {extraStatusInfo}
      </div>
    );
  };

  const createStoreRegionRows = (asin, regionName, storeRegions, rows, statusBlock, isFeatured) => {
    return storeRegions
      .sort(sortByStoreRegionNameAsc)
      .forEach((storeRegion) => {
        const {
          storeRegionName,
          availableAsinViabilities,
          unavailableAsinViabilities,
        } = storeRegion;

        const availableStoresList = getStoresList(availableAsinViabilities);
        const unavailableStoresList = getStoresList(unavailableAsinViabilities, false);
        const isChecked = selectedRegions[asin] ? selectedRegions[asin].includes(storeRegionName) : false;

        rows.push(
          <TableRow
            key={storeRegionName}
            highlightOnHover
          >
            <TableCell>
              <Checkbox
                checked={isChecked}
                disabled={!isFeatured}
                onChange={onRegionChange(asin, storeRegionName)}
              >
                {storeRegionName}
              </Checkbox>
            </TableCell>
            <TableCell>{statusBlock}</TableCell>
            <TableCell>{availableStoresList}</TableCell>
            <TableCell>{unavailableStoresList}</TableCell>
          </TableRow>,
        );
      });
  };

  const createRegionRows = (asin, viability, rows, isFeatured) => {
    return viability.forEach((regionViability) => {
      const {
        acceptableAvailability,
        availableStores,
        unavailableStores,
        isAvailable,
        regionName,
        storeRegions,
      } = regionViability;

      const statusBlock = getStatus(
        acceptableAvailability,
        availableStores,
        unavailableStores,
        isAvailable,
      );

      rows.push(
        <TableRow
          key={`${asin}/${regionName}`}
        >
          <TableCell>
            <h3>{regionName}</h3>
          </TableCell>
          <TableCell>
            {statusBlock}
          </TableCell>
          <TableCell columnSpan={2}>
            &nbsp;
          </TableCell>
        </TableRow>,
      );

      createStoreRegionRows(
        asin,
        regionName,
        storeRegions,
        rows,
        statusBlock,
        isFeatured,
      );
    });
  };

  const getTable = () => {
    const tables = [];
    const { asin: featuredAsin } = asinViabilities[0];

    asinViabilities.forEach(({ asin, viability }) => {
      const rows = [];
      const isFeatured = asin === featuredAsin;

      createRegionRows(asin, viability, rows, isFeatured);

      const headers = getHeaders(asin, isFeatured);

      tables.push((
        <Table
          key={`table-${asin}`}
          spacing="small"
          showStripes
          showDividers
        >
          {headers}
          {rows}
        </Table>
      ));
    });

    return tables;
  };

  const getNotification = () => {
    if (!notificationMessage) {
      return null;
    }

    return (
      <Alert
        size="large"
        type="warning"
        onClose={() => setNotificationMessage(null)}
      >
        {notificationMessage}
      </Alert>
    );
  };

  const notification = getNotification();
  const table = getTable();

  return (
    <>
      {notification}
      {table}
    </>
  );
};

AsinValidatorTable.propTypes = {
  viewMode: PropTypes.string.isRequired,
  onRegionCheck: PropTypes.func.isRequired,
  onSetAsinViabilities: PropTypes.func.isRequired,
};

export default AsinValidatorTable;
