import { API, graphqlOperation } from 'aws-amplify';
import { onSetTranslatedAuditLogsForHighlight } from '../../actionCreators';
import * as queries from '../../graphql/queries';
import {
  translateAuditLogsForHighlight,
} from '../../helpers/highlightingAuditLog';

export default (dispatch, getState, action, next) => {
  const { payload: { promotions } } = action;

  if (!promotions || !promotions.length) {
    return next(action);
  }

  const getAuditLogsForPromotions = () => {
    return promotions.map(({ id }) => {
      return API.graphql(graphqlOperation(queries.getPromotionAuditLog, { id }));
    });
  };

  const setTranslatedAuditLogsForHighlight = (translatedLogsForHighlight) => {
    if (!Object.keys(translatedLogsForHighlight).length) {
      return;
    }
    dispatch(onSetTranslatedAuditLogsForHighlight(translatedLogsForHighlight));
  };

  Promise
    .all(getAuditLogsForPromotions())
    .then(translateAuditLogsForHighlight)
    .then(setTranslatedAuditLogsForHighlight)
    .catch((e) => console.error(e));

  return next(action);
};
