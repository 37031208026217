import { connect } from 'react-redux';
import SideMenu from './SideMenu';
import {
  toggleSideMenu,
} from '../../actionCreators';
import {
  ExternalUrls,
  Urls,
} from '../../constants';
import { openLinkInNewTab } from '../../helpers/common';
import { isCurrentUserAdmin } from '../../selectors/user';

const getLinksList = (isAdmin) => {
  return [
    {
      name: 'Templates Builder',
      route: Urls.TEMPLATES,
      shouldShow: isAdmin,
    },
    {
      name: 'Metadata tool',
      route: Urls.METADATA_EDITOR,
      shouldShow: isAdmin,
    },
    {
      name: 'Report an Issue',
      route: ExternalUrls.REPORT_AN_ISSUE,
      shouldShow: true,
      isExternal: true,
    },
    {
      name: 'Request a Feature',
      route: ExternalUrls.REQUEST_A_FEATURE,
      shouldShow: true,
      isExternal: true,
    },
  ].filter(({ shouldShow }) => shouldShow);
};

const mapStateToProps = (state) => {
  const {
    router: {
      location: {
        pathname: currentRoute,
      },
    },
    Sitewide: {
      isSideMenuOpen,
    },
  } = state;

  return {
    isSideMenuOpen,
    currentRoute,
    linksList: getLinksList(isCurrentUserAdmin(state)),
    openLinkInNewTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onToggleSideMenu() {
      dispatch(toggleSideMenu());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
