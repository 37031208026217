import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from '@amzn/meridian/modal';
import Heading from '@amzn/meridian/heading';
import GridView from '../GridView';
import AddRowActionBar from '../Promotions/AddRowActionBar';
import { TemplateBuilderColumnsOrder } from '../../constants';
import styles from './AddRowModal.module.scss'

const AddRowModal = ({
  isOpen,
  onClose,
  weekName,
  templateRows,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Heading level={4} className={styles.headingText}>
        {`Add new row to ${weekName}`}
      </Heading>
      <div className={styles.contentContainer}>
        <GridView
          promotions={templateRows}
          columnOrder={TemplateBuilderColumnsOrder}
          customActionBar={AddRowActionBar}
          isStickyHeader
        />
      </div>
    </Modal>
  );
};

AddRowModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  weekName: PropTypes.string,
};
AddRowModal.defaultProps = {
  isOpen: false,
  onClose: _.noop,
  weekName: '',
};

export default AddRowModal;
