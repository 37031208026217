import { getCurrentPromotionWeek } from '../../selectors/promotions';
import { onAddPromotion } from '../../actionCreators';
import { Columns, PromotionStatuses } from '../../constants';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { yearQuarter, startDate, endDate } = getCurrentPromotionWeek(state);
  const { promotion } = action.payload;
  const newPromotion = {
    ...promotion,
    yearQuarter_startDate_endDate: `${yearQuarter}_${startDate}_${endDate}`,
    [Columns.STATUS.name]: PromotionStatuses.UNLOCKED.value,
  };
  dispatch(onAddPromotion(newPromotion, { isAddRowAction: true }));
  return next(action);
};
