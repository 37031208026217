import React from 'react';
import TreeSelect from '../TreeSelect';
import BadgeList from '../../../BadgeList';
import styles from './BadgeTreeSelect.module.scss';
import { Language } from '../../../../constants';

const BadgeTreeSelect = (props) => {
  const { showBadgeList } = props;
  const getBadgeList = () => {
    if (!showBadgeList) {
      return null;
    }
    const { input: selectedItems, currentItems } = props;

    return selectedItems
      ? (
        <div className={styles.badgeListContainer}>
          <BadgeList
            selectedItems={selectedItems.value}
            currentItems={currentItems}
            noCurrentItemsMessage={Language.AUDIT_STORE_REGIONS_UPDATED_DAILY}
          />
        </div>
      )
      : null;
  };

  const badgeList = getBadgeList();

  return (
    <>
      <TreeSelect {...props} />
      {badgeList}
    </>
  );
};

export default BadgeTreeSelect;
