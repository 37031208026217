import { connect } from 'react-redux';
import UserModeViewSwitcher from './UserModeViewSwitcher';
import { onSwitchUserViewMode } from '../../actionCreators';

const mapStateToProps = (state) => {
  const {
    User: {
      viewMode,
      currentUser: { isAdmin, isMetadataAdmin },
    },
  } = state;

  const isUserAdmin = isAdmin || isMetadataAdmin;

  return {
    viewMode,
    isUserAdmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onViewModeSwitch(viewMode) {
    dispatch(onSwitchUserViewMode(viewMode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserModeViewSwitcher);
