import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { onSetTemplates } from '../../actionCreators';

const translateTemplates = (templates) => templates.map((template) => {
  return {
    ...template,
    promoConfiguration: JSON.parse(template.promoConfiguration),
  };
});

export default (dispatch, getState, action, next) => {
  API
    .graphql(graphqlOperation(queries.getAllTemplates, {}))
    .then(({ data }) => {
      if (!data || !data.getAllTemplates) {
        return;
      }

      dispatch(onSetTemplates(translateTemplates(data.getAllTemplates)));
    })
    .catch((error) => console.error(error));

  return next(action);
};
