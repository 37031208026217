import { connect } from 'react-redux';
import PromotionContainer from './PromotionContainer';
import {
  onResetTab,
  onSetTab,
} from '../../actionCreators';
import { TabTypes } from '../../constants';

export const mapStateToProps = (state) => {
  const {
    Tabs: {
      [TabTypes.PROMOTION]: currentTab,
    },
  } = state;

  return {
    currentTab,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetDefaultTab() {
      dispatch(onResetTab(TabTypes.PROMOTION));
    },
    onSwitchTab(tabName) {
      dispatch(onSetTab(TabTypes.PROMOTION, tabName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionContainer);
