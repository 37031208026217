import { connect } from 'react-redux';
import AsinValidatorTable from './AsinValidatorTable';
import {
  onAsinValidatorSetAsinRegions,
  onAsinValidatorSetAsinViabilities,
} from '../../../actionCreators';

const mapStateToProps = (state) => {
  const {
    AsinValidator: {
      asinViabilities, viewMode, errors, selectedRegions,
    },
  } = state;

  return {
    asinViabilities,
    viewMode,
    selectedRegions,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAsinViabilities(asinViabilities) {
      dispatch(onAsinValidatorSetAsinViabilities(asinViabilities));
    },
    onRegionCheck(asin, region) {
      dispatch(onAsinValidatorSetAsinRegions(asin, region));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinValidatorTable);
