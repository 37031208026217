import React from 'react';
import PropTypes from 'prop-types';
import WebsiteNavigationTabs from './WebsiteNavigationTabs';
import styles from './WebsiteContainer.module.scss';
import HorizontalLoader from '../HorizontalLoader';
import ExportModal from '../ExportModal';

const WebsiteContainer = (props) => {
  const {
    showLoader,
    children,
  } = props;

  const loader = showLoader ? <HorizontalLoader /> : null;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.tabsContainer}>
          <WebsiteNavigationTabs />
        </div>
        {loader}
        <div className={styles.contentContainer}>
          <ExportModal />
          {children}
        </div>
      </div>
    </div>
  );
};

WebsiteContainer.propTypes = {
  showLoader: PropTypes.bool.isRequired,
};

export default WebsiteContainer;
