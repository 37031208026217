import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { TableCell, TableRow, TableSectionHeader } from '@amzn/meridian/table';
import Checkbox from '@amzn/meridian/checkbox';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Tooltip from '@amzn/meridian/tooltip';
import Alert from '@amzn/meridian/alert';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircleTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import AsinRecommendationModal from './AsinRecommendationModal';
import styles from './AsinViabilityTable.module.scss';
import { AsinValidatorMessages } from '../../../constants/message';
import { getRecommendedAsins } from '../../../helpers/asinViability';

const AsinViabilityTable = (props) => {
  const {
    approvedAsinViabilities,
    partiallyApprovedAsinViabilities,
    onShowStoreDetails,
    onRegionFilterChange,
    onExportFilteredRegions,
    regionFilters,
    onShowAsinRecommendationModal
  } = props;


  const [showApprovedAsins, setShowApprovedAsins] = useState(true)
  const [showPartiallyApprovedAsins, setShowPartiallyApprovedAsins] = useState(true)

  const isEmptyAsins = _.isEmpty(partiallyApprovedAsinViabilities) && _.isEmpty(approvedAsinViabilities)

  if (_.isEmpty(regionFilters) || isEmptyAsins) {
    return <p>No data to display</p>;
  }

  const onRegionChange = (region) => () => {
    onRegionFilterChange(region);
  }

  const onToggleApprovedSectionHeader = () => {
    setShowApprovedAsins(!showApprovedAsins);
  }

  const onTogglePartiallyApprovedSectionHeader = () => {
    setShowPartiallyApprovedAsins(!showPartiallyApprovedAsins);
  }

  const getHeaders = () => {
    const cells = Object.keys(regionFilters).map((region) => {
      const isChecked = regionFilters[region];
      return (
        <TableCell key={region}>
          <div className={styles.toggleCheck}>
            {region}
            <Checkbox
              checked={isChecked}
              onChange={onRegionChange(region)}
            />
          </div>
        </TableCell>
      )
    });

    return (
      <TableRow key="headers-row">
        <TableCell>&nbsp;</TableCell>
        {cells}
      </TableRow>
    );
  };

  const getCell = (asin, storeRegion, isStoreRegionAvailable) => {
    const checkMarkToken = isStoreRegionAvailable ? checkCircleTokens : closeCircleTokens;
    const highlighting = isStoreRegionAvailable ? styles.available : styles.notAvailable;

    return (
      <TableCell key={`${asin}/${storeRegion}`}>
        <div className={highlighting}>
          <Button type="icon" onClick={onShowStoreDetails(asin, storeRegion)}>
            <Icon tokens={checkMarkToken}>Status</Icon>
          </Button>
        </div>
      </TableCell>
    );
  };

  const shouldHideRows = (storeRegions) => {
    if (Object.keys(regionFilters).some((region) => {
      const currentStoreRegion = storeRegions[region];
      const isAvailable = currentStoreRegion && !currentStoreRegion.unavailableAsinViabilities.length;
      return regionFilters[region] && !isAvailable;
    })) {
      return true;
    }
    return false;
  }

  const getCells = (asin, storeRegions) => {

    if (shouldHideRows(storeRegions)) {
      return [];
    }

    return Object.keys(regionFilters).map((region) => {
      const currentStoreRegion = storeRegions[region];
      const isAvailable = currentStoreRegion
        && !currentStoreRegion.unavailableAsinViabilities.length;

      return getCell(asin, region, isAvailable);
    });

  };

  const getRows = (asinViabilities) => {
    const rows = [];
    Object.entries(asinViabilities).forEach(([asin, storeRegions]) => {
      const cells = getCells(asin, storeRegions);
      if (!cells.length) {
        return;
      }
      rows.push((
        <TableRow key={asin}>
          <TableCell>{asin}</TableCell>
          {cells}
        </TableRow>
      ));
    });

    return rows;
  };

  const getAsinWarning = (rows) => {
    if (!rows.length) {
      return (
        <Alert type='warning'>
          {AsinValidatorMessages.NO_ASINS_SELECTED}
        </Alert>
      );
    }
    return null;
  }

  const getNoAsinCoverageWarning = (recommendedAsins) => {
    if (_.isEmpty(recommendedAsins) && _.isEmpty(approvedAsinViabilities)) {
      return (
        <Alert type='warning'>
          {AsinValidatorMessages.NO_ASIN_COVERAGE}
        </Alert>
      )
    }
    return null;
  }

  const exportFilteredAsinRegions = () => {
    onExportFilteredRegions(approvedAsinViabilities, partiallyApprovedAsinViabilities);
  }

  const getAsinRecommendationButton = (recommendedAsins) => {
    const isDisabled = (!recommendedAsins);

    const asinButton = (
      <Button
        size='small'
        type='tertiary'
        onClick={onShowAsinRecommendationModal}
        disabled={isDisabled}
      >
        ASIN Recommendations
      </Button>
    )

    const content = isDisabled ?
      (
        <Tooltip position="top" title={AsinValidatorMessages.NO_ASIN_RECOMMENDATION}>
          {asinButton}
        </Tooltip>
      ) :
      asinButton;

    return (
      <div className={styles.leftSideButtonContainer}>
        {content}
      </div>
    )
  }

  const getExportAsinsButton = () => {
    return (
      <div className={styles.rightSideButtonContainer}>
        <Button
          size='small'
          type='primary'
          onClick={exportFilteredAsinRegions}
        >
          Export
        </Button>
      </div>
    )
  }

  const getTable = () => {
    const headers = getHeaders();
    const approvedRows = getRows(approvedAsinViabilities);
    const partiallyApprovedRows = getRows(partiallyApprovedAsinViabilities);

    const recommendedAsins = getRecommendedAsins(approvedAsinViabilities, partiallyApprovedAsinViabilities, regionFilters);
    const noAsinCoverageWarning = getNoAsinCoverageWarning(recommendedAsins);
    const noAsinWarning = getAsinWarning(partiallyApprovedRows);

    const asinRecommendationButton = getAsinRecommendationButton(recommendedAsins);
    const exportAsinsButton = getExportAsinsButton();
    return (
      <>
        {noAsinCoverageWarning}
        {noAsinWarning}
        <Table
          headerRows={1}
          spacing="small"
          showDividers
          showExpandedDividers
          stickyHeaderRow
        >
          {headers}
          <TableSectionHeader open={showApprovedAsins} onClick={onToggleApprovedSectionHeader}>
            <b>ASINs approved in all regions</b>
            {approvedRows}
          </TableSectionHeader>
          <TableSectionHeader open={showPartiallyApprovedAsins} onClick={onTogglePartiallyApprovedSectionHeader}>
            <b>Partially approved ASINs</b>
            {partiallyApprovedRows}
          </TableSectionHeader>

        </Table>
        <AsinRecommendationModal
          recommendedAsins={recommendedAsins}
          partiallyApprovedAsinViabilities={partiallyApprovedAsinViabilities}
        />
        <div className={styles.buttonsContainer}>
          {asinRecommendationButton}
          {exportAsinsButton}
        </div>
      </>

    );
  };

  return getTable();
};

AsinViabilityTable.propTypes = {
  onShowStoreDetails: PropTypes.func.isRequired,
};

export default AsinViabilityTable;
