import {
  onOpenToast,
  onUpdatedPromotionReceived,
} from '../../actionCreators';
import {
  Language, PromoActions,
} from '../../constants';
import { localToAppsync } from '../../helpers/translatePromotions';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { parseGraphQLResponse } from '../../helpers/api';
import { approvePromotionChange, rejectPromotionChange } from '../../graphql/mutations';
import { sendRequest } from './helper';

const getRequestParams = (promotion, username) => {
  const translatedPromotion = localToAppsync({
    ...promotion,
    version: promotion.version + 1,
  });

  delete translatedPromotion.id;

  return {
    promotion: translatedPromotion,
    username,
    id: promotion.id,
  };
};

const getRequestOptions = (type, promotion, username) => {
  return type === PromoActions.APPROVE_PROMOTION
    ? {
      graphQlApi: approvePromotionChange,
      graphQlParams: getRequestParams(promotion, username),
      messages: [
        Language.PROMOTION_APPROVED_SUCCESS_MESSAGE,
        Language.PROMOTION_APPROVED_FAIL_MESSAGE,
      ],
    }
    : {
      graphQlApi: rejectPromotionChange,
      graphQlParams: getRequestParams(promotion, username),
      messages: [
        Language.PROMOTION_REJECTED_SUCCESS_MESSAGE,
        Language.PROMOTION_REJECTED_FAIL_MESSAGE,
      ],
    };
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const {
    GridViewPage,
    User: { currentUser: { username } },
  } = state;

  const {
    type,
    payload: { id },
  } = action;

  const promotion = getPromotionById(GridViewPage, id);
  if (!promotion) {
    dispatch(onOpenToast(`Cannot find promotion ${id}!`));

    return next(action);
  }

  const onSuccess = (response, successMessage) => {
    dispatch(onUpdatedPromotionReceived(parseGraphQLResponse(response)));
    dispatch(onOpenToast(successMessage));
  };

  sendRequest(dispatch, getRequestOptions(type, promotion, username), onSuccess);

  return next(action);
};
