import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import {
  onClosePromotionStatusModal,
  onUpdatePromotion,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { GridViewPage, PromoStatusModal: { id } } = state;

  if (!action.payload) {
    return next(action);
  }
  const {
    payload: {
      status,
    },
  } = action;

  const selectedPromotion = getPromotionById(GridViewPage, id);
  if (!selectedPromotion || selectedPromotion.status === status) {
    return next(action);
  }

  const newPromotion = {
    ...selectedPromotion,
    status,
  };

  dispatch(onUpdatePromotion(newPromotion, { isStatusUpdate: true }));
  dispatch(onClosePromotionStatusModal());
  return next(action);
};
