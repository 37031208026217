import { createPromotionShell } from './promotions';
import { Columns, COMMA_DELIMETER, Language } from '../constants';
import { getTemplateString } from "./common";

export const PASTE_HERE_ROW_ID = 'forPasteRow';

export const pasteHereRow = () => {
  const promotionShell = createPromotionShell();
  for (const [key] of Object.entries(promotionShell)) {
    if (key === Columns.PAGE.name)  {
      promotionShell[key] = '[Paste row(s) here]';
    }
  }
  promotionShell[Columns.ID.name] = PASTE_HERE_ROW_ID;

  return promotionShell;
};

export const getStoreRegionErrors = (promotion, storeRegionErrorMessages) => {
  const { storeRegions = [], auditedStoreRegions = [] } = promotion;

  const storeRegionErrorText = [];
  if (!!storeRegionErrorMessages && storeRegionErrorMessages.length > 0) {
    storeRegionErrorText.push(...storeRegionErrorMessages);
  }

  if (!Array.isArray(storeRegions) || !Array.isArray(auditedStoreRegions)) {
    return storeRegionErrorText;
  }

  const unavailableRegions = auditedStoreRegions
    ? storeRegions.filter((region) => !auditedStoreRegions.includes(region))
    : [];
  if (unavailableRegions && unavailableRegions.length > 0) {
    const isOrAre = unavailableRegions.length > 1 ? 'are' : 'is';
    storeRegionErrorText.push(getTemplateString(
      Language.PROMOTION_UNAVAILABLE_REGIONS_ERROR_MESSAGE,
      {
        unavailableStoreRegions: `${unavailableRegions.join(`${COMMA_DELIMETER} `)} ${isOrAre}`,
      },
    ));
  }

  return storeRegionErrorText;
};