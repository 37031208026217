import { connect } from 'react-redux';
import { onCompletePageLoadTimer } from '../../../actionCreators';
import HistoryTimingWrapper from './HistoryTimingWrapper';
import { PageNames } from '../../../constants/metrics';

export const mapDispatchToProps = (dispatch, trackingProps) => {
  const { pageName = PageNames.UNKNOWN_PAGE } = trackingProps;

  return {
    pageName,
    onComponentLoaded(name) { dispatch(onCompletePageLoadTimer({ pageName: name })); },
  };
};

export default connect(null, mapDispatchToProps)(HistoryTimingWrapper);
