import { connect } from 'react-redux';
import { onPromoWeekAddRow } from '../../../actionCreators';
import AddRowActionBar from './AddRowActionBar';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddRow: () => {
      const { promotion } = ownProps;
      dispatch(onPromoWeekAddRow(promotion));
    },
  };
};

export default connect(null, mapDispatchToProps)(AddRowActionBar);
