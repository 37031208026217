import React from 'react';
import PropTypes from 'prop-types';
import AuditLog from '../AuditLogPage';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants/metrics';

const AuditLogPage = (props) => {
  const { match: { params: { id } } } = props;
  return <AuditLog entityId={id} />;
};

AuditLogPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default WithHistoryTiming(AuditLogPage, { pageName: PageNames.AUDIT_LOG });
