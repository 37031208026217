import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ChangeRow from './ChangeRow';
import FilterDisplay from './FilterDisplay';

import { metadataAuditLogChangeProps } from '../../proptypes';

import styles from './MetadataAuditLog.module.scss';

const MetadataAuditLog = ({
  selectedBusiness,
  onGetMetadataAuditLogsByBusiness,
  onMetadataAuditLogSetFilterString,
  changes,
  isLoaded,
}) => {
  useEffect(() => {
    onGetMetadataAuditLogsByBusiness(selectedBusiness);
    return () => {
      onMetadataAuditLogSetFilterString('');
    };
  }, [onGetMetadataAuditLogsByBusiness, onMetadataAuditLogSetFilterString, selectedBusiness]);
  const changeList = changes.map((change) => {
    return <ChangeRow key={change.version} change={change} />;
  });

  const header = {
    updatedAt: 'Date of Change',
    version: 'Version',
    username: 'Username',
    path: 'Field',
    dependencies: 'Dependencies',
    changes: 'Changes',
  };

  return isLoaded ? (
    <>
      <FilterDisplay />
      <div className={styles.changeList}>
        <ChangeRow change={header} isHeader />
        {changeList}
      </div>
    </>
  ) : (
    <div>Loading</div>
  );
};

MetadataAuditLog.propTypes = {
  selectedBusiness: PropTypes.string.isRequired,
  onGetMetadataAuditLogsByBusiness: PropTypes.func.isRequired,
  onMetadataAuditLogSetFilterString: PropTypes.func.isRequired,
  changes: PropTypes.arrayOf(metadataAuditLogChangeProps).isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

export default MetadataAuditLog;
