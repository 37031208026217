import { connect } from 'react-redux';
import ConnectionTimeoutModal from './ConnectionTimeoutModal';

const mapStateToProps = (state) => {
  const {
    Sitewide: { connectionErrors },
  } = state;

  return {
    shouldShow: connectionErrors.length > 0,
  };
};

export default connect(mapStateToProps)(ConnectionTimeoutModal);
