/* eslint-disable no-param-reassign, eqeqeq */

import { Columns } from '../../../constants';

const checkMetadata = (state, promotion, partialFieldsValidationList = {}) => {
  const { Meta: { metadata: { fields } } } = state;
  const fieldNames = Object.keys(fields);
  let invalidMetadataFields = false;

  const isPartialFieldsValidation = Object.keys(partialFieldsValidationList).length;
  fieldNames.forEach((fieldName) => {
    if (isPartialFieldsValidation && !partialFieldsValidationList[fieldName]) {
      return;
    }
    //TODO check if yearQuarter is used anywhere in Metadata, remove if unnecessary - @hanwooll
    if (!promotion[fieldName] || fieldName === Columns.STORE_REGIONS.name || fieldName === Columns.YEAR_QUARTER.name) { return; }
    const currentField = fields[fieldName];
    const promotionValue = promotion[fieldName];
    const dependencies = currentField.dependsOn;
    let currentOptions = currentField.options;
    let hasParentDependencies = true;
    dependencies.forEach((dependency) => {
      const dependencyValue = promotion[dependency];
      if (hasParentDependencies && dependencyValue) {
        currentOptions = currentOptions[dependencyValue];
      }
      if (!currentOptions || !dependencyValue) {
        hasParentDependencies = false;
      }
    });
    if (!hasParentDependencies || !currentOptions.find((option) => option == promotionValue)) {
      if (!promotion.errors) {
        promotion.errors = {};
      }
      if (!promotion.errors[fieldName]) { promotion.errors[fieldName] = {}; }
      promotion.errors[fieldName].text = `Value '${promotionValue}' not found in metadata field '${fieldName}'`;
      invalidMetadataFields = true;
    }
  });

  return invalidMetadataFields;
};

export default checkMetadata;
