export const DEBOUNCE_WAIT_TIME = 250;

// Buttons
export const GridViewHeaderButtons = Object.freeze({
  CLEAR_SEARCH: 'Clear Search',
  MANAGE_COLUMNS: 'Manage Columns',
  WRAP_CONTENT: 'Wrap Content',
  HIGHLIGHT_MUST_FILL_COLUMNS: 'Highlight must fill columns',
});
export const CustomViewButtons = Object.freeze({
  CANCEL: 'Cancel',
  RESET: 'Reset Columns',
  SAVE: 'Save',
});
