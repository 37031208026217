import { connect } from 'react-redux';

import { onMetadataAuditLogSetFilterString } from '../../../actionCreators';

import FilterDisplay from './FilterDisplay';

export const mapStateToProps = (state) => {
  const { MetadataAuditLog: { filterString } } = state;
  return {
    filterString,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onMetadataAuditLogSetFilterString(filterString) {
      dispatch(onMetadataAuditLogSetFilterString(filterString));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDisplay);
