import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PromoWeekList.module.scss';
import GridView from '../GridView';
import KeyboardListener from '../KeyboardListener';
import PromoActionBar from './PromoWeekActionBar';

const PromoWeekList = (props) => {
  const {
    onKeyboardPress,
    searchedPromotions,
    showActionBar,
    onLoadAuditLogs,
    onClearAuditLogs,
    translatedLogsForHighlight,
    columnOrder,
    columnsToCompare,
    promotionsForAuditLog,
    isContentWrappingEnabled,
    isMustFillHighlightEnabled,
  } = props;

  const [hasLogsLoaded, setLogsLoaded] = useState(false);

  useEffect(() => () => onClearAuditLogs(), []);

  useEffect(() => {
    if (!hasLogsLoaded && searchedPromotions.length > 0) {
      onLoadAuditLogs(promotionsForAuditLog);

      setLogsLoaded(true);
    }
  }, [searchedPromotions.length]);

  return (
    <div className={styles.gridContainer}>
      <KeyboardListener onKeyboardPress={onKeyboardPress}>
        <GridView
          promotions={searchedPromotions}
          columnOrder={columnOrder}
          showActionBar={showActionBar}
          customActionBar={PromoActionBar}
          translatedLogsForHighlight={translatedLogsForHighlight}
          columnsToCompare={columnsToCompare}
          showSearch
          enableContextMenu
          showPasteHereRow
          isStickyHeader
          isContentWrappingEnabled={isContentWrappingEnabled}
          isMustFillHighlightEnabled={isMustFillHighlightEnabled}
        />
      </KeyboardListener>
    </div>
  );
};

PromoWeekList.propTypes = {
  onKeyboardPress: PropTypes.func,
  onLoadAuditLogs: PropTypes.func,
  onClearAuditLogs: PropTypes.func,
  showActionBar: PropTypes.bool,
  isContentWrappingEnabled: PropTypes.bool,
  isMustFillHighlightEnabled: PropTypes.bool,
};
PromoWeekList.defaultProps = {
  showActionBar: false,
  isContentWrappingEnabled: false,
  isMustFillHighlightEnabled: false,
  onKeyboardPress: _.noop,
  onLoadAuditLogs: _.noop,
  onClearAuditLogs: _.noop,
};

export default PromoWeekList;
