import { PreviewActions } from '../../constants/preview';

export const initialState = {
  digitalCircularParams: '',
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case PreviewActions.SET_IS_LOADING: {
      const { payload: { isLoading } } = action;
      return {
        ...state,
        isLoading,
      }

    }
    case PreviewActions.SET_DIGITAL_CIRCULAR_PARAMS: {
      const { payload: { digitalCircularParams } } = action;

      return {
        ...state,
        digitalCircularParams,
      };
    }

    case PreviewActions.CLEAR_DIGITAL_CIRCULAR_PARAMS: {
      return {
        ...state,
        digitalCircularParams: '',
      };
    }
    default: {
      return state;
    }
  }
};
