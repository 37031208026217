import window from '../../helpers/window';
import { PerformanceTimerActions } from '../../constants/metrics';

export const initialState = {
  timers: {},
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case PerformanceTimerActions.TIMER_START: {
      const { payload: { timerName } } = action;

      return {
        ...state,
        timers: {
          ...state.timers,
          [timerName]: window.performance.now(),
        },
      };
    }
    case PerformanceTimerActions.TIMER_STOP: {
      const { payload: { timerName } } = action;

      return {
        ...state,
        timers: {
          ...state.timers,
          [timerName]: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};
