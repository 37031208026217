import { connect } from 'react-redux';
import EditWeekPage from './edit-week';

export const mapStateToProps = (state, props) => {
  const { match: { params: { startDate, endDate } } } = props;

  return {
    startDate,
    endDate,
  };
};

export default connect(mapStateToProps)(EditWeekPage);
