/* eslint-disable max-len */
import React from 'react';
import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import styles from './PublishPromotionsModal.module.scss';
import { PublishPromotionsRequestStatus } from '../../constants/publishPromotions';
import Alert from '@amzn/meridian/alert';
import { getTemplateString } from '../../helpers/common';
import { Language } from '../../constants';

const PublishPromotionsModal = (props) => {
  const {
    isModalOpen,
    sendRequest,
    setIsModalOpen,
    requestStatus,
    response,
  } = props;

  const onClose = () => {
    setIsModalOpen(false);
  };

  const getPublishPromotionModalContent = () => {
    if (requestStatus === PublishPromotionsRequestStatus.REQUEST_SENT) {
      return <Loader data-testid={'publishPromotionLoader'}/>
    }
    if (requestStatus === PublishPromotionsRequestStatus.REQUEST_SUCCEEDED) {
      return (
        <Alert type="success">
          <div
            dangerouslySetInnerHTML={{ __html: Language.PUBLISH_PROMOTIONS_REQUEST_SUCCESS }}
          />
        </Alert>
      )
    }
    if (requestStatus === PublishPromotionsRequestStatus.REQUEST_FAILED) {
      return (
        <Alert type="error" >
          <div
            dangerouslySetInnerHTML={{ __html: getTemplateString(Language.PUBLISH_PROMOTIONS_REQUEST_FAILED, { response }) }} // eslint-disable-line react/no-danger
          />
        </Alert>
      )
    }
    return (
      <span className={styles.message}>
        {Language.PUBLISH_PROMOTIONS_MESSAGE}
      </span>
    )
  }

  return (
    <Modal
      title="Publish Digital Circular"
      open={isModalOpen}
      onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-description"
    >
      {getPublishPromotionModalContent()}
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="primary" size="small" onClick={sendRequest}>
            Publish
          </Button>
          <Button type="primary" size="small" onClick={onClose}>
            Close
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default PublishPromotionsModal;
