import {
  onAddedPromotionReceived,
  onUpdatedPromotionReceived,
  onLoadPromotionsAuditLogForHighlight,
} from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotions';

export default (isNewPromotion) => (dispatch, getState, action, next) => {
  const { Meta: { metadata } } = getState();
  const { payload: { promotion } } = action;

  const actionName = isNewPromotion
    ? onAddedPromotionReceived
    : onUpdatedPromotionReceived;

  dispatch(actionName(appsyncToLocal(promotion, metadata)));
  dispatch(onLoadPromotionsAuditLogForHighlight([promotion]));

  return next(action);
};
