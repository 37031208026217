import { connect } from 'react-redux';
import { formValueSelector, blur } from 'redux-form';
import _ from 'lodash';
import {
  InputTypes,
  Form,
  Columns,
  PercentageColumns,
} from '../../../constants';
import FormField from './FormField';
import {
  inputControl,
  disabledInputControl,
  checkboxControl,
  textAreaControl,
  datePickerControl,
  timeControl,
  numberControl,
  percentControl,
} from '../../../helpers/formRenders';
import SelectControl from '../Controls/Select';
import MultiSelectControl from '../Controls/MultiSelect';
import BadgeTreeSelectControl from '../Controls/BadgeTreeSelect';
import AsinInputControl from '../Controls/AsinInput';
import BadgeMultiSelectControl from '../Controls/BadgeMultiSelect';
import TagInputControl from '../Controls/TagInput';
import AsinTagInputControl from '../Controls/AsinTagInput';
import {
  getString,
} from '../../../helpers/common';
import { isCurrentUserAdmin } from '../../../selectors/user';
import { isPromotionTypeFixedPrice, isPromotionTypePercentOff } from '../../../helpers/promotions';
import { X_PERCENT_OFF } from '../../../constants/promoForm';

export const getComponentByType = (type) => {
  switch (type) {
    case InputTypes.UNEDITABLE:
      return disabledInputControl;
    case InputTypes.INPUT:
      return inputControl;
    case InputTypes.ASIN_INPUT:
      return AsinInputControl;
    case InputTypes.SINGLE_SELECT:
      return SelectControl;
    case InputTypes.MULTI_SELECT:
      return MultiSelectControl;
    case InputTypes.BADGE_TREE_SELECT:
      return BadgeTreeSelectControl;
    case InputTypes.BADGE_MULTI_SELECT:
      return BadgeMultiSelectControl;
    case InputTypes.ASIN_TAG_INPUT:
      return AsinTagInputControl;
    case InputTypes.TAG_INPUT:
      return TagInputControl;
    case InputTypes.SINGLE_CHECKBOX:
      return checkboxControl;
    case InputTypes.TEXT_AREA_INPUT:
      return textAreaControl;
    case InputTypes.DATE_TIME:
      return datePickerControl;
    case InputTypes.TIME_INPUT:
      return timeControl;
    case InputTypes.NUMBER_INPUT:
      return numberControl;
    case InputTypes.PERCENT_INPUT:
      return percentControl;
    default:
      return inputControl;
  }
};

export const getTranslatedOptionsList = (options) => {
  if (!options || !options.length) {
    return [];
  }

  return options.map((option) => {
    if (_.isObject(option) && _.size(option) === 2) {
      const { value, label } = option;

      return {
        value,
        label,
      };
    }

    return {
      value: option,
      label: option,
    };
  });
};

const selector = formValueSelector(Form.PROMO);

const getOptions = (state, options, dependsOn, index = 0) => {
  if (index >= dependsOn.length) {
    return options;
  }
  if (!options) {
    return [];
  }
  const column = dependsOn[index];
  const value = getString(selector(state, column));

  if (!value) {
    return [];
  }
  if (options[value]) {
    return getOptions(state, options[value], dependsOn, index + 1);
  }
  return [];
};

const getOptionsList = (state, fields, fieldName) => {
  if (!fields[fieldName]) {
    return [];
  }
  const { options, dependsOn } = fields[fieldName];

  return getOptions(state, options, dependsOn);
};

export const mapStateToProps = (state, ownProps) => {
  const {
    FormPage:
      {
        requiredFields,
      },
    Meta: {
      metadata: {
        fields,
      },
    },
  } = state;
  const {
    name: fieldName,
    display,
    inputType,
    allowOverride,
    overrideOptions = [],
  } = ownProps;

  const isAdmin = isCurrentUserAdmin(state);

  let isDisabled = false;
  let constraintText = null;
  const options = overrideOptions && overrideOptions.length
    ? overrideOptions
    : getOptionsList(state, fields, fieldName);

  if (allowOverride) {
    const fieldValue = getString(selector(state, fieldName));
    if (fieldValue && !options.includes(fieldValue)) {
      options.push(fieldValue);
    }
  }

  // TODO since there's no way to set an input type AND uneditable flag, we need to hack it here for now by @hanwooll
  // @vkryukov will build a proper configuration to replace the hack to support both input type and disabling
  // Disabled/uneditable column should be a global attribute somewhere, this is being modified in
  // middleware/Promo/resetPromotion.js, components/PromoForm/FormField/index.js, components/GridViewCell/index.js
  let component = getComponentByType(inputType);

  if ([
    Columns.PAGE.name,
    Columns.VERTICAL.name,
    Columns.VENDOR_MANAGER.name,
    Columns.ZONE_NUMBER.name,
  ].includes(fieldName)) {
    if (!isAdmin) {
      isDisabled = true;
    }
    component = getComponentByType(InputTypes.INPUT);
  }

  if ([
    Columns.IS_CATEGORY_HERO.name,
    Columns.IS_COVER_PAGE_HERO.name,
    Columns.IS_PRIVATE_BRAND.name,
  ].includes(fieldName)) {
    if (!isAdmin) {
      isDisabled = true;
    }
    component = getComponentByType(InputTypes.SINGLE_CHECKBOX);
  }

  // TODO: This hack should be fixed along with the functionality above
  if ([
      Columns.CIRCULAR_PRICE.name,
      Columns.PRIME_MEMBER_LABEL.name,
    ].includes(fieldName)) {
    const promotionType = selector(state, Columns.PROMOTION_TYPE.name);
    if (isPromotionTypeFixedPrice(promotionType)) {
      component = getComponentByType(InputTypes.NUMBER_INPUT);
    } else if (isPromotionTypePercentOff(promotionType)) {
      component = getComponentByType(InputTypes.PERCENT_INPUT);
      constraintText = X_PERCENT_OFF
    }
  }

  if (PercentageColumns.includes(fieldName)) {
    component = getComponentByType(InputTypes.PERCENT_INPUT);
  }

  return {
    ...ownProps,
    // we don't use labels, just pure placeholders regarding to UI/UX
    placeholder: display,
    constraintText,
    component,
    isRequired: requiredFields.includes(fieldName),
    allowOverride,
    options: options && options.length > 0
      ? getTranslatedOptionsList(options)
      : [],
    isDisabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    // For some reason native redux-form onBlur() action removes changes made by onChange()
    // when using together with Meridian's autocomplete functionality.
    // This blur() action creator is supposed to be equal to the original onBlur()
    // and doing exactly the same thing but it turns out that it works well and the
    // bug doesn't happen.
    customOnBlur(fieldName, value) {
      dispatch(blur(Form.PROMO, fieldName, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
