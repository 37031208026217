import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GridViewHeader from '../GridViewHeader';
import GridViewRow from '../GridViewRow';
import HorizontalLoader from '../HorizontalLoader';
import GridView from '../GridView';
import styles from './AuditLogPage.module.scss';
import AuditLogActionBar from './AuditLogActionBar';

const AuditLog = ({
  onLoadAuditLogs,
  columnsToCompare,
  logs,
  columnOrder,
  isLoading,
  shouldDisplayVersionHistory,
  showRollback,
}) => {
  useEffect(() => {
    onLoadAuditLogs();
  }, [onLoadAuditLogs]);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <HorizontalLoader />
      </div>
    );
  }

  const getVersionHistoryAuditLog = () => {
    const versionsList = logs.reverse().map((entity) => {
      const {
        version,
        versionHistoryList,
        modificationTimestamp,
      } = entity;

      return (
        <li key={version} className={styles.item}>
          <div className={styles.description}>
            <h3 className={styles.version}>Version {version}</h3>
            <sup className={styles.dateTime}>{modificationTimestamp}</sup>
          </div>
          <GridView
            promotions={versionHistoryList}
            columnOrder={columnOrder}
            showActionBar={false}
            isReadOnly
            isStickyHeader
          />
        </li>
      );
    });

    return (
      <ul className={styles.list}>
        {versionsList}
      </ul>
    );
  };
  const getStandardAuditLog = () => {
    const content = logs.map((entity, index) => {
      return (
        <GridViewRow
          key={entity.version}
          promotion={entity}
          columnOrder={columnOrder}
          showActionBar={showRollback}
          customActionBar={AuditLogActionBar}
          shouldHighlight={true}
          columnsToCompare={columnsToCompare}
          lastRevisionToCompare={logs[index - 1]}
          isReadOnly
        />
      );
    }).toReversed();

    return (
      <table role="grid" className={styles.gridViewTable}>
        <tbody>
          <GridViewHeader
            columns={columnOrder}
            showActionBar={showRollback}
            showSearch={false}
            isSticky
          />
          {content}
        </tbody>
      </table>
    );
  };

  const content = shouldDisplayVersionHistory ? getVersionHistoryAuditLog() : getStandardAuditLog();

  return (
    <div className={styles.gridContainer}>
      {content}
    </div>
  );
};

AuditLog.propTypes = {
  onLoadAuditLogs: PropTypes.func.isRequired,
  columnsToCompare: PropTypes.arrayOf(PropTypes.string).isRequired,
  shouldDisplayVersionHistory: PropTypes.bool,
  showRollback: PropTypes.bool,
};
AuditLog.defaultProps = {
  shouldDisplayVersionHistory: false,
  showRollback: false,
};

export default AuditLog;
