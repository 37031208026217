import {
  Validation,
  Language,
  Columns,
  CARET,
  PromotionWeekColumns,
} from '../constants';
import { isGenericFreshBusiness } from './common';
import { getCampaignRequiredFields } from './requiredFields';
import { isValidStartDate } from './form';
import { BannerColumns, BannerConfig } from '../constants/banner';

/**
 * Form validators.
 */

export const isEmpty = (value) => !(value || typeof value === 'number');
export const isNumber = (value) => typeof value === 'number';
export const tooLong = (value) => value && value.length > Validation.MAX_LENGTH;

const validateStartEndTimeRange = (startDateColumn, endDateColumn, values, errors) => {
  const isStartDateValid = isValidStartDate(
    values[startDateColumn],
    values[endDateColumn],
  );
  if (!isEmpty(values[startDateColumn]) && !isStartDateValid) {
    errors[startDateColumn] = Language.VALIDATION_START_DATE_ERROR;
  }
};

export const validate = (values) => {
  const errors = {};

  const fieldsToValidate = getCampaignRequiredFields(values);

  fieldsToValidate.forEach((fieldName) => {
    if (isEmpty(values[fieldName])) {
      errors[fieldName] = Language.VALIDATION_REQUIRED;
    }
  });

  validateStartEndTimeRange(
    Columns.START_DATE.name,
    Columns.END_DATE.name,
    values,
    errors,
  );

  return errors;
};

export const warn = (values) => {
  const warnings = {};
  if (values.brand && values.brand.length < Validation.MIN_LENGTH) {
    warnings.brand = Language.VALIDATION_MIN_LENGTH;
  }
  if (isGenericFreshBusiness(values.business) && values.copy && values.copy.indexOf(CARET) !== -1) {
    warnings.copy = Language.CARET_PRESENT;
  }

  if (
    isGenericFreshBusiness(values.business)
    && values.copy
    && values.copy.length > Validation.COPY_MAX_LENGTH
  ) {
    warnings.copy = Language.VALIDATION_MAX_LENGTH;
  }

  if (
    isGenericFreshBusiness(values.business)
    && values.subHeadline
    && values.subHeadline.length > Validation.SUB_HEADLINE_MAX_LENGTH
  ) {
    warnings.subHeadline = Language.VALIDATION_MAX_LENGTH;
  }

  if (values.scheduler && values.scheduler.length < Validation.MIN_LENGTH) {
    warnings.scheduler = Language.VALIDATION_MIN_LENGTH;
  }

  if (values.vertical && values.vertical.length < Validation.MIN_LENGTH) {
    warnings.vertical = Language.VALIDATION_MIN_LENGTH;
  }

  if (values.vendor && values.vendor.length < Validation.MIN_LENGTH) {
    warnings.vendor = Language.VALIDATION_MIN_LENGTH;
  }

  return warnings;
};

export const validatePromoWeek = (values) => {
  const errors = {};

  [
    ...getCampaignRequiredFields(values),
    [Columns.TEMPLATE_ID.name],
    [PromotionWeekColumns.EDIT_DEADLINE.name],
    [PromotionWeekColumns.PRINT_DEADLINE.name],
  ].forEach((fieldName) => {
    if (isEmpty(values[fieldName])) {
      errors[fieldName] = Language.VALIDATION_REQUIRED;
    }
  });

  validateStartEndTimeRange(
    Columns.START_DATE.name,
    Columns.END_DATE.name,
    values,
    errors,
  );

  return errors;
};

export const validateBannerInputs = (values) => {
  const errors = {};

  // TODO: can be done nicer
  const banners = values[BannerConfig.FORM_STORAGE_VAR_NAME];
  if (banners) {
    banners.forEach((banner, index) => {
      if (!/^\d+$/.test(banner[BannerColumns.SLOT.name])) {
        if (!errors[BannerConfig.FORM_STORAGE_VAR_NAME]) {
          errors[BannerConfig.FORM_STORAGE_VAR_NAME] = {};
        }
        if (!errors[BannerConfig.FORM_STORAGE_VAR_NAME][index]) {
          errors[BannerConfig.FORM_STORAGE_VAR_NAME][index] = {};
        }
        errors[BannerConfig.FORM_STORAGE_VAR_NAME][index][BannerColumns.SLOT.name] = 'The value is required and should be a number!';
      }
    });
  }

  return errors;
};
