export default (state, data) => {
  return {
    ...state,
    // TODO: check if data.promotion has all required data
    promotions: state.promotions.map((promotion) => {
      if (promotion.id !== data.promotion.id) { return promotion; }
      return {
        ...promotion,
        errors: {
          ...promotion.errors,
          [data.column]: { text: data.message },
        },
      };
    }),
  };
};
