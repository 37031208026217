import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const KeyboardListener = ({ children, onKeyboardPress }) => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyboardPress);

    return () => {
      document.removeEventListener('keydown', onKeyboardPress);
    };
  });

  return (
    <div>
      { children }
    </div>
  );
};

KeyboardListener.propTypes = {
  children: PropTypes.element.isRequired,
  onKeyboardPress: PropTypes.func.isRequired,
};

export default KeyboardListener;
