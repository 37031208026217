import { connect } from 'react-redux';
import PromoWeekList from './PromoWeekList';
import {
  getSearchedPromotionsForCurrentWeek,
} from '../../selectors/promotionFiltersSelectors';
import {
  onLoadPromotionsAuditLogForHighlight,
  onClearTranslatedAuditLogsForHighlight,
  onKeyboardPress,
} from '../../actionCreators';
import { KeyboardKeys } from '../../constants';
import { getCurrentWeekPromotions } from '../../selectors/promotions';

const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      translatedLogsForHighlight,
      isContentWrappingEnabled,
      isMustFillHighlightEnabled,
    },
    Meta: { metadata: { columnOrder } },
    CustomView: { customColumnOrder },
  } = state;

  const columns = customColumnOrder
    ? customColumnOrder.filter((column) => column.isVisible)
    : columnOrder;

  const columnsToCompare = columnOrder.map((column) => column.name);

  return {
    promotionsForAuditLog: getCurrentWeekPromotions(state),
    searchedPromotions: getSearchedPromotionsForCurrentWeek(state),
    columnOrder: columns,
    columnsToCompare,
    translatedLogsForHighlight,
    isContentWrappingEnabled,
    isMustFillHighlightEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onLoadAuditLogs(promotionsForAuditLog) {
    dispatch(onLoadPromotionsAuditLogForHighlight(promotionsForAuditLog));
  },
  onClearAuditLogs() {
    dispatch(onClearTranslatedAuditLogsForHighlight());
  },
  onKeyboardPress(evt) {
    dispatch(onKeyboardPress(evt));
    // prevent default behavior
    if ([KeyboardKeys.TAB, KeyboardKeys.ENTER].includes(evt.key)) {
      evt.preventDefault();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoWeekList);
