import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { campaignColumnProps } from '../../proptypes';
import arrayMove from '../../helpers/arrayMove';
import { CustomViewButtons } from '../../constants/ui';
import styles from './CustomViewModal.module.scss';

const CustomViewModal = ({
  showModal,
  onCloseModal,
  onSetColumns,
  columnOrder,
  defaultColumnOrder,
  skipColumns
}) => {
  const [columns, setColumns] = useState(columnOrder);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [draggedColumn, setDraggedColumn] = useState(null);
  const [droppedColomn, setDroppedColumn] = useState(null);

  useEffect(() => {
    setColumns(columnOrder);
  }, [columnOrder, showModal]);

  const toggleColumn = (index) => {
    const newColumns = columns.map((column, i) => {
      if (i !== index) { return column; }
      return {
        ...column,
        isVisible: !column.isVisible,
      };
    });
    setColumns(newColumns);
  };

  const moveColumn = (indexFrom, indexTo) => {
    if (indexTo >= columns.length || indexTo < 0) { return; }
    const newOrder = arrayMove(columns, indexFrom, indexTo);
    setColumns(newOrder);
  };

  const onDragStart = (evt, index) => {
    evt.dataTransfer.setData('column', index);
    setDraggedColumn(index);
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
  };

  const onDrop = (evt, index) => {
    evt.preventDefault();
    const column = evt.dataTransfer.getData('column');
    moveColumn(column, index);
    setDroppedColumn(index);
    setTimeout(() => { setDroppedColumn(null); }, 1000);
  };

  const onDragEnter = (index) => {
    setHoveredColumn(index);
  };

  const onDragEnd = () => {
    setHoveredColumn(null);
    setDraggedColumn(null);
  };


  const columnView = columns.map((column, index) => {
    const classes = classnames({
      [styles.columnRow]: true,
      [styles.hoverAbove]: hoveredColumn === index && draggedColumn > index,
      [styles.hoverBelow]: hoveredColumn === index && draggedColumn < index,
      [styles.columnDropped]: droppedColomn === index,
    });

    if (skipColumns.includes(column.name)) { 
      return null;
    }

    return (
      <div
        className={classes}
        key={column.name}
        draggable="true"
        onDragStart={(evt) => { onDragStart(evt, index); }}
        onDragOver={(evt) => { onDragOver(evt); }}
        onDrop={(evt) => { onDrop(evt, index); }}
        onDragEnter={() => { onDragEnter(index); }}
        onDragEnd={() => { onDragEnd(); }}
        data-testid="checkbox"
      >
        <div className={styles.columnIcon}>
          <DragHandleIcon style={{ color: 'black' }} />
        </div>
        <div className={styles.columnCheck}>
          <Checkbox
            checked={column.isVisible}
            onChange={() => { toggleColumn(index); }}
            fillParent
          >
            {column.display}
          </Checkbox>
        </div>
      </div>
    );
  });

  const onClickSetColumns = () => {
    onSetColumns(columns);
    onCloseModal();
  };

  const onResetColumns = () => {
    onSetColumns(defaultColumnOrder);
  };

  return (
    <Modal open={showModal}>
      <div className={styles.columnView}>
        {columnView}
      </div>
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={onCloseModal}>{CustomViewButtons.CANCEL}</Button>
          <Button type="secondary" size="small" onClick={onResetColumns}>{CustomViewButtons.RESET}</Button>
          <Button
            type="secondary"
            size="small"
            data-testid="setColumns"
            onClick={onClickSetColumns}
          >
            {CustomViewButtons.SAVE}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

CustomViewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSetColumns: PropTypes.func.isRequired,
  columnOrder: campaignColumnProps.isRequired,
  defaultColumnOrder: campaignColumnProps.isRequired,
};

export default CustomViewModal;
