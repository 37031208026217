import { submitStringMetric } from '../../metrics/index';
import {
  UsageMetrics,
} from '../../constants';
import getExceptionsList from './exceptionsList';
import { MetricLocations, MetricTypes } from '../../constants/metrics';

const exceptionsList = getExceptionsList();
const getPrefixedMetricName = (actionName) => `${UsageMetrics.NAME_PREFIX}_${actionName}`;

export default ({ getState }) => (next) => (action) => {
  if (!exceptionsList[action.type]) {
    const { Sitewide: { selectedBusiness } } = getState();

    const metricContext = {
      business: selectedBusiness,
      location: MetricLocations.USAGE_TRACKING_MIDDLEWARE,
      type: MetricTypes.USAGE,
    };

    submitStringMetric(
      getPrefixedMetricName(action.type),
      metricContext,
    );
  }

  return next(action);
};
