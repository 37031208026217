import { Urls, DateFormats } from '../constants';
import { RouteRegexp } from '../constants/router';
import { getFormattedDateTime } from './dateTime';

const isRouteMatch = (route, matchUrl) => !!route.match(new RegExp(matchUrl, 'gi'));

export const isPromotionEditRoute = (route) => isRouteMatch(route, Urls.PROMOTION_EDIT);
export const isPromotionCloneRoute = (route) => isRouteMatch(route, Urls.PROMOTION_CLONE);
export const isWeekEditRoute = (route) => isRouteMatch(route, Urls.WEEK_EDIT);
export const isWeekNewRoute = (route) => isRouteMatch(route, Urls.WEEK_NEW);
export const isWeekPublishedRoute = (route) => isRouteMatch(route, Urls.WEEK_PUBLISHED);
export const isCalendarRoute = (route) => isRouteMatch(route, Urls.CALENDAR);
export const isPublishedPromotionsRoute = (route) => isRouteMatch(route, Urls.PUBLISHED_PROMOTION_LIST);
export const isPromotionWeekRoute = (route) => {
  // See Urls.PROMOTION_WEEK
  return !!route.match(RouteRegexp.PROMOTION_WEEK);
};
export const populateRouterParameters = (route, parameters) => {
  let routeWithParams = route;

  Object.keys(parameters).forEach((param) => {
    const regexp = new RegExp(`\\:${param}`, 'gi');
    routeWithParams = routeWithParams.replace(regexp, parameters[param]);
  });

  return routeWithParams;
};
export const createRoute = (route, placeholders) => {
  if (!route) {
    return '';
  }

  const formattedRoute = route.replace('\\_', '_');

  return placeholders
    ? populateRouterParameters(formattedRoute, placeholders)
    : formattedRoute;
};
export const createPromotionWeekRoute = (startDate, endDate) => {
  return createRoute(Urls.PROMOTION_WEEK, {
    startDate: getFormattedDateTime(startDate, {
      pattern: DateFormats.YYYY_MM_DD,
    }),
    endDate: getFormattedDateTime(endDate, {
      pattern: DateFormats.YYYY_MM_DD,
    }),
  });
};
export const getQueryParams = (url) => {
  const queryParams = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};

  queryParams.forEach((param) => {
    const [key, val] = param.split('=');
    params[key] = decodeURIComponent(val);
  });

  return params;
};
export const getCurrentLocation = (state) => {
  const { router: { location } } = state;

  return location || ''
};

export const getCurrentPathName = (state) => {
  const location = getCurrentLocation(state);

  if (!location || !location.pathname) {
    return '';
  }

  return location.pathname;
};
