import React from 'react';
import CheckboxComponent from '@amzn/meridian/checkbox';

import styles from './Checkbox.module.scss';
import { styledInputProps } from '../../../proptypes';

const Checkbox = ({
  inputValue,
  onInputChange,
}) => {
  const checked = !!inputValue;
  return (
    <div className={styles.checkboxWrapper}>
      <CheckboxComponent checked={checked} onChange={onInputChange} />
    </div>
  );
};

Checkbox.propTypes = styledInputProps;

export default Checkbox;
