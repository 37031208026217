import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { metadataAuditLogChangeProps } from '../../../proptypes';

import ChangeDisplay from '../ChangeDisplay';
import PathDisplay from '../PathDisplay';
import DependencyDisplay from '../DependencyDisplay';

import styles from '../MetadataAuditLog.module.scss';

const ChangeRow = ({ change, isHeader }) => {
  const classes = classnames({
    [styles.changeItem]: true,
    [styles.header]: isHeader,
  });

  const formatDate = (date) => {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
  };

  const changeDisplay = isHeader
    ? change.changes
    : <ChangeDisplay change={change} />;

  const pathDisplay = isHeader
    ? change.path
    : <PathDisplay path={change.path} />;

  const dependencyDisplay = isHeader
    ? change.dependencies
    : <DependencyDisplay path={change.path} />;

  const dateDisplay = isHeader
    ? change.updatedAt
    : formatDate(change.updatedAt);

  return (
    <>
      <div className={classes}>{change.version}</div>
      <div className={classes}>{dateDisplay}</div>
      <div className={classes}>{change.username}</div>
      <div className={classes}>{pathDisplay}</div>
      <div className={classes}>{dependencyDisplay}</div>
      <div className={classes}>{changeDisplay}</div>
    </>
  );
};

ChangeRow.propTypes = {
  change: metadataAuditLogChangeProps.isRequired,
  isHeader: PropTypes.bool,
};

ChangeRow.defaultProps = {
  isHeader: false,
};

export default ChangeRow;
