import classnames from 'classnames';
import React from 'react';
import sitewideStyles from '../../../styles/sitewide.module.scss';

// TODO: should be deprecated in favor of /src/components/Controls/Messages/Messages.js
export const getMessages = (touched, error, warning) => {
  const errorMessage = error && (
    <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)}>
      {error}
    </p>
  );
  const warningMessage = warning
    && (
      <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)}>
        {warning}
      </p>
    );

  return touched && (errorMessage || warningMessage);
};
