import { change } from 'redux-form';
import { Columns, Form } from '../../constants';
import { onAsinValidatorAutoSelectRegionFilters } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { payload: { asinViabilities } } = action;

  if (!asinViabilities || !asinViabilities.length) {
    return next(action);
  }
  const parsedAsins = asinViabilities.map(({ asin }) => asin).toString();
  dispatch(change(Form.PROMO, Columns.ASINS.name, parsedAsins, false));

  return next(action);
};
