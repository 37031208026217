import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import AsinValidatorPanel from './AsinViabilityPanel';
import AsinViabilityTable from './AsinViabilityTable';
import StoreCardList from '../StoreCardList';
import styles from './AsinViabilityDashboard.module.scss';

const AsinViabilityDashboard = (props) => {
  const {
    asinViabilities,
    getAsinViabilitiesData,
    getStoreDetails,
    getApprovedAsins,
    getPartiallyApprovedAsinsWithViabilities,
  } = props;

  const [asinStoreRegionDetails, setStoreDetails] = useState(null);

  const [
    storeRegionList,
    asinViabilitiesByStoreRegion,
  ] = useMemo(
    () => getAsinViabilitiesData(asinViabilities),
    [asinViabilities],
  );
  const approvedAsinsViabilities = useMemo(
    () => getApprovedAsins(storeRegionList, asinViabilitiesByStoreRegion),
    [storeRegionList, asinViabilitiesByStoreRegion],
  );

  const onShowStoreDetails = (selectedAsin, selectedStoreRegionName) => () => {
    setStoreDetails(getStoreDetails(
      asinViabilities,
      selectedAsin,
      selectedStoreRegionName,
    ));
  };


  const getAsinTableView = () => {
    if (!asinViabilities || !asinViabilities.length) {
      return null;
    }

    const partiallyApprovedAsinViabilitiesByStoreRegion = getPartiallyApprovedAsinsWithViabilities(
      asinViabilitiesByStoreRegion,
      approvedAsinsViabilities,
    );
    
    return (
      <div>
        <AsinViabilityTable
          approvedAsinViabilities={approvedAsinsViabilities}
          partiallyApprovedAsinViabilities={partiallyApprovedAsinViabilitiesByStoreRegion}
          onShowStoreDetails={onShowStoreDetails}
        />
      </div>
    );
  };

  const getAsinDetailsView = () => {
    if (!asinStoreRegionDetails) {
      return null;
    }
    const { availableAsinViabilities, unavailableAsinViabilities } = asinStoreRegionDetails;

    return (
      <>
        <h3>Details:</h3>
        <p>Available stores:</p>
        <StoreCardList
          storeList={availableAsinViabilities}
          isAvailable
        />
        <p>Unavailable stores:</p>
        <StoreCardList storeList={unavailableAsinViabilities} />
      </>
    );
  };

  const asinTableView = getAsinTableView();
  const asinDetailsView = getAsinDetailsView();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <AsinValidatorPanel />
        <div className={styles.innerContainer}>
          {asinTableView}
          {asinDetailsView}
        </div>
      </div>
    </div>
  );
};

AsinViabilityDashboard.propTypes = {
  targetDate: PropTypes.string,
  getAsinViabilitiesData: PropTypes.func.isRequired,
  getStoreDetails: PropTypes.func.isRequired,
  getApprovedAsins: PropTypes.func.isRequired,
  getPartiallyApprovedAsinsWithViabilities: PropTypes.func.isRequired,
};

AsinViabilityDashboard.defaultProps = {
  targetDate: '',
};

export default AsinViabilityDashboard;
