import { connect } from 'react-redux';
import Main from './Main';
import { getSelectedTemplate } from '../../../../selectors/templates';

const getTranslatedTemplateOptions = (templates) => {
  return templates.map((template) => {
    const { id, name } = template;

    return {
      value: id,
      label: name,
    };
  });
};

export const mapStateToProps = (state) => {
  const { Templates: { templates } } = state;

  const templateOptions = getTranslatedTemplateOptions(templates);
  const selectedTemplate = getSelectedTemplate(state);

  return {
    templateOptions,
    selectedTemplateName: selectedTemplate ? selectedTemplate.name : 'N/A',
  };
};

export default connect(mapStateToProps)(Main);
