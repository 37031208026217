import _ from 'lodash';
import { AuditLogTypes } from '../constants';
import {
  appsyncToLocal as appsyncToLocalPromotionWeek,
} from './translatePromotionWeek';
import { appsyncToLocal } from './translatePromotions';
import { isEmptyOrUnfilledArray, sanitizeStringForSearch } from './common';

export const getTranslatedData = (parsedData, auditLogType) => {
  try {
    if (auditLogType === AuditLogTypes.PROMOTION_WEEK) {
      return parsedData.map((data) => appsyncToLocalPromotionWeek(data));
    }

    if (auditLogType === AuditLogTypes.PROMOTION) {
      return parsedData.map((data) => appsyncToLocal(data));
    }

    if (auditLogType === AuditLogTypes.TEMPLATE_BUILDER) {
      return parsedData.map((data) => {
        const parsedTemplateConfiguration = JSON.parse(data.promoConfiguration);

        return {
          ...data,
          versionHistoryList: parsedTemplateConfiguration.rows,
        };
      });
    }
    return parsedData;
  } catch (e) {
    console.error('Cannot parse entity\'s JSON', e);
  }
};

export const getEntityWithMergedProps = (entity) => {
  return Object
    .keys(entity)
    .reduce((mergedProps, propKey) => {
      if (_.isObject(entity[propKey])) {
        return {
          ...mergedProps,
          ...entity[propKey],
        };
      } else {
        return {
          ...mergedProps,
          [propKey]: entity[propKey],
        };
      }
    }, {});
};

export const getAuditLogEntryAtVersion = (auditLog, version) => {
  return auditLog.filter((item) => item.promotion.version === version)[0];
};
export const extractCellHistoryFromLogs = (logs, columnName) => {
  const history = [];
  logs.forEach((log) => {
    const cellValue = log[columnName];
    if (cellValue === '' || isEmptyOrUnfilledArray(cellValue)) {
      return;
    }
    let alreadyExistsInHistory = false;
    history.forEach((value) => {
      if (_.isEqual(
        sanitizeStringForSearch(value),
        sanitizeStringForSearch(cellValue),
      )) {
        alreadyExistsInHistory = true;
      }
    });
    if (!alreadyExistsInHistory) {
      history.push(cellValue);
    }
  });
  return history.reverse();
};
