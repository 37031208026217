import { connect } from 'react-redux';
import {
  onResetPublishedPromotionAuditLog,
} from '../../actionCreators';
import PromoAuditLogModal from './PromoAuditLogModal';

export const mapStateToProps = (state) => {
  const {
    Meta: { metadata: { columnOrder } },
    Promo: { promoAuditLogs },
  } = state;

  const columnsToCompare = columnOrder.map((column) => column.name);

  return {
    isOpen: promoAuditLogs.length > 0,
    promoAuditLogs,
    columnsToCompare,
    columnOrder,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal() {
      dispatch(onResetPublishedPromotionAuditLog());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoAuditLogModal);
