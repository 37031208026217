import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@amzn/meridian/card';
import styles from './StoreCardList.module.scss';
import StoreCardModal from '../StoreCardModal';

const StoreCardList = ({ storeList, isAvailable }) => {
  const [storeDetails, setStoreDetails] = useState(null);

  const onCloseStoreDetailsModal = () => setStoreDetails(null);
  const onOpenStoreDetailsModal = (storeDetails) => () => setStoreDetails(storeDetails);

  const getStoreDetailsModal = () => (
    <StoreCardModal
      isOpen={!!storeDetails}
      onClose={onCloseStoreDetailsModal}
      store={storeDetails}
    />
  );

  const getStoreList = () => {
    const list = storeList.map((store) => (
      <li
        key={store.store_id}
        className={classnames({
          [styles.storeItem]: true,
          [styles.storeItemAvailable]: isAvailable,
          [styles.storeItemUnavailable]: !isAvailable,
        })}
      >
        <Card onClick={onOpenStoreDetailsModal(store)}>
          {store.store_id}
        </Card>
      </li>
    ));

    return <ul className={styles.storeList}>{list}</ul>;
  };

  const storeDetailsModal = getStoreDetailsModal();
  const list = getStoreList();

  return (
    <>
      {storeDetailsModal}
      {list}
    </>
  );
};

StoreCardList.propTypes = {
  isAvailable: PropTypes.bool,
};

StoreCardList.defaultProps = {
  isAvailable: false,
};

export default StoreCardList;
