import React from 'react';

import { metadataAuditLogChangeProps } from '../../../proptypes';

const ChangeDisplay = ({ change }) => {
  const { changes: [{ add, remove }] } = change;

  const getDisplay = (values, display) => {
    if (!values || !values.length) {
      return null;
    }
    const valueSet = values.join(', ');
    return (
      <div>
        {`${display}: ${valueSet}`}
      </div>
    );
  };

  return (
    <div>
      {getDisplay(add, 'Add')}
      {getDisplay(remove, 'Remove')}
    </div>
  );
};

ChangeDisplay.propTypes = {
  change: metadataAuditLogChangeProps.isRequired,
};

export default ChangeDisplay;
