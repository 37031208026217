import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {
  onAsinSelectImage,
  onAsinSetLoading,
  onAsinSetProductImages,
} from '../../actionCreators';
import extractAsins from '../../helpers/extractAsins';

const getTranslatedImages = (images, asin) => {
  return images.map((image) => {
    return {
      ...JSON.parse(image),
      asin,
    };
  });
};

export default (dispatch, getState, action, next) => {
  const { payload: { asin } } = action;

  if (!asin) {
    dispatch(onAsinSetProductImages([]));

    return next(action);
  }

  const asinToSend = Array.isArray(asin)
    ? asin
    : extractAsins(asin)[0];

  const params = {
    asin: asinToSend,
  };

  const onResponse = ({ data: { getProductImages: response } }) => {
    if (!response) {
      return;
    }

    try {
      const translatedImages = getTranslatedImages(response, asin);

      dispatch(onAsinSetProductImages(translatedImages));
    } catch (e) {
      console.error(e);
    }
  };

  dispatch(onAsinSetLoading(true));

  API
    .graphql(graphqlOperation(queries.getProductImages, params))
    .then(onResponse)
    .finally(() => {
      dispatch(onAsinSetLoading(false));
      dispatch(onAsinSelectImage(0));
    })
    .catch((error) => console.error(error));

  return next(action);
};
