import { connect } from 'react-redux';

import {
  onGetMetadataAuditLogsByBusiness,
  onMetadataAuditLogSetFilterString,
} from '../../actionCreators';

import {
  getFilteredMetadataAuditLogs,
} from '../../selectors/metadataAuditLogSelectors';

import MetadataAuditLog from './MetadataAuditLog';

export const mapStateToProps = (state) => {
  const {
    Meta: {
      initialMetadataLoaded,
    },
    Sitewide: {
      selectedBusiness,
    },
    MetadataAuditLog: { changes },
  } = state;

  const isLoaded = initialMetadataLoaded && !!changes.length;

  const filteredChanges = getFilteredMetadataAuditLogs(state);

  return {
    selectedBusiness,
    changes: filteredChanges,
    isLoaded,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onGetMetadataAuditLogsByBusiness(selectedBusiness) {
      return dispatch(onGetMetadataAuditLogsByBusiness(selectedBusiness));
    },
    onMetadataAuditLogSetFilterString(filterString) {
      return dispatch(onMetadataAuditLogSetFilterString(filterString));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetadataAuditLog);
