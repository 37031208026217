import React from 'react';
import PropTypes from 'prop-types';
import Autolinker from 'autolinker';
import styles from './PopOver.module.scss';
import Portal from '../Portal';

const PopOver = (props) => {
  const {
    children,
    isOpen,
    anchorNode,
    placement,
  } = props;

  if (!isOpen || !children || !anchorNode || !anchorNode.current) {
    return null;
  }

  const position = anchorNode.current.getBoundingClientRect();
  if (!position) {
    return null;
  }

  const getStyle = (placement, {
    left, right, top, bottom, height, width,
  }) => {
    switch (placement) {
      case 'top':
        return { left, bottom: window.innerHeight - bottom + height };
      case 'bottom':
        return { left, top: top + height };
      case 'left':
        return { top, right: window.innerWidth - right + width };
      case 'right':
        return { top, left: left + width };
      default:
        return {};
    }
  };
  const style = getStyle(placement, position);

  return (
    <Portal>
      <div
        className={styles.popOver}
        style={style}
        dangerouslySetInnerHTML={{ __html: Autolinker.link(children.toString()) }} // eslint-disable-line react/no-danger, max-len
      />
    </Portal>
  );
};

PopOver.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element.isRequired]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  placement: PropTypes.string,
};

PopOver.defaultProps = {
  placement: 'top',
};

export default PopOver;
