import {
  Columns,
  COMMA_DELIMETER,
  NEWLINE_DELIMETER,
  UNDERSCORE_DELIMETER,
  TemplateBuilderColumnsOrder,
} from '../../constants';
import {
  downloadFile,
  formatString,
  getPromotionWeekName,
  isBlankTemplateExportType,
  isExtendedExportType,
} from '../../helpers/export';
import { PromotionExportTypes, PromotionExportViewType } from '../../constants/export';
import {
  getSearchedPromotionsForCurrentWeek,
} from '../../selectors/promotionFiltersSelectors';
import {
  getPublishedPromotionsForCurrentWeek,
} from '../../selectors/promotions';

const extractPromotionString = (promotions, columnOrder, delimeter) => {
  const columnString = columnOrder.map((column) => {
    return `"${formatString(column.display)}"`;
  }).join(delimeter);
  const promotionString = promotions.map((promotion) => {
    return columnOrder.map((column) => {
      let value = promotion[column.name];
      if (Array.isArray(value)) { value = value.join(', '); }
      return `"${formatString(value)}"`;
    }).join(delimeter);
  }).join(NEWLINE_DELIMETER);
  return `${columnString}${NEWLINE_DELIMETER}${promotionString}`;
};

const getPromotionsWithSingleRegionSingleAsinPerLine = (promotions) => {
  const newPromotions = [];
  promotions.forEach((promotion) => {
    const { storeRegions, asins, picturedAsins = [] } = promotion;

    if (storeRegions && Array.isArray(storeRegions)) {
      storeRegions.forEach((storeRegion) => {
        asins.forEach((asin) => {
          const promotionWithSingleRegionSingleAsin = {
            ...promotion,
            [Columns.STORE_REGIONS.name]: storeRegion,
            [Columns.ASINS.name]: asin,
            [Columns.IS_FEATURED_ASIN.name]: picturedAsins.includes(asin),
          };
          newPromotions.push(promotionWithSingleRegionSingleAsin);
        });
      });
    } else {
      newPromotions.push(promotion);
    }
  });

  return newPromotions;
};

export const getEligiblePromotionsForExport = (state) => {
  const { Export: { exportType } } = state;

  const filteredSearchedPromotions = getSearchedPromotionsForCurrentWeek(state)
    .filter(({ isSoftDeleted }) => !isSoftDeleted);

  return isExtendedExportType(exportType)
    ? getPromotionsWithSingleRegionSingleAsinPerLine(filteredSearchedPromotions)
    : filteredSearchedPromotions;
};

export const getBlankTemplateForPromotions = (promotions) => {
  if (!promotions || !promotions.length) {
    return [];
  }
  const templateColumns = TemplateBuilderColumnsOrder.map(templateColumn => templateColumn.name);

  return promotions.map(promotion => {
    return Object.keys(promotion).reduce((newPromotion, columnName) => {
      return {
        ...newPromotion,
        [columnName]: templateColumns.includes(columnName) ? promotion[columnName] : '',
      };
    }, {});
  });
};

export const getPromotionsToExport = (state) => {
  const { Export: { exportViewType, exportType } } = state;

  switch (exportViewType) {
    case PromotionExportViewType.PREVIEW: {
      if (isBlankTemplateExportType(exportType)) {
        return getBlankTemplateForPromotions(
          getEligiblePromotionsForExport(state)
        );
      }

      return getEligiblePromotionsForExport(state);
    }
    case PromotionExportViewType.PUBLISHED: {
      return getPublishedPromotionsForCurrentWeek(state);
    }
    default: {
      return getEligiblePromotionsForExport(state);
    }
  }
};

const getFileName = (state) => {
  const { Export: { exportType } } = state;

  const reportFileTypePostfix = {
    [PromotionExportTypes.STANDARD.value]: 'standard',
    [PromotionExportTypes.EXTENDED.value]: 'extended',
    [PromotionExportTypes.VIABILITY.value]: 'viability',
    [PromotionExportTypes.BLANK_TEMPLATE.value]: 'blank_template',
  }[exportType] || '';

  const promotionWeekName = getPromotionWeekName(state);

  const fileNamePostfix = reportFileTypePostfix
    ? `${UNDERSCORE_DELIMETER}${reportFileTypePostfix}`
    : '';
  const fileName = promotionWeekName
    ? `${promotionWeekName}${fileNamePostfix}`
    : `promotions${fileNamePostfix}`;

  return `${fileName}.csv`;
};

const getColumnOrderWithAsinFeaturedColumn = (columnOrder) => {
  const asinColumnIndex = columnOrder.findIndex((column) => column.name === Columns.ASINS.name);

  const columnOrderWithFeaturedAsinColumn = [];
  for (let i = 0; i < columnOrder.length; i++) {
    columnOrderWithFeaturedAsinColumn.push(columnOrder[i]);

    if (asinColumnIndex === i) {
      columnOrderWithFeaturedAsinColumn.push(Columns.IS_FEATURED_ASIN);
    }
  }

  return columnOrderWithFeaturedAsinColumn;
};

const getColumnOrder = (state) => {
  const {
    Meta: { metadata: { columnOrder } },
    Export: { exportType },
  } = state;

  if (isExtendedExportType(exportType)) {
    return getColumnOrderWithAsinFeaturedColumn(columnOrder);
  }

  return columnOrder;
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  const exportColumnOrder = getColumnOrder(state);
  const content = getPromotionsToExport(state);
  const fileName = getFileName(state);

  const fileContent = extractPromotionString(
    content,
    exportColumnOrder,
    COMMA_DELIMETER,
  );

  downloadFile(fileContent, fileName);

  return next(action);
};
