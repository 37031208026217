import React, { useEffect } from 'react';
import Alert from '@amzn/meridian/alert';
import Modal from '@amzn/meridian/modal';
import PromotionContainer from '../../../PromotionContainer';
import HorizontalLoader from '../../../HorizontalLoader';
import WithHistoryTiming from '../../../WithHistoryTiming';
import { ModalNames } from '../../../../constants/metrics';

const EditPromotionPage = (props) => {
  const {
    isLoading,
    isFound,
    isClone,
    loadedPromotion,
    goBack,
    unSelectActiveCell,
  } = props;

  useEffect(() => {
    unSelectActiveCell();
  }, []);

  if (isLoading) {
    return (
      <HorizontalLoader>
        <p>Please, wait... The promotion is being loaded.</p>
      </HorizontalLoader>
    );
  }
  if (!isFound) {
    return <Alert type="error">No data found! Please try to re-open the promotion using the list view.</Alert>;
  }

  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={goBack(loadedPromotion)}
    >
      <PromotionContainer
        {...props}
        promotion={loadedPromotion}
        isClone={isClone}
      />
    </Modal>
  );
};

export default WithHistoryTiming(EditPromotionPage, { pageName: ModalNames.EDIT_PROMOTION });
