/* eslint-disable no-mixed-operators */
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { validatePromotion } from './validatePromotion';
import { BulkImportCompareColumns } from '../../../constants/bulkImport';
import { Columns, COMMA_DELIMETER, DASH_DELIMETER, PromotionStatuses } from '../../../constants';
import { appsyncToLocal, localToAppsync, parseArrayField } from '../../../helpers/translatePromotions';
import {
  createPromotionShell,
  isAllowedPageZoneNumberCombination,
  isPromotionActive,
} from '../../../helpers/promotions';
import { isPastEditDeadline } from "../../../helpers/promotionWeek";
import { isEmptyOrUnfilledArray } from '../../../helpers/common';
import { isCurrentUserAdmin } from '../../../selectors/user';
const getElementIndexByColumnName = (columnName, compositeKeyPartsLocation) => {
  if (!columnName) {
    return -1;
  }

  const index = compositeKeyPartsLocation[columnName.toLowerCase()];
  if (!index && index !== 0) {
    return -1;
  }

  return index;
};

const getColumnValueByName = (row, columnName, compositeKeyPartsLocation) => {
  const columnIndex = getElementIndexByColumnName(
    columnName,
    compositeKeyPartsLocation,
  );
  
  return row[columnIndex] || '';
};

const getCompositeKey = (row, keyColumnList, compositeKeyPartsLocation) => {
  return [
    getColumnValueByName(
      row,
      Columns.PAGE.display,
      compositeKeyPartsLocation,
    ),
    getColumnValueByName(
      row,
      Columns.ZONE_NUMBER.display,
      compositeKeyPartsLocation,
    ),
    getColumnValueByName(
      row,
      Columns.STORE_REGIONS.display,
      compositeKeyPartsLocation,
    ).replace(/\s/g, ''),
  ].join(DASH_DELIMETER);
};

const addError = (entity, columnName, errorMessage) => {
  if (!entity.errors) {
    entity.errors = {};
  }
  if (!entity.errors[columnName]) {
    entity.errors[columnName] = {};
  }

  entity.errors[columnName].text = errorMessage;

  return entity;
};

export const translatePromotions = (promotions) => promotions.map((promotion) => {
  const { storeRegions, picturedAsins } = promotion;
  return {
    ...promotion,
    ...parseArrayField(Columns.STORE_REGIONS.name, storeRegions),
    ...parseArrayField(Columns.PICTURED_ASINS.name, picturedAsins),
  };
});

const parseEmptyLists = (promotion) => {
  return {
    [Columns.ASINS.name]: promotion.asins || [],
    [Columns.PICTURED_ASINS.name]: promotion.picturedAsins || [],
    [Columns.STORE_IDS.name]: promotion.storeIds || [],
    [Columns.STORE_REGIONS.name]: promotion.storeRegions || [],
  };
};

const parsePromotion = (promotion) => {
  const updatedPromotion = {
    ...promotion,
    ...parseEmptyLists(promotion),
  };
  return updatedPromotion
};

const comparePromotionArrays = (existingPromo, newPromo, arrayFields) => {
  let isEqual = true;
  for (const field of arrayFields) {

    const existingEmpty = _.isEmpty(existingPromo[field]) || isEmptyOrUnfilledArray(existingPromo[field]);
    const newEmpty = _.isEmpty(newPromo[field]) || isEmptyOrUnfilledArray(newPromo[field]);

    if (existingEmpty && newEmpty) {
      isEqual = true;
    }
    else if (field === Columns.PICTURED_ASINS.name) {
      isEqual = _.isEqual(JSON.stringify(existingPromo[field], newPromo[field]))
    }
    else if (_.isEqual(existingPromo[field], newPromo[field])) {
      isEqual = true;
    }
    else {
      isEqual = false;
      break;
    }
  }

  return isEqual;
};

const comparePromotionEndDates = (existingPromo, newPromo) => {
  const existingDate = existingPromo[Columns.PROMOTION_END_DATE.name]
  const newDate = newPromo[Columns.PROMOTION_END_DATE.name]
  if ( _.isEmpty(existingDate) && _.isEmpty(newDate)) {
    return true;
  }

  return moment(existingDate).isSame(moment(newDate), 'day')
};

const isExistingPromotion = (existingPromo, newPromo) => {

  const promoToCompare = parsePromotion(newPromo);
  const existingPromoCopy = {...existingPromo}
  const isEqualPromotionEndDates = comparePromotionEndDates(existingPromoCopy, promoToCompare);

  delete promoToCompare.promotionEndDate;
  delete existingPromoCopy.promotionEndDate;
  delete promoToCompare.tempId;
  delete existingPromoCopy.auditedStoreRegions;

  const isEqualArrays = comparePromotionArrays(existingPromoCopy, promoToCompare, BulkImportCompareColumns)

  BulkImportCompareColumns.forEach((field) => {
    delete promoToCompare[field];
    delete existingPromoCopy[field];
  });

  
   return _.isEqual(existingPromoCopy, promoToCompare) && isEqualArrays && isEqualPromotionEndDates;
};

const removeUtf8SpecialCharacters = (str) => {
  return str ? str.replace(/[^\w\s\#\?]/gi, '') : str;
};


const processBulkData = (
  state,
  parsedRows,
  columnOrder,
  existingPromotions = {},
  promotionWeek,
  allowedPageZoneCombinations,
) => {
  const create = [];
  const update = [];
  const rejected = [];
  const ignored = [];
  let formatIssues = 0;

  const [headerColumns = [], ...rows] = parsedRows;
  const allowedColumnsNames = {};
  const compositeKeyPartsLocation = {};

  const {
    yearQuarter, startDate, endDate, editDeadline,
  } = promotionWeek;

  const compositeKeyColumns = [
    Columns.PAGE.display,
    Columns.ZONE_NUMBER.display,
    Columns.STORE_REGIONS.display,
  ].map((columnKey) => columnKey.trim().toLowerCase());

  columnOrder.forEach((column) => {
    headerColumns.forEach((headerColumn, headerColumnIndex) => {
      const formattedColumnNameFromMetadata = column.display.toLowerCase().trim();
      const formattedHeaderColumnName = removeUtf8SpecialCharacters(headerColumn.toLowerCase().trim());

      if (formattedHeaderColumnName === formattedColumnNameFromMetadata) {
        if (compositeKeyColumns.includes(formattedColumnNameFromMetadata)) {
          compositeKeyPartsLocation[formattedColumnNameFromMetadata] = headerColumnIndex;
        }
        allowedColumnsNames[headerColumnIndex] = {
          name: column.name,
        };
      }
    });
  });

  const allowedColumnsLength = Object.keys(allowedColumnsNames).length;

  rows.forEach((row) => {
    if (row.length !== allowedColumnsLength) {
      // data mismatch
      formatIssues++;
      return;
    }
    const compositeKey = getCompositeKey(
      row,
      [
        Columns.PAGE.display,
        Columns.ZONE_NUMBER.display,
        Columns.STORE_REGIONS.display,
      ],
      compositeKeyPartsLocation,
    );

    const initialPromotion = existingPromotions[compositeKey]
    ? {
      ...existingPromotions[compositeKey],
    }
    : createPromotionShell({
      yearQuarter,
      startDate,
      endDate,
    });

    row.forEach((column, columnIndex) => {
      if (allowedColumnsNames[columnIndex] && allowedColumnsNames[columnIndex].name) {
        const columnName = allowedColumnsNames[columnIndex].name;
        if (columnName === 'createdAt') {
          return;
        }
        initialPromotion[columnName] = column;
      }
    });

    const isRejected = validatePromotion(state, initialPromotion);
    const rawPromotion = localToAppsync(initialPromotion);

    if (isRejected) {
      // localToAppsync removes errors
      rejected.push({
        ...rawPromotion,
        errors: initialPromotion.errors,
      });
      return;
    } 
    rawPromotion.tempId = uuidv4();

    const existingPromotion = existingPromotions[compositeKey];
    const promotionLocal = appsyncToLocal(rawPromotion);

    const doesPromotionExist = isExistingPromotion(existingPromotion, promotionLocal);
    const shouldUpdatePromotion = !!existingPromotion;

    if (doesPromotionExist) {
      ignored.push(promotionLocal);
    } else if (shouldUpdatePromotion) {
      if (!isPromotionActive(existingPromotions[compositeKey], isCurrentUserAdmin(state))) {
        addError(
          rawPromotion,
          Columns.VENDOR_MANAGER.name,
          'Promotion is uneditable',
        );
        rejected.push(rawPromotion);
        return;
      }

      const compositeKeyExistsInUpdateList = update.find((promotion) => {
        return String(promotion.page) === String(rawPromotion.page)
          && String(promotion.zoneNumber) === String(rawPromotion.zoneNumber)
          && String(promotion.storeRegions) === String(rawPromotion.storeRegions);
      });

      if (compositeKeyExistsInUpdateList) {
        addError(
          rawPromotion,
          [
            Columns.PAGE.display,
            Columns.ZONE_NUMBER.display,
            Columns.STORE_REGIONS.display
          ].join(`${COMMA_DELIMETER} `),
          'Duplicate promotion found, rejecting repeated promotion',
        );
        rejected.push(rawPromotion);
        return;
      }

      update.push(rawPromotion);
    } else if (isAllowedPageZoneNumberCombination(rawPromotion, allowedPageZoneCombinations)) {
      if (isPastEditDeadline(promotionWeek)) {
        rawPromotion.status = PromotionStatuses.CHANGE_REQUESTED.value;
      }

      create.push(createPromotionShell(rawPromotion));
    } else {
      addError(
        rawPromotion,
        Columns.ZONE_NUMBER.name,
        'Page/Zone number combination is not allowed',
      );
      rejected.push(rawPromotion);
    }
  });

  const translatedCreate = translatePromotions(create);
  const translatedUpdate = translatePromotions(update);
  const translatedReject = translatePromotions(rejected);

  return {
    accepted: {
      create: translatedCreate,
      update: translatedUpdate,
    },
    rejected: translatedReject,
    formatIssues,
  };
};

export default processBulkData;
