export const parseGraphQLResponse = (response) => {
  if (!response || !response.data) {
    return null;
  }

  const { data } = response;
  const methodName = Object.keys(data)[0];
  if (!methodName) {
    return null;
  }

  return data[methodName];
};
