import { DigitalCircularPreviewContentType, CARD_ID } from '../constants/digitalCircular';

export const generateCardParams = (digitalCircularParams) => {
  if (digitalCircularParams) {
    const cardParams = {
      config: {},
      content: {
        facets: [],
        linkParameters: {},
        metadataMap: {
          content: digitalCircularParams,
        },
        recommendations: [],
        reftag: 'mix',
      },
    };
    return JSON.stringify(cardParams, null, 2);
  }
  return '';
};

export const getCopiedContent = (contentType, digitalCircularParams) => {
  if (contentType === DigitalCircularPreviewContentType.ID) {
    return CARD_ID;
  }

  if (contentType === DigitalCircularPreviewContentType.CARD_PARAMS) {
    return generateCardParams(digitalCircularParams);
  }

  return '';
}