import {
  isCalendarRoute,
  isPromotionWeekRoute,
  isPublishedPromotionsRoute,
} from '../../helpers/router';
import { RouteRegexp } from '../../constants/router';
import { onSetTab, onSetDatesRange, onYearQuarterSelect } from '../../actionCreators';
import { TabTypes, WebsiteNavigationTabList } from '../../constants';
import { getYearQuarter } from '../../helpers/common';

const isWebsiteNavigationRoute = (route) => {
  return (
    isCalendarRoute(route)
    || isPublishedPromotionsRoute(route)
    || isPromotionWeekRoute(route)
  );
};

const getWebsiteNavigationTabNameByRoute = (route, state) => {
  if (isCalendarRoute(route)) {
    return WebsiteNavigationTabList.CALENDAR.name;
  }
  if (isPublishedPromotionsRoute(route)) {
    return WebsiteNavigationTabList.PUBLISHED_PROMOTION_WEEKS.name;
  }
  if (isPromotionWeekRoute(route)) {
    return route;
  }

  return '';
};

export default (dispatch, getState, action, next) => {
  const { payload: { location } } = action;
  if (!location) {
    return next(action);
  }

  const { pathname } = location;

  if (!isWebsiteNavigationRoute(pathname)) {
    return next(action);
  }

  const state = getState();
  const { Tabs: { [TabTypes.WEBSITE_NAVIGATION]: currentWebsiteNavigationTabName } } = state;

  const newWebsiteNavigationTabName = getWebsiteNavigationTabNameByRoute(pathname, state);
  if (
    newWebsiteNavigationTabName
    && newWebsiteNavigationTabName !== currentWebsiteNavigationTabName
  ) {
    dispatch(onSetTab(TabTypes.WEBSITE_NAVIGATION, newWebsiteNavigationTabName));
  }

  if (isPromotionWeekRoute(pathname)) {
    const routeParts = pathname.match(RouteRegexp.PROMOTION_WEEK);
    if (routeParts && routeParts.length === 3) {
      const [, startDate, endDate] = routeParts;
      dispatch(onSetDatesRange([startDate, endDate]));
      dispatch(onYearQuarterSelect({ yearQuarter: getYearQuarter(startDate) }));
    }
  }

  return next(action);
};
