import {
  NOOP,
  GRAPHQL_ACTION,
} from '../constants';

export default ({
  graphqlAction = null,
  data = null,
  onSuccessAction = () => ({ type: NOOP }),
  onFailureAction = () => ({ type: NOOP }),
  onSuccessFunction = () => {},
  onFailureFunction = () => {},
  label = 'unlabeled action',
}) => ({
  type: GRAPHQL_ACTION,
  payload: {
    graphqlAction,
    data,
    onSuccessAction,
    onFailureAction,
    onSuccessFunction,
    onFailureFunction,
    label,
  },
});
