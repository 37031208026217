import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import styles from './PublishedPromotionList.module.scss';
import GridView from '../GridView';
import ExportButton from '../ExportButton';
import { getStringParts, getHumanFriendlyDate } from '../../helpers/promotions';
import { PromotionExportViewType } from '../../constants/export';

const PublishedPromotionList = ({ promotions, columnOrder, onShowAuditLog }) => {
  const getTitle = () => {
    if (!promotions || !promotions.length) {
      return null;
    }
    const [yearQuarter, dateStart, dateEnd] = getStringParts(promotions[0].yearQuarter_startDate_endDate);

    const week = `${getHumanFriendlyDate(dateStart)} - ${getHumanFriendlyDate(dateEnd)} (${yearQuarter})`;

    if (!week) {
      return null;
    }

    return (
      <h3>Published promotions for week: {week}</h3>
    );
  };

  const getButtons = () => {
    return (
      <div className={styles.buttonsContainer}>
        <div className={styles.buttonsContainerInner}>
          <Button
            onClick={onShowAuditLog}
            type="tertiary"
            size="small"
          >
            Show version history
          </Button>
          <ExportButton viewType={PromotionExportViewType.PUBLISHED} />
        </div>
      </div>
    );
  };

  const title = getTitle();
  const buttons = getButtons();

  return (
    <div className={styles.gridContainer}>
      <div className={styles.header}>
        {title}
      </div>
      <GridView
        promotions={promotions}
        columnOrder={columnOrder}
        showActionBar={false}
        enableContextMenu={false}
        isReadOnly
      />
      {buttons}
    </div>
  );
};

PublishedPromotionList.propTypes = {
  onShowAuditLog: PropTypes.func.isRequired,
};

export default PublishedPromotionList;
