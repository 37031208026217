import React from 'react';
import Modal from '@amzn/meridian/modal';
import TemplateBuilderContainer from '../../../TemplateBuilderContainer';
import { ModalNames } from '../../../../constants/metrics';
import WithHistoryTiming from '../../../WithHistoryTiming';

const EditTemplatePage = (props) => {
  const {
    template,
    match: { params: { id } },
  } = props;

  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={() => window.history.back()}
    >
      <TemplateBuilderContainer
        {...props}
        templateId={id}
        template={template}
      />
    </Modal>
  );
};

export default WithHistoryTiming(EditTemplatePage, { pageName: ModalNames.EDIT_TEMPLATES });
