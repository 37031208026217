import { connect } from 'react-redux';
import TemplateBuilderContainer from './TemplateBuilderContainer';
import {
  onSetRows,
  onSetTab,
  onSetTemplateName,
  onResetActiveTemplate,
  onSelectTemplateForEditing,
} from '../../actionCreators';
import { TabTypes } from '../../constants';

export const mapStateToProps = (state) => {
  const {
    Meta: {
      initialMetadataLoaded,
    },
    Tabs: {
      [TabTypes.TEMPLATE_BUILDER]: currentTab,
    },
    Templates: {
      activeTemplate,
      templates,
    },
  } = state;

  return {
    shouldShow: initialMetadataLoaded,
    currentTab,
    activeTemplate,
    templates,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onLoadTemplate(id) {
      dispatch(onSelectTemplateForEditing(id));
    },
    onSwitchTab(tabName) {
      dispatch(onSetTab(TabTypes.TEMPLATE_BUILDER, tabName));
    },
    onReset() {
      dispatch(onSetRows([]));
      dispatch(onSetTemplateName(''));
      dispatch(onResetActiveTemplate());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilderContainer);
