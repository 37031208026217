import React from 'react';
import PropTypes from 'prop-types';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import styles from './PromoImageReviewActionBar.module.scss';
import Tooltip from '@amzn/meridian/tooltip';

const PromoImageReviewActionBar = (props) => {
  const {
    promotion,
    onApprove,
  } = props;
  const { id } = promotion;

  const onApprovePromoImage = (promoId) => () => onApprove(promoId);

  const getControls = (promoId) => {
    const controls = [
      {
        name: 'Approve',
        handler: onApprovePromoImage,
        icon: checkCircleTokens,
      },
    ];

    const controlsList = controls.map(({ name, icon, handler }) => {
      return (
        <li key={name} className={styles.item}>
          <Tooltip position='top' title={name}>
            <Link
              key={`${name}/${promoId}`}
              href="#"
              rel="noopener noreferrer"
              onClick={handler(promoId)}
            >
              <Icon tokens={icon}>
                {name}
              </Icon>
            </Link>
          </Tooltip>
        </li>
      );
    });

    return (
      <ul className={styles.controlsList}>
        {controlsList}
      </ul>
    );
  };

  return getControls(id);
};

PromoImageReviewActionBar.propTypes = {
  onApprove: PropTypes.func.isRequired,
};

export default PromoImageReviewActionBar;
