// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
const arrayMove = (arrToModify, oldIndex, newIndex) => {
  const arr = [...arrToModify];
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export default arrayMove;
