import { formValueSelector, change } from 'redux-form';
import { Columns, COMMA_DELIMETER, Form } from '../../constants';
import {
  onAsinGetProductImages,
  onAsinSetProductImages,
} from '../../actionCreators';
import extractUniqueAsins from '../../helpers/extractUniqueAsins';
import { getDependentChildFields } from '../../helpers/form';
import extractAsins from '../../helpers/extractAsins';

const resetDependentChildFields = (dispatch, meta, fields) => {
  const { field } = meta;

  const childDependentFields = getDependentChildFields(field, fields);
  if (!childDependentFields) {
    return;
  }
  childDependentFields.forEach((childFieldName) => {
    dispatch(change(Form.PROMO, childFieldName, ''));
  });
};

const selector = formValueSelector(Form.PROMO);

export default (dispatch, getState, action, next) => {
  const { meta } = action;

  const state = getState();

  const {
    Meta: { metadata: { fields } },
  } = state;

  if (meta.field === Columns.ASINS.name) {
    const asins = selector(state, Columns.ASINS.name);
    if (!asins) {
      dispatch(onAsinSetProductImages([]));

      return next(action);
    }

    const uniqueAsins = extractUniqueAsins(asins);
    dispatch(onAsinGetProductImages(uniqueAsins[0]));

    return next({
      ...action,
      payload: uniqueAsins.join(COMMA_DELIMETER),
    });
  }

  if (meta.field === Columns.PICTURED_ASINS.name) {
    const { payload: asinString } = action;
    const picturedAsins = extractAsins(asinString);
    if (picturedAsins && picturedAsins.length) {
      const [firstAsin] = picturedAsins;

      dispatch(onAsinGetProductImages(firstAsin));
    }

    return next(action);
  }

  resetDependentChildFields(dispatch, meta, fields);

  return next(action);
};
