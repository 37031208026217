import { createSelector } from 'reselect';

export const getMetadataFilterString = (state) => {
  return state.MetadataAuditLog.filterString;
};

export const getMetadataAuditLogs = (state) => {
  const { MetadataAuditLog: { changes } } = state;
  return changes;
};

export const findValueInItem = (item, value) => {
  const regex = new RegExp(value, 'i');
  return regex.test(item);
};

export const findValueInArray = (arr, value) => {
  const found = !!arr.find((item) => {
    return findValueInItem(item, value);
  });
  return found;
};

export const getFilteredMetadataAuditLogs = createSelector(
  [getMetadataFilterString, getMetadataAuditLogs],
  (filterString, auditLogs) => {
    if (!filterString) { return auditLogs; }
    return auditLogs.filter((auditLog) => {
      const { changes: [{ add, remove }], username } = auditLog;
      return findValueInArray(add, filterString)
        || findValueInArray(remove, filterString)
        || findValueInItem(username, filterString);
    });
  },
);
