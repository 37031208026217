export const PromotionExportViewType = Object.freeze({
  PREVIEW: 'PREVIEW',
  PUBLISHED: 'PUBLISHED',
});
export const PromotionExportTypes = Object.freeze({
  STANDARD: {
    display: 'Standard',
    value: 'standard',
  },
  EXTENDED: {
    display: 'Extended',
    value: 'extended',
  },
  VIABILITY: {
    display: 'Viability',
    value: 'viability',
  },
  BLANK_TEMPLATE: {
    display: 'Blank (for bulk uploading)',
    value: 'blank',
  },
});
export const ExportActions = Object.freeze({
  EXPORT_CURRENT_VIEW: 'EXPORT_CURRENT_VIEW',
  EXPORT_PROMOTIONS: 'EXPORT_PROMOTIONS',
  EXPORT_PROMOTIONS_ASINS_VIABILITY: 'EXPORT_PROMOTIONS_ASINS_VIABILITY',
  OPEN_EXPORT_MODAL: 'OPEN_EXPORT_MODAL',
  CLOSE_EXPORT_MODAL: 'CLOSE_EXPORT_MODAL',
  SET_EXPORT_TYPE: 'SET_EXPORT_TYPE',
  SET_LOADING: 'SET_LOADING',
});
