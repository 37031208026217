import { Hosts } from '../constants';

const oauth = {
  domain: 'f3-circulator-domain-beta.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `http://${Hosts.DEV}`,
  redirectSignOut: `http://${Hosts.DEV}`,
  responseType: 'code',
};

const config = {
  Auth: {
    identityPoolId: 'us-east-1:6fb35344-80c9-4839-a34d-d8f315832f33',
    region: 'us-east-1',
    userPoolId: 'us-east-1_yBlFzcYlY',
    userPoolWebClientId: '7q1jd244t3nbhinmbrn0gtbgcg',
    oauth,
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://4jgoi7pq3ncf3iewmengm7wejy.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
};

export default config;
