import { COMMA_DELIMETER } from '../constants';
import { getSelectedPromotions } from '../selectors/promotions';

export const getCustomColumnOrder = (state) => {
  const {
    CustomView: { customColumnOrder },
    Meta: { metadata: { columnOrder } },
  } = state;

  const selectedColumnOrder = customColumnOrder ? customColumnOrder : columnOrder;

  return selectedColumnOrder.map(column => column.name);
};

export const getFormattedPromotions = (state) => {
  return getSelectedPromotions(state).map(promotion => {
    return getCustomColumnOrder(state).map(column => {
      const value = promotion[column];
      return Array.isArray(value) ? value.join(`${COMMA_DELIMETER} `) : value;
    });
  });
};

export const getFormattedForClipboardPromotions = (promotions) => {
  return promotions.map(promotion => promotion.join('\t')).join('\n');
};