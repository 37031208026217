import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Popover from '@amzn/meridian/popover';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Checkbox from '@amzn/meridian/checkbox';
import arrowsUpAndDownTokens from '@amzn/meridian-tokens/base/icon/arrows-up-and-down';
import Messages from '../Messages';
import treeSelectControlStyles from './TreeSelectControl.module.scss';
import { formControlInputProps } from '../../../proptypes';
import {
  translateDataFromControl,
  translateDataToControl,
  updateOptions,
  isAllOptionsChecked,
} from '../../../helpers/treeSelect';

const TreeSelectControl = (props) => {
  const {
    options,
    label,
    input,
    placeholder,
    meta,
    isRequired,
    isDisabled,
    showSelectAll,
    autoOpen,
    formatInputData,
    isFormViewMode,
  } = props;

  let buttonRef;

  const formatInput = formatInputData && typeof formatInputData === 'function'
    ? formatInputData
    : (data) => data;

  const {
    value: selectedOptions, name: inputName, onBlur, onChange,
  } = input;

  const [optionsList, setOptionsList] = useState([]);
  const [isDropDownListOpen, setIsDropDownListOpen] = useState(autoOpen);

  const onCommitChanges = () => {
    onChange(inputName, translateDataFromControl(optionsList));
    if (!isFormViewMode) {
      onBlur();
    }
  };

  useEffect(() => {
    const translatedData = translateDataToControl(
      formatInput(options, selectedOptions),
      selectedOptions,
    );

    setOptionsList(translatedData);

    return () => {
      setOptionsList([]);
    };
  }, [input]);

  const onToggleAll = (checked) => () => {
    setOptionsList(updateOptions([...optionsList], checked));
  };

  const onToggleDropDownList = () => {
    if (isDisabled) {
      return;
    }

    setIsDropDownListOpen(!isDropDownListOpen);
  };

  const onInputChange = (value, checked) => {
    setOptionsList(updateOptions([...optionsList], checked, value));
  };

  const onClose = () => {
    onCommitChanges();
    onToggleDropDownList();
  };

  const getRequiredMark = () => {
    if (!isRequired) {
      return null;
    }

    return <span className={treeSelectControlStyles.controlRequiredMark}>*</span>;
  };

  const getTagsList = () => {
    const tagsList = translateDataFromControl(optionsList);

    const tagItemList = tagsList && tagsList.length > 0
      ? tagsList.map((tagItem) => {
        return (
          <li
            key={tagItem}
            className={treeSelectControlStyles.tagItem}
          >
            <span className={treeSelectControlStyles.tag}>
              {tagItem}
            </span>
          </li>
        );
      })
      : (
        <li
          key="empty-list"
          className={classnames([
            treeSelectControlStyles.tagItem,
            treeSelectControlStyles.tagItemNoContent,
          ])}
        >
          <span className={treeSelectControlStyles.tag}>
            {label || placeholder || ''}
          </span>
        </li>
      );
    const tagsListClickHandler = !autoOpen ? onToggleDropDownList : _.noop;

    return (
      <ul
        className={treeSelectControlStyles.tagList}
        onClick={tagsListClickHandler}
      >
        {tagItemList}
      </ul>
    );
  };

  const getDropDownButton = () => {
    buttonRef = useRef();

    return (
      <Button
        type="icon"
        onClick={onToggleDropDownList}
        ref={buttonRef}
      >
        <Icon tokens={arrowsUpAndDownTokens}>Show/hide options list</Icon>
      </Button>
    );
  };

  const getOption = (isChecked, value, label, handler, positionShift) => {
    return (
      <li
        key={value}
        className={treeSelectControlStyles.item}
        style={{ paddingLeft: positionShift }}
      >
        <Checkbox
          checked={isChecked}
          onChange={handler}
          value={value}
        >
          {label}
        </Checkbox>
      </li>
    );
  };

  const getOptionsList = (options, spaceFromLeft = 0) => {
    if (!options || !options.length) {
      return null;
    }

    const newSpaceFromLeft = spaceFromLeft + 10;

    return options.map((option) => {
      const items = [];
      const {
        label, value, checked, children,
      } = option;

      items.push(getOption(
        checked,
        value,
        label,
        (isChecked) => onInputChange(value, isChecked),
        newSpaceFromLeft,
      ));

      if (children) {
        items.push(
          ...getOptionsList(children, newSpaceFromLeft),
        );
      }

      return items;
    });
  };

  const getDropDownList = () => {
    const itemList = getOptionsList(optionsList);
    if (showSelectAll && itemList) {
      const isChecked = isAllOptionsChecked(optionsList);
      itemList.unshift(getOption(
        isChecked,
        '',
        'Select All',
        onToggleAll(!isChecked),
        5,
      ));
    }

    return (
      <Popover
        anchorNode={buttonRef.current}
        open={isDropDownListOpen}
        onClose={onClose}
        position="right"
      >
        <ul className={treeSelectControlStyles.list}>
          {itemList}
        </ul>
      </Popover>
    );
  };

  const getSelectContainer = () => {
    const dropDownButton = getDropDownButton();
    const tagsList = getTagsList();
    const requiredMark = getRequiredMark();

    return (
      <div className={treeSelectControlStyles.selectContainer}>
        {tagsList}
        <span className={treeSelectControlStyles.dropdownSelectControl}>
          {dropDownButton}
        </span>
        {requiredMark}
      </div>
    );
  };

  const selectContainer = getSelectContainer();
  const dropDownList = getDropDownList();

  return (
    <div className={treeSelectControlStyles.treeSelectContainer}>
      {selectContainer}
      {dropDownList}
      <Messages meta={meta} />
    </div>
  );
};

TreeSelectControl.propTypes = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  autoOpen: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  isFormViewMode: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

TreeSelectControl.defaultProps = {
  isFormViewMode: false,
  isRequired: false,
  isDisabled: false,
  showSelectAll: false,
  autoOpen: false,
  label: '',
  placeholder: '',
  options: [],
  meta: null,
};

export default TreeSelectControl;
