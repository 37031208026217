import { COMMA_DELIMETER, Form } from '../../constants';
import { onOpenToast } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { form } = getState();

  const formName = form[Form.PROMO];

  const hasErrors = formName
    && formName.syncErrors
    && Object.keys(formName.syncErrors).length;

  if (!hasErrors) {
    return next(action);
  }

  const listOfFields = Object.keys(formName.syncErrors).join(`${COMMA_DELIMETER} `);
  const message = `[Error] Please make sure all of the following fields are correctly filled out: ${listOfFields}!`;

  dispatch(onOpenToast(message));

  return next(action);
};
