import { connect } from 'react-redux';
import PromoReviewActionBar from './PromoReviewActionBar';
import { Language, PromotionTabsList, TabTypes } from '../../../constants';
import {
  onApprovePromotion, onRejectPromotion,
  onSetTab,
} from '../../../actionCreators';

const mapStateToProps = (state, { promotion }) => {
  return {
    promotion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onApprove(id) {
      if (window.confirm(Language.PROMOTION_REVIEW_APPROVE)) {
        dispatch(onApprovePromotion(id));
      }
    },
    onReject(id) {
      if (window.confirm(Language.PROMOTION_REVIEW_REJECT)) {
        dispatch(onRejectPromotion(id));
      }
    },
    onShowAuditLog() {
      dispatch(onSetTab(TabTypes.PROMOTION, PromotionTabsList.AUDIT_LOG.name));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoReviewActionBar);
