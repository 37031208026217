import React from 'react';
import { FieldArray } from 'redux-form';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import minusCircleTokens from '@amzn/meridian-tokens/base/icon/minus-circle';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import FormField from '../../../PromoForm/FormField';
import { createNestedFormField } from '../../../../helpers/form';
import { BannerColumns, BannerConfig } from '../../../../constants/banner';
import { createBannerShell } from '../../../../helpers/banner';
import styles from '../../BannerForm.module.scss';

export const bannerListRender = ({ fields }) => {
  const bannerList = fields.map((bannerGroupName, index) => {
    const nestedFormField = createNestedFormField([
      bannerGroupName,
    ]);

    return (
      <div className={styles.formGroupWrapper}>
        <div className={styles.formSlotContainer}>
          <div className={styles.formGroupContent}>
            <div className={styles.formGroupSection}>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.DESKTOP_IMAGE)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.DESKTOP_IMAGE_ALT)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.DESKTOP_LINK)}
                />
              </div>
            </div>
            <div className={styles.formGroupSection}>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.MOBILE_IMAGE)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.MOBILE_IMAGE_ALT)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.MOBILE_LINK)}
                />
              </div>
            </div>
            <div className={styles.formGroupSection}>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.SLOT)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.STORE_IDS)}
                />
              </div>
              <div className={styles.formControl}>
                <FormField
                  {...nestedFormField(BannerColumns.STORE_REGIONS)}
                />
              </div>
            </div>
          </div>
          <div>
            <Button type="icon" onClick={() => fields.remove(index)}>
              <Icon tokens={minusCircleTokens}>Remove</Icon>
            </Button>
          </div>
        </div>
        <hr />
      </div>
    );
  });

  return (
    <div className={styles.bannerFormContainer}>
      <div>
        {bannerList}
      </div>
      <div className={styles.addButtonContainer}>
        <Button type="icon" onClick={() => fields.push(createBannerShell())}>
          <Icon tokens={plusCircleTokens}>Add</Icon>
        </Button>
      </div>
    </div>
  );
}


const Main = () => {
  return (
    <div className={styles.formGroup}>
      <FieldArray
        name={BannerConfig.FORM_STORAGE_VAR_NAME}
        component={bannerListRender}
      />
    </div>
  );
};

export default Main;
