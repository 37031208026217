import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { createPromotion, deletePromotion, updatePromotion } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { submitStringMetric, submitAppSyncError } from '../../metrics/index';
import { hasPromotionChanged } from '../../helpers/common';
import activeCellInputSubmit from './activeCellInputSubmit';
import flashFill from './flashFill';

import {
  Columns,
  FETCH_PROMOTION_WEEKS,
  GraphQLLabels,
  GridViewActions,
  GridViewCommitTypes,
  Language,
  PromoActions,
} from '../../constants';

import {
  onSetPromotions,
  onActiveCellInputSubmit,
  onUpdatedPromotionReceived,
  onAddedPromotionReceived,
  onSendPromotionEdit,
  onClearPromotions,
  onSetPromotionsLoading,
  onOpenToast,
  onShowLoadingSpinner,
  onParsePromotionASINs,
  onTemplateSave,
  onPushToEditHistory,
  onCloseAddRowModal,
  onCheckLoadingStatus,
  onCellInputUpdate,
} from '../../actionCreators';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import {
  appsyncToLocal,
  localToAppsync,
  getNewPromotionExcludedFields,
  formatPromotionForMutationInput,
} from '../../helpers/translatePromotions';
import { onBulkImportActionCompleted } from '../../actionCreators/bulkImport';
import { BulkImportActions } from '../../constants/bulkImport';
import { ToastMessages } from '../../constants/message';
import { ModificationTypes } from '../../constants/auditLog';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants/metrics';
import { isPromotionWeekLoaded } from '../../selectors/loadingStatus';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { formatPriceForDisplay } from '../../helpers/promotions';
import activeCellInputUpdate from './activeCellInputUpdate';

const movementAndSelectionActions = [
  GridViewActions.MOVE_ACTIVE_CELL,
  GridViewActions.SELECT_CELL,
  GridViewActions.UNSELECT_CELL,
];

const translatePromotionWeeks = (promotionWeeks, metadata) => {
  return promotionWeeks.map((promotionWeek) => appsyncToLocal(promotionWeek, metadata));
};

const getUpdateReason = ({ isUndo = false, isRollBack = false }) => {
  if (isUndo) {
    return ModificationTypes.UNDO;
  }
  if (isRollBack) {
    return ModificationTypes.ROLLBACK;
  }
  return ModificationTypes.UPDATE;
};

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;

  const { User: { currentUser } } = getState();
  const username = currentUser ? currentUser.username : null;

  const getPromotions = (yearQuarter_startDate_endDate) => {
    const params = {
      yearQuarter_startDate_endDate,
    };

    const metricContext = {
      location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: params,
      action: MetricNames.GET_PROMOTION_WEEKS,
      username,
    };

    submitStringMetric(MetricNames.GET_PROMOTION_WEEKS, metricContext);
    return API.graphql(graphqlOperation(queries.getPromotionsForWeek, params))
      .then((result) => {
        if (!result || !result.data) {
          console.log('No promotions found for selected promo weeks!'); // eslint-disable-line no-console
          return;
        }
        const state = getState();
        const { Meta: { metadata } } = state;

        if (!isPromotionWeekLoaded(state, yearQuarter_startDate_endDate)) {
          const { data: { getPromotionsForWeek } } = result;
          const translatedPromotions = translatePromotionWeeks(getPromotionsForWeek, metadata);
          dispatch(onSetPromotions(translatedPromotions));
          dispatch(onCheckLoadingStatus());
        }
      }).catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  if (action.type === FETCH_PROMOTION_WEEKS) {
    const {
      yearQuarter_startDate_endDate,
      forceReload,
    } = action.data;

    if (forceReload) {
      dispatch(onClearPromotions());
    }

    const { GridViewPage: { promotionsLoaded } } = store.getState();

    if (promotionsLoaded) {
      return next(action);
    }

    dispatch(onSetPromotionsLoading(true));
    dispatch(onShowLoadingSpinner());
    next(action);
    return getPromotions(yearQuarter_startDate_endDate);
  }

  if (movementAndSelectionActions.indexOf(action.type) !== -1) {
    if (!hasPromotionChanged(store.getState())) {
      return next(action);
    }
    dispatch(onActiveCellInputSubmit());
  }

  if (action.type === GridViewActions.ACTIVE_CELL_INPUT_SUBMIT) {
    return activeCellInputSubmit(store, next, action);
  }

  if (action.type === GridViewActions.ACTIVE_CELL_INPUT_UPDATE) {
    return activeCellInputUpdate(store, next, action);
  }

  if (action.type === PromoActions.ADD_PROMOTION) {
    const {
      payload: {
        promotion: newPromotion,
        options: { isBulkAction, isAddRowAction },
      },
    } = action;
    const {
      Meta: {
        metadata,
        types: { Promotion: promotionTypeFields },
      },
    } = store.getState();
    const excludedFields = getNewPromotionExcludedFields();

    const promotionToSend = localToAppsync(newPromotion);
    promotionToSend.version = 1;

    Object.keys(promotionToSend).forEach((key) => {
      if (excludedFields[key] || !promotionTypeFields.includes(key)) {
        delete promotionToSend[key];
      }
    });

    const dataToSend = {
      promotion: formatPromotionForMutationInput(promotionToSend),
      username,
    };

    const metricContext = {
      location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: dataToSend,
      action: MetricNames.ADD_PROMOTION,
      username,
    };

    submitStringMetric(MetricNames.ADD_PROMOTION, metricContext);
    dispatch(onGraphQLAction({
      graphqlAction: createPromotion,
      data: { input: dataToSend },
      label: GraphQLLabels.CREATE_PROMOTION,
      onSuccessFunction(response) {
        const { data: { createPromotion: result } } = response;
        const translatedPromotion = appsyncToLocal(result, metadata);
        dispatch(onAddedPromotionReceived(translatedPromotion));
        if (isAddRowAction) {
          dispatch(onCloseAddRowModal());
        }
        if (isBulkAction) {
          dispatch(onBulkImportActionCompleted(
            translatedPromotion.id,
            BulkImportActions.SUCCESS,
          ));
        } else {
          dispatch(onOpenToast(Language.PROMOTION_ADD_SUCCESS));
        }
      },
      onFailureFunction(error) {
        submitAppSyncError(error, metricContext);

        if (isBulkAction) {
          dispatch(onBulkImportActionCompleted(
            promotionToSend.id,
            BulkImportActions.ERROR,
          ));
        } else {
          dispatch(onOpenToast(Language.PROMOTION_ADD_ERROR));
        }
      },
    }));
    return next(action);
  }

  if (action.type === PromoActions.UPDATE_PROMOTION) {
    const {
      payload: {
        promotion: { id: rowId },
        options: { isStatusUpdate, isUndo, isRollBack },
      },
    } = action;

    // If form caching is enabled, we need to pass up to date version
    const { GridViewPage: { promotions } } = store.getState();
    const currentVersion = promotions.find((promotion) => promotion.id === rowId);
    const newAction = {
      type: action.type,
      payload: {
        ...action.payload,
        version: currentVersion.version,
      },
    };

    next(newAction);
    dispatch(onSendPromotionEdit(
      { rowId },
      {
        isStatusUpdate,
        isUndo,
        isRollBack,
        showToast: true,
      },
    ));
    return null;
  }

  if (action.type === GridViewActions.FETCH_PROMOTION) {
    const metricContext = {
      location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: action.data,
      action: MetricNames.FETCH_PROMOTION,
      username,
    };

    dispatch(onGraphQLAction({
      graphqlAction: queries.getPromotion,
      data: { id: action.data },
      label: GraphQLLabels.GET_PROMOTION,
      onSuccessFunction(data) {
        const fetchedPromotion = data.data.getPromotion;
        dispatch(onUpdatedPromotionReceived(fetchedPromotion));
      },
      onFailureFunction(error) {
        submitAppSyncError(error, metricContext);
      },
    }));
  }

  if (action.type === GridViewActions.FLASH_FILL) {
    return flashFill(store, next, action);
  }

  if (action.type === GridViewActions.SEND_PROMOTION_EDIT) {
    const {
      payload: {
        rowId,
        options: {
          showToast,
          isBulkAction,
          isStatusUpdate,
          isUndo,
          isRollBack,
        },
      },
    } = action;
    dispatch(onParsePromotionASINs(rowId));
    const {
      GridViewPage: { promotions },
      Promo: {
        dates: [startDate, endDate],
      },
      Meta: { metadata },
    } = store.getState();
    const editedRow = promotions.find((promotion) => promotion.id === rowId);
    if (!isUndo && !isBulkAction) {
      dispatch(onPushToEditHistory(rowId, editedRow.version, startDate, endDate));
    }
    const promotionToSend = localToAppsync(editedRow);
    const dataToSend = {
      id: rowId,
      promotion: formatPromotionForMutationInput(promotionToSend, isStatusUpdate),
      username,
      updateReason: getUpdateReason({ isUndo, isRollBack }),
    };

    const metricContext = {
      location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: dataToSend,
      action: MetricNames.EDIT_PROMOTION,
      username,
    };

    submitStringMetric(MetricNames.EDIT_PROMOTION, metricContext);
    dispatch(onGraphQLAction({
      graphqlAction: updatePromotion,
      data: { input: dataToSend },
      label: GraphQLLabels.UPDATE_PROMOTION,
      onSuccessFunction() {
        if (showToast) {
          const message = isUndo ? ToastMessages.UNDO_SUCCESS : ToastMessages.UPDATE_SUCCESS;
          dispatch(onOpenToast(message));
        }
        if (isBulkAction) {
          dispatch(onBulkImportActionCompleted(
            promotionToSend.id,
            BulkImportActions.SUCCESS,
          ));
        }
      },
      onFailureFunction(error) {
        const message = isUndo ? ToastMessages.UNDO_FAIL : ToastMessages.UPDATE_FAIL;
        dispatch(onOpenToast(message));
        submitAppSyncError(error, metricContext);

        if (isBulkAction) {
          dispatch(onBulkImportActionCompleted(
            promotionToSend.id,
            BulkImportActions.ERROR,
          ));
        }
      },
    }));
    return next(action);
  }

  if (action.type === PromoActions.DELETE_PROMOTION) {
    const { payload: { promotion: { id }, isSilentDelete } } = action;
    const { GridViewPage: { promotions } } = store.getState();

    const promotionToDelete = promotions.find((promotion) => promotion.id === id);
    if (!promotionToDelete || !Object.keys(promotionToDelete).length) {
      dispatch(onOpenToast('Error while deleting promotion!'));

      return next(action);
    }

    const promotionToSend = localToAppsync(promotionToDelete);
    const dataToSend = {
      id,
      promotion: formatPromotionForMutationInput(promotionToSend),
      username,
    };

    const metricContext = {
      location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: JSON.stringify(dataToSend),
      action: MetricNames.DELETE_PROMOTION,
      username,
    };

    submitStringMetric(MetricNames.DELETE_PROMOTION, metricContext);
    dispatch(onGraphQLAction({
      graphqlAction: deletePromotion,
      data: { input: dataToSend },
      label: GraphQLLabels.DELETE_PROMOTION,
      onSuccessFunction() {
        if (!isSilentDelete) {
          dispatch(onOpenToast('Promotion has been deleted!'));
        }
      },
      onFailureFunction(error) {
        dispatch(onOpenToast('Error while deleting promotion!'));
        submitAppSyncError(error, metricContext);
      },
    }));

    return next(action);
  }

  if (action.type === GridViewActions.ADD_ROW) {
    const { payload: { row } } = action;

    return next({
      ...action,
      payload: {
        row: {
          ...row,
          id: uuidv4(),
        },
      },
    });
  }

  if (action.type === GridViewActions.COMMIT_ROWS) {
    const { payload: { commitType } } = action;

    if (!commitType) {
      return next(action);
    }

    if (GridViewCommitTypes.TEMPLATE_BUILDER === commitType) {
      dispatch(onTemplateSave());
    }
  }

  return next(action);
};
