import React from 'react';
import Input from '@amzn/meridian/input';

import { styledInputProps } from '../../proptypes';

const StyledInput = ({
  inputValue, onInputChange, onBlur, placeholder, size = 'small', type = 'text',
}) => {
  return (
    <Input
      placeholder={placeholder}
      value={inputValue || ''}
      onChange={onInputChange}
      onBlur={onBlur}
      size={size}
      type={type}
      width="100%"
    />
  );
};

StyledInput.propTypes = styledInputProps;

export default StyledInput;
