import { change, formValueSelector } from 'redux-form';
import { Columns, Form } from '../../constants';
import { formatPriceForDisplay } from '../../helpers/promotions';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const selector = formValueSelector(Form.PROMO);

  const circularPrice = getCurrentValueForField(Columns.CIRCULAR_PRICE.name, state, selector, action);
  const isPrimeBenefit = getCurrentValueForField(Columns.IS_PRIME_BENEFIT.name, state, selector, action);
  const priceConditions = getCurrentValueForField(Columns.PRICE_CONDITIONS.name, state, selector, action);
  const primeMemberLabel = getCurrentValueForField(Columns.PRIME_MEMBER_LABEL.name, state, selector, action);
  const promotionType = getCurrentValueForField(Columns.PROMOTION_TYPE.name, state, selector, action);
  const unitOfMeasure = getCurrentValueForField(Columns.UNIT_OF_MEASURE.name, state, selector, action);

  const { circularPriceDisplay, primeMemberLabelDisplay } = formatPriceForDisplay(circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure);
  dispatch(change(Form.PROMO, Columns.CIRCULAR_PRICE_DISPLAY.name, circularPriceDisplay));
  dispatch(change(Form.PROMO, Columns.PRIME_MEMBER_LABEL_DISPLAY.name, primeMemberLabelDisplay));

  return next(action);
}

const getCurrentValueForField = (field, state, selector, action) => {
  const { meta, payload } = action;
  if (meta.field === field) {
    return payload;
  }
  return selector(state, field);
}