import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import NewWeekPage from './new-week';
import { getSelectedTemplate } from '../../../../selectors/templates';

const getTemplateWithTempAssignedIds = (rows) => {
  return rows.map((row) => {
    return {
      ...row,
      id: uuidv4(),
    };
  });
};

export const mapStateToProps = (state) => {
  const { Meta: { metadata: { columnOrder } } } = state;

  const template = getSelectedTemplate(state);
  const templateRows = template && template.promoConfiguration
    ? getTemplateWithTempAssignedIds(template.promoConfiguration.rows)
    : [];

  return {
    templateRows,
    columnOrder,
  };
};

const mapDispatchToProps = () => ({
  onClose: () => window.history.back(),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewWeekPage);
