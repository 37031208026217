import {
  AuditLogActions,
} from '../../constants';

export const initialState = {
  auditLogType: null,
  isLoading: false,
  isLoaded: false,
  logs: [],
  rawLogs: [],
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case AuditLogActions.SET_AUDIT_LOG_TYPE: {
      const { payload: { auditLogType } } = action;

      return {
        ...state,
        auditLogType,
      };
    }
    case AuditLogActions.CLEAR: {
      return initialState;
    }
    case AuditLogActions.SET_ENTITY_DATA: {
      const { payload: { entityData } } = action;

      return {
        ...state,
        logs: entityData,
      };
    }
    case AuditLogActions.SET_RAW_ENTITY_DATA: {
      const { payload: { rawEntityData } } = action;

      return {
        ...state,
        rawLogs: rawEntityData,
      };
    }
    case AuditLogActions.CLEAR_RAW_ENTITY_DATA: {
      return {
        ...state,
        rawLogs: [],
      };
    }
    case AuditLogActions.SET_LOADING: {
      const { payload: { isLoading } } = action;

      return {
        ...state,
        isLoading,
      };
    }
    default: {
      return state;
    }
  }
};
