import React from 'react';
import PropTypes from 'prop-types';
import Link from '@amzn/meridian/link';
import styles from './HintLink.module.scss';

const HintLink = ({ urlLink, urlText }) => {
  return (
    <div className={styles.container}>
      <Link
        href={urlLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {urlText || urlLink}
      </Link>
    </div>
  );
};

HintLink.propTypes = {
  urlLink: PropTypes.string.isRequired,
  urlText: PropTypes.string,
};

HintLink.defaultProps = {
  urlText: '',
};

export default HintLink;
