import {
  onDeletedPromotionReceived,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { payload: { promotion } } = action;

  dispatch(onDeletedPromotionReceived(promotion));

  return next(action);
};
