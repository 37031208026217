import React from 'react';
import Modal from '@amzn/meridian/modal';
import TemplateBuilder from '../../TemplateBuilder';
import WithHistoryTiming from '../../WithHistoryTiming';
import { PageNames } from '../../../constants/metrics';

const NewTemplatePage = () => {
  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={() => window.history.back()}
    >
      <TemplateBuilder />
    </Modal>
  );
};

export default WithHistoryTiming(NewTemplatePage, { pageName: PageNames.NEW_TEMPLATE });
