import { change } from 'redux-form';
import {
  Columns,
  Form,
} from '../../constants';
import {
  onAsinValidatorReset,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { AsinValidator: { asinViabilities, selectedRegions } } = getState();

  if (!asinViabilities || !asinViabilities.length) {
    return next(action);
  }
  // Select regions only for the first available ASIN for now
  const [firstAsinViability] = asinViabilities;
  const featuredRegions = selectedRegions[firstAsinViability.asin];

  dispatch(change(Form.PROMO, Columns.STORE_REGIONS.name, featuredRegions, true));
  dispatch(onAsinValidatorReset());

  return next(action);
};
