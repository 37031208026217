import { RouterActions } from '../../constants';
import locationChange from './locationChange';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === RouterActions.LOCATION_CHANGE) {
    return locationChange(dispatch, getState, action, next);
  }

  return next(action);
};
