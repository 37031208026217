import {
  onPerformanceTimerStop,
} from '../../actionCreators';
import { submitTimerMetric, submitStringMetric } from '../../metrics/index';
import window from '../../helpers/window';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
  PerformanceTimerActions,
} from '../../constants/metrics';

const timeout = {};
const TIMEOUT = 60000 * 5; // 5 min timeout

export default ({ getState, dispatch }) => (next) => (action) => {
  if (action.type === PerformanceTimerActions.TIMER_START) {
    const { payload: { timerName } } = action;
    if (timeout[timerName]) {
      window.clearTimeout(timeout);
      submitStringMetric(
        `${MetricNames.MULTIPLE_PERFORMANCE_TIMERS_RECORDED}_${timerName}`,
        {
          location: MetricLocations.PERFORMANCE_TIMER_MIDDLEWARE,
          type: MetricTypes.LOG,
        },
      );
    }
    timeout[timerName] = window.setTimeout(() => {
      dispatch(onPerformanceTimerStop(timerName));
      submitStringMetric(
        `${MetricNames.NO_PERFORMANCE_TIME_RECORDED}_${timerName}`,
        {
          location: MetricLocations.PERFORMANCE_TIMER_MIDDLEWARE,
          type: MetricTypes.LOG,
        },
      );
    }, TIMEOUT);
  }
  if (action.type === PerformanceTimerActions.TIMER_STOP) {
    const { payload: { timerName } } = action;
    if (timeout[timerName]) {
      window.clearTimeout(timeout);
    }
    const state = getState();
    const { PerformanceTimer: { timers } } = state;
    if (!timers[timerName]) {
      submitStringMetric(
        `${MetricNames.PERFORMANCE_TIME_RECORDED_WITHOUT_START}_${timerName}`,
        {
          location: MetricLocations.PERFORMANCE_TIMER_MIDDLEWARE,
          type: MetricTypes.LOG,
        },
      );

      return next(action);
    }

    const timerDiff = window.performance.now() - timers[timerName];

    submitTimerMetric(
      `${MetricNames.PERFORMANCE_TIME_RECORDED}_${timerName}`,
      timerDiff,
    );
  }
  return next(action);
};
