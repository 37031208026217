import React from 'react';

import Editor from './editor';

import styles from './MetadataEditor.module.scss';

const MetadataEditor = ({ fields }) => {
  const editor = fields ? (<Editor />) : (<div>Loading</div>);

  return (
    <div className={styles.editorWrapper}>
      {editor}
    </div>
  );
};

export default MetadataEditor;
