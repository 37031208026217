import {
  onAuditLogClearRawEntityData,
  onAuditLogSetEntityData,
} from '../../actionCreators';
import { getTranslatedData } from '../../helpers/auditlog';

export default (dispatch, getState, action, next) => {
  const {
    AuditLog: { auditLogType, rawLogs },
  } = getState();

  if (rawLogs && rawLogs.length > 0) {
    const translatedData = getTranslatedData(rawLogs, auditLogType);

    dispatch(onAuditLogSetEntityData(translatedData));
    dispatch(onAuditLogClearRawEntityData());
  }

  return next(action);
};
