import { REST_API } from '../../constants';

import metadata from '../../fixtures/metadata';

export default ({ dispatch }) => (next) => (action) => {
  if (action.type === REST_API) {
    const { payload: { url, onSuccess } } = action;

    if (!url || !url.length) {
      return null;
    }

    // Mock return until backend is finalized
    if (url === '/metadata') {
      return dispatch(onSuccess(metadata));
    }
  }

  return next(action);
};
