import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/meridian/alert';
import Link from '@amzn/meridian/link';
import styles from './PromoReviewMessage.module.scss';

const getLink = (text, handler) => {
  return (
    <Link
      href="#"
      rel="noopener noreferrer"
      onClick={handler}
    >
      {text}
    </Link>
  );
};

const PromoReviewMessage = (props) => {
  const {
    isReviewPromotionTableOpen,
    onOpenReviewTable,
    onCloseReviewTable,
    reviewMessage,
  } = props;

  const onToggleTable = () => {
    if (isReviewPromotionTableOpen) {
      onCloseReviewTable();
    } else {
      onOpenReviewTable();
    }
  };

  const getMessage = () => {
    const clickHereLink = getLink('Click here', onToggleTable);
    const bodyMessageText = isReviewPromotionTableOpen
      ? 'hide the list'
      : 'show the list';

    return (
      <>
        <h3
          className={styles.messageTitle}
          dangerouslySetInnerHTML={{ __html: reviewMessage }} // eslint-disable-line react/no-danger
        />
        <div>
          <p
            className={styles.messageText}
          >
            {clickHereLink} to {bodyMessageText}
          </p>
        </div>
      </>
    );
  };

  const message = getMessage();

  return (
    <div className={styles.container}>
      <Alert type="warning">
        {message}
      </Alert>
    </div>
  );
};

PromoReviewMessage.propTypes = {
  reviewMessage: PropTypes.string,
};
PromoReviewMessage.defaultProps = {
  reviewMessage: '',
};

export default PromoReviewMessage;
