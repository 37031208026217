import { BulkImportActions } from '../constants/bulkImport';

export const onBulkImportProcessFile = (fileData) => ({
  type: BulkImportActions.PROCESS_FILE,
  payload: { fileData },
});
export const onBulkImportSetPendingPromotions = (processedPromotions) => ({
  type: BulkImportActions.SET_PENDING_PROMOTIONS,
  payload: {
    processedPromotions,
  },
});
export const onBulkImportPerformImport = () => ({
  type: BulkImportActions.PERFORM_IMPORT,
});
export const onBulkImportActionCompleted = (id, responseType) => ({
  type: BulkImportActions.ACTION_COMPLETED,
  payload: { id, responseType },
});
export const onBulkImportSetForImport = (expectedPromotionCount) => ({
  type: BulkImportActions.SET_FOR_IMPORT,
  payload: {
    expectedPromotionCount,
  },
});
export const onBulkImportStartImporting = () => ({
  type: BulkImportActions.START_IMPORTING,
});
export const onBulkImportCompleteImporting = () => ({
  type: BulkImportActions.COMPLETE_IMPORTING,
});
export const onBulkImportStartFileParsing = () => ({
  type: BulkImportActions.START_FILE_PARSING,
});
export const onBulkImportCompleteFileParsing = () => ({
  type: BulkImportActions.COMPLETE_FILE_PARSING,
});
export const onBulkImportReset = () => ({
  type: BulkImportActions.RESET,
});

export const onBulkImportSetFailedPromotions = (id) => ({
  type: BulkImportActions.SET_FAILED_PROMOTIONS,
  payload: {
    id,
  },
});
export const onBulkImportSetSucceededPromotions = (id) => ({
  type: BulkImportActions.SET_SUCCEEDED_PROMOTIONS,
  payload: {
    id,
  },
});
export const onOpenBulkImportModal = () => ({
  type: BulkImportActions.OPEN_MODAL,
});
export const onCloseBulkImportModal = () => ({
  type: BulkImportActions.CLOSE_MODAL,
});
