import { connect } from 'react-redux';
import { Columns } from '../../constants';
import {
  onCustomViewSetColumnOrder,
} from '../../actionCreators';

import CustomViewModal from './CustomViewModal';

export const mapStateToProps = (state) => {
  const {
    Meta: { metadata: { columnOrder } },
    CustomView: { customColumnOrder },
  } = state;
  const defaultColumnOrder = columnOrder.map((column) => {
    return {
      ...column,
      isVisible: true,
    };
  });
  const skipColumns = [Columns.PAGE.name, Columns.ZONE_NUMBER.name];
  return {
    columnOrder: customColumnOrder || defaultColumnOrder,
    defaultColumnOrder,
    skipColumns,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetColumns(columns) {
      dispatch(onCustomViewSetColumnOrder(columns));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomViewModal);
