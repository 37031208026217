import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {
  onSetPreviewDigitalCircularParams,
  onSetPreviewDigitalCircularParamsIsLoading,
} from '../../actionCreators';
import { UNDERSCORE_DELIMETER } from '../../constants';
import { getPromotionsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';

export const extractPromotionIds = (promotions) => promotions.filter((promo) => !promo.isSoftDeleted).map((promo) => promo.id);

export default (dispatch, getState, action, next) => {
  const { payload: { region } } = action;

  const promotions = getPromotionsForCurrentWeek(getState());
  if (!promotions) {
    return next(action);
  }

  const promotionIds = extractPromotionIds(promotions);
  const [firstPromotion] = promotions;
  const [yearQuarter, startDate, endDate] = firstPromotion.yearQuarter_startDate_endDate.split(UNDERSCORE_DELIMETER);
  const generateDigitalCircularParamsInput = {
    publishPromotionsInput: {
      promotionIds,
      startDate_endDate: `${startDate}_${endDate}`,
      username: 'preview',
      yearQuarter,
    },
    isPreview: true,
    targetRegion: region,
  };

  const onResponse = ({ data: { generateDigitalCircularParams: params } }) => {
    if (!params) {
      return;
    }

    dispatch(onSetPreviewDigitalCircularParams(params));
  };

  dispatch(onSetPreviewDigitalCircularParamsIsLoading(true));
  API
    .graphql(graphqlOperation(queries.generateDigitalCircularParams, { input: generateDigitalCircularParamsInput }))
    .then(onResponse)
    .catch((error) => console.error(error))
    .finally(() => dispatch(onSetPreviewDigitalCircularParamsIsLoading(false)));
  return next(action);
};
