import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@amzn/meridian/input';
import Button from '@amzn/meridian/button';
import DatePicker from '@amzn/meridian/date-picker';
import styles from './AsinValidatorPanel.module.scss';
import { COMMA_DELIMETER } from '../../../constants';

const translateAsinsBeforeSend = (asins) => (asins && asins.length
  ? asins.split(COMMA_DELIMETER)
  : []);

const AsinViabilityPanel = (props) => {
  const {
    isLoading, targetDate, onSend, onSetDate,
  } = props;
  const [asins, setAsins] = useState('');

  const onSendToValidate = () => {
    onSend(translateAsinsBeforeSend(asins), targetDate);
  };

  const isButtonDisabled = isLoading || !targetDate || !asins;

  return (
    <div className={styles.container}>
      <div className={styles.searchControl}>
        <Input
          id="asins-list-input"
          value={asins}
          onChange={setAsins}
          type="text"
          size="small"
          placeholder="Enter ASINs to validate here..."
          disabled={isLoading}
        />
      </div>
      <div className={styles.datePicker}>
        <DatePicker
          value={targetDate}
          onChange={onSetDate}
          label=""
          monthsInView={1}
          size="small"
          clearButton
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={onSendToValidate}
          size="small"
          disabled={isButtonDisabled}
        >
          Run Validator
        </Button>
      </div>
    </div>
  );
};

AsinViabilityPanel.propTypes = {
  targetDate: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSend: PropTypes.func.isRequired,
  onSetDate: PropTypes.func.isRequired,
};

AsinViabilityPanel.defaultProps = {
  targetDate: '',
};

export default AsinViabilityPanel;
