import { DASH_DELIMETER } from '../constants';

export const getDelimeterSeparatedStrFromArrayWithoutSpaces = (
  str,
  delimeter = DASH_DELIMETER,
) => {
  if (!str) {
    return '';
  }
  if (!Array.isArray(str)) {
    return [];
  }

  return str.join(delimeter).replace(/\s/g, '');
};

export const storeRegionsMatch = (storeRegions1, storeRegions2) => {
  const [formattedStoreRegions1, formattedStoreRegions2] = [
    storeRegions1,
    storeRegions2,
  ].map((storeRegion) => getDelimeterSeparatedStrFromArrayWithoutSpaces(storeRegion));

  return formattedStoreRegions1 === formattedStoreRegions2;
};
