import {
  PromoActions,
  PromoWeekActions,
  PublishedPromotionActions,
} from '../../constants';

export const initialState = {
  isLoading: false,
  dates: [],
  publishedPromotions: [],
  promoAuditLogs: [],
  isModalOpen: false,
  weeksInQuarter: {},
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { payload } = action;

  switch (action.type) {
    case PromoActions.SET_LOADING: {
      const { isLoading } = payload;

      return {
        ...state,
        isLoading,
      };
    }
    case PromoActions.SET_DATES_RANGE: {
      const { dates } = payload;

      return {
        ...state,
        dates,
      };
    }
    case PublishedPromotionActions.SET: {
      const { promotions } = payload;

      return {
        ...state,
        publishedPromotions: promotions,
      };
    }
    case PublishedPromotionActions.SET_AUDIT_LOG: {
      const { promoAuditLogs } = payload;

      return {
        ...state,
        promoAuditLogs,
      };
    }
    case PublishedPromotionActions.RESET_AUDIT_LOG: {
      return {
        ...state,
        promoAuditLogs: [],
      };
    }
    case PromoWeekActions.SET_WEEKS_FOR_YEAR_QUARTER: {
      const { payload: { yearQuarter, weeks } } = action;

      return {
        ...state,
        weeksInQuarter: {
          ...state.weeksInQuarter,
          [yearQuarter]: weeks,
        },
      };
    }
    case PromoWeekActions.ADDED_PROMOTION_WEEK_RECEIVED: {
      const { payload: { week: newPromotionWeek } } = action;
      const { yearQuarter } = newPromotionWeek;

      const currentWeekQuarter = state.weeksInQuarter[yearQuarter];
      const isExist = currentWeekQuarter.find(({ startDate, endDate }) => {
        return startDate === newPromotionWeek.startDate && endDate === newPromotionWeek.endDate;
      });

      if (isExist) {
        return state;
      }

      return {
        ...state,
        weeksInQuarter: {
          ...state.weeksInQuarter,
          [yearQuarter]: [...currentWeekQuarter].concat(newPromotionWeek),
        },
      };
    }
    case PromoWeekActions.UPDATED_PROMOTION_WEEK_RECEIVED: {
      const { payload: { week: updatedWeek } } = action;
      const { yearQuarter } = updatedWeek;

      const newWeeksInQuarter = {
        ...state.weeksInQuarter,
        [yearQuarter]: state.weeksInQuarter[yearQuarter].map((promotionWeek) => {
          const { startDate, endDate } = promotionWeek;

          return startDate === updatedWeek.startDate && endDate === updatedWeek.endDate
            ? updatedWeek
            : promotionWeek;
        }),
      };

      return {
        ...state,
        weeksInQuarter: newWeeksInQuarter,
      };
    }
    case PromoWeekActions.DELETED_PROMOTION_WEEK_RECEIVED: {
      const { payload: { week: deletedWeek } } = action;
      const { yearQuarter } = deletedWeek;

      const newWeeksInQuarter = {
        ...state.weeksInQuarter,
        [yearQuarter]: state.weeksInQuarter[yearQuarter].filter(({ startDate, endDate }) => {
          return startDate !== deletedWeek.startDate && endDate !== deletedWeek.endDate;
        }),
      };

      return {
        ...state,
        weeksInQuarter: newWeeksInQuarter,
      };
    }
    default: {
      return state;
    }
  }
};
