import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
  onUnselectCell,
} from '../../actionCreators';

export const defaultDispatchToProps = (dispatch) => {
  return {
    onInputChange({ target }) {
      dispatch(onActiveCellInputUpdate(target.value));
    },
    onSubmit({ preventDefault }) {
      preventDefault();
      dispatch(onActiveCellInputSubmit());
    },
    onBlur() {
      dispatch(onUnselectCell());
    },
  };
};

export const defaultStateToProps = (state) => {
  const { GridViewPage: { activeCell, activeCellValue } } = state;

  const getInputValue = () => {
    if (!activeCell) { return null; }
    return activeCellValue;
  };

  return {
    inputValue: getInputValue(),
  };
};
