import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '@amzn/meridian/heading';
import Icon from '@amzn/meridian/icon';
import deleteTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import Button from '@amzn/meridian/button';

import Input from '../../StyledInputs/Input';

import styles from '../MetadataEditor.module.scss';
import sitewideStyles from '../../../styles/sitewide.module.scss';

import getColumnDisplayByColumnName from '../../../helpers/getColumnDisplayByColumnName';

const Column = ({
  dependency,
  dependencies,
  options,
  onSelectOption,
  onAddOption,
  onDeleteOption,
  selectedField,
  ableToAdd,
}) => {
  const [newValue, setNewValue] = useState('');

  const selectOption = (option) => {
    onSelectOption(dependency.name, option);
  };

  const onDeleteOptionValue = (value) => {
    onDeleteOption(value, selectedField, dependencies);
  };

  const addOption = () => {
    onAddOption(newValue, selectedField, dependencies);
  };

  const optionList = options.map((option) => {
    const classStyles = {
      [styles.option]: true,
      [styles.selected]: option === dependency.selection,
    };
    const onClick = () => {
      selectOption(option);
    };
    return (
      <div
        key={option}
        className={classnames(classStyles)}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        <button
          onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            onDeleteOptionValue(option);
          }}
          className={classnames(sitewideStyles.buttonAsLink, styles.deleteButton)}
          type="button"
          data-testid="delete"
        >
          <Icon tokens={deleteTokens} />
        </button>
        <span className={styles.valueWrapper}>
          {option}
        </span>
      </div>
    );
  });

  const addOptionField = (
    <div className={styles.add}>
      <div className={styles.addInput}>
        <Input
          inputValue={newValue}
          onInputChange={setNewValue}
          placeholder="Add new value"
          data-testid="addValue"
        />
      </div>
      <div className={styles.addButton}>
        <Button
          size="small"
          onClick={addOption}
          disabled={!ableToAdd}
          data-testid="add"
        >
          Add
        </Button>
      </div>
    </div>
  );

  const childrenSelected = (
    <>
      {addOptionField}
      {optionList}
    </>
  );

  return (
    <div className={styles.column}>
      <Heading level={4}>{getColumnDisplayByColumnName(dependency.name)}</Heading>
      {ableToAdd ? childrenSelected : null}
    </div>
  );
};

Column.propTypes = {
  dependency: PropTypes.shape({
    name: PropTypes.string,
    selection: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  dependencies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selection: PropTypes.string,
  })).isRequired,
  onAddOption: PropTypes.func.isRequired,
  onDeleteOption: PropTypes.func.isRequired,
  selectedField: PropTypes.string,
  ableToAdd: PropTypes.bool.isRequired,
};

Column.defaultProps = {
  selectedField: '',
};

export default Column;
