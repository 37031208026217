import { Auth } from 'aws-amplify';
import { appAdminsList, metaDataAdminsList } from './accessControlLists';

export const getUserName = (user) => {
  if (!user || !user.username || !user.username.length) {
    return '';
  }
  /* eslint-disable no-unused-vars */
  const [_, alias] = user.username.split('_');

  return alias;
};

export const getUserRealName = (user) => {
  let realName = '';
  try {
    if (!Object.keys(user).length || !user.signInUserSession) {
      return '';
    }
    const {
      signInUserSession: {
        idToken: {
          payload: {
            /* eslint-disable camelcase */
            given_name,
          },
        },
      },
    } = user;

    realName = given_name;
  } catch (e) {
    // TODO: track error
    console.error(e);
  }

  return realName;
};

export const getUserData = (user) => {
  const realName = getUserRealName(user) || 'guest';
  const username = getUserName(user) || 'guest';
  const isAdmin = !!appAdminsList.find((admin) => admin === username);
  const isMetadataAdmin = !!(isAdmin
    || metaDataAdminsList.find((metadataAdmin) => metadataAdmin === username));

  return {
    username,
    realName,
    isAdmin,
    isMetadataAdmin,
  };
};

export const signOut = () => {
  Auth.signOut();
};

export const redirectToLoginPage = () => {
  Auth.federatedSignIn();
};

export async function getCurrentUserInfo() {
  return Auth.currentAuthenticatedUser();
}

export async function hasAccessTokens() {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (e) {
    // TODO: add error logging
    return false;
  }

  return true;
}
