import {
  FormActions,
  Form,
} from '../../constants';
import onSubmitFailed from './onSubmitFailed';
import onBlur from './onBlur';
import onChange from './onChange';

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;
  const { form } = getState();

  if (!form[Form.PROMO]) {
    return next(action);
  }

  if (type === FormActions.SET_SUBMIT_FAILED) {
    return onSubmitFailed(dispatch, getState, action, next);
  }

  if (type === FormActions.BLUR) {
    return onBlur(dispatch, getState, action, next);
  }

  if (type === FormActions.CHANGE) {
    return onChange(dispatch, getState, action, next);
  }

  return next(action);
};
