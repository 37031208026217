import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import styles from './TagInput.module.scss';
import { COMMA_DELIMETER, KeyboardKeys } from '../../../constants';
import arrayMove from '../../../helpers/arrayMove';
import Messages from '../Messages';
import { formControlInputProps } from '../../../proptypes';
import { writeClipboardContent } from '../../../helpers/clipboardCopyPaste';

const DATA_TRANSFER_CONTAINER_NAME = 'tags';

const defaultParseInput = (inputValue) => {
  if (!inputValue || !inputValue.length) {
    return [];
  }

  const formattedInput = Array.isArray(inputValue)
    ? inputValue
    : inputValue.split(COMMA_DELIMETER);

  return formattedInput.filter(input => input);
}

const joinTags = (tags) => tags.join(COMMA_DELIMETER);

export const TagInput = (props) =>  {
  const {
    label,
    input,
    placeholder,
    meta,
    isRequired,
    isDisabled,
    isGridViewMode,
    showClearAllButton,
    showCopyAllButton,
    showTagCounter,
    parseInput,
  } = props;

  const [textInput, setTextInput] = useState('');
  const [hoveredTag, setHoveredTag] = useState(null);
  const [draggedTag, setDraggedTag] = useState(null);
  const [droppedTag, setDroppedTag] = useState(null);

  const parseUserInput = (input) => {
    return typeof parseInput === 'function' ? parseInput(input) : defaultParseInput(input);
  };

  const inputValues = parseUserInput(input && input.value ? input.value : '');

  const omCommitChanges = (newTags) => {
    const { onChange } = input;

    onChange(newTags);
  };

  const moveTag = (indexFrom, indexTo) => {
    if (indexTo >= inputValues.length || indexTo < 0) {
      return;
    }

    omCommitChanges(arrayMove(inputValues, indexFrom, indexTo));
  };

  const onDragStart = (e, index) => {
    e.dataTransfer.setData(DATA_TRANSFER_CONTAINER_NAME, index);

    setDraggedTag(index);
  };

  const onDragOver = (e) => e.preventDefault();

  const onDrop = (e, index) => {
    e.preventDefault();

    const data = e.dataTransfer.getData(DATA_TRANSFER_CONTAINER_NAME);
    moveTag(data, index);

    setDroppedTag(index);

    // We use timeout here to allow the animation on the element after d&d (see styles file)
    setTimeout(() => {
      setDroppedTag(null);
    }, 1000);
  };

  const onDragEnter = (index) => setHoveredTag(index);

  const onDragEnd = () => {
    setHoveredTag(null);
    setDraggedTag(null);
  };

  const onRemoveTag = (index) => () => {
    omCommitChanges(inputValues.filter((_, tagIndex) => tagIndex !== index));
  };

  const onCopyAll = () => writeClipboardContent(joinTags(inputValues));

  const onClearAll = () => omCommitChanges([]);

  const onTextInputChange = (e) => setTextInput(e.target.value);

  const getDeleteButton = (index) => (
    <button
      onClick={onRemoveTag(index)}
      className={styles.deleteButton}
    >
      &times;
    </button>
  );

  const getTagStyles = (index) => classnames({
    [styles.tagItem]: true,
    [styles.hoverAbove]: hoveredTag === index && draggedTag > index,
    [styles.hoverBelow]: hoveredTag === index && draggedTag < index,
    [styles.tagDropped]: droppedTag === index,
  });

  const createTag = (name, index) => {
    const deleteButton = getDeleteButton(index);

    return (
      <li
        key={name}
        draggable="true"
        className={getTagStyles(index)}
        onDragStart={(e) => {
          onDragStart(e, index);
        }}
        onDragOver={(e) => {
          onDragOver(e);
        }}
        onDrop={(e) => {
          onDrop(e, index);
        }}
        onDragEnter={() => {
          onDragEnter(index);
        }}
        onDragEnd={onDragEnd}
      >
        <span>{name}</span>
        {deleteButton}
      </li>
    )
  }

  const onKeyDown = (e) => {
    if ([KeyboardKeys.SPACE, KeyboardKeys.ENTER].includes(e.key)) {
      e.preventDefault();
      const newTags = Array.from(new Set([
        ...inputValues,
        ...parseUserInput(textInput),
      ]));

      omCommitChanges(newTags);
      setTextInput('');
    }
  };

  const getLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <p className={styles.label}>{label}</p>
    )
  };

  const getTagList = () => {
    if (!inputValues || !inputValues.length) {
      return null;
    }

    const list = inputValues.map((tag, index) => createTag(tag, index));

    return (
      <ul className={styles.tagList}>
        {list}
      </ul>
    );
  };

  const getTagCounter = () => {
    if (!showTagCounter) {
      return null;
    }

    const counterValue = !inputValues || !inputValues.length ? 0 : inputValues.length;

    return (
      <p className={styles.tagCounter}>Total count: {counterValue}</p>
    );
  };

  const getCopyAllButton = () => {
    if (!showCopyAllButton) {
      return null;
    }

    return (
      <Button type="icon" onClick={onCopyAll}>
        <Icon tokens={copyTokens}>Copy All</Icon>
      </Button>
    );
  };

  const getClearAllButton = () => {
    if (!showClearAllButton) {
      return null;
    }

    return (
      <Button type="icon" onClick={onClearAll}>
        <Icon tokens={trashTokens}>Clear All</Icon>
      </Button>
    );
  };

  const getInputControl = () => {
    const copyAllButton = getCopyAllButton();
    const clearAllButton = getClearAllButton();

    return (
      <div className={styles.inputControlContainer}>
        <div className={styles.inputContainer}>
          <input
            className={styles.inputControl}
            type="text"
            value={textInput}
            onKeyDown={onKeyDown}
            onChange={onTextInputChange}
            placeholder={placeholder}
            required={isRequired}
            disabled={isDisabled}
          />
        </div>
        <div className={styles.controlButtons}>
          {copyAllButton}
          {clearAllButton}
        </div>
      </div>
    );
  };

  const getContent = () => {
    const labelMark = getLabel();
    const tagList = getTagList();
    const inputControl = getInputControl();
    const tagCounter = getTagCounter();

    return (
      <div className={styles.contentContainer}>
        {labelMark}
        {tagList}
        {inputControl}
        {tagCounter}
      </div>
    );
  };

  const getErrors = () => {
    return (
      <div className={styles.errorContainer}>
        <Messages meta={meta} />
      </div>
    );
  };

  const content = getContent();
  const errors = getErrors();

  return (
    <div className={classnames({
      [styles.gridViewMode]: isGridViewMode,
    })}>
      <div className={styles.tagsInputContainer}>
        {content}
        {errors}
      </div>
    </div>
  );
}

TagInput.propTypes = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  isGridViewMode: PropTypes.bool,
  parseInput: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

TagInput.defaultProps = {
  isGridViewMode: false,
  isRequired: false,
  isDisabled: false,
  label: '',
  placeholder: '',
  meta: null,
  parseInput: () => {},
};

export default TagInput;
