import React from 'react';
import HistoryTimingWrapper from './HistoryTimingWrapper';

const WithHistoryTiming = (Component, trackingProps = {}) => (props) => {
  return (
    <HistoryTimingWrapper {...trackingProps}>
      <Component {...props} />
    </HistoryTimingWrapper>
  );
};

export default WithHistoryTiming;
