import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CircularPriceWarning from './CircularPriceWarning';
import { Columns, Form } from '../../../../../constants';
import { isPromotionTypeFixedPrice } from '../../../../../helpers/promotions';

const selector = formValueSelector(Form.PROMO);

export const mapStateToProps = (state) => ({
  shouldShow: isPromotionTypeFixedPrice(selector(state, Columns.PROMOTION_TYPE.name)),
});

export default connect(mapStateToProps)(CircularPriceWarning);
