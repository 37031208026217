import { onUpdatePromotion } from '../../actionCreators';
import { Columns } from '../../constants';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { createPromotionShell } from '../../helpers/promotions';

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }
  const {
    payload: {
      id,
    },
  } = action;

  const selectedPromotion = getPromotionById(state.GridViewPage, id);
  if (!selectedPromotion) {
    console.error(`Cannot find selected promotion with ID='${id}'`);
    return next(action);
  }

  const {
    discountTag,
    isCategoryHero,
    isCoverPageHro,
    isPrimeBenefit,
    page,
    vertical,
    vendorManager,
    zoneNumber,
    startDate,
    endDate,
    yearQuarter,
    version,
  } = selectedPromotion;

  // TODO: Disabled/uneditable column should be a global attribute somewhere, this is being modified in
  // middleware/Promo/resetPromotion.js, components/PromoForm/FormField/index.js, components/GridViewCell/index.js
  const newPromotion = createPromotionShell({
    [Columns.DISCOUNT_TAG.name]: discountTag,
    [Columns.IS_CATEGORY_HERO.name]: isCategoryHero,
    [Columns.IS_COVER_PAGE_HERO.name]: isCoverPageHro,
    [Columns.IS_PRIME_BENEFIT.name]: isPrimeBenefit,
    [Columns.PAGE.name]: page,
    [Columns.VERTICAL.name]: vertical,
    [Columns.VENDOR_MANAGER.name]: vendorManager,
    [Columns.ZONE_NUMBER.name]: zoneNumber,
    [Columns.START_DATE.name]: startDate,
    [Columns.END_DATE.name]: endDate,
    version,
    id,
    [Columns.YEAR_QUARTER.name]: yearQuarter,
    yearQuarter_startDate_endDate: `${yearQuarter}_${startDate}_${endDate}`,
  });

  dispatch(onUpdatePromotion(newPromotion));

  return next(action);
};
