import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import {
  MovementDirections,
} from '../../constants';
import {
  onSelectCell,
} from '../../actionCreators';
import {
  getVisibleColumns,
} from '../../helpers/customView';
import { getSearchedPromotionsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';

const moveActiveCell = ({ dispatch, getState }, direction) => {
  const state = getState();

  if (!direction) { return; }
  if (!state.GridViewPage.activeCell) { return; }
  const {
    GridViewPage: {
      activeCell: { rowId, columnName },
    },
    Meta: {
      metadata: { columnOrder },
    },
    CustomView: { customColumnOrder },
  } = state;
  const promotionsWeek = getSearchedPromotionsForCurrentWeek(state);
  let currentRow = getPromotionById(state.GridViewPage, rowId);
  let currentRowIndex = promotionsWeek.indexOf(currentRow);
  const currentColumn = columnName;
  const currentColumnOrder = customColumnOrder ? getVisibleColumns(customColumnOrder) : columnOrder;
  let currentColumnIndex = currentColumnOrder.findIndex((column) => {
    return column.name === currentColumn;
  });
  const columnsLength = currentColumnOrder.length;
  const rowsLength = promotionsWeek.length;
  switch (direction) {
    case MovementDirections.LEFT: {
      if (currentColumnIndex <= 0) { return; }
      dispatch(onSelectCell({
        rowId: currentRow.id,
        columnName: currentColumnOrder[currentColumnIndex - 1].name,
      }));
      return;
    }
    case MovementDirections.RIGHT: {
      if (currentColumnIndex >= columnsLength - 1) { return; }
      dispatch(onSelectCell({
        rowId: currentRow.id,
        columnName: currentColumnOrder[currentColumnIndex + 1].name,
      }));
      return;
    }
    case MovementDirections.TAB: {
      if (currentColumnIndex >= columnsLength - 1) {
        currentRowIndex = (currentRowIndex + 1) % rowsLength;
        currentRow = promotionsWeek[currentRowIndex];
        currentColumnIndex = ((currentColumnIndex + 1) % columnsLength) - 1;
      }
      dispatch(onSelectCell({
        rowId: currentRow.id,
        columnName: currentColumnOrder[currentColumnIndex + 1].name,
      }));
      return;
    }
    case MovementDirections.SHIFT_TAB: {
      if (currentColumnIndex <= 0) {
        currentColumnIndex = columnsLength;
        currentRowIndex--;
        if (currentRowIndex < 0) {
          currentRowIndex = rowsLength - 1;
        }
        currentRow = promotionsWeek[currentRowIndex];
      }
      dispatch(onSelectCell({
        rowId: currentRow.id,
        columnName: currentColumnOrder[currentColumnIndex - 1].name,
      }));
      return;
    }
    case MovementDirections.UP: {
      if (currentRowIndex <= 0) { return; }
      dispatch(onSelectCell({
        rowId: promotionsWeek[currentRowIndex - 1].id,
        columnName: currentColumn,
      }));
      return;
    }
    case MovementDirections.DOWN: {
      if (currentRowIndex >= rowsLength - 1) { return; }
      dispatch(onSelectCell({
        rowId: promotionsWeek[currentRowIndex + 1].id,
        columnName: currentColumn,
      }));
      return;
    }
    default: {
      break;
    }
  }
};

export default moveActiveCell;
