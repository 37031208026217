import { setPublishPromotionsResponse } from '../../actionCreators/publishPromotions';
import { PublishPromotionsRequestStatus } from '../../constants/publishPromotions';

export default (dispatch, getState, action, next) => {
  if (!action.payload) {
    return next(action);
  }

  const { payload: { isModalOpen } } = action;

  if (isModalOpen) {
    dispatch(setPublishPromotionsResponse(null, PublishPromotionsRequestStatus.REQUEST_NOT_SENT));
  }

  return next(action);
};
