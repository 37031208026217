/* eslint-disable */
/* Mock data. It will be removed soon. */
import { Columns } from '../constants';

export default {
  fields: {
    circularPlacementSlot: {
      dependsOn: [],
      options: ["Slot 1", "Slot 2"],
    },
    dealCategory: {
      dependsOn: [],
      options: ["Deal Category 1", "Deal Category 2"],
    },
    productCategory: {
      dependsOn: [],
      options: ["Product Category 1", "Product Category 2"],
    },
    yearQuarter: {
      dependsOn: [],
      options: ["2022-Q2", "2022-Q3", "2022-Q4"],
    },
  },
  columnOrder: Object.keys(Columns).map((key) => Columns[key]),
};
