import React from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import PropTypes from 'prop-types';
import { WebsiteNavigationTabList } from '../../../constants';

const WebsiteNavigationTabs = (props) => {
  const {
    activeRoute,
    onChangeRoute,
    hasPromotionWeekDatesSelected,
    startDate,
    endDate,
    promotionWeekRoute,
  } = props;

  const dateRangeLabel = hasPromotionWeekDatesSelected
    ? `(${startDate} - ${endDate})`
    : null;

   return (
    <TabGroup
      value={activeRoute}
      onChange={onChangeRoute}
      fill="tabs"
    >
      <Tab
        value={promotionWeekRoute}
        disabled={!hasPromotionWeekDatesSelected}
      >
        {WebsiteNavigationTabList.PROMOTION_WEEK.label} {dateRangeLabel}
      </Tab>
      <Tab value={WebsiteNavigationTabList.CALENDAR.name}>
        {WebsiteNavigationTabList.CALENDAR.label}
      </Tab>
      <Tab value={WebsiteNavigationTabList.PUBLISHED_PROMOTION_WEEKS.name}>
        {WebsiteNavigationTabList.PUBLISHED_PROMOTION_WEEKS.label}
      </Tab>
    </TabGroup>
  );
};

WebsiteNavigationTabs.propTypes = {
  activeRoute: PropTypes.string.isRequired,
  onChangeRoute: PropTypes.func.isRequired,
  promotionWeekRoute: PropTypes.string.isRequired,
  hasPromotionWeekDatesSelected: PropTypes.bool,
};

WebsiteNavigationTabs.defaultProps = {
  hasPromotionWeekDatesSelected: false,
};

export default WebsiteNavigationTabs;
