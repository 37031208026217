import {
  onResetBanners,
} from '../../actionCreators/banner';

export default (dispatch, getState, action, next) => {
  const { payload: { isModalOpen } } = action;

  if (!isModalOpen) {
    dispatch(onResetBanners());
  }

  return next(action);
};
