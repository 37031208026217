import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import Row from '@amzn/meridian/row';

import { useHistory } from 'react-router-dom';
import { submitStringMetric } from '../../metrics/index';
import { MetricLocations, MetricTypes } from '../../constants/metrics';

const Menu = ({
  isSideMenuOpen,
  currentRoute,
  linksList,
  openLinkInNewTab,
  onToggleSideMenu,
}) => {
  const history = useHistory();

  const onClickLink = useCallback((route, isExternal = false) => () => {
    submitStringMetric(
      route,
      {
        location: MetricLocations.SIDE_MENU,
        type: MetricTypes.CLICK,
      },
    );
    if (isExternal) {
      openLinkInNewTab(route);

      return;
    }
    history.push(route);
  }, [history, openLinkInNewTab]);

  const sideMenuAction = (funcToCall) => {
    return () => {
      onToggleSideMenu();
      if (typeof funcToCall === 'function') {
        funcToCall();
      }
    };
  };

  const links = linksList.map((link) => {
    const {
      name, route, iconComponent, isExternal,
    } = link;

    return (
      <SideMenuLink
        key={route}
        href={route}
        selected={currentRoute === route}
        onClick={sideMenuAction(onClickLink(route, isExternal))}
        collapsedIcon={iconComponent}
      >
        <Row>{name}</Row>
      </SideMenuLink>
    );
  });

  return (
    <Row alignmentVertical="stretch" spacing="none">
      <SideMenu
        open={isSideMenuOpen}
        minWidth={200}
      >
        {links}
      </SideMenu>
    </Row>
  );
};

Menu.propTypes = {
  openLinkInNewTab: PropTypes.func.isRequired,
  isSideMenuOpen: PropTypes.bool.isRequired,
  currentRoute: PropTypes.string.isRequired,
  linksList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
  onToggleSideMenu: PropTypes.func.isRequired,
};

export default Menu;
