import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PromoForm from './PromoForm';
import { Columns, COMMA_DELIMETER, Form } from '../../constants';

import { validate, warn } from '../../helpers/formValidators';
import {
  onAddPromotion,
  onUpdatePromotion,
} from '../../actionCreators';
import { getPromotionFieldsByBusiness } from '../../helpers/form';
import extractAsins from '../../helpers/extractAsins';
import { isCurrentUserAdmin } from '../../selectors/user';

const translateToForm = (formData) => {
  const { asins } = formData;

  return {
    ...formData,
    [Columns.ASINS.name]: Array.isArray(asins)
      ? extractAsins(asins.join(COMMA_DELIMETER)).join(COMMA_DELIMETER)
      : asins,
  };
};

const translateFromForm = (formData) => {
  const { asins } = formData;

  return {
    ...formData,
    [Columns.ASINS.name]: extractAsins(asins),
  };
};

export const mapStateToProps = (state, ownProps) => {
  const {
    isNew,
    isClone,
    loadedPromotion,
  } = ownProps;
  const {
    GridViewPage: {
      promotionsLoaded,
    },
    Meta: {
      initialMetadataLoaded,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const columnsSet = getPromotionFieldsByBusiness(selectedBusiness);
  let promotion = {};

  if (isClone && loadedPromotion) {
    // Clone
    promotion = {
      ...loadedPromotion,
      version: 1,
    };
  } else if (!isNew && loadedPromotion) {
    // Edit
    promotion = { ...loadedPromotion };
  } else {
    // Set defaults
    promotion = {};
  }

  const id = !isClone && loadedPromotion ? loadedPromotion.id : null;
  const isLoading = !promotionsLoaded && !initialMetadataLoaded;
  const isNotFound = !isNew && promotionsLoaded && !loadedPromotion;

  return {
    id,
    status: loadedPromotion.status,
    isAdmin: isCurrentUserAdmin(state),
    isClone,
    isLoading,
    isNew,
    isNotFound,
    isSoftDeleted: loadedPromotion.isSoftDeleted,
    initialValues: translateToForm(promotion),
    columns: columnsSet,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onCreate: (data) => {
    dispatch(onAddPromotion(translateFromForm(data)));
  },
  onUpdate: (data) => {
    dispatch(onUpdatePromotion(translateFromForm(data)));
  },
  onClone: (data) => {
    dispatch(onAddPromotion(translateFromForm(data)));
  },
});

const reduxFormConfig = {
  form: Form.PROMO,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate,
  warn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(reduxFormConfig)(PromoForm));
