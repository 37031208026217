import { connect } from 'react-redux';
import PreviewInstructions from './PreviewInstructions';
import { DigitalCircularPreviewContentType } from '../../constants/digitalCircular';
import { generateCardParams } from '../../helpers/digitalCircular';
import {
  onClearPreviewDigitalCircularParams,
  onLoadPreviewDigitalCircularParams,
  onCopyDigitalCircularPreviewInstructions,
  onSetPreviewDigitalCircularParamsIsLoading,
} from '../../actionCreators';

const mapStateToProps = (state) => {
  const {
    Meta: {
      metadata: {
        fields: {
          storeRegions,
        },
      },
    },
    PreviewInstructions: {
      isLoading,
      digitalCircularParams,
    },
  } = state;

  const cardParams = generateCardParams(digitalCircularParams);
  
  return {
    cardParams,
    isLoading,
    storeRegions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadDigitalCircularParams(region) {
      dispatch(onSetPreviewDigitalCircularParamsIsLoading(true));
      dispatch(onLoadPreviewDigitalCircularParams(region));
    },
    onClearDigitalCircularParams() {
      dispatch(onClearPreviewDigitalCircularParams());
    },
    onCopyDigitalCircularCardParams() {
      dispatch(onCopyDigitalCircularPreviewInstructions(DigitalCircularPreviewContentType.CARD_PARAMS));
    },
    onCopyDigitalCircularCardId() {
      dispatch(onCopyDigitalCircularPreviewInstructions(DigitalCircularPreviewContentType.ID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewInstructions);
