import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from '../history';
import reducers from '../reducers';
import appMiddleware from '../middleware/appMiddleware';
import AsinImages from '../middleware/AsinImages';
import AsinValidator from '../middleware/AsinValidator';
import AuditLogMiddleware from '../middleware/auditLogMiddleware';
import Banner from '../middleware/Banner';
import BulkImport from '../middleware/BulkImport';
import Clipboard from '../middleware/Clipboard';
import CustomViewMiddleware from '../middleware/CustomViewMiddleware';
import ExportMiddleware from '../middleware/export';
import formMiddleware from '../middleware/PromoForm';
import weekFormMiddleware from '../middleware/PromoWeekForm';
import generateId from '../middleware/generate-id';
import graphqlMiddleware from '../middleware/graphqlMiddleware';
import GridViewFilter from '../middleware/GridViewFilter';
import GridViewPageMiddleware from '../middleware/GridViewPageMiddleware';
import GridViewRequestMiddleware from '../middleware/GridViewRequestMiddleware';
import KeyboardListenerMiddleware from '../middleware/GridViewKeyboardListener';
import MetadataAuditLogMiddleware from '../middleware/metadataAuditLogMiddleware';
import MetadataEditorMiddleware from '../middleware/MetadataEditorMiddleware';
import metadataMiddleware from '../middleware/metadataMiddleware';
import Promo from '../middleware/Promo';
import restMiddleware from '../middleware/restMiddleware';
import router from '../middleware/router';
import Sitewide from '../middleware/Sitewide';
import Subscriptions from '../middleware/Subscriptions';
import Templates from '../middleware/Templates';
import User from '../middleware/userMiddleware';
import yearQuarterSelector from '../middleware/yearQuarterSelector';
import PageLoadTimer from '../middleware/PageLoadTimerMiddleware';
import PreviewInstructions from '../middleware/PreviewInstructions';
import PublishPromotions from '../middleware/PublishPromotions';
import LoadingStatus from '../middleware/LoadingStatus';
import PerformanceTimer from '../middleware/PerformanceTimer';
import usageTracker from '../middleware/usageTracker';

const middleware = [
  appMiddleware,
  AsinImages,
  AsinValidator,
  AuditLogMiddleware,
  Banner,
  BulkImport,
  Clipboard,
  CustomViewMiddleware,
  ExportMiddleware,
  formMiddleware,
  weekFormMiddleware,
  generateId,
  graphqlMiddleware,
  GridViewFilter,
  GridViewPageMiddleware,
  GridViewRequestMiddleware,
  KeyboardListenerMiddleware,
  MetadataAuditLogMiddleware,
  MetadataEditorMiddleware,
  metadataMiddleware,
  PageLoadTimer,
  PerformanceTimer,
  PreviewInstructions,
  Promo,
  PublishPromotions,
  restMiddleware,
  router,
  Sitewide,
  Subscriptions,
  Templates,
  User,
  yearQuarterSelector,
  LoadingStatus,
  routerMiddleware(history),
  usageTracker,
];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleware.push(createLogger());
}

const store = createStore(
  reducers,
  applyMiddleware(...middleware),
);

export default store;
