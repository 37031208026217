import React from 'react';
import AsinImagePicker from './AsinImagePicker';
import AsinTagInput from '../../../Controls/AsinTagInput';
import styles from './AsinInput.modules.scss';

const AsinInput = (props) => {
  const { input } = props;
  const onInputChange = (value) => {
    if (input) {
      input.onChange(value);
    }
  };

  const newProps = {
    ...props,
    placeholder: 'Enter new ASIN(s) here...',
    input: {
      ...input,
      onChange: onInputChange,
    },
  };

  return (
    <div className={styles.imageContainer}>
      <div className={styles.inputWrapper}>
        <AsinTagInput {...newProps} />
      </div>
      <AsinImagePicker />
    </div>
  );
};

export default AsinInput;
