import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/meridian/alert';
import styles from './CircularPriceWarning.module.scss';
import { Language } from '../../../../../constants';

const CircularPriceWarning = ({ shouldShow }) => {
  if (!shouldShow) {
    return null;
  }

  const getMessage = () => {
    return (
      <>
        <h3 className={styles.messageTitle}>
          {Language.CIRCULAR_PRICE_TYPE_CHANGE_MESSAGE_TITLE}
        </h3>
        <div>
          <p className={styles.messageText}>
            {Language.CIRCULAR_PRICE_TYPE_CHANGE_MESSAGE_BODY}
          </p>
        </div>
      </>
    );
  };

  const message = getMessage();

  return (
    <Alert
      type="warning"
    >
      {message}
    </Alert>
  );
};

CircularPriceWarning.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
};

export default CircularPriceWarning;
