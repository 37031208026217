import { connect } from 'react-redux';
import ColorLegendModal from './ColorLegendModal';
import { ColumnOrder, SingleCellColumnOrder, DemoPromotion } from '../../constants/colorLegend';
import { onSetColorLegendModalOpen } from '../../actionCreators';

const mapStateToProps = (state) => {
  const { GridViewPage: { isColorLegendModalOpen } } = state;

  return {
    isColorLegendModalOpen,
    demoPromotion: DemoPromotion,
    columnOrder: ColumnOrder,
    singleCellColumnOrder: SingleCellColumnOrder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(onSetColorLegendModalOpen(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorLegendModal);
