import { API, graphqlOperation } from 'aws-amplify';
import { getPromotionsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';
import * as queries from '../../graphql/queries';
import { parseGraphQLResponse } from '../../helpers/api';
import {
  getReportRows,
  getSimpleFileContent,
  downloadFile,
  getPromotionWeekName,
  getBaseHeaderRows,
} from '../../helpers/export';
import { Columns, Language, UNDERSCORE_DELIMETER } from '../../constants';
import { onOpenToast, onSetExportLoading } from '../../actionCreators';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';

const getAsinViabilityHeaderRows = () => [
  Columns.ID.display,
  Columns.PAGE.display,
  Columns.ZONE_NUMBER.display,
  Columns.ASINS.display,
  Columns.TITLE.display,
  Columns.IS_FEATURED_ASIN.display,
  Columns.REGIONS.display,
  Columns.STORE_REGIONS.display,
  Columns.IS_AVAILABLE.display,
];

const getReportRowsWithViabilityFields = (
  rows,
  columnsToAdd = {},
) => {
  if (!rows || !rows.length) {
    return [];
  }
  const {
    promotionId,
    page,
    zoneNumber,
    title,
  } = columnsToAdd;

  const baseHeaderRowsLength = getBaseHeaderRows().length;

  return [...rows].map((row, index) => {
    // Replace base headers with Asins Viability headers
    if (index === 0) {
      return [
        ...getAsinViabilityHeaderRows(),
        ...row.slice(baseHeaderRowsLength),
      ];
    }

    // Move around and re-order columns in regards to the new headers

    // Extract base columns
    const [
      asin,
      isFeaturedAsin,
      region,
      storeRegion,
      isAvailable,
    ] = row.slice(0, baseHeaderRowsLength);

    // Extract dynamically generated columns
    const dynamicColumns = row.slice(baseHeaderRowsLength);

    return [
      promotionId,
      page,
      zoneNumber,
      asin,
      title,
      isFeaturedAsin,
      region,
      storeRegion,
      isAvailable,
      ...dynamicColumns,
    ];
  });
};

const getFileName = (state) => {
  const exportWeekName = getPromotionWeekName(state);
  const filePostfix = `${exportWeekName ? UNDERSCORE_DELIMETER : ''}asins_viability_all`;

  return exportWeekName
    ? `${exportWeekName}${filePostfix}`
    : filePostfix;
};

const getAsinsViabilityResponses = (promotions) => {
  return promotions.map((promotion) => {
    const {
      asins,
      startDate,
      id,
      page,
      zoneNumber,
    } = promotion;
    const params = {
      asins: asins.map((asin) => asin.trim()),
      startDate,
    };

    return API.graphql(graphqlOperation(queries.getAsinViability, { input: params }))
      .then((response) => {
        return {
          ...response,
          promotionId: id,
          page,
          zoneNumber,
        };
      });
  });
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const currentWeekPromotions = getPromotionsForCurrentWeek(state);
  if (!currentWeekPromotions || !currentWeekPromotions.length) {
    return next(action);
  }

  dispatch(onSetExportLoading(true));
  Promise
    .all(getAsinsViabilityResponses(currentWeekPromotions))
    .then((responses) => {
      const combinedReportRows = [];
      let shouldAddHeaders = true;
      responses.forEach((response) => {
        const {
          promotionId,
          page,
          zoneNumber,
          data,
        } = response;

        const asinViabilities = parseGraphQLResponse({ data });
        if (!asinViabilities) {
          return;
        }

        const promotion = getPromotionById(state.GridViewPage, promotionId);

        const reportRowsWithViabilityFields = getReportRowsWithViabilityFields(
          getReportRows(asinViabilities),
          {
            promotionId,
            page,
            zoneNumber,
            title: promotion?.title,
          },
        );

        reportRowsWithViabilityFields.forEach((reportRow, index) => {
          if (!shouldAddHeaders && index === 0) {
            return;
          }
          combinedReportRows.push(reportRow);
        });

        shouldAddHeaders = false;
      });

      if (!combinedReportRows.length) {
        dispatch(onOpenToast(Language.ASIN_VIABILITY_REPORT_NO_ASINS_ERROR));
        return;
      }

      const fileName = getFileName(state);
      const fileContent = getSimpleFileContent(combinedReportRows);

      downloadFile(fileContent, fileName);
    })
    .finally(() => {
      dispatch(onSetExportLoading(false));
    })
    .catch((e) => console.error(e));

  return next(action);
};
