import moment from 'moment';
import { createSelector } from 'reselect';
import { DateFormats, UNDERSCORE_DELIMETER } from '../constants';
import { isPromotionActive } from '../helpers/promotions';
import { isCurrentUserAdmin } from './user';

export const getPublishedPromotions = (state) => state.Promo.publishedPromotions;
export const getPromotions = (state) => state.GridViewPage.promotions;
export const getActiveCell = (state) => state.GridViewPage.activeCell;
export const getSelectedRowsIds = (state) => {
  const { GridViewPage: { selectedRowsIds } } = state;

  if (!selectedRowsIds || !Object.keys(selectedRowsIds)) {
    return [];
  }

  return Object.entries(selectedRowsIds)
    .filter(([, isSelected]) => isSelected)
    .map(([rowId]) => rowId);
};
export const getWeeksInQuarter = (state) => state.Promo.weeksInQuarter;
export const getEditHistory = (state) => state.User.editHistory;
export const getYearQuarter = (state) => state.Sitewide.yearQuarter;
export const getSelectedDates = (state) => state.Promo.dates;

export const getPromotionWeeks = createSelector(
  [getWeeksInQuarter, getYearQuarter, getPromotions],
  (weeksInQuarter, yearQuarter, promotions) => {
    const weeksList = weeksInQuarter[yearQuarter] || [];

    return weeksList.map((week) => {
      const { startDate, endDate, name } = week;

      const count = promotions.reduce((total, { yearQuarter_startDate_endDate }) => {
        const [
          ,
          currentStartDate,
          currentEndDate,
        ] = yearQuarter_startDate_endDate.split(UNDERSCORE_DELIMETER);

        return (currentStartDate === startDate && currentEndDate === endDate)
          ? total + 1
          : total;
      }, 0);

      return {
        start: `${startDate} 00:00`,
        end: `${endDate} 00:00`,
        name,
        count,
      };
    });
  },
);

export const getCurrentPromotionWeek = createSelector(
  [getWeeksInQuarter, getYearQuarter, getSelectedDates],
  (weeksInQuarter, yearQuarter, selectedDates) => {
    const weeksList = weeksInQuarter[yearQuarter] || [];
    const [startDate, endDate] = selectedDates;

    const formattedStartDate = moment(startDate).format(DateFormats.YYYY_MM_DD);
    const formattedEndDate = moment(endDate).format(DateFormats.YYYY_MM_DD);

    return weeksList.find((week) => {
      const { startDate: weekStartDate, endDate: weekEndDate } = week;
      const formattedWeekStartDate = moment(weekStartDate).format(DateFormats.YYYY_MM_DD);
      const formattedWeekEndDate = moment(weekEndDate).format(DateFormats.YYYY_MM_DD);

      return (formattedStartDate === formattedWeekStartDate
        && formattedEndDate === formattedWeekEndDate);
    });
  },
);

export const getCurrentWeekPromotions = createSelector(
  [getCurrentPromotionWeek, getPromotions],
  (currentPromotionWeek, promotions) => {
    if (!currentPromotionWeek) {
      return [];
    }
    const { startDate, endDate } = currentPromotionWeek;

    return promotions.filter((promotion) => {
      return promotion.startDate === startDate && promotion.endDate === endDate;
    });
  },
);

export const getPublishedPromotionsForCurrentWeek = createSelector(
  [getCurrentPromotionWeek, getPublishedPromotions],
  (currentPromotionWeek, publishedPromotions) => {
    if (!currentPromotionWeek) {
      return [];
    }
    const { startDate, endDate } = currentPromotionWeek;

    return publishedPromotions.filter((publishedPromotion) => {
      return (
        publishedPromotion.startDate === startDate
        && publishedPromotion.endDate === endDate
      );
    });
  },
);

export const getLastEditedPromotionInCurrentWeek = createSelector(
  [getEditHistory, getPromotions, getSelectedDates],
  (editHistory, promotions, selectedDates) => {
    const [startDate, endDate] = selectedDates;
    const { id } = [...editHistory[startDate + endDate]].pop();
    return promotions.find((promotion) => promotion.id === id);
  },
);

export const hasSelectedRows = createSelector(
  [getSelectedRowsIds],
  (selectedRowsIds) => {
    return selectedRowsIds && selectedRowsIds.length > 0;
  },
);

export const getSelectedPromotions = createSelector(
  [getSelectedRowsIds, getCurrentWeekPromotions],
  (selectedRowsIds, currentWeekPromotions) => {
    return currentWeekPromotions.filter(promotion => selectedRowsIds.includes(promotion.id));
  },
);

export const isActiveCellEditMode = createSelector(
  [getActiveCell],
  (activeCell) => activeCell && activeCell.isEditMode
);

export const isActiveCellEditable = createSelector(
  [getActiveCell, getCurrentWeekPromotions, isCurrentUserAdmin],
  (activeCell, promotions, isAdmin) => {
    if (!activeCell || !activeCell.rowId || !promotions || !promotions.length) {
      return false;
    }

    const currentPromotion = promotions.find(promotion => promotion.id === activeCell.rowId);
    if (!currentPromotion) {
      return false;
    }

    return isPromotionActive(currentPromotion, isAdmin);
  }
);
