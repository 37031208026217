import { PublishPromotionsActions, PublishPromotionsRequestStatus } from '../../constants/publishPromotions';

export const initialState = {
  isModalOpen: false,
  response: null,
  requestStatus: PublishPromotionsRequestStatus.REQUEST_NOT_SENT,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case PublishPromotionsActions.SET_PUBLISH_PROMOTIONS_IS_MODAL_OPEN: {
      const { payload: { isModalOpen } } = action;
      return {
        ...state,
        isModalOpen,
      }
    }
    case PublishPromotionsActions.SET_PUBLISH_PROMOTIONS_RESPONSE: {
      const { payload: { response, requestStatus } } = action;
      return {
        ...state,
        response,
        requestStatus,
      }
    }

    default: {
      return state;
    }
  }
};
