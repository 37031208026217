import React from 'react';
import PropTypes from 'prop-types';
import styles from './Hint.module.scss';

const Hint = ({ children }) => {
  return (
    <div className={styles.hintContainer}>{children}</div>
  );
};

Hint.propTypes = {
  children: PropTypes.element,
};
Hint.defaultProps = {
  children: null,
};

export default Hint;
