import moment from 'moment';
import { Columns, PromotionStatuses, UNDERSCORE_DELIMETER } from '../constants';
import { PromotionTypes } from '../constants/promotions';
import { isUneditableStatus } from './status';

export const getWeekByStartEndDate = (state, week, options = {}) => {
  const {
    Promo: { weeksInQuarter },
    Sitewide: { yearQuarter: currentYearQuarter },
  } = state;
  const { startDate, endDate } = week;
  const { yearQuarter } = options;

  const selectedYearQuarter = yearQuarter || currentYearQuarter;

  if (!weeksInQuarter || !weeksInQuarter[selectedYearQuarter]) {
    return null;
  }

  const weeksList = weeksInQuarter[selectedYearQuarter];

  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  return weeksList.find((week) => week.startDate === formattedStartDate && week.endDate === formattedEndDate);
};

export const getPublishedPromotionByStartEndDate = (state, { startDate, endDate }) => {
  const {
    Promo: { publishedPromotions },
  } = state;

  if (!publishedPromotions || !publishedPromotions.length) {
    return null;
  }

  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  const startDate_endDate = `${formattedStartDate}${UNDERSCORE_DELIMETER}${formattedEndDate}`;

  return publishedPromotions.find((promotion) => promotion.startDate_endDate === startDate_endDate);
};

export const getStringParts = (str) => str.split(UNDERSCORE_DELIMETER);
export const getHumanFriendlyDate = (date) => moment(date).format('MMM Do');
export const sortByPageZoneNumber = (a, b) => a.page - b.page || a.zoneNumber - b.zoneNumber;
export const matchesSelectedDate = (selectedStartDate, selectedEndDate, startDate, endDate) => {
  const formattedSelectedStartDate = moment(selectedStartDate).format('YYYY-MM-DD');
  const formattedSelectedEndDate = moment(selectedEndDate).format('YYYY-MM-DD');
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

  return (formattedSelectedStartDate === formattedStartDate && formattedSelectedEndDate === formattedEndDate);
};

export const isPromotionTypeFixedPrice = (promotionType) => promotionType === PromotionTypes.FIXED_PRICE.value;
export const isPromotionTypePercentOff = (promotionType) => promotionType === PromotionTypes.PERCENT_OFF.value;
export const createPromotionShell = (overrides = {}) => {
  return {
    [Columns.ASINS.name]: '',
    [Columns.ASIN_IMAGE.name]: '',
    [Columns.CIRCULAR_PRICE.name]: '',
    [Columns.DESCRIPTION.name]: '',
    [Columns.DISCOUNT_TAG.name]: '',
    [Columns.EACH_PRICE_FOR_F3RS.name]: '',
    [Columns.IS_APLENTY.name]: '',
    [Columns.IS_CATEGORY_HERO.name]: '',
    [Columns.IS_COVER_PAGE_HERO.name]: '',
    [Columns.IS_FRESH_BRAND.name]: '',
    [Columns.IS_LOCAL.name]: '',
    [Columns.IS_PRIME_BENEFIT.name]: '',
    [Columns.IS_PRIVATE_BRAND.name]: '',
    [Columns.IS_USDA_CHOICE.name]: '',
    [Columns.IS_USDA_ORGANIC.name]: '',
    [Columns.PROMOTION_END_DATE.name]: '',
    [Columns.NOTES.name]: '',
    [Columns.PAGE.name]: '',
    [Columns.PAWS_ID.name]: '',
    [Columns.PRIME_MEMBER_LABEL.name]: '',
    [Columns.PRODUCT_CATEGORY.name]: '',
    [Columns.PROMOTION_TYPE.name]: '',
    [Columns.STATUS.name]: PromotionStatuses.UNLOCKED.value,
    [Columns.STORE_REGIONS.name]: [],
    [Columns.TITLE.name]: '',
    [Columns.UNIT_OF_MEASURE.name]: '',
    [Columns.VENDOR_MANAGER.name]: '',
    [Columns.VERTICAL.name]: '',
    [Columns.ZONE_NUMBER.name]: 0,
    ...overrides,
  };
};

export const isPromotionActive = (promotion, isAdmin) => {
  const { status, isSoftDeleted } = promotion;
  return !isUneditableStatus(status, isAdmin) && !isSoftDeleted
};

export const isAllowedPageZoneNumberCombination = (promotion, allowedPageZoneCombinations) => {
  const { page, zoneNumber } = promotion;

  if (!allowedPageZoneCombinations[page]) {
    return false;
  }

  return allowedPageZoneCombinations[page][zoneNumber];
};

export const getPromotionsGroupedByStartEndDate = (promotions) => {
  if (!promotions || !promotions.length) {
    return {};
  }

  const promotionsGroupedByStartEndDate = promotions.reduce((groupedPromotions, promotion) => {
    const { startDate, endDate } = promotion;
    const startDate_endDate = `${startDate}${UNDERSCORE_DELIMETER}${endDate}`;

    const promotionsInStartEndDate = groupedPromotions[startDate_endDate] || [];

    return {
      ...groupedPromotions,
      [startDate_endDate]:  [...promotionsInStartEndDate, promotion],
    };
  }, {});

  const orderedPromotionsGroupedByStartEndDate = {};
  Object.keys(promotionsGroupedByStartEndDate).sort().forEach((key) => {
    orderedPromotionsGroupedByStartEndDate[key] = promotionsGroupedByStartEndDate[key];
  });

  return orderedPromotionsGroupedByStartEndDate;
};

const formatFixedPricePriceForDisplay = (price, priceConditions, unitOfMeasure)  => {
  return `$${[price, unitOfMeasure, priceConditions].filter(x => typeof x === 'string' && x.length > 0).join(' ')}`;
}

const formatPercentOffPriceForDisplay = (price, priceConditions)  => {
  return `${[price, '% off', ' ', priceConditions].filter(x => typeof x === 'string' && x.length > 0).join('')}`
}

const formatPOSPriceForDisplay = (price, priceConditions)  => {
  return `${[price, priceConditions].filter(x => typeof x === 'string' && x.length > 0).join(' ')}`
}

export const formatPriceForDisplay = (circularPrice, isPrimeBenefit, priceConditions, primeMemberLabel, promotionType, unitOfMeasure) => {
  let circularPriceDisplay = '';
  let primeMemberLabelDisplay = '';
  if (promotionType === PromotionTypes.FIXED_PRICE.value) {
    if (circularPrice) {
      circularPriceDisplay = formatFixedPricePriceForDisplay(circularPrice, priceConditions, unitOfMeasure);
    }
    if (isPrimeBenefit && primeMemberLabel) {
      primeMemberLabelDisplay = formatFixedPricePriceForDisplay(primeMemberLabel, priceConditions, unitOfMeasure);
    }
  }
  if (promotionType === PromotionTypes.PERCENT_OFF.value) {
    if (circularPrice) {
      circularPriceDisplay = formatPercentOffPriceForDisplay(circularPrice, priceConditions);
    }
    if (isPrimeBenefit && primeMemberLabel) {
      primeMemberLabelDisplay = formatPercentOffPriceForDisplay(primeMemberLabel, priceConditions);
    }
  }
  if (promotionType === PromotionTypes.POS.value) {
    if (circularPrice) {
      circularPriceDisplay = formatPOSPriceForDisplay(circularPrice, priceConditions);
    }
    if (isPrimeBenefit && primeMemberLabel) {
      primeMemberLabelDisplay = formatPOSPriceForDisplay(primeMemberLabel, priceConditions);
    }
  }

  return {
    circularPriceDisplay,
    primeMemberLabelDisplay,
  };
}