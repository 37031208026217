import {
  onAsinValidatorSetAsinRegions,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { AsinValidator: { asinViabilities } } = getState();

  asinViabilities.forEach(({ asin, viability }) => {
    viability.forEach(({ isAvailable, storeRegions }) => {
      if (isAvailable) {
        storeRegions.forEach(({ storeRegionName }) => {
          dispatch(onAsinValidatorSetAsinRegions(asin, storeRegionName));
        });
      }
    });
  });

  return next(action);
};
