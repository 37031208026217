import moment from 'moment-timezone';
import {
  WFMColumns,
  Columns,
  BusinessTypes,
  ControlsWithHintLinks,
  FreshDEColumns,
  DOT_DELIMETER,
} from '../constants';

export const getPromotionFieldsByBusiness = (business) => {
  switch (business) {
    case BusinessTypes.FRESH.name:
      return Columns;
    case BusinessTypes.WFM.name:
    case BusinessTypes.NA_3P.name:
      return WFMColumns;
    case BusinessTypes.FRESH_DE.name:
      return FreshDEColumns;
    default:
      return Columns;
  }
};

export const getDependentChildFields = (fieldName, metaFields) => {
  if (getDependentChildFields.cachedFields[fieldName]) {
    return getDependentChildFields.cachedFields[fieldName];
  }

  const fieldsToProcess = [];
  Object.keys(metaFields).forEach((currentFieldName) => {
    const currentField = metaFields[currentFieldName];
    if (
      currentField.dependsOn
      && Array.isArray(currentField.dependsOn)
      && (currentField !== fieldName && currentField.dependsOn.includes(fieldName))
    ) {
      fieldsToProcess.push(currentFieldName);
    }
  });

  getDependentChildFields.cachedFields[fieldName] = fieldsToProcess;

  return fieldsToProcess;
};
getDependentChildFields.cachedFields = {};

export const shouldShowHintLink = (fieldName) => ControlsWithHintLinks.includes(fieldName);
export const isValidStartDate = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  if (startDate.isSame(endDate, 'days')) {
    return true;
  }

  return startDate.isBefore(endDate, 'days');
};

export const createNestedFormField = (groupName) => (column) => {
  return {
    ...column,
    name: [...groupName, column.name].join(DOT_DELIMETER),
  }
};