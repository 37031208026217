export const MetricTypes = Object.freeze({
  APPSYNC: 'appsync',
  LOCAL_STORAGE: 'localStorage',
  CLICK: 'click',
  VIEW: 'view',
  LOG: 'log',
  USAGE: 'usage',
});
export const MetricErrorMessage = Object.freeze({
  METADATA_PARSING_ERROR: 'Error parsing metadata',
});
export const MetricNames = Object.freeze({
  GET_PROMOTION_WEEKS: 'get promotion weeks',
  ADD_PROMOTION: 'add promotion',
  CLONE_PROMOTION: 'clone promotion',
  DELETE_PROMOTION: 'delete promotion',
  EDIT_PROMOTION: 'edit promotion',
  LIST_PROMOTION: 'list promotion',
  MENU_BUTTON: 'menu button',
  OPEN_EXPORT_MODAL: 'open export modal',
  TOGGLE_ACTION_BAR: 'toggle action bar',
  UPDATE_SOV_FLAGS: 'update SOV flags',
  FETCH_PROMOTION: 'fetch promotion',
  LIST_ANNOUNCEMENTS: 'list announcements',
  DELETE_ANNOUNCEMENT: 'delete announcement',
  ADD_ANNOUNCEMENT: 'add announcement',
  EDIT_ANNOUNCEMENT: 'edit announcement',
  CAMPAIGN_AUDIT_LOG: 'open audit log',
  PAGELOAD: 'pageLoad',
  CLICK_TO_CONTENT_INTERACTIVE: 'clickToContentInteractive',
  INITIAL_METADATA: 'initialMetadataLoaded',
  LAST_PROMOTION_LOADED: 'lastPromotionLoaded',
  NOTIFICATIONS_STOP_POLLING: 'stop notifications polling',
  CHECK_NOTIFICATIONS_LAST_SEEN_TIME: 'check notifications last seen time',
  CREATE_NOTIFICATIONS_LAST_SEEN_TIME: 'create notifications last seen time',
  UPDATE_NOTIFICATIONS_LAST_SEEN_TIME: 'update notifications last seen time',
  NOTIFICATIONS_MARK_AS_READ_BATCH: 'batch mark notifications as read',
  NOTIFICATION_OPEN: 'open notification',
  UPDATE_NEW_NOTIFICATIONS_COUNT: 'update new notifications count',
  MARK_AS_READ: 'mark notification as read',
  CREATE_COMMENT: 'create a new comment',
  DELETE_COMMENT: 'delete a comment',
  LOAD_NOTIFICATIONS: 'load notifications',
  LOAD_COMMENTS: 'load comments',
  COMMENTS_STOP_POLLING: 'stop polling comments',
  INTERNAL_PAGE_CHANGE: 'INTERNAL_PAGE_CHANGE',
  NO_PAGE_TRANSITION_RECORDED: 'NO_PAGE_TRANSITION_RECORDED',
  PAGE_TRANSITION_RECORDED_WITHOUT_START: 'PAGE_TRANSITION_RECORDED_WITHOUT_START',
  PERFORMANCE_TIME_RECORDED: 'PERFORMANCE_TIME_RECORDED',
  NO_PERFORMANCE_TIME_RECORDED: 'NO_PERFORMANCE_TIME_RECORDED',
  MULTIPLE_PERFORMANCE_TIMERS_RECORDED: 'MULTIPLE_PERFORMANCE_TIMERS_RECORDED',
  PERFORMANCE_TIME_RECORDED_WITHOUT_START: 'PERFORMANCE_TIME_RECORDED_WITHOUT_START',
  API_GRAPH_QL_MIDDLEWARE: 'GraphQL middleware (requests processor)',
  RUN_FLASH_FILL: 'run flash fill',
  APP_APPSYNC_SUBSCRIPTIONS_CONNECTION_CLOSED: 'app appsync subscriptions connection closed',
});
export const MetricLocations = Object.freeze({
  ACTION_CELL: 'action cell',
  BUSINESS_SELECTOR: 'business selector',
  GRID_VIEW_REQUEST_MIDDLEWARE: 'grid view request middleware',
  GRAPHQL_MIDDLEWARE: 'graphqlMiddleware',
  BULLETIN_BOARD_REQUEST_MIDDLEWARE: 'bulletin board request middleware',
  HEADER: 'header',
  METADATA: 'metadata',
  SIDE_MENU: 'side menu',
  NOTIFICATIONS_MIDDLEWARE: 'notifications middleware',
  NOTIFICATIONS_LIST_VIEW: 'notifications list view',
  COMMENTS_MIDDLEWARE: 'comments middleware',
  TIMER_MIDDLEWARE: 'timer middleware',
  PERFORMANCE_TIMER_MIDDLEWARE: 'performance timer middleware',
  USAGE_TRACKING_MIDDLEWARE: 'usage tracking middleware',
  FLASH_FILL_MIDDLEWARE: 'flash fill middleware',
  APP_SUBSCRIPTIONS: 'app subscriptions',
});
// TODO: update metrics later. For some reason IDE cannot properly find and rename all occurrences
export const PageNames = Object.freeze({
  ASIN_VIABILITY_DASHBOARD: 'ASIN_VIABILITY_DASHBOARD',
  AUDIT_LOG: 'AUDIT_LOG',
  CALENDAR: 'CALENDAR',
  METADATA: 'METADATA',
  METADATA_AUDIT: 'METADATA_AUDIT',
  PROMOTION_WEEK: 'PROMOTION_WEEK',
  PUBLISHED_PROMOTIONS: 'PUBLISHED_PROMOTIONS',
  TEMPLATES: 'TEMPLATES',
  UNKNOWN_PAGE: 'UNKNOWN_PAGE',
});

export const ModalNames = Object.freeze({
  EDIT_PROMOTION: 'Modal/EDIT_PROMOTION',
  EDIT_TEMPLATES: 'Modal/EDIT_TEMPLATES',
  EDIT_PROMOTION_WEEK: 'Modal/EDIT_PROMOTION_WEEK',
  NEW_PROMOTION_WEEK: 'Modal/NEW_PROMOTION_WEEK',
  NEW_TEMPLATE: 'Modal/NEW_TEMPLATE',
  PUBLISHED_PROMOTION_WEEK: 'Modal/PUBLISHED_PROMOTION_WEEK',
});
export const PageLoadTimerActions = Object.freeze({
  START_TIMER: 'PageLoadTimerActions/START_TIMER',
  COMPLETE_TIMER: 'PageLoadTimerActions/COMPLETE_TIMER',
  CANCEL_TIMER: 'PageLoadTimerActions/CANCEL_TIMER',
});
export const PerformanceTimerActions = Object.freeze({
  TIMER_START: 'PerformanceTimerActions/TIMER_START',
  TIMER_STOP: 'PerformanceTimerActions/TIMER_STOP',
});
