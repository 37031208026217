import { connect } from 'react-redux';
import {
  onCloseExportModal,
  onExportCurrentView,
  onSetExportType,
} from '../../actionCreators';
import ExportModal from './ExportModal';

export const mapStateToProps = (state) => {
  const {
    Export: {
      isExportModalOpen,
      exportType,
      exportViewType,
      isLoading,
    },
  } = state;

  return {
    isExportModalOpen,
    exportType,
    exportViewType,
    isLoading,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onOptionChange: (exportType) => {
      dispatch(onSetExportType(exportType));
    },
    onExport: () => {
      dispatch(onExportCurrentView());
    },
    onCloseModal: () => {
      dispatch(onCloseExportModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
