import { CustomViewActions } from '../../constants';

export const initialState = {
  customColumnOrder: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case CustomViewActions.SET_COLUMN_ORDER: {
      return {
        ...state,
        customColumnOrder: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
