import React from 'react';
import PromoReviewContainer from '../../PromoReviewContainer';
import Calendar from '../../Calendar';
import WithHistoryTiming from '../../WithHistoryTiming';
import { PageNames } from '../../../constants/metrics';

const CalendarPage = () => (
  <>
    <PromoReviewContainer />
    <Calendar />
  </>
);

export default WithHistoryTiming(CalendarPage, { pageName: PageNames.CALENDAR });
