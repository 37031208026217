import React from 'react';
import MetadataEditor from '../MetadataEditor';
// import AccessControl from '../AccessControl';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants/metrics';
// import { metaDataAdminsList } from '../../helpers/accessControlLists';

const MetadataPage = () => (
  // <AccessControl usersList={metaDataAdminsList}>
  <MetadataEditor />
  // </AccessControl>
);

export default WithHistoryTiming(MetadataPage, { pageName: PageNames.METADATA });
