import _ from 'lodash';
import {
  getNumberWithinRange,
  hasPromotionChanged,
} from '../../helpers/common';
import extractAsins from '../../helpers/extractAsins';
import {
  ASIN_DELIMETER,
  ASINColumns,
  Columns,
  GridViewSource,
  PercentageColumns,
} from '../../constants';
import {
  onActiveCellInputUpdate,
  onCellInputUpdate,
  onSendPromotionEdit,
} from '../../actionCreators';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { applyPrimeDiscount } from './applyPrimeDiscount';

const parseAsins = (state, dispatch) => {
  const { GridViewPage: { activeCell: { columnName }, activeCellValue } } = state;

  if (!columnName || ASINColumns.indexOf(columnName) < 0) {
    return;
  }

  const parsedAsins = extractAsins(activeCellValue).join(ASIN_DELIMETER);
  dispatch(onActiveCellInputUpdate(parsedAsins));
};

const parsePercentage = (state, dispatch) => {
  const { GridViewPage: { activeCell: { columnName }, activeCellValue } } = state;
  if (!PercentageColumns.includes(columnName)) {
    return;
  }
  const parsedValue = getNumberWithinRange(activeCellValue);
  if (parsedValue !== activeCellValue) {
    dispatch(onActiveCellInputUpdate(parsedValue));
  }
};

const resetCircularPrice = (state, dispatch, promotion) => {
  if (!promotion) {
    return;
  }

  const {
    GridViewPage: {
      activeCell: { columnName },
      activeCellValue: nextPromotionType,
    },
  } = state;

  const { promotionType, id } = promotion;
  if (columnName !== Columns.PROMOTION_TYPE.name
    || promotionType === nextPromotionType) {
    return;
  }

  dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL.name, ''));
  dispatch(onCellInputUpdate(id, Columns.CIRCULAR_PRICE.name, ''));
};

const updatePicturedAsins = (state, dispatch, promotion) => {
  const {
    GridViewPage: {
      activeCell: { columnName },
      activeCellValue: columnValue,
    },
  } = state;

  if (columnName === Columns.ASINS.name) {
    const asins = extractAsins(columnValue);
    const { picturedAsins, id } = promotion;
    if (picturedAsins && Array.isArray(picturedAsins)) {
      const newPicturedAsins = asins.filter((asin) => picturedAsins.includes(asin));
      if (!_.isEqual(newPicturedAsins, picturedAsins)) {
        dispatch(onCellInputUpdate(id, Columns.PICTURED_ASINS.name, newPicturedAsins));
      }
    }
  }
};

export default (store, next, action) => {
  const { dispatch, getState } = store;
  const state = getState();
  const { GridViewPage } = state;
  const { activeCell, source } = GridViewPage;

  if (!activeCell) {
    return next(action);
  }

  const { rowId } = activeCell;
  if (!hasPromotionChanged(state)) {
    return next(action);
  }

  parseAsins(state, dispatch);
  parsePercentage(state, dispatch);
  // Reset CIRCULAR_PRICE on PROMOTION_TYPE change
  const promotion = getPromotionById(GridViewPage, rowId);
  if (promotion) {
    resetCircularPrice(state, dispatch, promotion);
    applyPrimeDiscount(state, dispatch, promotion);
    updatePicturedAsins(state, dispatch, promotion);
  }

  next(action);

  if (source === GridViewSource.ROWS) {
    dispatch(onSendPromotionEdit({ rowId }));
  }

  return null;
};
