import { connect } from 'react-redux';
import GridView from './GridView';
import { isCurrentUserAdmin } from '../../selectors/user';

const mapStateToProps = (state) => {
  const {
    Meta: {
      metadata: {
        fields: {
          storeRegions,
        },
      },
    },
    GridViewPage: {
      selectedRowsIds,
    },
  } = state;

  return {
    isAdmin: isCurrentUserAdmin(state),
    storeRegions,
    selectedRowsIds,
  };
};

export default connect(mapStateToProps, null)(GridView);
