/* eslint-disable no-param-reassign */
import { validate } from '../../../helpers/formValidators';

const hasPartialValidationErrors = (promotion, partialFieldsValidationList) => {
  const errorsList = Object.keys(promotion.errors);

  for (let i = 0; i < errorsList.length; i++) {
    const columnName = errorsList[i];
    if (partialFieldsValidationList[columnName]) {
      return true;
    }
  }

  return false;
};

const validateRequiredFields = (promotion, partialFieldsValidationList = {}) => {
  const validations = validate(promotion);
  const validationFields = Object.keys(validations);
  const isPartialFieldsValidation = Object.keys(partialFieldsValidationList).length;

  if (validationFields.length === 0) {
    return false;
  }

  if (!promotion.errors) {
    promotion.errors = {};
  }

  validationFields.forEach((field) => {
    if (isPartialFieldsValidation && !partialFieldsValidationList[field]) {
      return;
    }
    if (!promotion.errors[field]) { promotion.errors[field] = {}; }
    promotion.errors[field].text = validations[field] || 'Missing required field';
  });

  return isPartialFieldsValidation
    ? hasPartialValidationErrors(promotion, partialFieldsValidationList)
    : true;
};

export default validateRequiredFields;
