import React from 'react';
import TagInput from '../TagInput';
import extractAsins from '../../../helpers/extractAsins';

const parseInput = (inputValue) => {
  if (!inputValue || !inputValue.length) {
    return [];
  }

  const formattedInput = Array.isArray(inputValue)
    ? inputValue
    : extractAsins(inputValue.trim());

  return formattedInput.filter(input => input).map(value => value.trim());
}

const AsinTagInput = (props) => {
  const newProps = {
    ...props,
    parseInput,
  };

  return <TagInput {...newProps} />
};

export default AsinTagInput;
