import { connect } from 'react-redux';
import PublishedPromotions from './PublishedPromotions';
import {
  onLoadPublishedPromotions,
  onSetDatesRange,
} from '../../actionCreators';

const mapStateToProps = (state) => {
  const {
    Promo: {
      isLoading,
      publishedPromotions,
      promotionAuditLogs,
    },
    Sitewide: { yearQuarter },
  } = state;

  return {
    isLoading,
    publishedPromotions,
    promotionAuditLogs,
    yearQuarter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadPublishedPromotions() {
      dispatch(onLoadPublishedPromotions());
    },
    onDateRangeChange(start, end) {
      dispatch(onSetDatesRange([start, end]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedPromotions);
