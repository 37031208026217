import generateDigitalCircularParams from './generateDigitalCircularParams';
import { PreviewActions } from '../../constants/preview';
import copyPreviewInstructions from './copyPreviewInstructions';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === PreviewActions.LOAD_DIGITAL_CIRCULAR_PARAMS) {
    return generateDigitalCircularParams(dispatch, getState, action, next);
  }
  if (type === PreviewActions.COPY_DIGITAL_CIRCULAR_PREVIEW_INSTRUCTIONS) {
    return copyPreviewInstructions(dispatch, getState, action, next);
  }

  return next(action);
};