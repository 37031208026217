import React from 'react';
import DatePicker from '@amzn/meridian/date-picker';

import { styledInputProps } from '../../proptypes';

import styles from './StyledInputs.module.scss';

const Input = ({
  inputValue,
  onInputChange,
  onBlur,
  autoFocus,
  presets,
}) => {
  return (
    <div className={styles.datePickerWrapper}>
      <DatePicker
        value={inputValue || ''}
        onChange={onInputChange}
        onBlur={onBlur}
        size="small"
        autoFocus={autoFocus}
        presets={presets}
      />
    </div>
  );
};

Input.propTypes = styledInputProps;

Input.defaultProps = {
  autoFocus: true,
  presets: [],
};

export default Input;
