import { BulkImportActions } from '../../constants/bulkImport';

export const initialState = {
  accepted: {
    create: [],
    update: [],
  },
  rejected: [],
  formatIssues: 0,
  isProcessed: false,
  isImporting: false,
  isFileParsing: false,
  isModalOpen: false,
  expectedPromotionCount: 0,
  succeededPromotions: [],
  failedPromotions: [],
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case BulkImportActions.RESET: {
      return {
        ...initialState,
      };
    }
    case BulkImportActions.SET_PENDING_PROMOTIONS: {
      const { payload: { processedPromotions } } = action;

      return {
        ...state,
        ...processedPromotions,
        isProcessed: true,
      };
    }
    case BulkImportActions.SET_FOR_IMPORT: {
      const { payload: { expectedPromotionCount } } = action;

      return {
        ...state,
        expectedPromotionCount,
        succeededPromotions: [],
        failedPromotions: [],
      };
    }
    case BulkImportActions.SET_SUCCEEDED_PROMOTIONS: {
      const { payload: { id } } = action;
      return {
        ...state,
        succeededPromotions: [...state.succeededPromotions, id],
      };
    }
    case BulkImportActions.SET_FAILED_PROMOTIONS: {
      const { payload: { id } } = action;

      return {
        ...state,
        failedPromotions: [...state.failedPromotions, id],
      };
    }
    case BulkImportActions.START_IMPORTING: {
      return {
        ...state,
        isImporting: true,
      };
    }
    case BulkImportActions.COMPLETE_IMPORTING: {
      return {
        ...state,
        isImporting: false,
      };
    }
    case BulkImportActions.START_FILE_PARSING: {
      return {
        ...state,
        isFileParsing: true,
      };
    }
    case BulkImportActions.COMPLETE_FILE_PARSING: {
      return {
        ...state,
        isFileParsing: false,
      };
    }
    case BulkImportActions.OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true,
      };
    }
    case BulkImportActions.CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};
