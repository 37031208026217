import {
  NOOP,
  FETCH_PROMOTION_WEEKS,
  KEYPRESS,
  CLEAR_PROMOTIONS,
  SET_PROMOTIONS_LOADING,
  SET_PROMOTIONS_LOADED,
  SET_PROMOTIONS,
  ON_METADATA_RECEIVED,
  REST_API,
  PAGE_INIT,
  GRAPHQL_ACTION,
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  LoadingActions,
} from '../../constants';
import { PerformanceTimerActions, PageLoadTimerActions } from '../../constants/metrics';

const getExceptionsList = () => ({
  [NOOP]: true,
  [FETCH_PROMOTION_WEEKS]: true,
  [KEYPRESS]: true,
  [CLEAR_PROMOTIONS]: true,
  [SET_PROMOTIONS_LOADING]: true,
  [SET_PROMOTIONS_LOADED]: true,
  [SET_PROMOTIONS]: true,
  [ON_METADATA_RECEIVED]: true,
  [REST_API]: true,
  [PAGE_INIT]: true,
  [GRAPHQL_ACTION]: true,
  [SHOW_LOADING_SPINNER]: true,
  [HIDE_LOADING_SPINNER]: true,
  [PageLoadTimerActions.START_TIMER]: true,
  [PageLoadTimerActions.CANCEL_TIMER]: true,
  [PageLoadTimerActions.COMPLETE_TIMER]: true,
  [PerformanceTimerActions.TIMER_START]: true,
  [PerformanceTimerActions.TIMER_STOP]: true,
  [LoadingActions.CHECK_LOADING_STATUS]: true,
});

export default getExceptionsList;
