import { AsinImageActions } from '../../constants';

export const initialState = {
  images: [],
  isLoading: false,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case AsinImageActions.SET_PRODUCT_IMAGES: {
      const { payload: { images } } = action;

      return {
        ...state,
        images,
      };
    }
    case AsinImageActions.SET_LOADING: {
      const { payload: { flag } } = action;

      return {
        ...state,
        isLoading: flag,
      };
    }
    default: {
      return state;
    }
  }
};
