import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { onSetPublishedPromotionAuditLog, onSetLoading, onOpenToast } from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotions';
import { joinYearQuarterDates } from '../../helpers/dateTime';

export const translatePublishedPromotions = (data, metadata) => {
  return data.map((campaign) => appsyncToLocal(campaign, metadata));
};

export default (dispatch, getState, action, next) => {
  const { payload: { yearQuarter, startDate, endDate } } = action;

  const yearQuarter_startDate_endDate = joinYearQuarterDates({
    yearQuarter,
    startDate,
    endDate,
  });

  const params = {
    yearQuarter_startDate_endDate,
  };

  const onResponse = (response) => {
    const { data: { getPublishedPromotionAuditLog } } = response;

    if (!getPublishedPromotionAuditLog.length) {
      dispatch(onOpenToast('No history found for the selected week!'));
      return;
    }
    try {
      const logs = getPublishedPromotionAuditLog.map((log) => {
        const { publishedPromotion: { promotions, stores } } = log;

        return {
          ...log,
          publishedPromotion: {
            ...log.publishedPromotion,
            promotions: translatePublishedPromotions(JSON.parse(promotions)),
            stores: JSON.parse(stores),
          },
        };
      });
      dispatch(onSetPublishedPromotionAuditLog(logs));
    } catch (error) {
      console.error(error);
    }
  };

  dispatch(onSetLoading(true));

  API
    .graphql(graphqlOperation(queries.getPublishedPromotionAuditLog, params))
    .then(onResponse)
    .catch((error) => console.error(error))
    .finally(() => {
      dispatch(onSetLoading(false));
    });

  return next(action);
};
