import moment from 'moment';
import {
  DASH_DELIMETER,
} from '../../constants';
import { onYearQuarterSelect } from '../../actionCreators';
import { getYearQuarter } from '../../helpers/common';

const shouldUpdateCurrentYearQuarter = (currentYearQuarter, newTimeFrame) => {
  const [currentYear, currentQuarter] = currentYearQuarter.split(DASH_DELIMETER);

  const timeFrame = moment(newTimeFrame);
  const selectedYear = timeFrame.year();
  const selectedQuarter = timeFrame.quarter();

  return (parseInt(currentYear, 10) !== selectedYear
    || parseInt(currentQuarter[1], 10) !== selectedQuarter);
};

export default (dispatch, getState, action, next) => {
  const { payload: { timeFrame } } = action;

  if (!timeFrame) {
    return next(action);
  }

  const state = getState();
  const { Sitewide: { yearQuarter } } = state;

  if (shouldUpdateCurrentYearQuarter(yearQuarter, timeFrame)) {
    dispatch(onYearQuarterSelect({
      yearQuarter: getYearQuarter(timeFrame),
    }));
  }

  return next(action);
};
