import _ from 'lodash';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import GridViewHeader from '../GridViewHeader';
import GridViewRow from '../GridViewRow';
import { campaignProps, campaignColumnProps } from '../../proptypes';
import {
  DiscountTag, Language, PopOverDirection, PromotionStatuses,
} from '../../constants';
import arraySubtract from '../../helpers/arraySubtract';
import { getTemplateString } from '../../helpers/common';
import styles from './GridView.module.scss';
import { pasteHereRow, PASTE_HERE_ROW_ID } from '../../helpers/gridView';
import { isUneditableStatus } from '../../helpers/status';

const shouldHighlight = (translatedLogsForHighlight, { status }) => {
  return !_.isEmpty(translatedLogsForHighlight) &&
    (PromotionStatuses.CHANGE_REQUESTED.value === status || PromotionStatuses.REVIEW_CHANGE_REQUESTED.value === status);
};

const getLatestApprovedRevision = (approvedRevisions, id) => {
  return approvedRevisions && approvedRevisions[id] ? approvedRevisions[id] : {};
};

const buildStoreRegionErrorMapForPageKey = (promotions, storeRegions) => {
  if (!promotions || !Array.isArray(promotions) || !storeRegions || !storeRegions.options) {
    return {};
  }
  const combinedStoreRegionsForPageKey = {};
  promotions.filter((promotion) => !promotion.isSoftDeleted).forEach((promotion) => {
    const { storeRegions = [], page, zoneNumber } = promotion;
    const pageZone = `${page} - ${Math.floor(zoneNumber)}`;
    const storeRegionsForPageKey = combinedStoreRegionsForPageKey[pageZone]
      ? combinedStoreRegionsForPageKey[pageZone]
      : [];
    combinedStoreRegionsForPageKey[pageZone] = [...storeRegionsForPageKey, ...storeRegions];
  });

  const storeRegionErrorMapForPageKey = {};
  Object.entries(combinedStoreRegionsForPageKey).forEach(([pageZone, combinedStoreRegion]) => {
    const expectedStoreRegions = [...storeRegions.options];
    const actualStoreRegions = combinedStoreRegion;
    const extraStoreRegions = [...new Set(arraySubtract(actualStoreRegions, expectedStoreRegions))];
    const missingStoreRegions = [...new Set(arraySubtract(expectedStoreRegions, actualStoreRegions))];
    if (Array.isArray(extraStoreRegions) && extraStoreRegions.length !== 0) {
      if (!storeRegionErrorMapForPageKey[pageZone]) {
        storeRegionErrorMapForPageKey[pageZone] = [];
      }
      storeRegionErrorMapForPageKey[pageZone].push(
        getTemplateString(Language.PROMOTION_EXTRA_REGIONS_ERROR_MESSAGE, { extraStoreRegions }),
      );
    }
    if (Array.isArray(missingStoreRegions) && missingStoreRegions.length !== 0) {
      if (!storeRegionErrorMapForPageKey[pageZone]) {
        storeRegionErrorMapForPageKey[pageZone] = [];
      }
      storeRegionErrorMapForPageKey[pageZone].push(
        getTemplateString(Language.PROMOTION_MISSING_REGIONS_ERROR_MESSAGE, { missingStoreRegions }),
      );
    }
  });

  return storeRegionErrorMapForPageKey;
};

const GridView = (props) => {
  const {
    columnOrder,
    columnsToCompare,
    customActionBar,
    enableContextMenu,
    isAdmin,
    isReadOnly,
    isStickyHeader,
    isContentWrappingEnabled,
    isMustFillHighlightEnabled,
    promotions,
    showActionBar,
    showSearch,
    storeRegions,
    translatedLogsForHighlight,
    selectedRowsIds,
    showPasteHereRow,
  } = props;

  const getRows = (
    promotions,
    columnOrder,
    translatedLogsForHighlight,
    storeRegionErrorMapForPageKey,
  ) => {
    if (!promotions || !promotions.length) {
      return null;
    }

    const rows = promotions.map((promotion, index) => {
      const {
        id, tempId, status, discountTag, isPrimeBenefit, isSoftDeleted, page, zoneNumber,
      } = promotion;
      const pageZone = `${page} - ${Math.floor(zoneNumber)}`;

      const key = id || tempId || uuidv4();
      const isLocked = isUneditableStatus(status, isAdmin);
      const isEdlp = discountTag === DiscountTag.EDLP;
      const shouldHighlightRow = shouldHighlight(translatedLogsForHighlight, promotion);

      const latestApprovedRevision = shouldHighlightRow
        ? getLatestApprovedRevision(translatedLogsForHighlight, id)
        : {};

      const listOfColumnsToCompare = shouldHighlightRow
        ? columnsToCompare
        : [];

      return (
        <GridViewRow
          columnOrder={columnOrder}
          columnsToCompare={listOfColumnsToCompare}
          customActionBar={customActionBar}
          enableContextMenu={enableContextMenu}
          popOverDirection={index < promotions.length - 5 ? PopOverDirection.UP : PopOverDirection.DOWN}
          isReadOnly={!!(isReadOnly || isLocked || isSoftDeleted)}
          isSelected={selectedRowsIds[id]}
          isSelectionAllowed={!selectedRowsIds[PASTE_HERE_ROW_ID]}
          key={key}
          lastRevisionToCompare={latestApprovedRevision}
          promotion={promotion}
          showActionBar={showActionBar}
          shouldHighlight={shouldHighlightRow}
          storeRegionErrorMessages={storeRegionErrorMapForPageKey[pageZone]}
          isContentWrappingEnabled={isContentWrappingEnabled}
          isMustFillHighlightEnabled={isMustFillHighlightEnabled}
          shouldStylize={true}
        />
      );
    });

    if (showPasteHereRow) {
      rows.push((
        <GridViewRow
          key={PASTE_HERE_ROW_ID}
          columnOrder={columnOrder}
          customActionBar={customActionBar}
          enableContextMenu={false}
          isSelected={selectedRowsIds[PASTE_HERE_ROW_ID]}
          isSelectionAllowed
          promotion={pasteHereRow()}
          showActionBar={showActionBar}
          showSelectButtonOnly
        />
      ));
    }

    return rows;
  };

  const stringifiedPromotions = JSON.stringify(promotions);

  const storeRegionErrorMapForPageKey = useMemo(
    () => buildStoreRegionErrorMapForPageKey(promotions, storeRegions),
    [stringifiedPromotions, JSON.stringify(storeRegions)]
  );

  const rows = useMemo(
    () => getRows(
      promotions,
      columnOrder,
      translatedLogsForHighlight,
      storeRegionErrorMapForPageKey,
    ),
    [
      stringifiedPromotions,
      JSON.stringify(columnOrder),
      JSON.stringify(translatedLogsForHighlight),
      JSON.stringify(selectedRowsIds),
      isAdmin,
      isContentWrappingEnabled,
      isMustFillHighlightEnabled,
    ],
  );

  return (
    <table
      role="grid"
      className={classnames({
        [styles.gridViewTable]: true,
        [styles.gridViewTableWithPasteHereRow]: !!showPasteHereRow,
      })}
    >
      <tbody>
        <GridViewHeader
          columns={columnOrder}
          showActionBar={showActionBar}
          showSearch={showSearch}
          isSticky={isStickyHeader}
          isContentWrappingEnabled={isContentWrappingEnabled}
          isMustFillHighlightEnabled={isMustFillHighlightEnabled}
        />
        {rows}
      </tbody>
    </table>
  );
};

GridView.propTypes = {
  columnOrder: campaignColumnProps.isRequired,
  enableContextMenu: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isStickyHeader: PropTypes.bool,
  isContentWrappingEnabled: PropTypes.bool,
  isMustFillHighlightEnabled: PropTypes.bool,
  promotions: PropTypes.arrayOf(campaignProps).isRequired,
  showActionBar: PropTypes.bool,
  showSearch: PropTypes.bool,
  showPasteHereRow: PropTypes.bool,
  storeRegions: PropTypes.arrayOf(PropTypes.string),
  selectedRowsIds: PropTypes.objectOf(PropTypes.string),
};

GridView.defaultProps = {
  enableContextMenu: false,
  isAdmin: false,
  isReadOnly: false,
  isStickyHeader: false,
  isContentWrappingEnabled: false,
  isMustFillHighlightEnabled: false,
  showActionBar: true,
  showSearch: false,
  showPasteHereRow: false,
  storeRegions: [],
  selectedRowsIds: {},
};

export default GridView;
