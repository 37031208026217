import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';

import { styledInputProps } from '../../proptypes';

const getLabel = (selectedOptions) => {
  return selectedOptions.map((option) => option.label).join(', ');
};

const onChange = (selectedOptions, onInputChange, options) => {
  // onChange by default sends the selectedOptions in the order they were selected,
  // not the order they are listed in the list, this function rearranges them in list order
  const selectedOptionsValues = {};
  selectedOptions.forEach((option) => { selectedOptionsValues[option] = true; });
  onInputChange(options.filter((option) => {
    return selectedOptionsValues[option];
  }));
};

const MultiSelect = ({
  inputValue, onInputChange, options, onBlur,
}) => {
  const [currentOptionsSelection, setCurrentOptionsSelection] = useState(inputValue || []);
  const [searchTerm, updateSearchTerm] = useState('');

  const selections = options.filter((option) => (
    option.indexOf(searchTerm) !== -1
  )).map((option) => (
    <SelectOption value={option} label={option} key={option} />
  ));

  const emptyResults = (
    <Column alignment="center stretch" spacing="small" spacingInset="small">
      <Text alignment="center">No results</Text>
    </Column>
  );

  return (
    <Select
      onBlur={(value) => {
        onInputChange(currentOptionsSelection);
        onBlur(value);
      }}
      value={currentOptionsSelection}
      onChange={(selectedOptions) => {
        onChange(selectedOptions, setCurrentOptionsSelection, options);
      }}
      onSearch={updateSearchTerm}
      searchQuery={searchTerm}
      valueLabel={getLabel}
      size="small"
      width="100%"
      autoFocus
    >
      {selections}
      {!selections.length && emptyResults}
    </Select>
  );
};

MultiSelect.propTypes = {
  ...styledInputProps,
  options: PropTypes.arrayOf(PropTypes.string),
};

MultiSelect.defaultProps = {
  options: [],
};

export default MultiSelect;
