import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import * as mutations from '../../graphql/mutations';
import { getPromotionsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';
import { setPublishPromotionsResponse } from '../../actionCreators/publishPromotions';
import { PublishPromotionsRequestStatus } from '../../constants/publishPromotions';
import { getUsername } from '../../selectors/user';
import { splitYearQuarterDates } from '../../helpers/dateTime';

const extractPromotionIds = (promotions) => promotions.filter((promo) => !promo.isSoftDeleted).map((promo) => promo.id);

export default (dispatch, getState, action, next) => {
  const promotions = getPromotionsForCurrentWeek(getState());
  if (_.isEmpty(promotions)) {
    return next(action);
  }

  const promotionIds = extractPromotionIds(promotions);
  const [firstPromotion] = promotions;
  const {yearQuarter, startDate, endDate} = splitYearQuarterDates(firstPromotion.yearQuarter_startDate_endDate);

  const generatePublishPromotionsInput = {
    promotionIds,
    startDate_endDate: `${startDate}_${endDate}`,
    username: getUsername(getState()),
    yearQuarter
  };

  const onResponse = (response) => {
    dispatch(setPublishPromotionsResponse(JSON.stringify(response), PublishPromotionsRequestStatus.REQUEST_SUCCEEDED));
  };

  dispatch(setPublishPromotionsResponse(null, PublishPromotionsRequestStatus.REQUEST_SENT));
  API
    .graphql(graphqlOperation(mutations.publishPromotions, { input: generatePublishPromotionsInput }))
    .then(onResponse)
    .catch((error) => dispatch(setPublishPromotionsResponse(JSON.stringify(error), PublishPromotionsRequestStatus.REQUEST_FAILED)))
  return next(action);
};
