import {
  UPDATE_REQUIRED_FIELDS,
} from '../../constants';

export const initialState = {
  requiredFields: [],
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type } = action;

  switch (type) {
    case UPDATE_REQUIRED_FIELDS: {
      const { data } = action;
      return {
        ...state,
        requiredFields: data,
      };
    }
    default: {
      return state;
    }
  }
};
