import { connect } from 'react-redux';

import MetadataEditor from './MetadataEditor';

const mapStateToProps = (state) => {
  const { Meta: { metadata: { fields } } } = state;

  return {
    fields,
  };
};

export default connect(mapStateToProps)(MetadataEditor);
