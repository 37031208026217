import React from 'react';
import Modal from '@amzn/meridian/modal';
import PromotionWeekContainer from '../../../PromotionWeekContainer';
import WithHistoryTiming from '../../../WithHistoryTiming';
import { ModalNames } from '../../../../constants/metrics';

const EditWeekPage = (props) => {
  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={() => window.history.back()}
    >
      <PromotionWeekContainer {...props} />
    </Modal>
  );
};

export default WithHistoryTiming(EditWeekPage, { pageName: ModalNames.EDIT_PROMOTION_WEEK });
