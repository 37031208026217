import { connect } from 'react-redux';
import Main from './Main';
import { BannerColumns } from '../../../../constants/banner';

export const mapStateToProps = (state) => {
  const { Banner: { banners } } = state;

  return {
    columnList: BannerColumns,
    banners,
  };
};

export default connect(mapStateToProps)(Main);
