import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Checkbox from '@amzn/meridian/checkbox';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import trashTokens from '@amzn/meridian-tokens/base/icon/trash';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import closeCircleTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import menuMeatballTokens from '@amzn/meridian-tokens/base/icon/menu-meatball';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import Tooltip from '@amzn/meridian/tooltip';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { campaignProps } from '../../../proptypes';
import { isUneditableStatus } from '../../../helpers/status';
import { InputTypes, Language, Urls } from '../../../constants';
import styles from './PromoWeekActionBar.module.scss';

const lockableActionBarItems = [
  Language.ACTION_BUTTON_DELETE,
  Language.ACTION_BUTTON_RESET,
  Language.ACTION_BUTTON_CLONE,
  Language.ACTION_BUTTON_SOFT_LOCK,
];

const PromoWeekActionBar = ({
  promotion,
  onDelete,
  onReset,
  onSoftDelete,
  onUpdateStatus,
  onSelect,
  isAdmin,
  isSelected,
  isSelectionAllowed,
  editOverride,
  toggleEditOverride,
  showSelectButtonOnly,
}) => {
  const history = useHistory();
  const { id, status, isSoftDeleted } = promotion;
  const isLocked = isUneditableStatus(status, isAdmin) && !editOverride;

  const onClonePromotion = () => history.push(`${Urls.PROMOTION_CLONE}/${id}`);
  const onEditPromotion = () => history.push(`${Urls.PROMOTION_EDIT}/${id}`);
  const onDeletePromotion = () => onDelete(id);
  const onResetPromotion = () => onReset(id);
  const onSoftDeletePromotion = () => onSoftDelete(id);
  const onUpdatePromoStatus = () => onUpdateStatus(id, status);
  const onSelectPromotion = () => onSelect(id);
  const onSetPromoEditOverride = () => toggleEditOverride();

  const shouldLockItem = (name) => {
    if (isLocked) {
      return lockableActionBarItems.includes(name);
    }
    if (isSoftDeleted) {
      return lockableActionBarItems.includes(name) && Language.ACTION_BUTTON_SOFT_LOCK !== name;
    }
    return false;
  };

  const getButtonList = () => {
    const overrideButtonName = editOverride
      ? Language.PROMOTION_EDIT_OVERRIDE_ON
      : Language.PROMOTION_EDIT_OVERRIDE_OFF;

    const editButtonName = (isLocked || isSoftDeleted)
      ? Language.ACTION_BUTTON_VIEW
      : Language.ACTION_BUTTON_EDIT;

    const softLockButtonName = isSoftDeleted
      ? Language.ACTION_BUTTON_UNDO_SOFT_LOCK
      : Language.ACTION_BUTTON_SOFT_LOCK;

    const buttonList = [
      {
        inputType: InputTypes.SINGLE_CHECKBOX,
        name: Language.ACTION_BUTTON_SELECT,
        handler: onSelectPromotion,
        shouldRender: () => true,
        isDisabled: shouldLockItem(Language.ACTION_BUTTON_SELECT) || !isSelectionAllowed,
        isChecked: isSelected,
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: overrideButtonName,
        handler: onSetPromoEditOverride,
        materialUiIcon: editOverride ? <LockOpenIcon /> : <LockIcon />,
        shouldRender: () => isAdmin,
        isDisabled: (!isLocked && !editOverride) || !isAdmin,
        isChecked: !isLocked || editOverride,
        styleClasses: {
          [styles.lockButton]: true,
        },
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: editButtonName,
        handler: onEditPromotion,
        icon: (isLocked || isSoftDeleted) ? viewTokens : editTokens,
        shouldRender: () => true,
        isDisabled: shouldLockItem(editButtonName),
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: Language.ACTION_BUTTON_CLONE,
        handler: onClonePromotion,
        icon: copyTokens,
        shouldRender: () => true,
        isDisabled: shouldLockItem(Language.ACTION_BUTTON_CLONE),
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: Language.ACTION_BUTTON_RESET,
        handler: onResetPromotion,
        icon: undoTokens,
        shouldRender: () => true,
        isDisabled: shouldLockItem(Language.ACTION_BUTTON_RESET),
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: softLockButtonName,
        handler: onSoftDeletePromotion,
        icon: closeCircleTokens,
        shouldRender: () => true,
        isDisabled: shouldLockItem(softLockButtonName),
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: Language.ACTION_BUTTON_DELETE,
        handler: onDeletePromotion,
        icon: trashTokens,
        shouldRender: () => isAdmin,
        isDisabled: shouldLockItem(Language.ACTION_BUTTON_DELETE),
      },
      {
        inputType: InputTypes.SINGLE_BUTTON,
        name: Language.ACTION_BUTTON_UPDATE_STATUS,
        handler: onUpdatePromoStatus,
        icon: menuMeatballTokens,
        shouldRender: () => isAdmin,
        isDisabled: shouldLockItem(Language.ACTION_BUTTON_UPDATE_STATUS),
      },
    ];

    if (showSelectButtonOnly) {
      return buttonList.slice(0, 1);
    }

    return buttonList;
  }

  const getIcon = ({ icon, materialUiIcon, name }) => {
    if (materialUiIcon) {
      return materialUiIcon;
    }

    return (
      <Icon tokens={icon}>
        {name}
      </Icon>
    );
  };

  const getCheckboxControl = ({ id, name, isChecked, isDisabled, handler }) => {
    return (
      <Checkbox
        key={`${name}/${id}`}
        checked={isChecked}
        disabled={isDisabled}
        onChange={handler}
      />
    );
  };

  const getButtonControl = (buttonConfig) => {
    const { name, isDisabled, styleClasses, handler } = buttonConfig;
    const iconContent = getIcon(buttonConfig);
    const buttonContent = iconContent || name;

    return (
      <Tooltip position="top" title={name}>
        <Button
          className={classnames({
            [styles.button]: true,
            [styles.disabledButton]: isDisabled,
            ...styleClasses,
          })}
          type="link"
          size="small"
          key={`${name}/${id}`}
          disabled={isDisabled}
          onClick={handler}
        >
          {buttonContent}
        </Button>
      </Tooltip>
    );
  }

  const getControlByType = (buttonConfig) => {
    const { inputType } = buttonConfig;

    switch(inputType) {
      case InputTypes.SINGLE_CHECKBOX: {
        return getCheckboxControl(buttonConfig);
      }
      case InputTypes.SINGLE_BUTTON: {
        return getButtonControl(buttonConfig);
      }
      default: {
        return getButtonControl(buttonConfig);
      }
    }
  }

  const getControlList = () => {
    const controlList = getButtonList()
      .filter(({ shouldRender }) => shouldRender())
      .map((buttonConfig) => {
        const { name } = buttonConfig;
        const control = getControlByType(buttonConfig);

        return (
          <li key={name}>
            {control}
          </li>
        );
      });

    return (
      <ul className={styles.controlsList}>
        {controlList}
      </ul>
    );
  };

  return getControlList();
};

PromoWeekActionBar.propTypes = {
  promotion: campaignProps.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSoftDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  editOverride: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  isSelectionAllowed: PropTypes.bool,
  showSelectButtonOnly: PropTypes.bool,
  toggleEditOverride: PropTypes.func,
};

PromoWeekActionBar.defaultProps = {
  showSelectButtonOnly: false,
  isSelected: false,
  isSelectionAllowed: true,
  toggleEditOverride: _.noop,
}

export default PromoWeekActionBar;
