import React from 'react';
import Textarea from '@amzn/meridian/textarea';
import { styledInputProps } from '../../../proptypes';

const TextAreaInput = ({
  onSubmit,
  inputValue,
  onInputChange,
  onBlur,
  attributes = {},
}) => {
  const defaultAttributes = {
    width: '300%',
  };

  const mergedAttributes = {
    ...defaultAttributes,
    ...attributes,
  };

  return (
    <form
      onSubmit={onSubmit}
    >
      <Textarea
        value={inputValue || ''}
        onChange={(newValue) => onInputChange({ target: { value: newValue } })}
        onBlur={onBlur}
        size="small"
        placeholder="Enter value..."
        rows={1}
        autoFocus
        {...mergedAttributes}
      />
    </form>
  );
};

TextAreaInput.propTypes = styledInputProps;

export default TextAreaInput;
