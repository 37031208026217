export const PublishPromotionsActions = Object.freeze({
  SET_PUBLISH_PROMOTIONS_IS_MODAL_OPEN: 'PublishPromotionsActions/SET_PUBLISH_PROMOTIONS_IS_MODAL_OPEN',
  SET_PUBLISH_PROMOTIONS_IS_LOADING: 'PublishPromotionsActions/SET_PUBLISH_PROMOTIONS_IS_LOADING',
  SET_PUBLISH_PROMOTIONS_RESPONSE: 'PublishPromotionsActions/SET_PUBLISH_PROMOTIONS_RESPONSE',
  SEND_PUBLISH_PROMOTIONS_REQUEST: 'PublishPromotionsActions/SEND_PUBLISH_PROMOTIONS_REQUEST',
});

export const PublishPromotionsRequestStatus = Object.freeze({
  REQUEST_FAILED: 'PublishPromotionsRequestStatus/REQUEST_FAILED',
  REQUEST_NOT_SENT: 'PublishPromotionsRequestStatus/NOT_SENT',
  REQUEST_SENT: 'PublishPromotionsRequestStatus/SENT',
  REQUEST_SUCCEEDED: 'PublishPromotionsRequestStatus/REQUEST_SUCCEEDED',
})
