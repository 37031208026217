export const PromotionTypes = Object.freeze({
  FIXED_PRICE: {
    display: 'Fixed price',
    value: 'Fixed price',
  },
  PERCENT_OFF: {
    display: 'Percent off',
    value: 'Percent off',
  },
  POS: {
    display: 'POS',
    value: 'POS',
  },
});
