import { Hosts } from '../constants';

const oauth = {
  domain: 'f3-circulator-domain-prod.auth.us-east-1.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `https://${Hosts.PROD_READABLE}`,
  redirectSignOut: `https://${Hosts.PROD_READABLE}`,
  responseType: 'code',
};

const config = {
  Auth: {
    identityPoolId: 'us-east-1:3ac01e40-e7fb-4858-80fd-7f73bd3d1d2a',
    region: 'us-east-1',
    userPoolId: 'us-east-1_UWhXmVhre',
    userPoolWebClientId: '4schq6874o88old1e1kubin0o0',
    oauth,
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://2swevrz2mfe4npmicoo56wnliy.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
};

export default config;
