import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';

const ExportButton = ({ onExport }) => {
  return (
    <Button
      size="small"
      type="primary"
      onClick={onExport}
    >
      Export
    </Button>
  );
};

ExportButton.propTypes = {
  onExport: PropTypes.func.isRequired,
};

export default ExportButton;
