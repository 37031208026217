import React from 'react';
import Main from './Sections/Main';
import SubmitButtons from './Sections/SubmitButtons';
import styles from './BannerForm.module.scss';

const BannerForm = (props) => {
  const {
    onSave,
    handleSubmit,
    submitting,
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <form
          className={styles.form}
          onSubmit={handleSubmit(onSave)}
        >
          <div className={styles.formInnerContainer}>
            <Main />
            <SubmitButtons submitting={submitting} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BannerForm;
