import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import {
  ArrayObjectColumns,
  BusinessTypes,
  Columns,
  DASH_DELIMETER,
  SovTypes,
} from '../constants';
import getPromotionById from '../reducers/gridViewPage/getPromotionById';

export const getString = (value) => {
  const valueType = Object.prototype.toString.call(value);
  const allowedTypes = [
    '[object String]',
    '[object Number]',
  ];

  if (!value || !allowedTypes.includes(valueType)) {
    return '';
  }

  return String(value);
};
export const getNumber = (value) => {
  if (Object.prototype.toString.call(value) === '[object Boolean]') {
    return 0;
  }

  const newValue = +value;
  if (!newValue) {
    return 0;
  }

  return newValue;
};
export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
}

export const openLinkInNewTab = (url) => window.open(url, '_blank');
export const isFreshBusiness = (businessType) => businessType === BusinessTypes.FRESH.name;
export const isWFMBusiness = (businessType) => businessType === BusinessTypes.WFM.name;
export const isFreshSGBusiness = (businessType) => businessType === BusinessTypes.FRESH_SG.name;
export const isFreshUKBusiness = (businessType) => businessType === BusinessTypes.FRESH_UK.name;
export const isFreshDEBusiness = (businessType) => businessType === BusinessTypes.FRESH_DE.name;
export const is3PNABusiness = (businessType) => businessType === BusinessTypes.NA_3P.name;
export const isFreshITBusiness = (businessType) => businessType === BusinessTypes.FRESH_IT.name;
export const isFreshESBusiness = (businessType) => businessType === BusinessTypes.FRESH_ES.name;
export const is3PFRBusiness = (businessType) => businessType === BusinessTypes.FR_3P.name;
export const is3PUKBusiness = (businessType) => businessType === BusinessTypes.UK_3P.name;
export const is3PITBusiness = (businessType) => businessType === BusinessTypes.IT_3P.name;
export const is3PESBusiness = (businessType) => businessType === BusinessTypes.ES_3P.name;
export const is3PDEBusiness = (businessType) => businessType === BusinessTypes.DE_3P.name;
export const is3PJPBusiness = (businessType) => businessType === BusinessTypes.JP_3P.name;
export const isFreshJPBusiness = (businessType) => businessType === BusinessTypes.FRESH_JP.name;
export const isGenericFreshBusiness = (business) => {
  return (
    isFreshBusiness(business)
    || isFreshSGBusiness(business)
    || isFreshUKBusiness(business)
    || isFreshDEBusiness(business)
    || isFreshESBusiness(business)
    || isFreshITBusiness(business)
  );
};
export const isJPBusiness = (business) => {
  return (
    is3PJPBusiness(business)
    || isFreshJPBusiness(business)
  );
};
export const isGeneric3PBusiness = (business) => {
  return (
    is3PNABusiness(business)
    || is3PUKBusiness(business)
    || is3PESBusiness(business)
    || is3PITBusiness(business)
    || is3PDEBusiness(business)
    || is3PFRBusiness(business)
    || is3PJPBusiness(business)
  );
};
export const isUSBusiness = (business) => {
  return (
    isFreshBusiness(business)
    || isWFMBusiness(business)
    || is3PNABusiness(business)
  );
};
export const isUKBusiness = (business) => {
  return (
    isFreshUKBusiness(business)
      || is3PUKBusiness(business)
  );
};
export const isDEBusiness = (business) => {
  return (
    isFreshDEBusiness(business)
      || is3PDEBusiness(business)
  );
};
export const isESBusiness = (business) => {
  return (
    isFreshESBusiness(business)
      || is3PESBusiness(business)
  );
};
export const isITBusiness = (business) => {
  return (
    isFreshITBusiness(business)
      || is3PITBusiness(business)
  );
};
export const shouldShowMerchantBlock = (business) => isGeneric3PBusiness(business);
export const shouldShowTrafficDriversBlock = (business) => {
  return !isJPBusiness(business);
};
export const convertDecimalToPercent = (value) => {
  if (Object.prototype.toString.call(value) !== '[object Number]') {
    return -1;
  }
  const parsedInt = parseInt(value * 100, 10);
  if (!parsedInt && parsedInt !== 0) {
    return -1;
  }
  return Math.ceil(parsedInt);
};
export const updatePageTitle = (newPageTitle) => {
  document.title = newPageTitle;
};
export const hasPromotionChanged = (state) => {
  const { GridViewPage } = state;
  const { activeCell, activeCellValue } = GridViewPage;

  if (!activeCell) {
    return false;
  }
  const { rowId, columnName } = activeCell;

  const campaign = getPromotionById(GridViewPage, rowId);
  if (!campaign) {
    return false;
  }

  const hasDiff = ArrayObjectColumns.includes(columnName)
    ? !_.isEqual(campaign[columnName], activeCellValue)
    : campaign[columnName] !== activeCellValue;

  if (!hasDiff) {
    return false;
  }
  return true;
};

export const isValidUrl = (value) => {
  /* eslint-disable-next-line max-len */
  return /^(?:(?:(?:https?|http):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
};

/**
 * Get human friendly column name by its internal name.
 *
 * @param {string} columnName
 * @returns {string}
 */
export const getColumnDisplayNameByName = (columnName) => {
  const columnsNames = getColumnDisplayNameByName.cachedColumnsNames;

  if (!Object.keys(columnsNames).length) {
    Object
      .keys(Columns)
      .forEach((columnId) => {
        const { name, display } = Columns[columnId];
        columnsNames[name] = display;
      });
  }

  return columnsNames[columnName]
    ? columnsNames[columnName]
    : '';
};
getColumnDisplayNameByName.cachedColumnsNames = {};

export const nl2br = (str, breakTag = '<br />') => {
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const sanitize = (str) => {
  return sanitizeHtml(str, {
    allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
      'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'abbr', 'code', 'hr', 'br',
      'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
    disallowedTagsMode: 'discard',
    selfClosing: ['img', 'br', 'hr'],
    allowedAttributes: {
      a: ['href'],
      img: ['src'],
      p: ['style'],
    },
    allowedStyles: {
      '*': {
        // Match HEX and RGB
        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        'text-align': [/^left$/, /^right$/, /^center$/],
        // Match any number with px, em, or %
        'font-size': [/^\d+(?:px|em|%)$/],
      },
      p: {
        'font-size': [/^\d+rem$/],
      },
    },
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false,
  });
};

/**
 * Allows to use templates in strings.
 *
 * @param string
 * @param mapping
 * @returns string
 *
 * Usage example:
 * getTemplateString('Hello world! My name is #{username}! I like #{animalType}!', {
 *   username: 'John',
 *   animalType: 'cats',
 * })
 *
 * =>
 *
 * Hello world! My name is John! I like cats'!
 */
export const getTemplateString = (string, mapping = {}) => {
  const matches = string.match(/#{[a-zA-Z]+?}/g);
  if (!matches) {
    return string;
  }
  let replacedString = string;

  matches.forEach((templateStringToReplace) => {
    const templateString = templateStringToReplace.replace(/#|{|}/g, '');
    if (mapping[templateString]) {
      replacedString = replacedString.replace(templateStringToReplace, mapping[templateString]);
    }
  });

  return replacedString;
};
// Sov is disabled for these sov types since Content Symphony sets them automatically
export const shouldDisableSovForSovType = (sovType) => [
  SovTypes.RANDOM,
  SovTypes.DEFAULT,
].includes(sovType);

export const getYearQuarter = (dateTime) => {
  const newYearQuarter = dateTime ? moment(dateTime) : moment();

  return `${newYearQuarter.year()}-Q${newYearQuarter.quarter()}`;
};

export const getNextYearQuarter = (currentYearQuarter) => {
  const [year, quarter] = currentYearQuarter.split(DASH_DELIMETER);
  const newYearQuarter = moment(year)
    .quarter(quarter.substr(1, quarter.length))
    .add(1, 'Q');

  return `${newYearQuarter.year()}-Q${newYearQuarter.quarter()}`;
};

export const getPercentage = (current, total) => Math.ceil((current / total) * 100);
export const formatToPercentage = (value) => Math.ceil(value * 100);
export const getNumberWithinRange = (value, min = 0, max = 100) => {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
};
export const applyPercentageDiscount = (value, discount = 0) => getNumberWithinRange((1 / 100) * (100 - discount)) * value;

export const isEmptyOrUnfilledArray = (arr) => {
  if (!Array.isArray(arr)) {
    return false;
  }
  if (arr.length === 0) {
    return true;
  }
  if (arr.length === 1) {
    return arr[0] === null || arr[0] === '';
  }
  return false;
};
export const sanitizeStringForSearch = (s) => s.toString().toLowerCase().replace(/\s/g, '');

export const createItemShell = (columns, overrides = {}) => {
  const itemShell = Object.values(columns).reduce((itemShell, { name }) => ({
    ...itemShell,
    [name]: overrides[name] !== undefined ? overrides[name] : '',
  }), {});

  Object.entries(overrides).forEach(([key, value]) => {
    if (itemShell.hasOwnProperty(key)) {
      return;
    }
    itemShell[key] = value;
  });

  return itemShell;
};