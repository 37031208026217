import { API, graphqlOperation } from 'aws-amplify';
import {
  onOpenToast,
  onLoadWeeksForYearQuarters,
} from '../../actionCreators';
import * as mutations from '../../graphql/mutations';
import { getWeekByStartEndDate } from '../../helpers/promotions';
import { localToAppsync } from '../../helpers/translatePromotionWeek';

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }

  const {
    User: { currentUser: { username } },
    Sitewide: { yearQuarter },
  } = state;

  const {
    payload: {
      week,
    },
  } = action;

  const { startDate, endDate } = week;

  const weekToUpdate = getWeekByStartEndDate(state, week);

  if (!weekToUpdate) {
    dispatch(onOpenToast(`Cannot find week! ${startDate}/${endDate}`));
    return next(action);
  }

  const translatedPromotionWeek = localToAppsync({
    ...week,
    yearQuarter,
    version: weekToUpdate.version + 1,
  });

  const params = {
    promotionWeek: translatedPromotionWeek,
    username,
  };

  const onResponse = () => {
    dispatch(onLoadWeeksForYearQuarters([yearQuarter]));
  };

  API
    .graphql(graphqlOperation(mutations.updatePromotionWeek, { input: params }))
    .then(onResponse)
    .catch((error) => console.error(error));

  return next(action);
};
