import React from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import PropTypes from 'prop-types';

const onRefreshPage = () => window.location.reload();

const ConnectionTimeoutModal = ({ shouldShow }) => {
  return (
    <Modal
      title="Warning! Connection timed out!"
      open={shouldShow}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-description"
    >
      <Text id="modal-description">
        The connection to the server has been timed out. All changes made after this point will not be saved.<br />
        If you want to reconnect to the server, you have to refresh the page.
      </Text>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="primary" size="small" onClick={onRefreshPage}>
            Refresh
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ConnectionTimeoutModal.propTypes = {
  shouldShow: PropTypes.bool,
};
ConnectionTimeoutModal.defaultProps = {
  shouldShow: false,
};

export default ConnectionTimeoutModal;
