import {
  ExportActions,
  PromotionExportTypes,
  PromotionExportViewType,
} from '../../constants/export';

export const initialState = {
  isExportModalOpen: false,
  isLoading: false,
  exportViewType: PromotionExportViewType.PREVIEW,
  exportType: PromotionExportTypes.STANDARD.value,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type } = action;

  switch (type) {
    case ExportActions.OPEN_EXPORT_MODAL: {
      const { payload: { exportViewType } } = action;

      return {
        ...state,
        exportViewType,
        isExportModalOpen: true,
      };
    }
    case ExportActions.CLOSE_EXPORT_MODAL: {
      return {
        ...state,
        isExportModalOpen: false,
      };
    }
    case ExportActions.SET_EXPORT_TYPE: {
      const { payload: { exportType } } = action;

      return {
        ...state,
        exportType,
      };
    }
    case ExportActions.SET_LOADING: {
      const { payload: { isLoading } } = action;

      return {
        ...state,
        isLoading,
      };
    }
    default: {
      return state;
    }
  }
};
