import { connect } from 'react-redux';
import PromoReviewContainer from './PromoReviewContainer';
import { isCurrentUserAdmin } from '../../selectors/user';
import {
  getPromotionsToImageReview,
  getPromotionsToReview,
} from '../../selectors/promotionFiltersSelectors';
import {
  onCloseImageReviewPromotionTable,
  onCloseReviewPromotionTable,
  onOpenImageReviewPromotionTable,
  onOpenReviewPromotionTable,
} from '../../actionCreators';

const mapStateToProps = (state) => {
  const {
    GridViewPage: { isEditReviewPromotionTableOpen, isImageReviewPromotionTableOpen },
  } = state;

  return {
    isAdmin: isCurrentUserAdmin(state),
    isEditReviewPromotionTableOpen,
    isImageReviewPromotionTableOpen,
    promotionsToImageReviewCount: getPromotionsToImageReview(state).length,
    promotionsToReviewCount: getPromotionsToReview(state).length,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenImageReviewTable() {
      dispatch(onOpenImageReviewPromotionTable());
    },
    onOpenReviewTable() {
      dispatch(onOpenReviewPromotionTable());
    },
    onCloseImageReviewTable() {
      dispatch(onCloseImageReviewPromotionTable());
    },
    onCloseReviewTable() {
      dispatch(onCloseReviewPromotionTable());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoReviewContainer);
