export const ToastMessages = Object.freeze({
  UNDO_SUCCESS: 'Undo successful',
  UNDO_FAIL: 'Error. Undo failed!',
  NO_MORE_UNDO: 'There is nothing left to Undo in this Promotion Week!',
  UPDATE_SUCCESS: 'Promotion has been successfully updated',
  UPDATE_FAIL: 'Error. Promotion update failed!',
});

export const AsinValidatorMessages = Object.freeze({
  NO_ASINS_ALL: 'There are no ASINs that are available in all regions.',
  NO_ASINS_SELECTED: 'There are no ASINs available for your selected regions.',
  NO_ASIN_COVERAGE: 'The set of ASINs provided cannot cover all regions',
  NO_ASIN_RECOMMENDATION: 'No ASIN recommendations for this set of ASINs',
})
