import graphqlAction from './graphqlAction';
import { getMetadata, getMetadataAuditLog } from '../graphql/queries';
import { updateMetadata } from '../graphql/mutations';
import {
  AsinImageActions,
  AsinValidatorActions,
  AuditLogActions,
  CLEAR_PROMOTIONS,
  CLOSE_TOAST,
  CommentActions,
  CustomViewActions,
  FeatureNotificationActions,
  FETCH_PROMOTION_WEEKS,
  GET_GRAPHQL_TYPES,
  GridViewActions,
  HIDE_LOADING_SPINNER,
  KEYPRESS,
  LoadingActions,
  MetadataAuditLogActions,
  MetadataEditorActions,
  NOOP,
  NotificationsActions,
  ON_ADD_METADATA_ENTRY,
  ON_CLEAR_METADATA,
  ON_METADATA_RECEIVED,
  ON_REMOVE_METADATA_ENTRY,
  ON_UPDATE_METADATA,
  OPEN_TOAST,
  PAGE_INIT,
  PromoActions,
  PromoWeekActions,
  PublishedPromotionActions,
  SELECT_BUSINESS,
  SELECT_TIMEFRAME,
  SELECT_YEAR_QUARTER,
  SET_GRAPHQL_TYPES,
  SET_NEW_USER_EXPERIENCE_SHOWN,
  SET_PROMOTIONS,
  SET_PROMOTIONS_LOADED,
  SET_PROMOTIONS_LOADING,
  SHOW_LOADING_SPINNER,
  SiteWideActions,
  TabsActions,
  TemplateActions,
  ThirdPartyIFrameActions,
  TOGGLE_SIDE_MENU,
  UPDATE_REQUIRED_FIELDS,
} from '../constants';
import { SubscriptionActions } from '../constants/subscriptions';
import { UserActions } from '../constants/user';
import { ExportActions } from '../constants/export';
import { PreviewActions } from '../constants/preview';
import {
  PageLoadTimerActions,
  PerformanceTimerActions,
} from '../constants/metrics';

export const onKeyboardPress = (event) => ({ type: KEYPRESS, data: event });
export const onSelectCell = ({ rowId, columnName }) => ({
  type: GridViewActions.SELECT_CELL,
  payload: {
    rowId,
    columnName,
  },
});
export const onUnselectCell = () => ({
  type: GridViewActions.UNSELECT_CELL,
});
export const onActiveCellInputUpdate = (event) => ({
  type: GridViewActions.ACTIVE_CELL_INPUT_UPDATE,
  data: event,
});
export const onResetActiveCell = () => ({ type: GridViewActions.RESET_ACTIVE_CELL });
export const onActiveCellInputSubmit = () => ({ type: GridViewActions.ACTIVE_CELL_INPUT_SUBMIT });
export const onCellInputUpdate = (promotionId, columnName, value) => ({
  type: GridViewActions.CELL_INPUT_UPDATE,
  payload: {
    promotionId,
    columnName,
    value,
  },
});
export const onFlashFill = (promotionId, columnName, value) => ({
  type: GridViewActions.FLASH_FILL,
  payload: {
    promotionId,
    columnName,
    value,
  },
});
export const onSetPromotions = (promotions) => ({
  type: SET_PROMOTIONS,
  payload: { promotions },
});
export const onClearPromotions = () => ({
  type: CLEAR_PROMOTIONS,
});
export const onSetPromotionsLoading = (isLoading) => ({
  type: SET_PROMOTIONS_LOADING,
  data: isLoading,
});
export const onSetPromotionsLoaded = (isLoaded) => ({
  type: SET_PROMOTIONS_LOADED,
  payload: { isLoaded },
});
export const onFetchPromotionWeeks = (
  yearQuarter_startDate_endDate,
  forceReload = false,
) => ({
  type: FETCH_PROMOTION_WEEKS,
  data: {
    yearQuarter_startDate_endDate,
    forceReload,
  },
});
export const onMoveActiveCell = (direction) => ({
  type: GridViewActions.MOVE_ACTIVE_CELL,
  data: { direction },
});
export const onSetActiveCellEditMode = (isEditMode) => ({
  type: GridViewActions.SET_ACTIVE_CELL_EDIT_MODE,
  payload: {
    isEditMode,
  }
});
export const onPressUndo = () => ({
  type: GridViewActions.PRESS_UNDO,
});
export const onLoadPromotionsAuditLogForHighlight = (promotions) => ({
  type: GridViewActions.LOAD_PROMOTIONS_AUDIT_LOG_FOR_HIGHLIGHT,
  payload: { promotions },
});
export const onSetTranslatedAuditLogsForHighlight = (translatedLogsForHighlight) => ({
  type: GridViewActions.SET_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT,
  payload: { translatedLogsForHighlight },
});
export const onClearTranslatedAuditLogsForHighlight = () => ({
  type: GridViewActions.CLEAR_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT,
});
export const onOpenReviewPromotionTable = () => ({
  type: GridViewActions.OPEN_REVIEW_PROMOTION_TABLE,
});
export const onCloseReviewPromotionTable = () => ({
  type: GridViewActions.CLOSE_REVIEW_PROMOTION_TABLE,
});
export const onOpenImageReviewPromotionTable = () => ({
  type: GridViewActions.OPEN_IMAGE_REVIEW_PROMOTION_TABLE,
});
export const onCloseImageReviewPromotionTable = () => ({
  type: GridViewActions.CLOSE_IMAGE_REVIEW_PROMOTION_TABLE,
});
export const onAddPromotion = (promotion, optionsOverride = {}) => {
  const options = {
    isBulkAction: false,
    hasFailedSlotsValidation: false,
    isAddRowAction: false,
    ...optionsOverride,
  };
  return {
    type: PromoActions.ADD_PROMOTION,
    payload: {
      promotion,
      options,
    },
  };
};
export const onAddedPromotionReceived = (promotion) => ({
  type: PromoActions.ADDED_PROMOTION_RECEIVED,
  payload: {
    promotion,
  },
});
export const onUpdatePromotion = (promotion, optionsOverride = {}) => {
  const options = {
    isStatusUpdate: false,
    isUndo: false,
    ...optionsOverride,
  };
  return {
    type: PromoActions.UPDATE_PROMOTION,
    payload: {
      promotion,
      options,
    },
  };
};
export const onUpdatedPromotionReceived = (promotion) => ({
  type: PromoActions.UPDATED_PROMOTION_RECEIVED,
  payload: {
    promotion,
  },
});
export const onApprovePromotionImage = (id) => ({
  type: PromoActions.APPROVE_PROMOTION_IMAGE,
  payload: {
    id,
  },
});
export const onApprovePromotion = (id) => ({
  type: PromoActions.APPROVE_PROMOTION,
  payload: {
    id,
  },
});
export const onRejectPromotion = (id) => ({
  type: PromoActions.REJECT_PROMOTION,
  payload: {
    id,
  },
});
export const onClearPreviewDigitalCircularParams = () => ({
  type: PreviewActions.CLEAR_DIGITAL_CIRCULAR_PARAMS,
});
export const onLoadPreviewDigitalCircularParams = (region) => ({
  type: PreviewActions.LOAD_DIGITAL_CIRCULAR_PARAMS,
  payload: {
    region,
  },
});
export const onSetPreviewDigitalCircularParams = (digitalCircularParams) => ({
  type: PreviewActions.SET_DIGITAL_CIRCULAR_PARAMS,
  payload: {
    digitalCircularParams,
  },
});
export const onCopyDigitalCircularPreviewInstructions = (contentType) => ({
  type: PreviewActions.COPY_DIGITAL_CIRCULAR_PREVIEW_INSTRUCTIONS,
  payload: {
    contentType,
  },
});
export const onSetPreviewDigitalCircularParamsIsLoading = (isLoading) => ({
  type: PreviewActions.SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
export const onMetadataReceived = (data) => ({
  type: ON_METADATA_RECEIVED,
  data,
});
export const onClearMetadata = () => ({
  type: ON_CLEAR_METADATA,
});
export const onPerformMetadataUpdate = () => ({
  type: ON_UPDATE_METADATA,
});
export const onFetchMetadata = (business) => {
  return graphqlAction({
    graphqlAction: getMetadata,
    data: { business },
    onSuccessAction: onMetadataReceived,
    onFailureAction: (error) => {
      console.error({ error });
    },
    label: 'fetchMetadata',
  });
};
export const onUpdateMetadataAction = (
  username,
  business,
  metadata,
  version,
  onFailureFunction = () => {},
  onSuccessFunction = () => {},
) => graphqlAction({
  graphqlAction: updateMetadata,
  data: {
    input: {
      business,
      metadata: {
        metadata,
        version: version + 1,
      },
      username,
    },
  },
  label: 'updateMetadata',
  onSuccessFunction,
  onFailureFunction,
});
export const onDeletePromotion = (promotion, { isSilentDelete } = { isSilentDelete: false }) => ({
  type: PromoActions.DELETE_PROMOTION,
  payload: {
    promotion,
    isSilentDelete,
  },
});
export const onDeletedPromotionReceived = (promotion) => ({
  type: PromoActions.DELETED_PROMOTION_RECEIVED,
  payload: {
    promotion,
  },
});
export const onPromoWeekAddRow = (promotion) => ({
  type: PromoWeekActions.ADD_ROW,
  payload: {
    promotion,
  },
});
export const onAddSearchColumn = (search, columnName) => ({
  type: GridViewActions.ADD_SEARCH_COLUMN,
  payload: {
    search,
    columnName,
  },
});
export const onAddSearchColumns = (columns) => ({
  type: GridViewActions.ADD_SEARCH_COLUMNS,
  payload: {
    columns,
  },
});
export const onRemoveColumnsFromSearch = (columns) => ({
  type: GridViewActions.REMOVE_COLUMNS_FROM_SEARCH,
  payload: {
    columns,
  },
});
export const onClearSearch = () => ({
  type: GridViewActions.CLEAR_SEARCH,
});
export const onSetContentWrapping = (isEnabled) => ({
  type: GridViewActions.SET_CONTENT_WRAPPING,
  payload: {
    isEnabled,
  },
});
export const onSetMustFillHighlight = (isEnabled) => ({
  type: GridViewActions.SET_MUST_FILL_HIGHLIGHT,
  payload: {
    isEnabled,
  },
});
export const onPageInit = () => ({
  type: PAGE_INIT,
});
export const onAddMetadataEntry = (data) => ({
  type: ON_ADD_METADATA_ENTRY,
  data,
});
export const onRemoveMetadataEntry = (data) => ({
  type: ON_REMOVE_METADATA_ENTRY,
  data,
});
export const onBusinessSelect = ({ business, forceReload }) => ({
  type: SELECT_BUSINESS,
  data: {
    business,
    forceReload,
  },
});
export const onYearQuarterSelect = ({ yearQuarter }) => ({
  type: SELECT_YEAR_QUARTER,
  data: {
    yearQuarter,
  },
});
export const onSelectTimeFrame = (timeFrame) => ({
  type: SELECT_TIMEFRAME,
  payload: {
    timeFrame,
  },
});
export const onSendPromotionEdit = (data, optionsOverride = {}) => {
  const options = {
    isStatusUpdate: false,
    showToast: false,
    isBulkAction: false,
    isUndo: false,
    ...optionsOverride,
  };
  return {
    type: GridViewActions.SEND_PROMOTION_EDIT,
    payload: {
      ...data,
      options,
    },
  };
};
export const onParseFiltersFromUrl = () => ({
  type: GridViewActions.PARSE_FILTERS_FROM_URL,
});
export const onLoadGraphQLTypes = () => ({
  type: GET_GRAPHQL_TYPES,
});
export const onSetGraphQLTypes = (payload) => ({
  type: SET_GRAPHQL_TYPES,
  payload,
});
export const onOpenToast = (message, timeout) => ({
  type: OPEN_TOAST,
  data: {
    message,
    timeout,
  },
});
export const onCloseToast = (data) => ({
  type: CLOSE_TOAST,
  data,
});
export const onExportCurrentView = () => ({
  type: ExportActions.EXPORT_CURRENT_VIEW,
});
export const onExportPromotions = () => ({
  type: ExportActions.EXPORT_PROMOTIONS,
});
export const onExportPromotionsAsinsViability = () => ({
  type: ExportActions.EXPORT_PROMOTIONS_ASINS_VIABILITY,
});
export const onOpenExportModal = (exportViewType) => ({
  type: ExportActions.OPEN_EXPORT_MODAL,
  payload: {
    exportViewType,
  },
});
export const onCloseExportModal = () => ({
  type: ExportActions.CLOSE_EXPORT_MODAL,
});
export const onSetExportType = (exportType) => ({
  type: ExportActions.SET_EXPORT_TYPE,
  payload: {
    exportType,
  },
});
export const onSetExportLoading = (isLoading) => ({
  type: ExportActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onSetConnectionError = (error) => ({
  type: SiteWideActions.SET_CONNECTION_ERROR,
  payload: {
    error,
  },
});
export const toggleSideMenu = () => ({
  type: TOGGLE_SIDE_MENU,
});
export const onUpdateRequiredFields = (data) => ({
  type: UPDATE_REQUIRED_FIELDS,
  data,
});
export const onShowLoadingSpinner = () => ({
  type: SHOW_LOADING_SPINNER,
});
export const onHideLoadingSpinner = () => ({
  type: HIDE_LOADING_SPINNER,
});
export const onLoadNotifications = () => ({
  type: FeatureNotificationActions.LOAD,
});
export const onSetUser = (user) => ({
  type: UserActions.SET_USER,
  payload: {
    user,
  },
});
export const onSetUserUnableToSignIn = () => ({
  type: UserActions.SET_USER_UNABLE_TO_SIGN_IN,
});
export const onSignOut = () => ({
  type: UserActions.LOG_OUT_USER,
});
export const onSwitchUserViewMode = (viewMode) => ({
  type: UserActions.SWITCH_VIEW_MODE,
  payload: {
    viewMode,
  },
});
export const onParsePromotionASINs = (rowId) => ({
  type: GridViewActions.PARSE_PROMOTION_ASINS,
  data: rowId,
});
export const onPushToEditHistory = (rowId, versionBeforeUpdate, startDate, endDate) => ({
  type: UserActions.PUSH_EDIT_HISTORY,
  payload: {
    rowId,
    versionBeforeUpdate,
    startDate,
    endDate,
  },
});
export const onPopFromEditHistory = (startDate, endDate) => ({
  type: UserActions.POP_EDIT_HISTORY,
  payload: {
    startDate,
    endDate,
  },
});
export const onMetadataEditorSelectField = (field) => ({
  type: MetadataEditorActions.SELECT_FIELD,
  data: field,
});
export const onMetadataEditorSetDependencies = (dependencies) => ({
  type: MetadataEditorActions.SET_DEPENDENCIES,
  data: dependencies,
});
export const onMetadataEditorSetDependentOption = ({ name, selection }) => ({
  type: MetadataEditorActions.SET_DEPENDENT_OPTION,
  data: { name, selection },
});
export const onMetadataEditorAddOption = (option) => ({
  type: MetadataEditorActions.ADD_OPTION,
  data: option,
});
export const onMetadataEditorDeleteOption = (option) => ({
  type: MetadataEditorActions.DELETE_OPTION,
  data: option,
});
export const onMetadataEditorAddOptionForField = (data) => ({
  type: MetadataEditorActions.ADD_OPTION_FOR_FIELD,
  data,
});
export const onMetadataEditorDeleteOptionForField = (data) => ({
  type: MetadataEditorActions.DELETE_OPTION_FOR_FIELD,
  data,
});
export const onAuditLogSetEntity = (entity, auditLogType) => ({
  type: AuditLogActions.SET_ENTITY,
  payload: {
    entity,
    auditLogType,
  },
});
export const onAuditLogSetAuditLogType = (auditLogType) => ({
  type: AuditLogActions.SET_AUDIT_LOG_TYPE,
  payload: {
    auditLogType,
  },
});
export const onAuditLogFetch = (entity) => ({
  type: AuditLogActions.FETCH,
  payload: {
    entity,
  },
});
export const onAuditLogClear = () => ({
  type: AuditLogActions.CLEAR,
});
export const onAuditLogSetLoading = (isLoading) => ({
  type: AuditLogActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onAuditLogSetEntityData = (entityData) => ({
  type: AuditLogActions.SET_ENTITY_DATA,
  payload: {
    entityData,
  },
});
export const onAuditLogSetRawEntityData = (rawEntityData) => ({
  type: AuditLogActions.SET_RAW_ENTITY_DATA,
  payload: {
    rawEntityData,
  },
});
export const onAuditLogClearRawEntityData = () => ({
  type: AuditLogActions.CLEAR_RAW_ENTITY_DATA,
});
export const onSetNewUserExperienceShown = (isShown) => ({
  type: SET_NEW_USER_EXPERIENCE_SHOWN,
  payload: isShown,
});
export const onCustomViewSetColumnOrder = (columnOrder) => ({
  type: CustomViewActions.SET_COLUMN_ORDER,
  payload: columnOrder,
});
export const onThirdPartyIFrameEnableExperience = () => ({
  type: ThirdPartyIFrameActions.ENABLE_EXPERIENCE,
});
export const onThirdPartyIFrameOpenComposerForCampaignId = (id) => ({
  type: ThirdPartyIFrameActions.OPEN_COMPOSER,
  payload: id,
});
export const onThirdPartyIFrameEvent = (event) => ({
  type: ThirdPartyIFrameActions.COMPOSER_EVENT,
  payload: event,
});
export const onNotificationsEnableExperience = () => ({
  type: NotificationsActions.ENABLE_EXPERIENCE,
});
export const onCommentsSetActiveCampaignId = (campaignId) => ({
  type: CommentActions.SET_ACTIVE_CAMPAIGN_ID,
  payload: {
    campaignId,
  },
});
export const onCommentsModalOpen = () => ({
  type: CommentActions.OPEN_MODAL,
});
export const onStartPageLoadTimer = () => ({
  type: PageLoadTimerActions.START_TIMER,
});
export const onCompletePageLoadTimer = ({ pageName }) => ({
  type: PageLoadTimerActions.COMPLETE_TIMER,
  payload: {
    pageName,
  },
});
export const onCancelPageLoadTimer = () => ({
  type: PageLoadTimerActions.CANCEL_TIMER,
});
export const onMetadataAuditLogsReceived = (payload) => ({
  type: MetadataAuditLogActions.METADATA_AUDIT_LOGS_RECEIVED,
  payload,
});
export const onMetadataAuditLogsAddLog = (payload) => ({
  type: MetadataAuditLogActions.ADD_LOG,
  payload,
});
export const onMetadataAuditLogsClearData = () => ({
  type: MetadataAuditLogActions.CLEAR_DATA,
});
export const onMetadataAuditLogSetFilterString = (filterString) => ({
  type: MetadataAuditLogActions.SET_FILTER_STRING,
  payload: filterString,
});
export const onGetMetadataAuditLogsByBusiness = (business) => {
  return graphqlAction({
    graphqlAction: getMetadataAuditLog,
    data: { business },
    onSuccessAction: onMetadataAuditLogsReceived,
    label: 'fetchMetadataAuditLogs',
  });
};
export const onPerformanceTimerStart = (timerName) => ({
  type: PerformanceTimerActions.TIMER_START,
  payload: {
    timerName,
  },
});
export const onPerformanceTimerStop = (timerName) => ({
  type: PerformanceTimerActions.TIMER_STOP,
  payload: {
    timerName,
  },
});
export const onSetTab = (tabType, tabName) => ({
  type: TabsActions.SET_TAB,
  payload: {
    tabType,
    tabName,
  },
});
export const onResetTab = (tabType) => ({
  type: TabsActions.RESET_TAB,
  payload: {
    tabType,
  },
});
export const onAsinValidatorSetOpen = (isOpen) => ({
  type: AsinValidatorActions.SET_OPEN,
  payload: {
    isOpen,
  },
});
export const onAsinValidatorSendAsins = (asins, startDate) => ({
  type: AsinValidatorActions.SEND_ASINS,
  payload: {
    asins,
    startDate,
  },
});
export const onAsinValidatorSetAsinViabilities = (asinViabilities) => ({
  type: AsinValidatorActions.SET_ASIN_VIABILITIES,
  payload: {
    asinViabilities,
  },
});
export const onAsinValidatorApproveRegions = () => ({
  type: AsinValidatorActions.APPROVE_REGIONS,
});
export const onAsinValidatorSetDate = (targetDate) => ({
  type: AsinValidatorActions.SET_DATE,
  payload: {
    targetDate,
  },
});
export const onAsinValidatorSetLoading = (isLoading) => ({
  type: AsinValidatorActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onAsinValidatorSetViewMode = (viewMode) => ({
  type: AsinValidatorActions.SET_VIEW_MODE,
  payload: {
    viewMode,
  },
});
export const onAsinValidatorReset = () => ({
  type: AsinValidatorActions.RESET,
});
export const onAsinValidatorSetAsinRegions = (asin, region) => ({
  type: AsinValidatorActions.SET_ASIN_REGIONS,
  payload: {
    asin,
    region,
  },
});
export const onAsinValidatorAutoSelectAvailableRegions = () => ({
  type: AsinValidatorActions.AUTO_SELECT_AVAILABLE_REGIONS,
});
export const onAsinValidatorExportResults = () => ({
  type: AsinValidatorActions.EXPORT_RESULTS,
});
export const onClearAsinValidatorRegionFilters = () => ({
  type: AsinValidatorActions.CLEAR_REGION_FILTERS,
});
export const onAsinValidatorAutoSelectRegionFilters = (asinViabilities) => ({
  type: AsinValidatorActions.AUTO_SELECT_REGION_FILTERS,
  payload: {
    asinViabilities,
  }
});
export const onSetAsinValidatorRegionFilter = (region) => ({
  type: AsinValidatorActions.SET_REGION_FILTER,
  payload: {
    region,
  },
});
export const onInitAsinValidatorRegionFilters = (storeRegionList) => ({
  type: AsinValidatorActions.INIT_REGION_FILTERS,
  payload: {
    storeRegionList,
  }
});
export const onAsinValidatorExportFilteredRegions = (approved, partiallyApproved) => ({
  type: AsinValidatorActions.EXPORT_FILTERED_REGIONS,
  payload: {
    approved,
    partiallyApproved,
  }
});
export const onSetAsinRecommendationModalOpen = (isOpen) => ({
  type: AsinValidatorActions.SET_ASIN_RECOMMENDATION_MODAL_OPEN,
  payload: {
    isOpen
  },
});
export const onSetDatesRange = (dates) => ({
  type: PromoActions.SET_DATES_RANGE,
  payload: {
    dates,
  },
});
export const onLoadPublishedPromotions = () => ({
  type: PublishedPromotionActions.LOAD,
});
export const onSetPublishedPromotions = (promotions) => ({
  type: PublishedPromotionActions.SET,
  payload: {
    promotions,
  },
});
export const onLoadPublishedPromotionAuditLog = (
  yearQuarter,
  startDate,
  endDate,
) => ({
  type: PublishedPromotionActions.LOAD_AUDIT_LOG,
  payload: {
    yearQuarter,
    startDate,
    endDate,
  },
});
export const onAddPromotionWeek = (week) => ({
  type: PromoWeekActions.ADD_PROMOTION_WEEK,
  payload: {
    week,
  },
});
export const onAddedPromotionWeekReceived = (week) => ({
  type: PromoWeekActions.ADDED_PROMOTION_WEEK_RECEIVED,
  payload: {
    week,
  },
});
export const onUpdatePromotionWeek = (week) => ({
  type: PromoWeekActions.UPDATE_PROMOTION_WEEK,
  payload: {
    week,
  },
});
export const onUpdatedPromotionWeekReceived = (week) => ({
  type: PromoWeekActions.UPDATED_PROMOTION_WEEK_RECEIVED,
  payload: {
    week,
  },
});
export const onDeletePromotionWeek = (startDate, endDate) => ({
  type: PromoWeekActions.DELETE_PROMOTION_WEEK,
  payload: {
    startDate,
    endDate,
  },
});
export const onDeletedPromotionWeekReceived = (week) => ({
  type: PromoWeekActions.DELETED_PROMOTION_WEEK_RECEIVED,
  payload: {
    week,
  },
});
export const onCreatePromotions = (params) => {
  const {
    templateId,
    startDate,
    endDate,
    yearQuarter,
  } = params;

  return {
    type: PromoActions.CREATE_PROMOTIONS,
    payload: {
      templateId,
      startDate,
      endDate,
      yearQuarter,
    },
  };
};
export const onResetPromotion = (id) => {
  return {
    type: PromoActions.RESET_PROMOTION,
    payload: {
      id,
    },
  };
};
export const onUpdatePromotionStatus = (status) => {
  return {
    type: PromoActions.UPDATE_PROMOTION_STATUS,
    payload: {
      status,
    },
  };
};
export const onSoftDeletePromotion = (id) => {
  return {
    type: PromoActions.SOFT_DELETE_PROMOTION,
    payload: {
      id,
    },
  };
};
export const onOpenPromotionStatusModal = (id, status) => {
  return {
    type: PromoActions.OPEN_PROMOTION_STATUS_MODAL,
    payload: {
      id,
      status,
    },
  };
};
export const onClosePromotionStatusModal = () => {
  return {
    type: PromoActions.CLOSE_PROMOTION_STATUS_MODAL,
  };
};
export const onLoadTemplates = () => ({
  type: TemplateActions.LOAD_TEMPLATES,
});
export const onSetTemplates = (templates) => ({
  type: TemplateActions.SET_TEMPLATES,
  payload: {
    templates,
  },
});
export const onSelectTemplateForEditing = (id) => ({
  type: TemplateActions.SELECT_TEMPLATE_FOR_EDITING,
  payload: {
    id,
  },
});
export const onSetActiveTemplate = (template) => ({
  type: TemplateActions.SET_ACTIVE_TEMPLATE,
  payload: {
    template,
  },
});
export const onDeleteTemplate = (id) => ({
  type: TemplateActions.DELETE,
  payload: {
    id,
  },
});
export const onResetActiveTemplate = () => ({
  type: TemplateActions.RESET_ACTIVE_TEMPLATE,
});
export const onTemplateSave = () => ({
  type: TemplateActions.SAVE,
});
export const onSetTemplateName = (name) => ({
  type: TemplateActions.SET_TEMPLATE_NAME,
  payload: {
    name,
  },
});
export const onLoadWeeksForYearQuarters = (yearQuarters, loadPromotions = true) => ({
  type: PromoWeekActions.LOAD_WEEKS_FOR_YEAR_QUARTER,
  payload: {
    yearQuarters,
    loadPromotions,
  },
});
export const onSetWeeksForYearQuarter = (yearQuarter, weeks) => ({
  type: PromoWeekActions.SET_WEEKS_FOR_YEAR_QUARTER,
  payload: {
    yearQuarter,
    weeks,
  },
});
export const onSetPublishedPromotionAuditLog = (promoAuditLogs) => ({
  type: PublishedPromotionActions.SET_AUDIT_LOG,
  payload: {
    promoAuditLogs,
  },
});
export const onSetLoading = (isLoading) => ({
  type: PromoActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onResetPublishedPromotionAuditLog = () => ({
  type: PublishedPromotionActions.RESET_AUDIT_LOG,
});
export const onSelectRow = (rowId) => ({
  type: GridViewActions.ON_SELECT_ROW,
  payload: {
    rowId,
  },
});
export const onResetSelectedRows = () => ({
  type: GridViewActions.ON_RESET_SELECTED_ROWS,
});
export const onAddRow = (row) => ({
  type: GridViewActions.ADD_ROW,
  payload: {
    row,
  },
});
export const onRemoveRow = (id) => ({
  type: GridViewActions.REMOVE_ROW,
  payload: {
    id,
  },
});
export const onOpenAddRowModal = () => ({
  type: GridViewActions.OPEN_ADD_ROW_MODAL,
});
export const onCloseAddRowModal = () => ({
  type: GridViewActions.CLOSE_ADD_ROW_MODAL,
});
export const onSetColorLegendModalOpen = (isOpen) => ({
  type: GridViewActions.SET_COLOR_LEGEND_MODAL_OPEN,
  payload: {
    isOpen,
  },
});
export const onSetSource = (source) => ({
  type: GridViewActions.SET_SOURCE,
  payload: {
    source,
  },
});
export const onCommitRow = (commitType) => ({
  type: GridViewActions.COMMIT_ROWS,
  payload: {
    commitType,
  },
});
export const onSetRows = (rows) => ({
  type: GridViewActions.SET_ROWS,
  payload: {
    rows,
  },
});
export const onResetPreviewRows = () => ({
  type: GridViewActions.RESET_PREVIEW_ROWS,
});
export const onAsinGetProductImages = (asin) => ({
  type: AsinImageActions.GET_PRODUCT_IMAGES,
  payload: {
    asin,
  },
});
export const onAsinSetProductImages = (images) => ({
  type: AsinImageActions.SET_PRODUCT_IMAGES,
  payload: {
    images,
  },
});
export const onAsinSelectImage = (imageIndex) => ({
  type: AsinImageActions.SELECT_IMAGE,
  payload: {
    imageIndex,
  },
});
export const onAsinSetLoading = (flag) => ({
  type: AsinImageActions.SET_LOADING,
  payload: {
    flag,
  },
});
export const onSubscriptionPromotionAdded = (promotion) => ({
  type: SubscriptionActions.PROMOTION_ADDED,
  payload: {
    promotion,
  },
});
export const onSubscriptionPromotionUpdated = (promotion) => ({
  type: SubscriptionActions.PROMOTION_UPDATED,
  payload: {
    promotion,
  },
});
export const onSubscriptionPromotionDeleted = (promotion) => ({
  type: SubscriptionActions.PROMOTION_DELETED,
  payload: {
    promotion,
  },
});
export const onSubscriptionPromotionWeekAdded = (week) => ({
  type: SubscriptionActions.PROMOTION_WEEK_ADDED,
  payload: {
    week,
  },
});
export const onSubscriptionPromotionWeekUpdated = (week) => ({
  type: SubscriptionActions.PROMOTION_WEEK_UPDATED,
  payload: {
    week,
  },
});
export const onSubscriptionPromotionWeekDeleted = (week) => ({
  type: SubscriptionActions.PROMOTION_WEEK_DELETED,
  payload: {
    week,
  },
});
export const onRollBackPromotion = (promotion) => ({
  type: AuditLogActions.ROLLBACK,
  payload: {
    promotion,
  },
});
export const onCheckLoadingStatus = () => ({
  type: LoadingActions.CHECK_LOADING_STATUS,
});
