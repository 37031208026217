import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import caretRightTokens from '@amzn/meridian-tokens/base/icon/caret-right';
import { Language } from '../../constants';
import styles from '../GridViewCell/GridViewCell.module.scss';
import { MAX_ITEMS_IN_CELL_HISTORY } from '../../constants/contextMenu';

const ContextMenu = ({
  anchorNode,
  open,
  size,
  position,
  cellHistory,
  onClickHistoryCell,
  isAdmin,
  isReadOnly,
  allowApproveReject,
  onFetchHistory,
  onClearHistory,
  onApprovePromotion,
  onRejectPromotion,
  onFlashFill,
  onClose,
}) => {
  const [historyMenuOpen, setHistoryMenuOpen] = useState(false);
  const historyAnchorNode = useRef();
  useEffect(() => {
    if (open) {
      onFetchHistory();
    }
    return onClearHistory;
  }, [open]);

  const onApprovePromotionMenuItemClick = () => {
    if (window.confirm(Language.PROMOTION_REVIEW_APPROVE)) {
      onApprovePromotion();
    }
  };
  const onRejectPromotionMenuItemClick = () => {
    if (window.confirm(Language.PROMOTION_REVIEW_REJECT)) {
      onRejectPromotion();
    }
  };
  const onFlashFillMenuItemClick = () => {
    if (window.confirm(Language.FLASH_FILL_CONFIRMATION_MSG)) {
      onFlashFill();
    }
  };
  const onHistoryMenuItemClick = (item) => () => {
    onClickHistoryCell(item);
    setHistoryMenuOpen(false);
    onClose();
  };

  const getCellHistoryMenuItems = () => {
    const cellHistoryToRender = cellHistory.slice(0, MAX_ITEMS_IN_CELL_HISTORY);
    return cellHistoryToRender.map((item) => {
      return (
        <MenuItem
          key={`cellHistory/${item.toString()}`}
          onClick={onHistoryMenuItemClick(item)}
          disabled={isReadOnly}
        >
          {item.toString()}
        </MenuItem>
      );
    });
  };

  const cellHistoryMenu = historyMenuOpen
    ? (
      <Menu
        size={size}
        open={open}
        anchorNode={historyAnchorNode.current}
        position="right"
        onClose={() => setHistoryMenuOpen(false)}
      >
        {getCellHistoryMenuItems()}
      </Menu>
    )
    : null;

  const menuItems = [
    <MenuItem
      key="contextMenu/flashfill"
      disabled={isReadOnly}
      onClick={onFlashFillMenuItemClick}
    >
      {Language.FLASH_FILL_CONTEXT_MENU_ITEM}
    </MenuItem>,
    <MenuItem
      key="contextMenu/divider"
      disabled
    >
      <Divider />
    </MenuItem>,
    <MenuItem
      key="contextMenu/cellHistory"
      disabled={!cellHistory || !cellHistory.length}
      onClick={() => setHistoryMenuOpen(true)}
    >
      <div
        ref={historyAnchorNode}
        className={styles.menuItemWithIcon}
      >
        {Language.HISTORY_CONTEXT_MENU_ITEM}
        <Icon tokens={caretRightTokens} />
        {cellHistoryMenu}
      </div>
    </MenuItem>,
  ];

  const adminMenuItems = [
    <MenuItem
      key="contextMenu/approve"
      disabled={!allowApproveReject}
      onClick={onApprovePromotionMenuItemClick}
    >
      {Language.APPROVE_PROMOTION_CONTEXT_MENU_ITEM}
    </MenuItem>,
    <MenuItem
      key="contextMenu/reject"
      disabled={!allowApproveReject}
      onClick={onRejectPromotionMenuItemClick}
    >
      {Language.REJECT_PROMOTION_CONTEXT_MENU_ITEM}
    </MenuItem>,
  ];

  const getMenuItems = () => {
    if (isAdmin) {
      return adminMenuItems.concat(menuItems);
    }
    return menuItems;
  };

  return (
    <Menu
      anchorNode={anchorNode.current}
      id="cell-context-menu"
      open={open}
      position={position}
      size={size}
      onClose={onClose}
    >
      {getMenuItems()}
    </Menu>
  );
};

ContextMenu.propTypes = {
  open: PropTypes.bool,
  isAdmin: PropTypes.bool,
  size: PropTypes.string,
  position: PropTypes.string,
  allowApproveReject: PropTypes.bool.isRequired,
  allowRevert: PropTypes.bool.isRequired,
  onClickHistoryCell: PropTypes.func,
  onFetchHistory: PropTypes.func,
  onClearHistory: PropTypes.func,
  onApprovePromotion: PropTypes.func,
  onRejectPromotion: PropTypes.func,
  onClose: PropTypes.func,
};

ContextMenu.defaultProps = {
  open: false,
  isAdmin: false,
  size: 'small',
  position: 'bottom',
  onClickHistoryCell: _.noop,
  onFetchHistory: _.noop,
  onClearHistory: _.noop,
  onApprovePromotion: _.noop,
  onRejectPromotion: _.noop,
  onClose: _.noop,
};

export default ContextMenu;
