import { connect } from 'react-redux';
import App from './app';
import {
  onPageInit,
  onSubscriptionPromotionAdded,
  onSubscriptionPromotionUpdated,
  onSubscriptionPromotionDeleted,
  onSubscriptionPromotionWeekAdded,
  onSubscriptionPromotionWeekUpdated,
  onSubscriptionPromotionWeekDeleted,
  onOpenToast,
  onSetConnectionError,
  // onUploadLocalMetadata,
} from '../../actionCreators';
import './App.css';

export const mapDispatchToProps = (dispatch) => {
  return {
    onPageInit() {
      dispatch(onPageInit());
      // uncomment to allow for uploading local metadata files
      // dispatch(onUploadLocalMetadata('Fresh'));
    },
    onSubscriptionReceivedAddedPromotion(promotion) {
      dispatch(onSubscriptionPromotionAdded(promotion));
    },
    onSubscriptionReceivedUpdatedPromotion(promotion) {
      dispatch(onSubscriptionPromotionUpdated(promotion));
    },
    onSubscriptionReceivedDeletedPromotion(promotion) {
      dispatch(onSubscriptionPromotionDeleted(promotion));
    },
    onSubscriptionReceivedAddedPromotionWeek(promotion) {
      dispatch(onSubscriptionPromotionWeekAdded(promotion));
    },
    onSubscriptionReceivedUpdatedPromotionWeek(promotion) {
      dispatch(onSubscriptionPromotionWeekUpdated(promotion));
    },
    onSubscriptionReceivedDeletedPromotionWeek(promotion) {
      dispatch(onSubscriptionPromotionWeekDeleted(promotion));
    },
    onOpenToast(message) {
      dispatch(onOpenToast(message, 0));
    },
    onConnectionError(appsyncError) {
      dispatch(onSetConnectionError(appsyncError));
    },
  };
};

export default connect(null, mapDispatchToProps)(App);
