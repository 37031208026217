import _ from 'lodash';
import { getLocation } from 'connected-react-router';
import {
  onAddSearchColumns,
} from '../../actionCreators';
import { createFilterString } from '../../helpers/filters';
import {
  GridViewActions,
} from '../../constants';
import history from '../../history';
import { getQueryParams, isPromotionWeekRoute } from '../../helpers/router';

/* eslint-disable consistent-return */
const pushFiltersToHistory = (state) => {
  if (!state.GridViewPage || !state.GridViewPage.searchTerms) {
    return;
  }
  const { GridViewPage: { searchTerms } } = state;

  history.push(`?${createFilterString(_.omitBy(searchTerms, _.isNil))}`);
};
/* eslint-enable consistent-return */

const hasRouteMatch = (state) => isPromotionWeekRoute(getLocation(state).pathname);

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;

  next(action);

  const hasActionMatch = _.includes([
    GridViewActions.ADD_SEARCH_COLUMN,
    GridViewActions.REMOVE_COLUMNS_FROM_SEARCH,
    GridViewActions.CLEAR_SEARCH,
  ], action.type);

  if (hasActionMatch && hasRouteMatch(getState())) {
    pushFiltersToHistory(getState());
  }

  if (action.type === GridViewActions.PARSE_FILTERS_FROM_URL) {
    if (!hasRouteMatch(getState())) {
      return;
    }
    try {
      const queryParams = getQueryParams(window.location.href);
      if (!queryParams || !queryParams.filters) {
        return;
      }
      const parsedFilters = JSON.parse(queryParams.filters) || [];

      dispatch(onAddSearchColumns(parsedFilters));
    } catch {
      console.error('Unable to parse filters from url');
    }
  }
};
