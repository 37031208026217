import React from 'react';
import TagInput from '../Controls/TagInput';

export default (props) => {
  const {
    inputValue,
    onInputChange,
    onCommitChanges,
    onBlur,
  } = props;

  const newProps = {
    ...props,
    input: {
      name: 'genericTagInput',
      value: inputValue,
      onChange: (value) => onInputChange(value),
      onBlur: () => {
        onCommitChanges();
        onBlur();
      },
    },
  };

  return <TagInput {...newProps} />;
};
