import React from 'react';
import MultiSelectControl from '../MultiSelect';
import BadgeList from '../../../BadgeList';
import styles from './BadgeMultiSelect.module.scss';

const BadgeMultiSelect = (props) => {
  const getBadgeList = () => {
    const { input: storeRegions, auditedStoreRegions } = props;

    return storeRegions
      ? (
        <BadgeList
          selectedItems={storeRegions.value}
          currentItems={auditedStoreRegions}
        />
      )
      : null;
  };

  const badgeList = getBadgeList();

  return (
    <>
      <MultiSelectControl {...props} />
      <div className={styles.badgeListContainer}>
        {badgeList}
      </div>
    </>
  );
};

export default BadgeMultiSelect;
