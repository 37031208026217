import window from '../../helpers/window';
import { PageLoadTimerActions } from '../../constants/metrics';

export const initialState = {
  timer: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case PageLoadTimerActions.START_TIMER: {
      return {
        ...state,
        timer: window.performance.now(),
      };
    }
    case PageLoadTimerActions.COMPLETE_TIMER: {
      return {
        ...state,
        timer: null,
      };
    }
    case PageLoadTimerActions.CANCEL_TIMER: {
      return {
        ...state,
        timer: null,
      };
    }
    default: {
      return state;
    }
  }
};
