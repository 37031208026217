import {
  onCellInputUpdate,
  onSendPromotionEdit,
} from '../../actionCreators';
import { isPromotionActive } from '../../helpers/promotions';
import { isCurrentUserAdmin } from '../../selectors/user';

export default (store, next, action) => {
  const { dispatch, getState } = store;
  const {
    payload: {
      promotionId,
      columnName,
      value,
    },
  } = action;
  const { GridViewPage: { promotions } } = getState();
  const {
    page, title, yearQuarter_startDate_endDate, zoneNumber,
  } = promotions.find((promotion) => promotion.id === promotionId);
  const relatedPromotions = promotions.filter((promotion) => {
    const isRelated = promotion.yearQuarter_startDate_endDate === yearQuarter_startDate_endDate
      && promotion.page === page
      && promotion.zoneNumber === zoneNumber
      && promotion.title === title;
    return isPromotionActive(promotion, isCurrentUserAdmin(getState())) && isRelated && promotion[columnName] !== value;
  });

  relatedPromotions.forEach((relatedPromotion) => {
    dispatch(onCellInputUpdate(relatedPromotion.id, columnName, value));
    dispatch(onSendPromotionEdit({
      rowId: relatedPromotion.id
    }, {
      showToast: true
    }));
  });

  return next(action);
};
