import { getUsername } from '../../selectors/user';
import { submitStringMetric } from '../../metrics/index';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants/metrics';

const getShortErrorMessage = (appsyncError) => {
  if (!appsyncError.error || !appsyncError.error.errors) {
    return '';
  }
  const { error: { errors } } = appsyncError;
  const [firstError] = errors;

  return firstError && firstError.message ? firstError.message : firstError;
};

export default (dispatch, getState, action, next) => {
  const { payload: { error } } = action;

  if (!error) {
    return next(action);
  }

  const metricContext = {
    location: MetricLocations.APP_SUBSCRIPTIONS,
    type: MetricTypes.APPSYNC,
    data: error,
    action: MetricNames.APP_APPSYNC_SUBSCRIPTIONS_CONNECTION_CLOSED,
    username: getUsername(getState()),
  };

  const metricContextWithError = {
    ...metricContext,
    error,
  };

  submitStringMetric(MetricNames.APP_APPSYNC_SUBSCRIPTIONS_CONNECTION_CLOSED, metricContextWithError);

  const newAction = {
    ...action,
    payload: {
      ...action.payload,
      error: getShortErrorMessage(error),
    },
  };

  return next(newAction);
};
