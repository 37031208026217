import { connect } from 'react-redux';
import { defaultStateToProps, defaultDispatchToProps } from '../helpers';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import TagInput from '../../StyledInputs/TagInput';

export const mapStateToProps = (state) => ({
  ...defaultStateToProps(state),
});

export const mapDispatchToProps = (dispatch) => ({
  ...defaultDispatchToProps(dispatch),
  onInputChange(value) {
    dispatch(onActiveCellInputUpdate(value));
  },
  onCommitChanges() {
    dispatch(onActiveCellInputSubmit());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TagInput);
