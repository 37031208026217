import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Tooltip from '@amzn/meridian/tooltip';
import styles from '../../PromoForm.module.scss';
import {
  getStatusDisplayNameByValue,
  isUneditableStatus,
} from '../../../../helpers/status';
import CircularPriceWarning from './CircularPriceWarning';
import { Language } from '../../../../constants';
import { getTemplateString } from '../../../../helpers/common';

const SubmitButtons = ({ submitting, status, isAdmin, isSoftDeleted }) => {
  const isLocked = isUneditableStatus(status, isAdmin) && !isAdmin;

  const getDisabledButtonMessage = () => {
    if (isSoftDeleted) {
      return Language.FORM_SAVE_BUTTON_DISABLED_SOFT_DELETED;
    }

    if (isLocked) {
      return getTemplateString(
        Language.FORM_SAVE_BUTTON_DISABLED_STATUS_LOCK,
        {
          status: getStatusDisplayNameByValue(status),
        });
    }

    if (submitting) {
      return Language.FORM_SAVE_BUTTON_DISABLED_FORM_SUBMITTING;
    }

    return '';
  };

  const getSaveButton = () => {
    const button = (
      <Button
        submit
        type="primary"
        disabled={submitting || isLocked || isSoftDeleted}
      >
        Save
      </Button>
    );

    return isSoftDeleted || isLocked || submitting
      ? (
        <Tooltip
          title={getDisabledButtonMessage()}
          position="end"
        >
          {button}
        </Tooltip>
      )
      : button;
  };

  return (
    <div className={styles.controlPanel}>
      <div className={styles.warnings}>
        <CircularPriceWarning />
      </div>
      <div className={styles.submitButtonContainer}>
        <div className={classnames(styles.wrapperInner, styles.submitButtonWrapper)}>
          {getSaveButton()}
        </div>
      </div>
    </div>
  );
};

SubmitButtons.propTypes = {
  submitting: PropTypes.bool,
};

SubmitButtons.defaultProps = {
  submitting: false,
};

export default SubmitButtons;
