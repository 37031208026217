import { connect } from 'react-redux';
import Header from './Header';
import {
  DeployLocations,
  Urls,
} from '../../constants';
import { toggleSideMenu } from '../../actionCreators';
import { getDeployedLocation } from '../../helpers/env';
import { submitStringMetric } from '../../metrics/index';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants/metrics';

const linksList = [
  {
    name: 'ASIN Dashboard',
    route: Urls.ASIN_DASHBOARD,
  },
];

const getEnvironmentLabel = () => {
  const deployedLocation = getDeployedLocation();

  const allowedLocations = [
    DeployLocations.DEV,
    DeployLocations.BETA,
  ];

  return allowedLocations.includes(deployedLocation) ? deployedLocation : '';
};

const mapStateToProps = ((state) => {
  const { router: { location: { pathname: route } } } = state;

  return {
    route,
    linksList,
    environmentLabel: getEnvironmentLabel(),
  };
});

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuButtonClick() {
      submitStringMetric(
        MetricNames.MENU_BUTTON,
        {
          location: MetricLocations.HEADER,
          type: MetricTypes.CLICK,
        },
      );
      dispatch(toggleSideMenu());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
