import {
  PAGE_INIT,
  SET_PROMOTIONS_LOADED,
  PerformanceTimerNames,
  BusinessTypes,
} from '../../constants';
import {
  onParseFiltersFromUrl,
  onLoadGraphQLTypes,
  onPerformanceTimerStart,
  onPerformanceTimerStop,
  onYearQuarterSelect,
  onClearMetadata,
  onUpdateRequiredFields,
  onFetchMetadata,
} from '../../actionCreators';
import { getYearQuarter } from '../../helpers/common';
import { getCampaignRequiredFields } from '../../helpers/requiredFields';

export default ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type === PAGE_INIT) {
    const { Promo: { dates: [startDate] } } = getState();

    dispatch(onClearMetadata());
    dispatch(onUpdateRequiredFields(getCampaignRequiredFields()));
    dispatch(onFetchMetadata(BusinessTypes.FRESH.name));
    dispatch(onYearQuarterSelect({ yearQuarter: getYearQuarter(startDate) }));
    dispatch(onParseFiltersFromUrl());
    dispatch(onPerformanceTimerStart(PerformanceTimerNames.APP_INIT));
    dispatch(onLoadGraphQLTypes());
  }

  if (action.type === SET_PROMOTIONS_LOADED) {
    const { payload: { isLoaded } } = action;
    if (isLoaded) {
      dispatch(onPerformanceTimerStop(PerformanceTimerNames.APP_INIT));
    }
  }

  return null;
};
