import {
  Columns,
  COMMA_DELIMETER,
  NEWLINE_DELIMETER,
  UNDERSCORE_DELIMETER,
} from '../constants';
import {
  PromotionExportTypes,
  PromotionExportViewType,
} from '../constants/export';
import { getWeekByStartEndDate } from './promotions';

export const formatString = (string) => {
  // In the CSV spec, a double quote character is escaped by a second double quote character
  const stringToParse = `${string}` || '';
  return stringToParse.replace(/"/g, '""');
};

export const getLink = (fileName, fileObject) => {
  const link = document.createElement('a');

  link.setAttribute('href', fileObject);
  link.setAttribute('download', fileName);

  return link;
};

export const getFileObject = (fileContent) => {
  const blob = new Blob([fileContent], {
    encoding: 'UTF-8',
    type: 'text/csv;charset=UTF-8',
  });

  return window.URL.createObjectURL(blob);
};

export const startFileDownload = (link) => {
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getSimpleFileContent = (rows) => {
  return rows
    .map((row) => row.map((column) => `"${formatString(column)}"`).join(COMMA_DELIMETER))
    .join(NEWLINE_DELIMETER);
};

export const downloadFile = (fileContent, fileName) => {
  const fileObject = getFileObject(fileContent);
  const link = getLink(fileName, fileObject);

  startFileDownload(link);
};

const getIgnoredColumnsNames = () => [
  'asin',
  'state',
];

export const getBaseHeaderRows = () => [
  Columns.ASINS.display,
  Columns.IS_FEATURED_ASIN.display,
  Columns.REGIONS.display,
  Columns.STORE_REGIONS.display,
  Columns.IS_AVAILABLE.display,
];

const getRows = (asinViabilities, presetValues = []) => {
  const rows = [];

  [...asinViabilities].forEach((asinAvailability) => {
    getIgnoredColumnsNames().forEach((columnName) => delete asinAvailability[columnName]);

    const asinAvailabilityValues = Object.values(asinAvailability);
    if (!asinAvailabilityValues || !asinAvailabilityValues.length) {
      return;
    }

    rows.push([
      ...presetValues,
      ...asinAvailabilityValues,
    ]);
  });

  return rows;
};

const getDynamicHeaderRows = (storeRegion) => {
  const {
    availableAsinViabilities,
    unavailableAsinViabilities,
  } = storeRegion;

  let headersSource = [];
  if (availableAsinViabilities && availableAsinViabilities.length > 0) {
    headersSource = availableAsinViabilities;
  } else if (unavailableAsinViabilities && unavailableAsinViabilities.length > 0) {
    headersSource = unavailableAsinViabilities;
  }

  if (!headersSource.length) {
    return [];
  }

  const [firstAsinViability] = headersSource;
  if (!firstAsinViability || !Object.keys(firstAsinViability).length) {
    return [];
  }

  return Object
    .keys(firstAsinViability)
    .filter((columnName) => !getIgnoredColumnsNames().includes(columnName));
};

export const getFilteredRegionRows = (asinViabilities, regionFilters) => {
    const rowHeaders = [Columns.ASINS.display, ...Object.keys(regionFilters)];
    const rows = [rowHeaders];

    Object.keys(asinViabilities).forEach((asin) => {
      const asinData = asinViabilities[asin];
      const asinRow = [asin];
      let skipAsin = false;
      for (const region of Object.keys(regionFilters)) {
        const { unavailableAsinViabilities } = asinData[region];
        const isUnavailable = unavailableAsinViabilities.length;
        const isChecked = regionFilters[region];
        if (isUnavailable && isChecked) {
          skipAsin = true;
          break;
        }
        const availabilityText = !isUnavailable ? 'Available' : 'Unavailable';
        asinRow.push(availabilityText);
      }
      if (!skipAsin) {
        rows.push(asinRow);
      }
    });
    return rows;
};


export const getReportRows = (asinViabilities) => {
  let shouldSetHeaders = true;
  const rows = [];

  asinViabilities.forEach(({ asin, viability }, asinIndex) => {
    viability.forEach(({ regionName, storeRegions, isAvailable }) => {
      storeRegions.forEach((storeRegion) => {
        const {
          storeRegionName,
          availableAsinViabilities,
          unavailableAsinViabilities,
        } = storeRegion;

        if (shouldSetHeaders) {
          shouldSetHeaders = false;

          rows.push([
            ...getBaseHeaderRows(),
            ...getDynamicHeaderRows(storeRegion),
          ]);
        }

        const isFeaturedAsin = asinIndex === 0;

        const baseRowsData = [
          asin,
          isFeaturedAsin,
          regionName,
          storeRegionName,
          isAvailable,
        ];

        rows.push(...getRows(availableAsinViabilities, baseRowsData));
        rows.push(...getRows(unavailableAsinViabilities, baseRowsData));
      });
    });
  });

  return rows;
};

export const isBlankTemplateExportType = (exportType) => exportType === PromotionExportTypes.BLANK_TEMPLATE.value;
export const isExtendedExportType = (exportType) => exportType === PromotionExportTypes.EXTENDED.value;
export const isViabilityExportType = (exportType) => exportType === PromotionExportTypes.VIABILITY.value;
export const isPublishedViewType = (exportType) => exportType === PromotionExportViewType.PUBLISHED;

export const getSanitizedFilename = (filename) => filename.replace(/[^A-Z0-9]/ig, UNDERSCORE_DELIMETER);
export const getPromotionWeekName = (state) => {
  const { Promo: { dates } } = state;
  if (!dates || !dates.length) {
    return '';
  }

  const [startDate, endDate] = dates;

  const weekContainer = getWeekByStartEndDate(state, { startDate, endDate });
  if (!weekContainer) {
    return '';
  }

  return weekContainer.name
    ? getSanitizedFilename(weekContainer.name)
    : '';
};
