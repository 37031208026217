import _ from 'lodash';
import { createSelector } from 'reselect';
import { UNDERSCORE_DELIMETER } from '../constants';
import { isQuarterLoaded } from '../reducers/LoadingStatus/isLoaded';

export const areAllPromotionsLoaded = (state) => state.LoadingStatus.isLoaded;
export const isPromotionWeekLoaded = createSelector(
  [
    (state) => state.LoadingStatus.yearQuarters,
    (state, yearQuarter_startDate_endDate) => yearQuarter_startDate_endDate,
  ],
  (loadingStatus, yearQuarter_startDate_endDate) => {
    const [yearQuarter] = yearQuarter_startDate_endDate.split(UNDERSCORE_DELIMETER);
    return loadingStatus[yearQuarter][yearQuarter_startDate_endDate];
  }
);

export const isYearQuarterLoaded = createSelector(
  [
    (state) => state.LoadingStatus.yearQuarters,
    (state, yearQuarter) => yearQuarter,
  ],
  (loadingStatus, yearQuarter) => {
    if (!loadingStatus[yearQuarter] || _.isEmpty(loadingStatus[yearQuarter])) {
      return false;
    }
    return isQuarterLoaded(loadingStatus[yearQuarter]);
  }
);