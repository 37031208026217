import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Masthead, { MastheadTitle, MastheadLink } from '@amzn/meridian/masthead';
import { useHistory } from 'react-router-dom';
import Icon from '@amzn/meridian/icon';
import Tag from '@amzn/meridian/tag';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import menuTokens from '@amzn/meridian-tokens/base/icon/menu';
import UserPanel from '../UserPanel';
import UserModeViewSwitcher from '../UserModeViewSwitcher';
import styles from './Header.module.scss';
import { submitStringMetric } from '../../metrics/index';
import { MetricLocations, MetricTypes } from '../../constants/metrics';

const Header = ({
  route: currentRoute, linksList, onMenuButtonClick, environmentLabel,
}) => {
  const history = useHistory();

  const onClickLink = useCallback((route) => () => {
    submitStringMetric(
      route,
      {
        location: MetricLocations.HEADER,
        type: MetricTypes.CLICK,
      },
    );
    history.push(route);
  }, [history]);

  const links = linksList.map(({ name, route, iconComponent: IconAdd }) => (
    <MastheadLink
      key={route}
      selected={currentRoute === route}
      onClick={onClickLink(route)}
    >
      <div className={styles.linkContainer}>
        {IconAdd ? <IconAdd /> : null}
        <span className={styles.link}>{name}</span>
      </div>
    </MastheadLink>
  ));

  const environmentTag = environmentLabel
    ? <sup className={styles.logoText}><Tag>{environmentLabel}</Tag></sup>
    : null;

  return (
    <div>
      <Masthead size="medium">
        <MastheadTitle href="#">
          <div className={styles.logoContainer}>
            <Button type="icon" onClick={onMenuButtonClick}>
              <Icon tokens={menuTokens}>Menu</Icon>
            </Button>
            <Heading
              className={styles.logoText}
              level={4}
            >
              F3 Circulator
              {environmentTag}
            </Heading>
          </div>
        </MastheadTitle>
        {links}
        <UserModeViewSwitcher />
        <UserPanel />
      </Masthead>
    </div>
  );
};

Header.propTypes = {
  environmentLabel: PropTypes.string.isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  linksList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
};

export default Header;
