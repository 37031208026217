import { connect } from 'react-redux';
import {
  onBulkImportProcessFile,
} from '../../actionCreators/bulkImport';
import BulkImporter from './BulkImporter';

export const mapStateToProps = (state) => {
  const {
    BulkImport: {
      isProcessed,
      validationMode,
    },
  } = state;

  return {
    isProcessed,
    validationMode,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onProcessFile(data) {
      dispatch(onBulkImportProcessFile(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImporter);
