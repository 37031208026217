import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import {
  onUpdatePromotion,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }
  const {
    payload: {
      id,
    },
  } = action;

  const selectedPromotion = getPromotionById(state.GridViewPage, id);
  const { isSoftDeleted } = selectedPromotion;

  const newPromotion = {
    ...selectedPromotion,
    isSoftDeleted: !isSoftDeleted,
  };

  dispatch(onUpdatePromotion(newPromotion));

  return next(action);
};
