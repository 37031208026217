import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';

import styles from './Loader.module.scss';

const LoaderComponent = ({ isLoading }) => {
  return isLoading ? (
    <div className={styles.loaderWrapper}>
      <div className={styles.loader}>
        <Loader />
      </div>
    </div>
  ) : null;
};

LoaderComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoaderComponent;
