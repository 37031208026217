import PropTypes from 'prop-types';
import { Columns, InputTypes } from '../constants';

const {
  ID,
  STATUS,
  SCHEDULER,
  VERTICAL,
  VENDOR,
  BRAND,
  MERCHANT,
  PLATFORM,
  PAGETYPE,
  PAGE_NAME,
  PLACEMENT,
  START_DATE,
  END_DATE,
  DESKTOP_SLOT,
  MOBILE_SLOT,
  CAMPAIGN_MARKER,
  CAMPAIGN_NAME,
  RSP,
  HERO_ASINS,
  LANDING_ASINS,
  COPY,
  MARKETING_MANAGER_NOTES,
  TARGETING,
  TARGETING_EXCLUDE,
  DEDUPE_RULES,
  SUB_HEADLINE,
  TERMS_AND_CONDITIONS,
  USE_NEW_TEMPLATE,
  DESIGN_SIM,
  SCHEDULING_INSTRUCTIONS,
  CONTRA_COGS,
  IS_ANIMATED_CONTENT,
  SPANISH_COPY,
  TRANSLATION_STATUS,
} = Columns;

export const campaignProps = PropTypes.shape({
  [ID]: PropTypes.string,
  [STATUS]: PropTypes.string,
  [SCHEDULER]: PropTypes.string,
  [PAGETYPE]: PropTypes.string,
  [VERTICAL]: PropTypes.string,
  [VENDOR]: PropTypes.string,
  [BRAND]: PropTypes.string,
  [MERCHANT]: PropTypes.string,
  [PLATFORM]: PropTypes.string,
  [PAGE_NAME]: PropTypes.string,
  [PLACEMENT]: PropTypes.string,
  [START_DATE]: PropTypes.string,
  [END_DATE]: PropTypes.string,
  [DESKTOP_SLOT]: PropTypes.string,
  [MOBILE_SLOT]: PropTypes.string,
  [CAMPAIGN_MARKER]: PropTypes.string,
  [CAMPAIGN_NAME]: PropTypes.string,
  [RSP]: PropTypes.number,
  [HERO_ASINS]: PropTypes.string,
  [LANDING_ASINS]: PropTypes.string,
  [COPY]: PropTypes.string,
  [MARKETING_MANAGER_NOTES]: PropTypes.string,
  [TARGETING]: PropTypes.string,
  [TARGETING_EXCLUDE]: PropTypes.string,
  [DEDUPE_RULES]: PropTypes.string,
  [SUB_HEADLINE]: PropTypes.string,
  [TERMS_AND_CONDITIONS]: PropTypes.string,
  [USE_NEW_TEMPLATE]: PropTypes.string,
  [DESIGN_SIM]: PropTypes.string,
  [SCHEDULING_INSTRUCTIONS]: PropTypes.string,
  [CONTRA_COGS]: PropTypes.string,
  [IS_ANIMATED_CONTENT]: PropTypes.bool,
  [SPANISH_COPY]: PropTypes.bool,
  [TRANSLATION_STATUS]: PropTypes.bool,
});
export const campaignInputProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.arrayOf(PropTypes.string),
]);
export const campaignColumnProps = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  display: PropTypes.string,
  inputType: PropTypes.oneOf(Object.keys(InputTypes)),
}));
export const styledInputProps = {
  onSubmit: PropTypes.func,
  inputValue: campaignInputProps,
  onInputChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
};
export const filterProps = PropTypes.shape({
  column: PropTypes.string,
  comparator: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
export const sortProps = PropTypes.shape({

});
export const filterViewProps = PropTypes.shape({
  name: PropTypes.string,
  filters: PropTypes.arrayOf(filterProps),
  sorts: PropTypes.arrayOf(sortProps),
});
export const userPanelProps = {
  username: PropTypes.string.isRequired,
  realName: PropTypes.string.isRequired,
  onSignOut: PropTypes.func.isRequired,
};
export const formControlInputProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});
export const formControlProps = {
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};
export const columnsProps = {
  columns: campaignProps.isRequired,
};

export const metadataAuditLogChangeProps = PropTypes.shape({
  version: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  username: PropTypes.string,
  date: PropTypes.string,
});

export const promotionColumnProps = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string,
  display: PropTypes.string,
  inputType: PropTypes.oneOf(Object.keys(InputTypes)),
}));

export const asinValidatorProps = {
  recommendedAsins: PropTypes.shape({
    asin: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  regionFilters: PropTypes.shape({
    region: PropTypes.bool,
  }).isRequired,
}