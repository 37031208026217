import React from 'react';
import AsinTagInput from '../Controls/AsinTagInput';

export default (props) => {
  const {
    inputValue,
    onInputChange,
    onCommitChanges,
    onBlur,
  } = props;

  const newProps = {
    ...props,
    isGridViewMode: true,
    showCopyAllButton: true,
    showClearAllButton: true,
    showTagCounter: true,
    placeholder: 'Enter new ASIN(s) here...',
    input: {
      name: 'genericAsinTagInput',
      value: inputValue,
      onChange: (value) => onInputChange(value),
      onBlur: () => {
        onCommitChanges();
        onBlur();
      },
    },
  };

  return <AsinTagInput {...newProps} />;
};
