import { Columns } from '../constants';

const getColumnDisplayByColumnName = (columnName) => {
  let name = null;
  Object.keys(Columns).forEach((key) => {
    if (Columns[key].name === columnName) {
      name = Columns[key].display;
    }
  });
  return name;
};

export default getColumnDisplayByColumnName;
