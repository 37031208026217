import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import CalendarMonthView from '@amzn/meridian/calendar-month-view';
import CalendarTimeframeSelect from '@amzn/meridian/calendar-timeframe-select';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import PromotionWeek from './PromotionWeek';
import styles from './Calendar.module.scss';
import { Urls } from '../../constants';

const Calendar = (props) => {
  const history = useHistory();

  const {
    onTimeFrameChange,
    promotionWeeks,
    yearQuarter,
    timeFrame,
    timeNow,
    isAdmin,
  } = props;

  const onCreateNewPromoWeek = () => history.push(Urls.WEEK_NEW);
  const onTodayClick = () => onTimeFrameChange(timeNow);
  const onCalendarTimeFrameSelect = (value) => onTimeFrameChange(value);

  const promotionWeeksList = promotionWeeks.map((event) => {
    const {
      start, end, count, name,
    } = event;

    const startDate = moment(start).format('MMM Do');
    const endDate = moment(end).format('MMM Do');

    return {
      start,
      end,
      render: (props) => (
        <PromotionWeek
          {...props}
        >
          <h3 className={styles.weekTitle}>{name}</h3>
          <p className={styles.weekContent}>
            <strong>{count} promotions</strong> running on {startDate} - {endDate}
          </p>
        </PromotionWeek>
      ),
    };
  });

  const getCalendar = () => {
    return (
      <Column
        type="outline"
        heights={['fit', 'fill']}
        spacing="none"
        height={400}
        width="100%"
      >
        <Row
          spacingInset="400"
          alignmentHorizontal="justify"
          widths={[75, 'fit', 75]}
        >
          <Button type="secondary" onClick={onTodayClick}>
            Today
          </Button>
          <CalendarTimeframeSelect
            type="month"
            value={timeFrame}
            onChange={onCalendarTimeFrameSelect}
          />
        </Row>
        <CalendarMonthView
          viewDate={timeFrame}
          now={timeNow}
          distributedEvents={promotionWeeksList}
        />
      </Column>
    );
  };

  const getWeekButton = () => {
    if (!isAdmin) {
      return null;
    }

    return (
      <Button onClick={onCreateNewPromoWeek}>
        <Icon tokens={plusCircleTokens} />Add Promotion Week
      </Button>
    );
  };

  const addWeekButton = getWeekButton();
  const calendar = getCalendar();

  return (
    <>
      <div className={styles.panel}>
        <div className={styles.info}>
          {addWeekButton}
        </div>
      </div>
      <div className={styles.calendarContainer}>
        <div>{yearQuarter}</div>
        {calendar}
      </div>
    </>
  );
};

Calendar.propTypes = {
  onTimeFrameChange: PropTypes.func.isRequired,
  yearQuarter: PropTypes.string.isRequired,
  timeFrame: PropTypes.string.isRequired,
  timeNow: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Calendar;
