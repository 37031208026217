import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../GridViewCell/GridViewCell.module.scss';
import { styledInputProps } from '../../../proptypes';

const Input = ({
  onSubmit,
  inputValue,
  onInputChange,
  onBlur,
  type,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <input
        value={inputValue || ''}
        onChange={onInputChange}
        className={styles.activeInput}
        ref={(input) => { return setTimeout(() => { return input && input.focus(); }, 0); }}
        onBlur={onBlur}
        type={type}
      />
    </form>
  );
};

Input.propTypes = {
  ...styledInputProps,
  type: PropTypes.string,
};
Input.defaultProps = {
  type: 'text',
};

export default Input;
