import _ from 'lodash';
import {
  applyPercentageDiscount,
  getNumberWithinRange,
} from '../../helpers/common';
import { onCellInputUpdate } from '../../actionCreators';
import { Columns } from '../../constants';

export const applyPrimeDiscount = (state, dispatch, existingPromotion) => {
  const {
    GridViewPage: {
      activeCell: { columnName },
      activeCellValue: columnValue,
    },
  } = state;

  if (columnName === Columns.IS_PRIME_BENEFIT.name) {
    return handleIsPrimeBenefit(existingPromotion, columnValue, dispatch);
  }
  if (!existingPromotion.isPrimeBenefit) {
    return;
  }
  if (columnName === Columns.CIRCULAR_PRICE.name) {
    return handleCircularPrice(existingPromotion, columnValue, dispatch);
  }
  if (columnName === Columns.PRIME_DISCOUNT.name) {
    return handlePrimeDiscount(existingPromotion, columnValue, dispatch);
  }
  if (columnName === Columns.PRIME_MEMBER_LABEL.name) {
    return handlePrimeMemberLabel(existingPromotion, columnValue, dispatch);
  }
};
const handleIsPrimeBenefit = ({ id, primeDiscount, circularPrice, primeMemberLabel }, columnValue, dispatch) => {
  if (!columnValue || !primeDiscount || !circularPrice ||
    _.isNaN(Number(circularPrice))) {
    return;
  }
  if (!primeMemberLabel) {
    const newPrimePrice = applyPercentageDiscount(
      circularPrice,
      primeDiscount,
      ).toFixed(2);
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL.name, newPrimePrice));
  }
};
const handleCircularPrice = ({ id, primeDiscount, primeMemberLabel }, columnValue, dispatch) => {
  if (!primeDiscount || !columnValue || _.isNaN(Number(columnValue))) {
    return;
  }
  const newPrimePrice = applyPercentageDiscount(
    columnValue,
    primeDiscount,
    ).toFixed(2);
  if (newPrimePrice !== primeMemberLabel) {
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL.name, newPrimePrice));
  }
};
const handlePrimeDiscount = ({ id, circularPrice, primeMemberLabel }, columnValue, dispatch) => {
  if (!circularPrice || _.isNaN(Number(circularPrice))) {
    return;
  }
  const newPrimePrice = applyPercentageDiscount(
    circularPrice,
    getNumberWithinRange(columnValue),
    ).toFixed(2);
  if (newPrimePrice !== primeMemberLabel) {
    dispatch(onCellInputUpdate(id, Columns.PRIME_MEMBER_LABEL.name, newPrimePrice));
  }
};
const handlePrimeMemberLabel = ({ id, primeDiscount, circularPrice }, columnValue, dispatch) => {
  if (primeDiscount) {
    dispatch(onCellInputUpdate(id, Columns.PRIME_DISCOUNT.name, null));
  }
};