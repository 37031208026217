import { connect } from 'react-redux';

import Loader from './Loader';

const mapStateToProps = (state) => {
  const {
    Sitewide: { showLoader },
    // BulkImport: { isImporting },
  } = state;
  const isImporting = false;
  return {
    isLoading: showLoader || isImporting,
  };
};

export default connect(mapStateToProps)(Loader);
