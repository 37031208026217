import AsinViabilityTable from './AsinViabilityTable';
import { onAsinValidatorExportFilteredRegions, onSetAsinRecommendationModalOpen, onSetAsinValidatorRegionFilter } from '../../../actionCreators';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const {
    AsinValidator: {
      regionFilters
    },
  } = state;

  return {
    regionFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegionFilterChange(region) {
      dispatch(onSetAsinValidatorRegionFilter(region));
    },
    onExportFilteredRegions(approved, partiallyApproved) {
      dispatch(onAsinValidatorExportFilteredRegions(approved, partiallyApproved));
    },
    onShowAsinRecommendationModal() {
      dispatch(onSetAsinRecommendationModalOpen(true));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinViabilityTable);
