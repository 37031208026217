import React from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, currentTab, onSwitchTab }) => {
  if (!tabs || !tabs.length) {
    return null;
  }

  const tabsList = tabs.map(({ name, label }) => <Tab key={name} value={name}>{label}</Tab>);

  return (
    <TabGroup
      value={currentTab}
      onChange={onSwitchTab}
      fill="tabs"
    >
      {tabsList}
    </TabGroup>
  );
};

Tabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
};

export default Tabs;
