import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian/modal';
import Heading from '@amzn/meridian/heading';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import styles from './StoreCardModal.module.scss';

const StoreCardModal = (props) => {
  const { isOpen, onClose, store } = props;

  if (!isOpen) {
    return null;
  }

  const getHeaders = () => {
    return (
      <TableRow key="row-headers">
        <TableCell>
          <strong>Property</strong>
        </TableCell>
        <TableCell>
          <strong>Value</strong>
        </TableCell>
      </TableRow>
    );
  };

  const getRows = () => {
    if (!store || !Object.keys(store).length) {
      return (
        <TableRow
          key="no-rows-to-show"
        >
          <TableCell columnSpan={2}>
            N/A
          </TableCell>
        </TableRow>
      );
    }

    const rows = [];
    Object.entries(store).forEach(([property, value]) => {
      rows.push(
        <TableRow
          key={`${property}/${value}`}
        >
          <TableCell>
            {property}
          </TableCell>
          <TableCell>
            {String(value)}
          </TableCell>
        </TableRow>,
      );
    });

    return rows;
  };

  const getContent = () => {
    const headers = getHeaders();
    const rows = getRows();

    return (
      <Table
        key="store-info-table"
        spacing="small"
        showStripes
        showDividers
      >
        {headers}
        {rows}
      </Table>
    );
  };

  const getHeader = () => (store && store.store_id ? store.store_id : '');

  const header = getHeader();
  const content = getContent();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      scrollContainer="modal"
    >
      <Heading level={2} type="h500">
        {header}
      </Heading>
      <div className={styles.content}>
        {content}
      </div>
    </Modal>
  );
};

StoreCardModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
StoreCardModal.defaultProps = {
  isOpen: false,
};

export default StoreCardModal;
