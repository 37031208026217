import { v4 as uuidv4 } from 'uuid';

/*
TODO: IDs for new campaigns or anything else we might need
should be assigned by server. We're generating IDs on the front-end now just
to make the mocks work. Moving forward it will be updated.
 */

export default () => (next) => (action) => {
  const { generateId, ...rest } = action;
  if (!generateId) {
    next(rest);
  } else {
    const current = {
      ...rest,
      data: {
        ...rest.data,
        id: uuidv4(),
      },
    };
    next(current);
  }
};
