import React from 'react';
import HintLink from './HintLink';
import { Language } from '../../../constants';
import { isValidUrl } from '../../../helpers/common';

const WrappedHintLink = (Component) => (props) => {
  const { urlLink, urlText } = props;

  if (!isValidUrl(urlLink)) {
    return null;
  }

  return (
    <Component
      urlText={urlText || Language.CLICK_HERE_TO_ACCESS_LINK}
      urlLink={urlLink}
    />
  );
};

export default WrappedHintLink(HintLink);
