import _ from 'lodash';
import {
  Language, DASH_DELIMETER, UNDERSCORE_DELIMETER
} from '../../constants';
import {
  getFilteredRegionRows,
  getSimpleFileContent,
  downloadFile,
} from '../../helpers/export';


const getFileName = (date) => {
  const dateTime = date.replace(DASH_DELIMETER, UNDERSCORE_DELIMETER);

  return `asin_filtered_regions_${dateTime}`;
};

export default (dispatch, getState, action, next) => {
  const { AsinValidator: { regionFilters, targetDate } } = getState();
  const { payload: { approved, partiallyApproved } } = action;


  const isEmptyAsins = _.isEmpty(approved) && _.isEmpty(partiallyApproved);
  if (isEmptyAsins) {
    console.error(Language.ASIN_VALIDATOR_EXPORT_ERROR_NO_DATA);

    return next(action);
  }
  const combinedAsins = {...approved, ...partiallyApproved}
  const filteredRows = getFilteredRegionRows(combinedAsins, regionFilters);

  if (!filteredRows) {
    console.error(Language.ASIN_VALIDATOR_EXPORT_ERROR_NO_DATA);

    return next(action);
  }

  const fileName = getFileName(targetDate);
  const fileContent = getSimpleFileContent(filteredRows);

  downloadFile(fileContent, fileName);

  return next(action);
};
