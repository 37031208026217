import { connect } from 'react-redux';

import { defaultDispatchToProps, defaultStateToProps } from '../helpers';

import TimeInput from './TimeInput';

export const mapStateToProps = (state) => {
  return {
    ...defaultStateToProps(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeInput);
