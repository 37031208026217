import { Columns } from './index';

export const GridViewPopOverEnabledColumns = Object.freeze([
  Columns.TITLE.name,
  Columns.ASINS.name,
  Columns.ASIN_IMAGE.name,
  Columns.STORE_REGIONS.name,
  Columns.DESCRIPTION.name,
  Columns.NOTES.name,
]);
