import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AsinImagePreview from './AsinImagePreview';
import { Columns, COMMA_DELIMETER, Form } from '../../constants';

const selector = formValueSelector(Form.PROMO);

const getFirstImage = (image) => {
  const images = image.split(COMMA_DELIMETER);
  if (images.length > 1) {
    return images[0];
  }

  return image;
};

export const mapStateToProps = (state) => {
  const asinImage = selector(state, Columns.ASIN_IMAGE.name);

  return {
    asinImage: getFirstImage(asinImage),
  };
};

export default connect(mapStateToProps)(AsinImagePreview);
