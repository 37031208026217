import {
  FormActions,
  Form,
} from '../../constants';
import onChange from './onChange';

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;
  const { form } = getState();

  if (!form[Form.PROMO_WEEK]) {
    return next(action);
  }

  if (type === FormActions.CHANGE) {
    return onChange(dispatch, getState, action, next);
  }

  return next(action);
};
