import React from 'react';
import Loader from '@amzn/meridian/loader';
import styles from './HorizontalLoader.module.scss';

const HorizontalLoader = ({ children }) => (
  <div className={styles.loadingBarContainer}>
    <span className={styles.loadingProgressText}>
      {children ? children : 'Loading...'}
    </span>
    <div className={styles.loadingProgressBar}>
      <Loader type="linear" />
    </div>
  </div>
);

export default HorizontalLoader;
