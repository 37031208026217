import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Heading from '@amzn/meridian/heading';
import styles from './PromoReviewList.module.scss';
import GridView from '../GridView';
import { getPromotionsGroupedByStartEndDate } from '../../helpers/promotions';
import PromoReviewActionBar from './PromoReviewActionBar';
import PromoImageReviewActionBar from './PromoImageReviewActionBar';

const PromoReviewList = (props) => {
  const {
    promotionsToReview,
    translatedLogsForHighlight,
    columnOrder,
    columnsToCompare,
    getFormattedWeekName,
    onLoadAuditLogs,
    onClearAuditLogs,
    isImageReviewMode,
  } = props;

  const [hasLogsLoaded, setLogsLoaded] = useState(false);

  useEffect(() => () => onClearAuditLogs(), []);

  useEffect(() => {
    if (!hasLogsLoaded && promotionsToReview.length > 0) {
      onLoadAuditLogs(promotionsToReview);

      setLogsLoaded(true);
    }
  }, [promotionsToReview.length]);

  const getPromotionWeekForReview = (promotionList) => {
    const [firstWeek] = promotionList;
    const weekName = getFormattedWeekName(firstWeek);

    return (
      <>
        <Heading level={3}>
          {weekName}
        </Heading>
        <GridView
          promotions={promotionList}
          columnOrder={columnOrder}
          showActionBar={true}
          customActionBar={isImageReviewMode ? PromoImageReviewActionBar : PromoReviewActionBar}
          translatedLogsForHighlight={translatedLogsForHighlight}
          columnsToCompare={columnsToCompare}
          enableContextMenu={false}
          isStickyHeader
        />
      </>
    );
  }

  const getReviewContent = () => {
    const promotionsGroupedByStartEndDate = getPromotionsGroupedByStartEndDate(
      promotionsToReview
    );
    if (_.isEmpty(promotionsGroupedByStartEndDate)) {
      return null;
    }

    const weekList = Object
      .entries(promotionsGroupedByStartEndDate)
      .map(([key, promotionList]) => {
        const promotionWeekForReview = getPromotionWeekForReview(promotionList)

        return (
          <li key={key} className={styles.weekItem}>
            {promotionWeekForReview}
          </li>
        );
      });

    return (
      <ul className={styles.weeksList}>
        {weekList}
      </ul>
    );
  };

  return (
    <div className={styles.gridContainer}>
      {getReviewContent()}
    </div>
  );
};

PromoReviewList.propTypes = {
  getWeekName: PropTypes.func.isRequired,
  onLoadAuditLogs: PropTypes.func,
  onClearAuditLogs: PropTypes.func,
  showActionBar: PropTypes.bool,
};
PromoReviewList.defaultProps = {
  showActionBar: false,
  onLoadAuditLogs: _.noop,
  onClearAuditLogs: _.noop,
};

export default PromoReviewList;
