import { formValueSelector } from 'redux-form';
import { onAsinSelectImage } from '../../actionCreators';
import { Columns, Form } from '../../constants';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const selector = formValueSelector(Form.PROMO);

  next(action);

  // Make first ASIN image as selected by default if there is no ASIN_IMAGE already set
  const asinImage = selector(state, Columns.ASIN_IMAGE.name);
  if (asinImage) {
    return next(action);
  }

  dispatch(onAsinSelectImage(0));

  return next(action);
};
