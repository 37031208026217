import {
  ON_METADATA_RECEIVED,
  ON_UPDATE_METADATA,
  GET_GRAPHQL_TYPES,
} from '../../constants';
import {
  onUpdateMetadataAction,
  onOpenToast,
  onFetchMetadata,
  onLoadTemplates,
  onLoadWeeksForYearQuarters,
  onSetGraphQLTypes,
} from '../../actionCreators';
import window from '../../helpers/window';
import { submitError, submitTimerMetric } from '../../metrics/index';
import {
  getYearQuarter,
  getNextYearQuarter,
} from '../../helpers/common';
import { getGraphQLTypeFields } from '../../helpers/graphql';
import { GraphQLTypes } from '../../constants/graphql';
import { MetricLocations, MetricNames } from '../../constants/metrics';

export const getProcessedMetadata = (metadata) => {
  return {
    ...metadata,
    columnOrder: metadata.columnOrder,
    fields: {
      ...metadata.fields,
    },
  };
};

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type } = action;

  if (type === ON_METADATA_RECEIVED) {
    const {
      Meta: { initialMetadataLoaded },
      Sitewide: { yearQuarter },
    } = getState();
    if (!initialMetadataLoaded) {
      submitTimerMetric(MetricNames.INITIAL_METADATA, Math.floor(window.performance.now()));
    }
    try {
      const { data: { data: { getMetadata: { metadata, version } } } } = action;

      if (!initialMetadataLoaded) {
        const selectedYearQuarter = yearQuarter || getYearQuarter();
        const yearQuartersToLoad = [selectedYearQuarter, getNextYearQuarter(selectedYearQuarter)];
        dispatch(onLoadWeeksForYearQuarters(yearQuartersToLoad));
      }

      dispatch(onLoadTemplates());

      return next({
        ...action,
        data: {
          ...getProcessedMetadata(JSON.parse(metadata)),
          version,
        },
      });
    } catch (e) {
      submitError(JSON.stringify(e), { location: MetricLocations.METADATA });
      dispatch(onOpenToast('Error parsing metadata. Please try refreshing the page.'));
      return null;
    }
  }

  if (type === ON_UPDATE_METADATA) {
    const {
      Meta: { metadata },
      Sitewide: { selectedBusiness },
      User: { currentUser },
    } = getState();
    const username = currentUser ? currentUser.username : null;
    const { version } = metadata;
    const onFailureFunction = (error) => {
      dispatch(onOpenToast('Error updating metadata. Please try refreshing the page.', 0));
      console.error({ error });
    };
    const onSuccessFunction = () => {
      dispatch(onFetchMetadata(selectedBusiness));
      dispatch(onOpenToast('Successfully edited metadata.'));
    };
    dispatch(onUpdateMetadataAction(
      username,
      selectedBusiness,
      JSON.stringify(metadata),
      version,
      onFailureFunction,
      onSuccessFunction,
    ));
  }

  if (type === GET_GRAPHQL_TYPES) {
    getGraphQLTypeFields(GraphQLTypes.Promotion).then((result) => dispatch(onSetGraphQLTypes({ Promotion: result })));
  }

  return next(action);
};
