/* eslint-disable */
import {
  getFID,
  getLCP,
  getFCP,
  getTTFB,
} from 'web-vitals';

import { submitTimerMetric } from '.';

const callback = (metric) => {
  // submitTimerMetric(metric.name, metric.value);
};

getFID(callback);
getLCP(callback);
getFCP(callback);
getTTFB(callback);
