import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircleTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import clockTokens from '@amzn/meridian-tokens/base/icon/clock';
import styles from './PromoReviewActionBar.module.scss';
import { Urls } from '../../../constants';
import Tooltip from '@amzn/meridian/tooltip';

const PromoReviewActionBar = (props) => {
  const {
    promotion,
    onApprove,
    onReject,
    onShowAuditLog,
  } = props;
  const history = useHistory();
  const { id } = promotion;

  const onApprovePromo = (promoId) => () => onApprove(promoId);
  const onRejectPromo = (promoId) => () => onReject(promoId);
  const onShowPromoAuditLog = (promoId) => () => {
    onShowAuditLog();
    history.push(`${Urls.PROMOTION_EDIT}/${promoId}`);
  };

  const getControls = (promoId) => {
    const controls = [
      {
        name: 'Approve',
        handler: onApprovePromo,
        icon: checkCircleTokens,
      },
      {
        name: 'Reject',
        handler: onRejectPromo,
        icon: closeCircleTokens,
      },
      {
        name: 'Show Audit Log',
        handler: onShowPromoAuditLog,
        icon: clockTokens,
      },
    ];

    const controlsList = controls.map(({ name, icon, handler }) => {
      return (
        <li key={name} className={styles.item}>
          <Tooltip position='top' title={name}>
            <Link
              key={`${name}/${promoId}`}
              href="#"
              rel="noopener noreferrer"
              onClick={handler(promoId)}
            >
              <Icon tokens={icon}>
                {name}
              </Icon>
            </Link>
          </Tooltip>
        </li>
      );
    });

    return (
      <ul className={styles.controlsList}>
        {controlsList}
      </ul>
    );
  };

  return getControls(id);
};

PromoReviewActionBar.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onShowAuditLog: PropTypes.func.isRequired,
};

export default PromoReviewActionBar;
