// TODO: The selectors do not use the gridview reducer's legacy filters/sorts
// However, in order to not break existing functionality, this file needs to be cleaned
// up after a closer logic examination
import { createSelector } from 'reselect';
import {
  Comparators,
  SortOrder,
  defaultSortColumns,
  FilterGroupTypes,
  PromotionImageStatuses,
  PromotionStatuses,
} from '../constants';
import {
  getPromotions,
  getSelectedDates,
} from './promotions';
import { splitYearQuarterDates } from '../helpers/dateTime';
import { isPromotionActive, matchesSelectedDate, sortByPageZoneNumber } from '../helpers/promotions';
import { sanitizeStringForSearch } from '../helpers/common';
import { isCurrentUserAdmin } from './user';

const defaultSort = [{
  column: defaultSortColumns.CREATED_AT,
  order: SortOrder.DESCENDING.value,
}];

export const getFilters = () => [];
export const getSorts = () => defaultSort;

export const filterPromotions = (promotions, filter) => {
  if (filter.type && filter.rules) {
    if (filter.type === FilterGroupTypes.OR) {
      const promotionListings = filter.rules.map((childFilter) => {
        return filterPromotions(promotions, childFilter);
      });
      const mergedPromotionListings = [].concat(...promotionListings);
      const dedupedPromotionListings = mergedPromotionListings.filter(
        (promotion, index) => mergedPromotionListings.indexOf(promotion) === index,
      );
      return dedupedPromotionListings;
    }
    return filter.rules.reduce(filterPromotions, promotions);
  }

  const { column, comparator, value } = filter;

  return promotions.filter((promotion) => {
    const colValue = promotion[column] ? String(promotion[column]).toUpperCase() : '';
    const updatedValue = value ? String(value).toUpperCase() : '';

    switch (comparator) {
      case Comparators.GREATER_THAN:
        return colValue > updatedValue;
      case Comparators.LESS_THAN:
        return colValue < updatedValue;
      case Comparators.EQUALS:
        return colValue === updatedValue;
      case Comparators.DOES_NOT_EQUAL:
        return colValue !== updatedValue;
      case Comparators.CONTAINS:
        return colValue.indexOf(updatedValue) !== -1;
      case Comparators.DOES_NOT_CONTAIN:
        return colValue.indexOf(updatedValue) === -1;
      case Comparators.EMPTY:
        return colValue === '';
      case Comparators.NOT_EMPTY:
        return colValue !== '';
      default:
        return true;
    }
  });
};

export const createSortFunction = (fn, sort) => {
  // If the two comparisons are equal, go into the next sort until you find
  // an unequal column, and sort on that.
  const { column, order } = sort;
  return (a, b) => {
    if (a[column] === b[column]) { return fn(a, b); }
    const sortValue = a[column] > b[column] ? -1 : 1;
    // reverse the order if descending
    return sortValue * (order === SortOrder.DESCENDING.value ? 1 : -1);
  };
};

export const getFilteredPromotions = createSelector(
  [getFilters, getPromotions],
  (filters, promotions) => {
    return filters.reduce(filterPromotions, promotions);
  },
);

export const getFilteredSortedPromotions = createSelector(
  [getSorts, getFilteredPromotions],
  (sorts, promotions) => {
    const noSort = () => 0;
    const sortFunction = [...sorts].reverse().reduce(createSortFunction, noSort);
    return promotions.sort(sortFunction);
  },
);

export const getSearchTerms = (state) => {
  const { GridViewPage: { searchTerms } } = state;
  return searchTerms;
};

export const getPromotionsForCurrentWeek = createSelector(
  [getPromotions, getSelectedDates],
  (promotions, selectedDates) => {
    return promotions.filter((promotion) => {
      const { yearQuarter_startDate_endDate } = promotion;
      const { startDate, endDate } = splitYearQuarterDates(yearQuarter_startDate_endDate);
      const [selectedStartDate, selectedEndDate] = selectedDates;

      return matchesSelectedDate(
        selectedStartDate,
        selectedEndDate,
        startDate,
        endDate,
      );
    }).sort(sortByPageZoneNumber);
  },
);

export const getSearchedPromotionsForCurrentWeek = createSelector(
  [getPromotionsForCurrentWeek, getSearchTerms],
  (promotionsWeek, searchTerms) => {
    if (!searchTerms) {
      return promotionsWeek;
    }
    return promotionsWeek.filter((promotion) => {
      let inSearch = true;
      Object.entries(searchTerms).forEach(([columnName, searchTerm]) => {
        if (searchTerm && searchTerm !== '') {
          const cellString = sanitizeStringForSearch(promotion[columnName]);
          const searchString = sanitizeStringForSearch(searchTerm);
          if (typeof promotion[columnName] === 'number') {
            inSearch = inSearch && cellString === searchString;
          } else {
            inSearch = inSearch && cellString.includes(searchString);
          }
        }
      });
      return inSearch;
    });
  },
);

export const getPromotionsToReview = createSelector(
  [getFilteredSortedPromotions],
  (filteredSortedPromotions) => {
    return filteredSortedPromotions
      .filter((promotion) =>
        promotion.status === PromotionStatuses.CHANGE_REQUESTED.value ||
        promotion.status === PromotionStatuses.REVIEW_CHANGE_REQUESTED.value
      )
      .sort(sortByPageZoneNumber);
  },
);

export const getPromotionsToImageReview = createSelector(
  [getFilteredSortedPromotions, isCurrentUserAdmin],
  (filteredSortedPromotions, isAdmin) => {
    const filterPromotionForImageReview = (promotion) => {
      const isPromotionImageIncorrect = !!promotion.imageStatus
        && promotion.imageStatus !== PromotionImageStatuses.IMAGE_MATCHES_ASIN
        && promotion.imageStatus !== PromotionImageStatuses.MANUALLY_CONFIRMED;
      return isPromotionActive(promotion, isAdmin) && isPromotionImageIncorrect;
    };
    return filteredSortedPromotions
      .filter(filterPromotionForImageReview)
      .sort(sortByPageZoneNumber);
  },
);

export const getAllowedPageZoneCombinationsForCurrentWeek = createSelector(
  [getPromotionsForCurrentWeek],
  (currentWeekPromotions) => {
    const allowedPageZoneNumberCombinationList = {};

    currentWeekPromotions.forEach(({ page, zoneNumber }) => {
      if (!page || !zoneNumber) {
        return;
      }
      if (!allowedPageZoneNumberCombinationList[page]) {
        allowedPageZoneNumberCombinationList[page] = {};
      }
      if (!allowedPageZoneNumberCombinationList[page][zoneNumber]) {
        allowedPageZoneNumberCombinationList[page][zoneNumber] = true;
      }
    });

    return allowedPageZoneNumberCombinationList;
  }
);
