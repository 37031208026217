import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import {
  onDeletePromotion,
  onOpenToast,
  onLoadWeeksForYearQuarters,
} from '../../actionCreators';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { getWeekByStartEndDate } from '../../helpers/promotions';

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }

  const {
    User: { currentUser: { username } },
    Sitewide: { yearQuarter },
  } = state;

  const {
    payload: {
      startDate,
      endDate,
    },
  } = action;

  const selectedWeek = getWeekByStartEndDate(state, { startDate, endDate });
  if (!selectedWeek) {
    dispatch(onOpenToast('Cannot delete week!'));

    return next(action);
  }

  const getPromotionsForWeek = (yearQuarter_startDate_endDate) => {
    return API.graphql(graphqlOperation(queries.getPromotionsForWeek, {
      yearQuarter_startDate_endDate,
    }));
  };

  const deletePromotionWeek = (promotionWeek, username) => {
    const params = {
      promotionWeek: {
        ...promotionWeek,
        version: promotionWeek.version + 1,
      },
      username,
    };

    return API.graphql(graphqlOperation(mutations.deletePromotionWeek, { input: params }));
  };

  const process = () => {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    const requestParams = {
      yearQuarter,
      startDate_endDate: `${formattedStartDate}_${formattedEndDate}`,
    };

    API
      .graphql(graphqlOperation(queries.getPromotionWeek, requestParams))
      .then(({ data: { getPromotionWeek } }) => {
        return getPromotionWeek && getPromotionWeek.length ? getPromotionWeek[0] : {};
      })
      .then((promotionWeek) => {
        if (!promotionWeek) {
          return [];
        }

        return deletePromotionWeek(promotionWeek, username);
      })
      .then(({ data: { deletePromotionWeek } }) => {
        if (!deletePromotionWeek) {
          return [];
        }

        const { yearQuarter, startDate_endDate } = deletePromotionWeek;
        const weekId = `${yearQuarter}_${startDate_endDate}`;

        return getPromotionsForWeek(weekId);
      })
      .then(({ data: { getPromotionsForWeek: promotionsList } }) => {
        return promotionsList && promotionsList.length
          ? promotionsList
          : [];
      })
      .then((promotionsList) => {
        if (promotionsList.length > 0) {
          promotionsList.forEach((promotion) => {
            dispatch(onDeletePromotion(promotion, { isSilentDelete: true }));
          });
        }
      })
      .finally(() => {
        dispatch(onLoadWeeksForYearQuarters([yearQuarter]));
      })
      .catch((error) => console.error(error));
  };

  process();

  return next(action);
};
