import {
  BannerActions,
} from '../../constants/banner';
import loadBanners from './loadBanners';
import saveBanners from './saveBanners';
import openModal from './openModal';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === BannerActions.LOAD_BANNERS) {
    return loadBanners(dispatch, getState, action, next);
  }

  if (type === BannerActions.SAVE_BANNERS) {
    return saveBanners(dispatch, getState, action, next);
  }

  if (type === BannerActions.OPEN_MODAL) {
    return openModal(dispatch, getState, action, next);
  }

  return next(action);
};
