import {
  onMetadataEditorSetDependencies,
  onAddMetadataEntry,
  onRemoveMetadataEntry,
} from '../../actionCreators';
import {
  MetadataEditorActions,
} from '../../constants';

const dependenciesToParents = (dependencies) => {
  return dependencies.map((dependency) => {
    return {
      field: dependency.name,
      value: dependency.selection,
    };
  });
};

export default ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  if (action.type === MetadataEditorActions.SELECT_FIELD) {
    if (!action.data) {
      dispatch(onMetadataEditorSetDependencies([]));
    } else {
      const { Meta: { metadata: { fields } } } = state;
      const dependencyNames = fields[action.data].dependsOn;
      const dependencies = dependencyNames.map((dependency) => {
        return { name: dependency, selection: null };
      });
      dependencies.push({ name: action.data, selection: null });
      dispatch(onMetadataEditorSetDependencies(dependencies));
    }
  }
  if (action.type === MetadataEditorActions.ADD_OPTION) {
    const { MetadataEditor: { dependencies, selectedField } } = state;
    const parents = dependenciesToParents(dependencies);
    const value = action.data;
    const field = selectedField;
    dispatch(onAddMetadataEntry({ value, field, parents }));
  }
  if (action.type === MetadataEditorActions.ADD_OPTION_FOR_FIELD) {
    const { dependencies, selectedField, value } = action.data;
    const parents = dependenciesToParents(dependencies);
    const field = selectedField;
    dispatch(onAddMetadataEntry({ value, field, parents }));
  }
  if (action.type === MetadataEditorActions.DELETE_OPTION) {
    const { MetadataEditor: { dependencies, selectedField } } = state;
    const parents = dependenciesToParents(dependencies);
    const value = action.data;
    const field = selectedField;

    dispatch(onRemoveMetadataEntry({ value, field, parents }));
  }
  if (action.type === MetadataEditorActions.DELETE_OPTION_FOR_FIELD) {
    const { dependencies, selectedField, value } = action.data;
    const parents = dependenciesToParents(dependencies);
    const field = selectedField;
    dispatch(onRemoveMetadataEntry({ value, field, parents }));
  }
  return next(action);
};
