import { PublishPromotionsActions } from '../../constants/publishPromotions';
import publishPromotions from './publishPromotions';
import isModalOpen from './isModalOpen';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === PublishPromotionsActions.SEND_PUBLISH_PROMOTIONS_REQUEST) {
    return publishPromotions(dispatch, getState, action, next);
  }

  if (type === PublishPromotionsActions.SET_PUBLISH_PROMOTIONS_IS_MODAL_OPEN) {
    return isModalOpen(dispatch, getState, action, next);
  }

  return next(action);
};