import { PromotionStatuses } from '../constants';

export const isUneditableStatus = (status, isAdmin) => {
  return status === PromotionStatuses.PRINT_LOCKED.value
    || (status === PromotionStatuses.REVIEW_CHANGE_REQUESTED.value && !isAdmin)
    || (status === PromotionStatuses.REVIEW_LOCKED.value && !isAdmin);
}

const getStatusList = () => {
  return Object.values(PromotionStatuses)
    .reduce((statuses, { display, value }) => ({
      ...statuses,
      [value]: display,
    }), {});
};

export const getStatusDisplayNameByValue = (status) => {
  return getStatusList()[status] || 'N/A';
};