import {
  OPEN_TOAST,
  CLOSE_TOAST,
  TOGGLE_SIDE_MENU,
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  SET_NEW_USER_EXPERIENCE_SHOWN,
  FeatureNotificationActions,
  BusinessTypes,
  GridViewActions,
  SELECT_BUSINESS,
  SELECT_YEAR_QUARTER,
  SELECT_TIMEFRAME,
  SiteWideActions,
} from '../../constants';

const defaultTimeout = 3000;

export const initialState = {
  toastId: 0,
  toasts: [],
  notifications: [],
  isSideMenuOpen: false,
  showLoader: false,
  showNewUserExperience: false,
  staticData: {
    businessTypes: Object.values(BusinessTypes),
  },
  isActionBarExpanded: false,
  selectedBusiness: BusinessTypes.FRESH.name,
  yearQuarter: '',
  timeFrame: '',
  connectionErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_TOAST: {
      const timeout = typeof action.data.timeout === 'undefined' ? defaultTimeout : action.data.timeout;
      const toastId = state.toastId + 1;
      const newToast = {
        id: `${toastId}`, // required to be a string
        message: action.data.message,
        timeout,
      };
      return {
        ...state,
        toastId,
        toasts: [...state.toasts, newToast],
      };
    }
    case SELECT_BUSINESS: {
      return {
        ...state,
        selectedBusiness: action.data.business,
      };
    }
    case SELECT_YEAR_QUARTER: {
      return {
        ...state,
        yearQuarter: action.data.yearQuarter,
      };
    }
    case SELECT_TIMEFRAME: {
      const { payload: { timeFrame } } = action;
      return {
        ...state,
        timeFrame,
      };
    }
    case CLOSE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.data),
      };
    }
    case GridViewActions.TOGGLE_ACTION_BAR: {
      return {
        ...state,
        isActionBarExpanded: !state.isActionBarExpanded,
      };
    }
    case TOGGLE_SIDE_MENU: {
      return {
        ...state,
        isSideMenuOpen: !state.isSideMenuOpen,
      };
    }
    case SHOW_LOADING_SPINNER: {
      return {
        ...state,
        showLoader: true,
      };
    }
    case HIDE_LOADING_SPINNER: {
      return {
        ...state,
        showLoader: false,
      };
    }
    case FeatureNotificationActions.ADD_BULK: {
      return {
        ...state,
        notifications: action.data,
      };
    }
    case FeatureNotificationActions.CLOSE: {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => {
          return notification.version !== action.data.version;
        }),
      };
    }
    case FeatureNotificationActions.CLOSE_BULK: {
      return {
        ...state,
        notifications: [],
      };
    }
    case SET_NEW_USER_EXPERIENCE_SHOWN: {
      return {
        ...state,
        showNewUserExperience: action.payload,
      };
    }
    case SiteWideActions.SET_CONNECTION_ERROR: {
      const { payload: { error } } = action;

      const connectionErrors = Array.from(new Set(
        [
          ...state.connectionErrors,
          error,
        ],
      ));

      return {
        ...state,
        connectionErrors,
      };
    }
    default: {
      return state;
    }
  }
};
