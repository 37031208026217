import { API, graphqlOperation } from 'aws-amplify';

const getGraphQLFieldNamesQuery = (typeName) => /* GraphQL */ `
    query {
        __type(name: "${typeName}") {
            fields {
                name
            }
        }
    }
`;

export const getGraphQLTypeFields = async (typeName) => {
  const { data: { __type: { fields } } } = await API.graphql(graphqlOperation(getGraphQLFieldNamesQuery(typeName)));
  return fields.map((field) => field.name);
};
