import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Hub } from 'aws-amplify';
import Card, { CardHeader } from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import {
  getUserData,
  getCurrentUserInfo,
  hasAccessTokens,
  redirectToLoginPage,
} from '../../helpers/cognito';
import { ExternalUrls, Language } from '../../constants';
import styles from './CognitoAuth.module.scss';
import sitewideStyles from '../../styles/sitewide.module.scss';
import { openLinkInNewTab } from '../../helpers/common';

const MAX_ATTEMPTS = 3;
const INTERVAL_TIMEOUT = 500;

export const openLink = ({ target }) => {
  if (!target) {
    return;
  }
  openLinkInNewTab(target.href);
};

const CognitoAuth = ({
  children,
  user,
  cannotAuthenticate,
  onSetUser,
  onSetUserUnableToSignIn,
}) => {
  useEffect(() => {
    let attempts = 0;
    let intervalId;

    const checkForTokens = () => {
      hasAccessTokens().then((hasToken) => {
        if (hasToken) {
          getCurrentUserInfo()
            .then((userInfo) => {
              onSetUser(getUserData(userInfo));
            });

          if (intervalId) {
            clearInterval(intervalId);
          }
        } else if (attempts > MAX_ATTEMPTS) {
          if (intervalId) {
            clearInterval(intervalId);
          }
          onSetUserUnableToSignIn();
        }
        attempts += 1;
      });
    };

    // Token checker. In case a sign in event happens.
    intervalId = setInterval(checkForTokens, INTERVAL_TIMEOUT);

    Hub.listen('auth', ({ payload: { event, data: userInfo } }) => {
      if (event === 'signIn') {
        onSetUser(getUserData(userInfo));
      } else if (event === 'signOut') {
        redirectToLoginPage();
      }
    });
  }, [onSetUser, onSetUserUnableToSignIn]);

  const getLoginAgainMessage = () => {
    return (
      <div className={styles.contentBlock}>
        <p className={styles.authErrorMessage}>
          {Language.CANNOT_AUTHENTICATE}
        </p>
        <Button
          size="small"
          type="primary"
          onClick={redirectToLoginPage}
        >
          {Language.AUTHENTICATE_TRY_AGAIN}
        </Button>
      </div>
    );
  };

  const getAccessRequiredMessage = () => {
    const link = (
      <a
        onClick={openLink}
        href={ExternalUrls.GET_ACCESS_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={sitewideStyles.link}
      >
        here
      </a>
    );
    return (
      <div className={styles.contentBlock}>
        <div className={styles.contentBlockWrapper}>
          {Language.AUTHENTICATE_ACCESS_REQUIRED} {link}
        </div>
      </div>
    );
  };

  const accessRequiredMessage = getAccessRequiredMessage();
  const loginAgainMessage = getLoginAgainMessage();

  const authCard = cannotAuthenticate ? (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Card>
          <CardHeader>
            <Heading level={2} type="h500">
              Error Authenticating
            </Heading>
          </CardHeader>
          <div className={styles.contentContainer}>
            {loginAgainMessage}
            {accessRequiredMessage}
          </div>
        </Card>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Card>
          <CardHeader>
            <Heading level={2} type="h500">
              Authenticating
            </Heading>
          </CardHeader>
          <Text type="b300">Please wait...</Text>
        </Card>
      </div>
    </div>
  );

  return user
    ? children
    : authCard;
};

CognitoAuth.propTypes = {
  children: PropTypes.element.isRequired,
  onSetUser: PropTypes.func.isRequired,
  onSetUserUnableToSignIn: PropTypes.func.isRequired,
  cannotAuthenticate: PropTypes.bool.isRequired,
};

export default CognitoAuth;
