import { connect } from 'react-redux';
import AuditLogActionBar from './AuditLogActionBar';
import getPromotionById from '../../../reducers/gridViewPage/getPromotionById';
import { onRollBackPromotion } from '../../../actionCreators';
import { isUneditableStatus } from '../../../helpers/status';
import { isCurrentUserAdmin } from '../../../selectors/user';

const mapStateToProps = (state, ownProps) => {
  const { promotion } = ownProps;
  const currentPromotion = getPromotionById(state.GridViewPage, promotion.id);
  return {
    disabled: isUneditableStatus(currentPromotion.status, isCurrentUserAdmin(state))
      || promotion.version === currentPromotion.version,
  };
};

const mapDispatchToProps = (dispatch, { promotion }) => {
  return {
    onRollBack: () => {
      dispatch(onRollBackPromotion(promotion));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogActionBar);
