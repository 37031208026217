import {
  AsinValidatorActions,
} from '../../constants';
import sendAsins from './sendAsins';
import approveRegions from './approveRegions';
import selectRegions from './selectRegions';
import exportResults from './exportResults';
import setAsinViabilities from './setAsinViabilities';
import selectRegionFilters from './selectRegionFilters';
import setOpen from './setOpen';
import exportFilteredRegions from './exportFilteredRegions';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === AsinValidatorActions.SEND_ASINS) {
    return sendAsins(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.APPROVE_REGIONS) {
    return approveRegions(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.AUTO_SELECT_AVAILABLE_REGIONS) {
    return selectRegions(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.AUTO_SELECT_REGION_FILTERS) {
    return selectRegionFilters(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.EXPORT_RESULTS) {
    return exportResults(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.SET_ASIN_VIABILITIES) {
    return setAsinViabilities(dispatch, getState, action, next);
  }

  if (type === AsinValidatorActions.SET_OPEN) {
    return setOpen(dispatch, getState, action, next);
  }
  if (type == AsinValidatorActions.EXPORT_FILTERED_REGIONS) {
    return exportFilteredRegions(dispatch, getState, action, next);
  }
  
  return next(action);
};
