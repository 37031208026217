import { change } from 'redux-form';
import {
  Form,
  PromotionWeekColumns,
  PromotionWeekDeadlines,
} from '../../constants';

import { getDeadlineFromStartDate } from '../../helpers/dateTime';

export default (dispatch, getState, action, next) => {
  const { meta: { field }, payload } = action;
  if (field === PromotionWeekColumns.START_DATE.name) {
    const editDeadline = getDeadlineFromStartDate(payload, PromotionWeekDeadlines.EDIT_DEADLINE);
    const reviewDeadline = getDeadlineFromStartDate(payload, PromotionWeekDeadlines.REVIEW_DEADLINE);
    const printDeadline = getDeadlineFromStartDate(payload, PromotionWeekDeadlines.PRINT_DEADLINE);
    dispatch(change(Form.PROMO_WEEK, PromotionWeekColumns.EDIT_DEADLINE.name, editDeadline));
    dispatch(change(Form.PROMO_WEEK, PromotionWeekColumns.REVIEW_DEADLINE.name, reviewDeadline));
    dispatch(change(Form.PROMO_WEEK, PromotionWeekColumns.PRINT_DEADLINE.name, printDeadline));
  }

  return next(action);
};
