import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';

const AddRowActionBar = ({ onAddRow }) => {
  return (
    <Button
      size="small"
      onClick={onAddRow}
    >
      <Icon tokens={plusTokens} />
    </Button>
  );
};

AddRowActionBar.propTypes = {
  onAddRow: PropTypes.func.isRequired,
};

export default AddRowActionBar;
