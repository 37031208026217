import { connect } from 'react-redux';
import PromotionWeek from './PromotionWeek';
import history from '../../../history';
import {
  onDeletePromotionWeek,
  onSetDatesRange,
} from '../../../actionCreators';
import { isCurrentUserAdmin } from '../../../selectors/user';
import { createPromotionWeekRoute } from '../../../helpers/router';

const mapStateToProps = (state) => ({
  isAdmin: isCurrentUserAdmin(state),
});

const mapDispatchToProps = (dispatch, props) => {
  const { start, end } = props;

  return {
    onDateRangeChange() {
      dispatch(onSetDatesRange([start, end]));
      history.push(createPromotionWeekRoute(start, end));
    },
    onDelete() {
      dispatch(onDeletePromotionWeek(start, end));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionWeek);
