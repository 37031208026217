import React from 'react';
import PropTypes from 'prop-types';
import Tag from '@amzn/meridian/tag';
import Tooltip from '@amzn/meridian/tooltip';
import styles from './BadgeList.module.scss';
import { Language } from '../../constants';

const BadgeList = (props) => {
  const {
    selectedItems,
    currentItems,
    noCurrentItemsMessage = Language.NO_VALIDATION_AVAILABLE,
    sortFunc = null,
  } = props;

  if (!selectedItems || !selectedItems.length || !Array.isArray(selectedItems)) {
    return null;
  }

  const noCurrentItems = !currentItems || !Array.isArray(currentItems);
  const syncedItems = selectedItems.filter((item) => currentItems.includes(item));
  const sortedItemList = sortFunc && typeof sortFunc === 'function'
    ? selectedItems.sort(sortFunc)
    : selectedItems;
  const itemList = sortedItemList.map((item) => {
    const type = () => {
      if (noCurrentItems) {
        return 'neutral';
      }
      if (syncedItems.includes(item)) {
        return 'success';
      }
      return 'error';
    };

    return (
      <li key={item} className={styles.item}>
        <Tag type={type()}>
          {item}
        </Tag>
      </li>
    );
  });

  const listComponents = (
    <ul className={styles.list}>
      {itemList}
    </ul>
  );

  return noCurrentItems
    ? (
      <Tooltip
        title={noCurrentItemsMessage}
        position="end"
      >
        {listComponents}
      </Tooltip>
    )
    : listComponents;
};

BadgeList.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  currentItems: PropTypes.arrayOf(PropTypes.string),
  noCurrentItemsMessage: PropTypes.string,
};

BadgeList.defaultProps = {
  selectedItems: [],
  currentItems: [],
  noCurrentItemsMessage: '',
};

export default BadgeList;
