import { connect } from 'react-redux';
import PromoWeekModal from './PromoWeekModal';

export const mapStateToProps = (state) => {
  const { Promo: { isWeekModalOpen } } = state;

  return {
    isWeekModalOpen,
  };
};

export default connect(mapStateToProps)(PromoWeekModal);
