import { connect } from 'react-redux';
import PublishedPromotionList from './PublishedPromotionList';
import { onLoadPublishedPromotionAuditLog } from '../../actionCreators';
import { getStringParts } from '../../helpers/promotions';

const mapStateToProps = (state) => {
  const {
    Meta: { metadata: { columnOrder } },
  } = state;

  return {
    columnOrder,
  };
};

const mapDispatchToProps = (dispatch, { promotions }) => {
  return {
    onShowAuditLog() {
      if (!promotions || !promotions.length) {
        return;
      }
      const [
        yearQuarter,
        startDate,
        endDate,
      ] = getStringParts(promotions[0].yearQuarter_startDate_endDate);

      dispatch(onLoadPublishedPromotionAuditLog(yearQuarter, startDate, endDate));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishedPromotionList);
