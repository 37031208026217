import exportPromotions from './export';
import exportPromotionsAsinsViability from './exportPromotionsAsinsViability';
import { ExportActions } from '../../constants/export';
import { isViabilityExportType } from '../../helpers/export';
import { onExportPromotions, onExportPromotionsAsinsViability } from '../../actionCreators';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;

  if (type === ExportActions.EXPORT_CURRENT_VIEW) {
    const { Export: { exportType } } = getState();
    if (isViabilityExportType(exportType)) {
      dispatch(onExportPromotionsAsinsViability());
    } else {
      dispatch(onExportPromotions());
    }
  }

  if (type === ExportActions.EXPORT_PROMOTIONS) {
    return exportPromotions(dispatch, getState, action, next);
  }

  if (type === ExportActions.EXPORT_PROMOTIONS_ASINS_VIABILITY) {
    return exportPromotionsAsinsViability(dispatch, getState, action, next);
  }

  return next(action);
};
