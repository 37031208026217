import { connect } from 'react-redux';
import PublishedWeekPage from './published-week';
import { getPublishedPromotionByStartEndDate } from '../../../../helpers/promotions';

export const mapStateToProps = (state, props) => {
  const { match: { params: { startDate, endDate } } } = props;

  const promotionContainer = getPublishedPromotionByStartEndDate(state, { startDate, endDate });
  const promotions = promotionContainer ? promotionContainer.promotions : [];

  return {
    promotions,
  };
};

export default connect(mapStateToProps)(PublishedWeekPage);
