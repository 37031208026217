import moment from 'moment';
import { connect } from 'react-redux';
import Promotions from './Promotions';
import {
  getWeekByStartEndDate,
} from '../../helpers/promotions';
import { onOpenBulkImportModal } from '../../actionCreators/bulkImport';
import { onOpenAddRowModal, onSetColorLegendModalOpen } from '../../actionCreators';
import { onSetBannerModalOpen } from '../../actionCreators/banner';
import { isCurrentUserAdmin } from '../../selectors/user';
import { setPublishPromotionsIsModalOpen } from '../../actionCreators/publishPromotions';

const mapStateToProps = (state) => {
  const {
    Meta: {
      initialMetadataLoaded,
    },
    Promo: {
      dates: [startDate, endDate],
    },
  } = state;

  const formattedStartDate = moment(startDate).format('MM/DD');
  const formattedEndDate = moment(endDate).format('MM/DD');
  const week = getWeekByStartEndDate(state, { startDate, endDate });
  const weekName = week && week.name ? week.name : '';

  return {
    initialMetadataLoaded,
    isAdmin: isCurrentUserAdmin(state),
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    weekName,
  };
};

const mapDispatchToProps = (dispatch, { viewType }) => {
  return {
    onOpenBulkImport() {
      dispatch(onOpenBulkImportModal(viewType));
    },
    openPublishPromotionModal() {
      dispatch(setPublishPromotionsIsModalOpen(true));
    },
    onAddRow() {
      dispatch(onOpenAddRowModal());
    },
    onShowColorLegend() {
      dispatch(onSetColorLegendModalOpen(true));
    },
    onShowBanner() {
      dispatch(onSetBannerModalOpen(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
