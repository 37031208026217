export const getDependentOptionsForFieldOnCampaign = (options, campaign, dependsOn, index = 0) => {
  if (index >= dependsOn.length) { return options; }
  if (!options) { return []; }
  const column = dependsOn[index];
  if (!campaign[column]) { return []; }
  const value = campaign[column];
  if (options[value]) {
    return getDependentOptionsForFieldOnCampaign(options[value], campaign, dependsOn, index + 1);
  }
  return [];
};

export const getDependencyAwareOptions = (filterOptionValue, selectedFilters) => {
  let options = [];
  if (Array.isArray(filterOptionValue.options)) {
    options = filterOptionValue.options;
  }
  if (filterOptionValue
    && filterOptionValue.dependsOn
    && filterOptionValue.dependsOn.length > 0) {
    let iterator = filterOptionValue.options;
    filterOptionValue.dependsOn.forEach((dependency) => {
      if (selectedFilters[dependency]) {
        iterator = iterator[selectedFilters[dependency]];
      }
    });
    if (Array.isArray(iterator)) {
      options = iterator;
    }
  }
  return options;
};
