import { createSelector } from 'reselect';
import { isAdminViewMode } from '../helpers/user';

export const getUser = (state) => state.User;
export const getUsername = createSelector(
  [getUser],
  ({ currentUser: { username } }) => username,
);
export const isCurrentUserAdmin = createSelector(
  [getUser],
  ({ viewMode, currentUser: { isAdmin, isMetadataAdmin } }) => {
    return (isAdmin || isMetadataAdmin) && isAdminViewMode(viewMode);
  },
);
