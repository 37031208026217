export const UserActions = Object.freeze({
  SET_USER: 'SET_USER',
  SET_USER_UNABLE_TO_SIGN_IN: 'SET_USER_UNABLE_TO_SIGN_IN',
  LOG_OUT_USER: 'LOG_OUT_USER',
  SWITCH_VIEW_MODE: 'SWITCH_ADMIN_VIEW_MODE',
  PUSH_EDIT_HISTORY: 'PUSH_EDIT_HISTORY',
  POP_EDIT_HISTORY: 'POP_EDIT_HISTORY',
});
export const UserViewMode = Object.freeze({
  USER: { name: 'user', label: 'User' },
  ADMIN: { name: 'admin', label: 'Admin' },
});
