import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian//modal';
import BulkImporter from '../BulkImporter';
import styles from './BulkImporterModal.module.scss';

const BulkImporterModal = (props) => {
  const {
    isOpen,
    onClose,
  } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      bodySpacingInset="none"
    >
      <div className={styles.wrapper}>
        <BulkImporter />
      </div>
    </Modal>
  );
};

BulkImporterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BulkImporterModal;
