import _ from 'lodash';
import {
  KEYPRESS,
  MovementDirections,
  KeyboardKeys,
} from '../../constants';
import {
  onResetActiveCell,
  onMoveActiveCell,
  onPressUndo,
  onSetActiveCellEditMode,
} from '../../actionCreators';
import {
  onCopyToClipboard,
  onPasteFromClipboard,
} from '../../actionCreators/clipboard';
import { isActiveCellEditable, isActiveCellEditMode } from '../../selectors/promotions';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const relevantActions = [
    KEYPRESS,
  ];

  if (!_.includes(relevantActions, action.type)) {
    return next(action);
  }
  // handle keyboard presses here
  const {
    data: {
      key,
      altKey,
      shiftKey,
      ctrlKey,
      metaKey, // cmd key on mac
    },
  } = action;
  
  const isCommandKey = ctrlKey || metaKey;

  if (altKey) { return null; }

  if (key === KeyboardKeys.ESCAPE) {
    dispatch(onResetActiveCell());
    dispatch(onSetActiveCellEditMode(false));

    return next(action);
  }

  const state = getState();

  if (key === KeyboardKeys.ENTER) {
    if (isActiveCellEditMode(state) && !isCommandKey) {
      dispatch(onSetActiveCellEditMode(false));
      dispatch(onMoveActiveCell(MovementDirections.DOWN));

      return next(action);
    }

    dispatch(
      (isActiveCellEditable(state)
          ? onSetActiveCellEditMode(true)
          : onMoveActiveCell(MovementDirections.DOWN)
      )
    );

    return next(action);
  }

  if (isActiveCellEditMode(state)) {
    return next(action);
  }

  switch (key) {
    case KeyboardKeys.ARROW_LEFT: {
      dispatch(onMoveActiveCell(MovementDirections.LEFT));

      return next(action);
    }
    case KeyboardKeys.ARROW_RIGHT: {
      dispatch(onMoveActiveCell(MovementDirections.RIGHT));

      return next(action);
    }
    case KeyboardKeys.ARROW_UP: {
      dispatch(onMoveActiveCell(MovementDirections.UP));

      return next(action);
    }
    case KeyboardKeys.ARROW_DOWN: {
      dispatch(onMoveActiveCell(MovementDirections.DOWN));

      return next(action);
    }
    case KeyboardKeys.TAB: {
      dispatch(onMoveActiveCell(shiftKey
        ? MovementDirections.SHIFT_TAB
        : MovementDirections.TAB))

      return next(action);
    }
    case KeyboardKeys.C: {
      if (isCommandKey) {
        return dispatch(onCopyToClipboard());
      }

      return next(action);
    }
    case KeyboardKeys.V: {
      if (isCommandKey) {
        return dispatch(onPasteFromClipboard());
      }

      return next(action);
    }
    case KeyboardKeys.Z: {
      if (isCommandKey) {
        return dispatch(onPressUndo());
      }

      return next(action);
    }
    default: {
      return next(action);
    }
  }
};
