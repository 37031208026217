import moment from 'moment';
import {
  Language,
} from '../../constants';
import {
  getReportRows,
  getSimpleFileContent,
  downloadFile,
} from '../../helpers/export';
import { getFormattedDateTime } from '../../helpers/dateTime';

const getFileName = (asin, date) => {
  const dateTime = getFormattedDateTime(date, { pattern: 'MM_DD_YY_hh_mma' });

  return `asins_viability_${asin}_${dateTime}`;
};

export default (dispatch, getState, action, next) => {
  const { AsinValidator: { asinViabilities } } = getState();

  if (!asinViabilities || !asinViabilities.length) {
    console.error(Language.ASIN_VALIDATOR_EXPORT_ERROR_NO_DATA);

    return next(action);
  }

  const reportRows = getReportRows(asinViabilities);
  if (!reportRows) {
    console.error(Language.ASIN_VALIDATOR_EXPORT_ERROR_NO_DATA);

    return next(action);
  }
  const [firstAsinViability] = asinViabilities;

  const fileName = getFileName(firstAsinViability.asin, moment().format());
  const fileContent = getSimpleFileContent(reportRows);

  downloadFile(fileContent, fileName);

  return next(action);
};
