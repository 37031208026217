import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian/modal';
import styles from './CreatePromotionWeek.module.scss';
import GridView from '../GridView';
import PromotionWeekContainer from '../PromotionWeekContainer';
import { promotionColumnProps } from '../../proptypes';

const CreatePromotionWeek = ({ templateRows, columnOrder, onClose }) => {
  const getContent = () => {
    const previewTemplate = templateRows && templateRows.length
      ? (
        <div className={styles.gridContainer}>
          <GridView
            promotions={templateRows}
            columnOrder={columnOrder}
            showActionBar={false}
            isReadOnly
            isStickyHeader
          />
        </div>
      )
      : null;

    return (
      <div className={styles.previewContainer}>
        <PromotionWeekContainer />
        {previewTemplate}
      </div>
    );
  };

  const content = getContent();

  return (
    <Modal
      open
      scrollContainer="modal"
      onClose={onClose}
    >
      {content}
    </Modal>
  );
};

CreatePromotionWeek.propTypes = {
  templateRows: PropTypes.array,
  columnOrder: promotionColumnProps.isRequired,
  onClose: PropTypes.func.isRequired,
};

CreatePromotionWeek.defaultProps = {
  templateRows: [],
}

export default CreatePromotionWeek;
