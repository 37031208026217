import { getCurrentPathName, isPromotionWeekRoute } from '../../helpers/router';
import {
  hasSelectedRows
} from '../../selectors/promotions';
import pastePromotionRows from './pastePromotionRows';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const currentPathName = getCurrentPathName(state);

  if (!currentPathName) {
    return next(action);
  }

  if (isPromotionWeekRoute(currentPathName) && hasSelectedRows(state)) {
    return pastePromotionRows(dispatch, getState, action, next);
  }

  return next(action);
}