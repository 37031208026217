import { UNDERSCORE_DELIMETER } from '../constants';

const extractDates = (promotionWeek) => {
  const { startDate_endDate } = promotionWeek;
  const [startDate, endDate] = startDate_endDate.split(UNDERSCORE_DELIMETER);

  return {
    startDate,
    endDate,
  };
};

export const appsyncToLocal = (promotionWeek) => {
  const translatedPromotionWeek = {
    ...promotionWeek,
    ...extractDates(promotionWeek),
  };

  delete translatedPromotionWeek.startDate_endDate;

  const nonNulledTranslatedPromotionWeek = {};
  Object.keys(translatedPromotionWeek).forEach((column) => {
    nonNulledTranslatedPromotionWeek[column] = translatedPromotionWeek[column] || '';
  });

  return nonNulledTranslatedPromotionWeek;
};

const parseDates = (promotionWeek) => {
  const { startDate, endDate } = promotionWeek;

  return {
    startDate_endDate: `${startDate}_${endDate}`,
  };
};

export const localToAppsync = (promotionWeek) => {
  const translatedPromotionWeek = {
    ...promotionWeek,
    ...parseDates(promotionWeek),
  };

  delete translatedPromotionWeek.startDate;
  delete translatedPromotionWeek.endDate;

  return translatedPromotionWeek;
};
