import { connect } from 'react-redux';
import PromoImageReviewActionBar from './PromoImageReviewActionBar';
import { Language } from '../../../constants';
import {
  onApprovePromotionImage,
} from '../../../actionCreators';

const mapDispatchToProps = (dispatch) => {
  return {
    onApprove(id) {
      if (window.confirm(Language.PROMOTION_IMAGE_REVIEW_APPROVE)) {
        dispatch(onApprovePromotionImage(id));
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(PromoImageReviewActionBar);
