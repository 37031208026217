import { connect } from 'react-redux';
import ExportButton from './ExportButton';
import { onOpenExportModal } from '../../actionCreators';

const mapDispatchToProps = (dispatch, { viewType }) => {
  return {
    onExport() {
      dispatch(onOpenExportModal(viewType));
    },
  };
};

export default connect(null, mapDispatchToProps)(ExportButton);
