import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import { onUpdatePromotion } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { GridViewPage } = getState();
  const { payload: { promotion } } = action;
  delete promotion.modificationTimestamp;
  delete promotion.modificationType;
  delete promotion.username;
  const currentPromotion = getPromotionById(GridViewPage, promotion.id);
  promotion.version = currentPromotion.version;
  promotion.status = currentPromotion.status;
  dispatch(onUpdatePromotion(promotion, { isRollBack: true }));
  return next(action);
};
