import getPromotionById from './getPromotionById';

const selectCell = (state, action) => {
  const { payload: activeCell } = action;

  if (!activeCell || !Object.keys(activeCell).length) {
    return {
      ...state,
      activeCell: null,
      activeCellValue: null,
    }
  }

  const { rowId, columnName } = activeCell;

  const cellData = getPromotionById(state, rowId);
  const activeCellValue = cellData ? cellData[columnName] : null;

  const newActiveCell = cellData
    ? {
      ...activeCell,
      isEditMode: false,
    } : null;

  return {
    ...state,
    activeCell: newActiveCell,
    activeCellValue,
  };
};

export default selectCell;
