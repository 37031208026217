import React from 'react';
import PropTypes from 'prop-types';

import styles from '../MetadataAuditLog.module.scss';

const DependencyDisplay = ({ dependencies }) => {
  const dependencyList = dependencies.map((dependency, i) => {
    const isLast = i === dependencies.length - 1;
    const className = isLast ? styles.last : null;
    return (
      <span key={dependency.field}>
        {`${dependency.field}: `}
        <span className={className}>
          {dependency.option}
        </span>
        {!isLast && ', '}
      </span>
    );
  });
  return (
    <div>{dependencyList}</div>
  );
};

DependencyDisplay.propTypes = {
  dependencies: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    option: PropTypes.string,
  })).isRequired,
};

export default DependencyDisplay;
