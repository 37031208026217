import { UserActions, UserViewMode } from '../../constants/user';

export const initialState = {
  currentUser: null,
  cannotAuthenticate: false,
  viewMode: UserViewMode.USER.name,
  editHistory: {},
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case UserActions.SET_USER: {
      const { payload: { user } } = action;

      return {
        ...state,
        currentUser: user,
      };
    }
    case UserActions.SET_USER_UNABLE_TO_SIGN_IN: {
      return {
        ...state,
        cannotAuthenticate: true,
      };
    }
    case UserActions.SWITCH_VIEW_MODE: {
      const { payload: { viewMode } } = action;

      return {
        ...state,
        viewMode,
      };
    }
    case UserActions.PUSH_EDIT_HISTORY: {
      const {
        payload: {
          rowId,
          versionBeforeUpdate,
          startDate,
          endDate,
        },
      } = action;
      const promoWeekHistory = [];
      if (state.editHistory[startDate + endDate]) {
        promoWeekHistory.push(...state.editHistory[startDate + endDate]);
      }
      promoWeekHistory.push({ id: rowId, versionBeforeUpdate });
      return {
        ...state,
        editHistory: {
          ...state.editHistory,
          [startDate + endDate]: promoWeekHistory,
        },
      };
    }
    case UserActions.POP_EDIT_HISTORY: {
      const { payload: { startDate, endDate } } = action;
      const promoWeekHistory = state.editHistory[startDate + endDate];
      const editHistoryExists = promoWeekHistory && promoWeekHistory.length > 0;
      if (editHistoryExists) {
        promoWeekHistory.pop();
      }
      return {
        ...state,
        editHistory: {
          ...state.editHistory,
          [startDate + endDate]: editHistoryExists ? promoWeekHistory : null,
        },
      };
    }
    default: {
      return state;
    }
  }
};
