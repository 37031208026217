import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../GridViewCell/GridViewCell.module.scss';
import { styledInputProps } from '../../../proptypes';

const Input = ({
  onSubmit,
  inputValue,
  onInputChange,
  onBlur,
  isFocusDisabled,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <input
        value={inputValue}
        type="time"
        onChange={onInputChange}
        className={styles.activeInput}
        ref={(input) => {
          if (!isFocusDisabled) {
            return setTimeout(() => { return input && input.focus(); }, 0);
          }
          return null;
        }}
        onBlur={onBlur}
      />
    </form>
  );
};

Input.propTypes = {
  ...styledInputProps,
  isFocusDisabled: PropTypes.bool,
};

Input.defaultProps = {
  isFocusDisabled: false,
};

export default Input;
