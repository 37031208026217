import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import Tile from '@amzn/meridian/tile';
import TileActionBar from '@amzn/meridian/tile/tile-action-bar';
import { useHistory } from 'react-router-dom';
import { CalendarConfig, Urls } from '../../../constants';

const PromotionWeek = (props) => {
  const history = useHistory();

  const {
    start,
    end,
    clipStart,
    clipEnd,
    children,
    onDateRangeChange,
    onDelete,
    isAdmin,
  } = props;

  const getActionsList = () => {
    const actions = [
      {
        label: 'Show',
        onClick: () => {
          onDateRangeChange();
        },
      },
    ];
    const adminActions = [
      {
        label: 'Update',
        onClick: () => {
          const startDate = moment(start).format('YYYY-MM-DD');
          const endDate = moment(end).format('YYYY-MM-DD');

          history.push(`${Urls.WEEK_EDIT}/${startDate}/${endDate}`);
        },
      },
      {
        label: 'Delete',
        onClick: () => {
          if (window.confirm('Are you sure you want to delete this Promotion Week and all of its promotions? This action cannot be undone.')) { // eslint-disable-line max-len
            onDelete();
          }
        },
      },
    ];

    if (isAdmin) {
      adminActions.forEach((adminAction) => {
        actions.push(adminAction);
      });
    }

    return actions;
  };

  const actionsList = getActionsList();

  return (
    <Tile
      clipEnd={clipEnd}
      clipStart={clipStart}
      accent={CalendarConfig.ACCENT_COLOR}
      accentPosition="start"
      actionBar={<TileActionBar actions={actionsList} />}
    >
      {children}
    </Tile>
  );
};

PromotionWeek.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  clipStart: PropTypes.bool,
  clipEnd: PropTypes.bool,
};
PromotionWeek.defaultProps = {
  clipStart: false,
  clipEnd: false,
};

export default React.memo(PromotionWeek);
