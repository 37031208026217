import React from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';
import Alert from '@amzn/meridian/alert';
import Heading from '@amzn/meridian/heading';
import Main from './Sections/Main';
import SubmitButtons from './Sections/SubmitButtons/SubmitButtons';
import AsinValidatorModal from '../AsinValidatorModal';
import { Language } from '../../constants';
import sitewideStyles from '../../styles/sitewide.module.scss';
import styles from './PromoForm.module.scss';
import { columnsProps } from '../../proptypes';
import history from '../../history';

const PromoForm = (props) => {
  const {
    onCreate,
    onClone,
    onUpdate,
    handleSubmit,
    submitting,
    isAdmin,
    isNew,
    isClone,
    isLoading,
    isNotFound,
    isSoftDeleted,
    status,
    columns,
  } = props;

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Loader type="circular" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className={styles.wrapper}>
        <div className={sitewideStyles.centerWrapper}>
          <Alert>{Language.PROMOTION_FORM_NOT_FOUND}</Alert>
        </div>
      </div>
    );
  }

  const onModalClose = () => history.goBack();

  const submitPromotion = (data) => {
    if (isNew) {
      onCreate(data);
    } else if (isClone) {
      onClone(data);
    } else {
      onUpdate(data);
    }
  };

  const processSubmit = (data) => {
    submitPromotion(data);

    onModalClose();
  };

  return (
    <div className={styles.wrapper}>
      {
        isClone && 
        <Heading level={3} alignment='center'>
          Clone Promotion
        </Heading>
      }
      <div className={styles.wrapperInner}>
        <AsinValidatorModal />
        <form
          className={styles.form}
          onSubmit={handleSubmit(processSubmit)}
        >
          <div className={styles.formInnerContainer}>
            <Main columns={columns} />
            <SubmitButtons submitting={submitting} status={status} isAdmin={isAdmin} isSoftDeleted={isSoftDeleted} />
          </div>
        </form>
      </div>
    </div>
  );
};

PromoForm.propTypes = {
  isAdmin: PropTypes.bool,
  isClone: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  isNotFound: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  ...columnsProps,
};

PromoForm.defaultProps = {
  isNew: false,
  isClone: false,
};

export default PromoForm;
