import PropTypes from 'prop-types';
import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import undoTokens from '@amzn/meridian-tokens/base/icon/undo';
import Tooltip from '@amzn/meridian/tooltip';
import { Language } from '../../../constants';

const AuditLogActionBar = ({ onRollBack, disabled }) => {
  return (
    <Tooltip position="top" title={Language.ACTION_BUTTON_ROLLBACK}>
      <Button
        size="small"
        onClick={onRollBack}
        type="tertiary"
        disabled={disabled}
      >
        <Icon tokens={undoTokens} />
      </Button>
    </Tooltip>
  );
};

AuditLogActionBar.propTypes = {
  onRollBack: PropTypes.func.isRequired,
};

export default AuditLogActionBar;
