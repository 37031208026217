import { connect } from 'react-redux';
import EditPromotionPage from './edit-promotion';
import getPromotionById from '../../../../reducers/gridViewPage/getPromotionById';
import { areAllPromotionsLoaded } from '../../../../selectors/loadingStatus';
import { createPromotionWeekRoute } from '../../../../helpers/router';
import history from '../../../../history';
import { onUnselectCell } from '../../../../actionCreators';

const goBack = (promotion) => () => {
  if (document.referrer || !promotion) {
    window.history.back();

    return;
  }

  const { startDate, endDate } = promotion;

  history.push(createPromotionWeekRoute(startDate, endDate));
};

export const mapStateToProps = (state, props) => {
  const { match: { params: { id } }, isClone = false } = props;

  const promotion = getPromotionById(state.GridViewPage, id);
  const isLoading = !areAllPromotionsLoaded(state);

  return {
    isLoading,
    isFound: !isLoading && promotion,
    isClone,
    loadedPromotion: promotion,
    goBack,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    unSelectActiveCell() {
      dispatch(onUnselectCell());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPromotionPage);
