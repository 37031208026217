import { AsinImageActions } from '../../constants';
import getProductImages from './getProductImages';
import setProductImages from './setProductImages';
import imageSelect from './imageSelect';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === AsinImageActions.GET_PRODUCT_IMAGES) {
    return getProductImages(dispatch, getState, action, next);
  }

  if (type === AsinImageActions.SET_PRODUCT_IMAGES) {
    return setProductImages(dispatch, getState, action, next);
  }

  if (type === AsinImageActions.SELECT_IMAGE) {
    return imageSelect(dispatch, getState, action, next);
  }

  return next(action);
};
