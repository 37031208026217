import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import AppLayout from '@amzn/meridian/app-layout';
import useSubscription from '../../customHooks/useSubscription';
// import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import Header from '../Header';
import SideMenu from '../SideMenu';
import MetadataEditor from '../routes/metadata';
import {
  onCreatePromotion,
  onUpdatePromotion,
  onDeletePromotion,
  onCreatePromotionWeek,
  onUpdatePromotionWeek,
  onDeletePromotionWeek,
  onApprovePromotionChange,
  onRejectPromotionChange,
} from '../../graphql/subscriptions';
import history from '../../history';
import { Urls, Colors } from '../../constants';
import ToastManager from '../ToastManager';
import Loader from '../Loader';
import CustomViewModal from '../CustomViewModal';
import PromoAuditLogModal from '../PromoAuditLogModal';
import PromoWeekModal from '../PromoWeekModal';
import NewUserExperience from '../NewUserExperience';
import AuditLogPage from '../routes/auditLog';
import MetadataAuditLogPage from '../routes/metadataAuditLog';
import WebsiteContainerPage from '../routes/website-container';
import TemplatesPage from '../routes/templates';
import NewTemplatePage from '../routes/modals/new-template';
import EditTemplatePage from '../routes/modals/edit-template';
import AsinViabilityDashboardPage from '../routes/asin-dashboard';
import PromoStatusModal from '../PromoStatusModal';
import ConnectionTimeoutModal from '../ConnectionTimeoutModal';

import '../../metrics/performanceObserver';

const App = ({
  onPageInit,
  onSubscriptionReceivedAddedPromotion,
  onSubscriptionReceivedUpdatedPromotion,
  onSubscriptionReceivedDeletedPromotion,
  onSubscriptionReceivedAddedPromotionWeek,
  onSubscriptionReceivedUpdatedPromotionWeek,
  onSubscriptionReceivedDeletedPromotionWeek,
  onThirdPartyIFrameEvent,
  onConnectionError,
}) => {
  useEffect(() => {
    onPageInit();
  }, [onPageInit]);

  useEffect(() => {
    window.addEventListener('message', onThirdPartyIFrameEvent, false);

    return () => {
      window.removeEventListener('message', onThirdPartyIFrameEvent);
    };
  });

  const initSubscriptions = () => {
    [
      [
        onCreatePromotion,
        onSubscriptionReceivedAddedPromotion,
      ],
      [
        onUpdatePromotion,
        onSubscriptionReceivedUpdatedPromotion,
      ],
      [
        onApprovePromotionChange,
        onSubscriptionReceivedUpdatedPromotion,
      ],
      [
        onRejectPromotionChange,
        onSubscriptionReceivedUpdatedPromotion,
      ],
      [
        onDeletePromotion,
        onSubscriptionReceivedDeletedPromotion,
      ],
      [
        onCreatePromotionWeek,
        onSubscriptionReceivedAddedPromotionWeek,
      ],
      [
        onUpdatePromotionWeek,
        onSubscriptionReceivedUpdatedPromotionWeek,
      ],
      [
        onDeletePromotionWeek,
        onSubscriptionReceivedDeletedPromotionWeek,
      ],
    ].forEach(([graphQlMethod, onNext]) => {
      useSubscription(graphQlMethod, onNext, onConnectionError);
    });
  };

  initSubscriptions();

  return (
    <ConnectedRouter history={history}>
      <AppLayout
        headerComponent={Header}
        sidebarComponent={SideMenu}
        backgroundColor={Colors.ALTERNATE_SECONDARY}
      >
        <ConnectionTimeoutModal />
        <Header />
        <SideMenu />
        <ToastManager />
        <Loader />
        <NewUserExperience />
        <CustomViewModal />
        <PromoAuditLogModal />
        <PromoWeekModal />
        <PromoStatusModal />
        <Switch>
          <Redirect from="/" exact to={Urls.CALENDAR} />
          <Redirect from={Urls.DEPRECATED_PROMOTIONS} exact to={Urls.CALENDAR} />
          <Route path={`${Urls.AUDIT_LOG}/:id`} component={AuditLogPage} />
          <Route path={Urls.METADATA_AUDIT_LOG} component={MetadataAuditLogPage} />
          <Route path={Urls.METADATA_EDITOR} component={MetadataEditor} />
          <Route path={Urls.CALENDAR} component={WebsiteContainerPage} />
          <Route path={Urls.PUBLISHED_PROMOTION_LIST} component={WebsiteContainerPage} />
          <Route path={Urls.PROMOTION_WEEK} component={WebsiteContainerPage} />
          <Route path={Urls.WEEK_NEW} component={WebsiteContainerPage} />
          <Route path={Urls.TEMPLATE_NEW} component={NewTemplatePage} />
          <Route path={`${Urls.WEEK_EDIT}/:startDate/:endDate`} component={WebsiteContainerPage} />
          <Route path={`${Urls.WEEK_PUBLISHED}/:startDate/:endDate`} component={WebsiteContainerPage} />
          <Route path={`${Urls.PROMOTION_EDIT}/:id`} component={WebsiteContainerPage} />
          <Route path={`${Urls.PROMOTION_CLONE}/:id`} component={WebsiteContainerPage} />
          <Route path={`${Urls.TEMPLATE_EDIT}/:id`} component={EditTemplatePage} />
          <Route path={Urls.TEMPLATES} component={TemplatesPage} />
          <Route path={Urls.ASIN_DASHBOARD} component={AsinViabilityDashboardPage} />
          <Route path={Urls.ERROR} render={() => <h2>The requested URL is not available</h2>} />
          <Route render={() => (<div>The requested URL is not found</div>)} />
        </Switch>
      </AppLayout>
    </ConnectedRouter>
  );
};

App.propTypes = {
  onPageInit: PropTypes.func.isRequired,
};

export default App;
