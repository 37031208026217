import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../../PromoWeekForm.module.scss';
import FormField from '../../../PromoForm/FormField';

const Main = ({
  columnsList, templateOptions, isNew, selectedTemplateName,
}) => {
  const getNewView = () => {
    return (
      <>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.NAME}
            />
          </div>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.TEMPLATE_ID}
              overrideOptions={templateOptions}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.START_DATE}
            />
          </div>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.END_DATE}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.EDIT_DEADLINE}
            />
          </div>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.REVIEW_DEADLINE}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...columnsList.PRINT_DEADLINE}
            />
          </div>
        </div>
      </>
    );
  };

  const getEditView = () => {
    return (
      <>
        <div className={styles.formGroupSection}>
          <div className={classnames([styles.formControl, styles.fullSizeFormControl])}>
            Week name:
            <FormField
              {...columnsList.NAME}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            Edit Deadline:
            <FormField
              {...columnsList.EDIT_DEADLINE}
            />
          </div>
          <div className={styles.formControl}>
            Review Deadline:
            <FormField
              {...columnsList.REVIEW_DEADLINE}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            Print Deadline:
            <FormField
              {...columnsList.PRINT_DEADLINE}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames([styles.formControl, styles.fullSizeFormControl])}>
            Template: <br /> <strong>{selectedTemplateName}</strong>
          </div>
        </div>
      </>
    );
  };

  const formView = isNew ? getNewView() : getEditView();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          {formView}
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  isNew: PropTypes.bool,
  selectedTemplateName: PropTypes.string,
};
Main.defaultProps = {
  selectedTemplateName: '',
  isNew: false,
};

export default Main;
