import {
  PerformanceTimerNames,
} from '../../constants';
import {
  onPerformanceTimerStart,
  onPerformanceTimerStop,
} from '../../actionCreators';
import { onBulkImportReset, onBulkImportStartFileParsing } from '../../actionCreators/bulkImport';
import performImport from './performImport';
import processBulkImportData from './processBulkImportData';
import actionCompleted from './actionCompleted';
import { BulkImportActions } from '../../constants/bulkImport';

export default ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  const { type } = action;
  if (type === BulkImportActions.PROCESS_FILE) {
    dispatch(onBulkImportStartFileParsing());

    const { Meta: { metadata: { columnOrder } } } = state;

    setTimeout(() => {
      processBulkImportData(dispatch, state, action, columnOrder);
    }, 10);
  }
  if (type === BulkImportActions.PERFORM_IMPORT) {
    performImport(dispatch, state);
  }
  if (type === BulkImportActions.ACTION_COMPLETED) {
    actionCompleted(dispatch, getState, action);
  }
  if (type === BulkImportActions.CLOSE_MODAL) {
    dispatch(onBulkImportReset());
  }
  if (type === BulkImportActions.START_IMPORTING) {
    dispatch(onPerformanceTimerStart(PerformanceTimerNames.BULK_IMPORT));
  }
  if (type === BulkImportActions.COMPLETE_IMPORTING) {
    dispatch(onPerformanceTimerStop(PerformanceTimerNames.BULK_IMPORT));
  }

  return next(action);
};
