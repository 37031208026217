import {
  PromotionWeekTabsList,
  PromotionTabsList,
  TemplateBuilderTabsList,
  WebsiteNavigationTabList,
  TabsActions,
  TabTypes,
} from '../../constants';

export const initialState = {
  [TabTypes.PROMOTION_WEEK]: PromotionWeekTabsList.WEEK.name,
  [TabTypes.PROMOTION]: PromotionTabsList.PROMOTION.name,
  [TabTypes.TEMPLATE_BUILDER]: TemplateBuilderTabsList.TEMPLATE_BUILDER.name,
  [TabTypes.WEBSITE_NAVIGATION]: WebsiteNavigationTabList.CALENDAR.name,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case TabsActions.SET_TAB: {
      const { tabName, tabType } = payload;

      return {
        ...state,
        [tabType]: tabName,
      };
    }
    case TabsActions.RESET_TAB: {
      const { tabType } = payload;
      return {
        ...state,
        [tabType]: initialState[tabType],
      };
    }
    case TabsActions.RESET_TABS: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};
