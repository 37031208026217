import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './PromotionContainer.module.scss';
import AuditLogPage from '../AuditLogPage';
import PromoForm from '../PromoForm';
import Tabs from '../Tabs';
import {
  PromotionTabsList,
  AuditLogTypes,
} from '../../constants';

const PromotionContainer = (props) => {
  const {
    isClone,
    promotion,
    currentTab,
    onSwitchTab,
    onSetDefaultTab,
  } = props;

  useEffect(() => () => onSetDefaultTab(), []);

  const getAuditLog = () => {
    if (!promotion) {
      return null;
    }

    return (
      <AuditLogPage
        entity={promotion}
        logType={AuditLogTypes.PROMOTION}
        showRollback
      />
    );
  };

  const getPromotionForm = () => {
    return (
      <PromoForm
        isNew={!promotion}
        isClone={isClone}
        loadedPromotion={promotion}
      />
    );
  };

  const getComponentByTab = () => {
    switch (currentTab) {
      case PromotionTabsList.PROMOTION.name:
        return getPromotionForm();
      case PromotionTabsList.AUDIT_LOG.name:
        return getAuditLog();
      default:
        return getPromotionForm();
    }
  };

  const getTabs = () => {
    if (!promotion) {
      return null;
    }
    const tabsList = Object.values(PromotionTabsList);

    return (
      <Tabs
        onSwitchTab={onSwitchTab}
        currentTab={currentTab}
        tabs={tabsList}
      />
    );
  };

  const tabs = getTabs();
  const component = getComponentByTab();

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          {tabs}
          <div className={styles.scrollableContent}>
            {component}
          </div>
        </div>
      </div>
    </>
  );
};

PromotionContainer.propTypes = {
  isClone: PropTypes.bool,
  currentTab: PropTypes.string.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
  onSetDefaultTab: PropTypes.func.isRequired,
};

PromotionContainer.defaultProps = {
  isClone: false,
};

export default PromotionContainer;
