import { SELECT_YEAR_QUARTER } from '../../constants';
import { setSelectedYearQuarter } from '../../helpers/yearQuarterSelector';
import {
  onLoadWeeksForYearQuarters,
  onSetPromotionsLoaded,
} from '../../actionCreators';
import { isYearQuarterLoaded } from '../../selectors/loadingStatus';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;
  const state = getState();
  if (type === SELECT_YEAR_QUARTER) {
    const { data: { yearQuarter } } = action;

    setSelectedYearQuarter(yearQuarter);

    next(action);

    if (!isYearQuarterLoaded(state, yearQuarter)) {
      dispatch(onSetPromotionsLoaded(false));
      dispatch(onLoadWeeksForYearQuarters([yearQuarter]));
    }
  }

  return next(action);
};
