import { PromotionStatuses } from '../constants';
import { parseGraphQLResponse } from './api';

const getLastApprovedRevision = (auditLog) => {
  if (!auditLog) {
    return null;
  }

  for (let i = auditLog.length; i >= 0; i--) {
    if (!auditLog[i] || !auditLog[i].promotion) {
      continue;
    }
    const { promotion: { status } } = auditLog[i];

    if (PromotionStatuses.EDIT_LOCKED.value === status || PromotionStatuses.REVIEW_LOCKED.value === status) {
      return auditLog[i].promotion;
    }
  }

  return {};
};

export const translateAuditLogsForHighlight = (rawAuditLogs) => (
  rawAuditLogs.reduce((logs, rawAuditLog) => {
    const promotionAuditLog = parseGraphQLResponse(rawAuditLog);
    if (!promotionAuditLog || !promotionAuditLog.length) {
      return logs;
    }
    const [firstAuditLogEntry] = promotionAuditLog;
    const { promotion: { id } } = firstAuditLogEntry;

    return {
      ...logs,
      [id]: getLastApprovedRevision(promotionAuditLog),
    };
  }, {})
);
