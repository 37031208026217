import React, { forwardRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Input from '@amzn/meridian/input';
import PropTypes from 'prop-types';
import { campaignInputProps } from '../../proptypes';
import styles from '../GridViewCell/GridViewCell.module.scss';
import useDebounce from '../../customHooks/useDebounce';
import { DEBOUNCE_WAIT_TIME } from '../../constants/ui';

const GridViewHeaderCell = forwardRef(
  ({
    columnName,
    children,
    existingSearchTerm,
    isSticky,
    left,
    showSearch,
    onSearch,
    isAdmin,
    applyMustFillHighlight,
  }, ref) => {
    const [searchTerm, setSearchTerm] = useState(existingSearchTerm);

    useEffect(() => {
      if (showSearch) {
        setSearchTerm(existingSearchTerm);
      }
    }, [existingSearchTerm]);

    const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_WAIT_TIME);

    useEffect(() => {
      if (showSearch && debouncedSearchTerm !== existingSearchTerm) {
        onSearch(debouncedSearchTerm);
      }
    }, [debouncedSearchTerm]);

    const onSearchChange = (value) => setSearchTerm(value);

    const getCellContainerStyles = () => {
      const customColumnWidthStyles = {
        [styles.defaultColumnWidth]: true,
        [styles.adminColumnWidth]: isAdmin,
        [styles.customerColumnWidth]: !isAdmin,
        [styles[`${columnName}ColumnWidth`]]: styles[`${columnName}ColumnWidth`],
      };

      return {
        [styles.cellHeaderContainer]: true,
        [styles.sticky]: isSticky,
        [styles.mustFillHighlightHeaderCell]: applyMustFillHighlight,
        ...customColumnWidthStyles,
      };
    };

    const getCellProperties = () => {
      const cellProperties = {
        className: classnames(getCellContainerStyles()),
      };
      if (left > 0) {
        cellProperties.style = {
          left: `${left}px`,
        };
      }
      if (ref) {
        cellProperties.ref = ref;
      }

      return cellProperties;
    };

    const getColumnSearch = () => {
      if (!showSearch) {
        return null;
      }

      return (
        <div className={styles.columnSearch}>
          <Input
            size="small"
            placeholder="Search"
            onChange={onSearchChange}
            value={searchTerm}
          />
        </div>
      );
    };

    const cellProperties = getCellProperties();
    const columnSearch = getColumnSearch();
    const getChildren = () => {
      const mustFillAsterisk = applyMustFillHighlight
        ? <span className={styles.mustFillAsterisk}>*</span>
        : null;
      return (
        <div>
          {children}
          {mustFillAsterisk}
        </div>
      );
    };

    return (
      <th {...cellProperties}>
        <div className={styles.cell}>
          <span className={styles.columnSearch}>{getChildren()}</span>
          {columnSearch}
        </div>
      </th>
    );
  },
);

GridViewHeaderCell.propTypes = {
  columnName: PropTypes.string.isRequired,
  children: campaignInputProps.isRequired,
  isSticky: PropTypes.bool,
  isAdmin: PropTypes.bool,
  showSearch: PropTypes.bool,
  onSearch: PropTypes.func,
  existingSearchTerm: PropTypes.string,
};

GridViewHeaderCell.defaultProps = {
  isSticky: false,
  isAdmin: false,
  showSearch: false,
  existingSearchTerm: '',
  onSearch: _.noop,
};

export default GridViewHeaderCell;
