import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { Columns, Form } from '../constants';

const selector = formValueSelector(Form.PROMO_WEEK);

export const getTemplates = (state) => state.Templates.templates;
export const getSelectedTemplateId = (state) => selector(state, Columns.TEMPLATE_ID.name);

export const getSelectedTemplate = createSelector(
  [getTemplates, getSelectedTemplateId],
  (templates, selectedTemplateId) => {
    return templates.find((template) => template.id === selectedTemplateId);
  },
);
