import { API, graphqlOperation } from 'aws-amplify';
import {
  onAsinValidatorSetAsinViabilities,
  onAsinValidatorSetLoading,
  onAsinValidatorAutoSelectAvailableRegions,
  onAsinValidatorAutoSelectRegionFilters,
} from '../../actionCreators';
import * as queries from '../../graphql/queries';

export default (dispatch, getState, action, next) => {
  const { payload: { asins, startDate } } = action;

  if (!asins || !asins.length || !startDate) {
    return next(action);
  }

  const params = {
    asins: asins.map((asin) => asin.trim()),
    startDate,
  };

  const onResponse = (response) => {
    const { data: { getAsinViability: regionalizedAsinViability } } = response;

    dispatch(onAsinValidatorSetAsinViabilities(regionalizedAsinViability));
    dispatch(onAsinValidatorAutoSelectAvailableRegions());
    dispatch(onAsinValidatorAutoSelectRegionFilters());
  };

  dispatch(onAsinValidatorSetLoading(true));

  API
    .graphql(graphqlOperation(queries.getAsinViability, { input: params }))
    .then(onResponse)
    .finally(() => {
      dispatch(onAsinValidatorSetLoading(false));
    })
    .catch((error) => console.error(error));

  return next(action);
};
