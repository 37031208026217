import React from 'react';
import TreeSelectControl from '../Controls/TreeSelectControl';

export default (props) => {
  const {
    inputValue,
    onInputChange,
    onCommitChanges,
    onBlur,
  } = props;

  const newProps = {
    ...props,
    input: {
      name: 'genericTreeSelect',
      value: inputValue,
      onChange: (name, value) => onInputChange(value),
      onBlur: () => {
        onCommitChanges();
        onBlur();
      },
    },
  };

  return <TreeSelectControl {...newProps} />;
};
