import { createPortal } from 'react-dom';
import { useEffect } from 'react';

const Portal = ({ children }) => {
  const mount = document.getElementById('root');
  const portal = document.createElement('div');
  useEffect(() => {
    mount.appendChild(portal);
    return () => {
      mount.removeChild(portal);
    };
  }, [portal, mount]);
  return createPortal(children, portal);
};

export default Portal;
