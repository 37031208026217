/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HistoryTimingWrapper = (props) => {
  const { children, onComponentLoaded, pageName } = props;

  useEffect(() => {
    onComponentLoaded(pageName);
  }, [pageName]);
  return (
    <>
      { children }
    </>
  );
};

HistoryTimingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onComponentLoaded: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
};

export default HistoryTimingWrapper;
