import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '@amzn/meridian/toggle';
import styles from './UserModeViewSwitcher.module.scss';
import { UserViewMode } from '../../constants/user';
import { isAdminViewMode } from '../../helpers/user';

const UserModeViewSwitcher = ({ viewMode, isUserAdmin, onViewModeSwitch }) => {
  if (!isUserAdmin) {
    return null;
  }

  const isAdminView = isAdminViewMode(viewMode);

  const onChange = () => {
    onViewModeSwitch((
      isAdminView ? UserViewMode.USER.name : UserViewMode.ADMIN.name
    ));
  };

  const viewModeDescriptionText = isAdminView
    ? UserViewMode.ADMIN.label
    : UserViewMode.USER.label;

  return (
    <div className={styles.container}>
      <Toggle
        checked={isAdminView}
        onChange={onChange}
      />
      <span className={styles.descriptionText}>
        {viewModeDescriptionText}
      </span>
    </div>
  );
};

UserModeViewSwitcher.propTypes = {
  viewMode: PropTypes.string.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
  onViewModeSwitch: PropTypes.func.isRequired,
};

export default UserModeViewSwitcher;
