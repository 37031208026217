import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import RadioButton from '@amzn/meridian/radio-button';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import AsinValidatorTable from './AsinValidatorTable';
import { AsinValidatorViewMode } from '../../constants';
import styles from './AsinValidatorModal.module.scss';

const AsinValidatorModal = (props) => {
  const {
    errors,
    asinViabilities,
    viewMode,
    isOpen,
    onClose,
    onSubmit,
    onChangeViewMode,
    onExportResults,
  } = props;

  if (!isOpen) {
    return null;
  }

  if (!asinViabilities || !asinViabilities.length) {
    return null;
  }

  const onSwitchViewMode = (viewMode) => () => onChangeViewMode(viewMode);

  const getViewChangeModeButtons = () => {
    const buttonsList = [
      AsinValidatorViewMode.COMPACT,
      AsinValidatorViewMode.FULL,
    ].map(({ name, label }) => {
      return (
        <RadioButton
          key={name}
          checked={viewMode === name}
          onChange={onSwitchViewMode(name)}
          name="viewMode"
          value={name}
        >
          {label}
        </RadioButton>
      );
    });

    return (
      <div className={styles.buttonsContainer}>
        <span>View mode:</span>
        <div className={styles.buttons}>
          {buttonsList}
        </div>
      </div>
    );
  };

  const getErrors = () => {
    if (!errors || !errors.length) {
      return null;
    }

    const errorsList = errors.map((error) => {
      return (
        <li key={error} className={styles.item}>
          <Alert
            size="small"
            type="error"
          >
            {error}
          </Alert>
        </li>
      );
    });

    return (
      <ul className={styles.list}>
        {errorsList}
      </ul>
    );
  };

  const getControlButtons = () => ([
    {
      label: 'Confirm Regions',
      onClick: onSubmit,
      type: 'primary',
      size: 'small',
    },
  ].map(({ label, ...restAttributes }) => (
    <Button
      key={label}
      {...restAttributes}
    >
      {label}
    </Button>
  )));

  const getFooter = () => {
    const mainControlButtons = getControlButtons();

    return (
      <ModalFooter>
        <div className={styles.controlsContainer}>
          <Row alignment="center left" widths="fit">
            <Button
              type="tertiary"
              size="small"
              onClick={onExportResults}
            >
              Export
            </Button>
          </Row>
          <Row alignment="center right" widths="fit">
            {mainControlButtons}
          </Row>
        </div>
      </ModalFooter>
    );
  };

  const getTable = () => {
    return <AsinValidatorTable />;
  };

  const viewModeSwitchButtons = getViewChangeModeButtons();
  const errorsList = getErrors();
  const content = getTable();
  const footer = getFooter();

  return (
    <Modal open={isOpen} onClose={onClose} scrollContainer="modal">
      <div className={styles.wrapper}>
        {viewModeSwitchButtons}
        {content}
      </div>
      {errorsList}
      {footer}
    </Modal>
  );
};

AsinValidatorModal.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  viewMode: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeViewMode: PropTypes.func.isRequired,
  onExportResults: PropTypes.func.isRequired,
};

AsinValidatorModal.defaultProps = {
  errors: [],
};

export default AsinValidatorModal;
