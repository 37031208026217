import { LoadingActions } from '../../constants';
import {
  onHideLoadingSpinner,
  onSetPromotionsLoaded,
  onSetPromotionsLoading,
} from '../../actionCreators';
import { submitTimerMetric } from '../../metrics/index';
import { MetricNames } from '../../constants/metrics';
import { areAllPromotionsLoaded } from '../../selectors/loadingStatus';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;
  if (type === LoadingActions.CHECK_LOADING_STATUS) {
    const state = getState();
    if (areAllPromotionsLoaded(state)) {
      dispatch(onSetPromotionsLoading(false));
      dispatch(onHideLoadingSpinner());
      submitTimerMetric(MetricNames.LAST_PROMOTION_LOADED, Math.floor(window.performance.now()));
      dispatch(onSetPromotionsLoaded(true));
    }
  }
  return next(action);
}