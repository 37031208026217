import React from 'react';
import PropTypes from 'prop-types';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';

const ToastManager = ({ toasts, onCloseToast }) => {
  return (
    <Toaster toasts={toasts} onCloseToast={onCloseToast}>
      {(toast) => <Alert toast onClose={toast.onClose}>{toast.message}</Alert>}
    </Toaster>
  );
};

ToastManager.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    timeout: PropTypes.number,
  })).isRequired,
  onCloseToast: PropTypes.func.isRequired,
};

export default ToastManager;
