import { onAsinValidatorReset } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { payload: { isOpen } } = action;

  if (typeof isOpen !== 'undefined' && !isOpen) {
    dispatch(onAsinValidatorReset());
  }

  return next(action);
};
