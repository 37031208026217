import { connect } from 'react-redux';
import AsinViabilityDashboard from './AsinViabilityDashboard';

const getAsinViabilitiesData = (asinViabilities) => {
  const storeRegionList = new Set();
  const asinViabilitiesByStoreRegion = {};

  asinViabilities.forEach((asinViability) => {
    const { asin, viability } = asinViability;
    viability.forEach(({ storeRegions }) => {
      if (!storeRegions || !storeRegions.length) {
        return;
      }
      storeRegions.forEach((storeRegion) => {
        const { storeRegionName } = storeRegion;
        storeRegionList.add(storeRegionName);

        if (!asinViabilitiesByStoreRegion[asin]) {
          asinViabilitiesByStoreRegion[asin] = {};
        }
        if (!asinViabilitiesByStoreRegion[asin][storeRegionName]) {
          asinViabilitiesByStoreRegion[asin][storeRegionName] = storeRegion;
        }
      });
    });
  });

  const sortedStoreRegions = Array.from(storeRegionList).sort();

  return [
    sortedStoreRegions,
    asinViabilitiesByStoreRegion,
  ];
};

const getStoreDetails = (asinViabilities, asin, storeRegion) => {
  const asinViability = asinViabilities.find((asinViability) => asinViability.asin === asin);
  if (!asinViability) {
    return null;
  }
  const { viability } = asinViability;

  const currentViability = viability.find(({ regionName }) => {
    return regionName === storeRegion.substr(0, 2);
  });
  if (!currentViability) {
    return null;
  }

  const { storeRegions } = currentViability;
  const storeDetails = storeRegions.find(({ storeRegionName }) => storeRegionName === storeRegion);

  if (!storeDetails) {
    return null;
  }

  return storeDetails;
};

const getApprovedAsins = (storeRegionList, asinViabilitiesByStoreRegion) => {
  const approvedAsins = {};
  Object.entries(asinViabilitiesByStoreRegion).forEach(([asin, storeRegions]) => {
    let availableStoreRegionsCount = 0;
    storeRegionList.forEach((storeRegion) => {
      if (storeRegions[storeRegion]
        && !storeRegions[storeRegion].unavailableAsinViabilities.length) {
        availableStoreRegionsCount++;
      }
    });
    if (Object.keys(storeRegions).length === availableStoreRegionsCount) {
      approvedAsins[asin] = asinViabilitiesByStoreRegion[asin];
    }
  });

  return approvedAsins;
};

const getPartiallyApprovedAsinsWithViabilities = (
  asinViabilitiesByStoreRegion,
  approvedAsins,
) => {
  return Object.keys(asinViabilitiesByStoreRegion)
    .filter((asin) => !Object.keys(approvedAsins).includes(asin))
    .reduce((obj, key) => {
      obj[key] = asinViabilitiesByStoreRegion[key];
      return obj;
    }, {});
};

const mapStateToProps = (state) => {
  const { AsinValidator: { asinViabilities, targetDate } } = state;

  return {
    asinViabilities,
    targetDate,
    getAsinViabilitiesData,
    getStoreDetails,
    getApprovedAsins,
    getPartiallyApprovedAsinsWithViabilities,
  };
};


export default connect(mapStateToProps)(AsinViabilityDashboard);
