import { onStartPageLoadTimer, onCancelPageLoadTimer } from '../../actionCreators';
import {
  RouterActions,
} from '../../constants';
import { submitTimerMetric, submitStringMetric } from '../../metrics/index';
import window from '../../helpers/window';
import {
  MetricLocations,
  MetricNames,
  MetricTypes, PageLoadTimerActions,
} from '../../constants/metrics';

let timeout = null;
const TIMEOUT = 5000;

export default ({ getState, dispatch }) => (next) => (action) => {
  if (action.type === RouterActions.LOCATION_CHANGE) {
    dispatch(onStartPageLoadTimer());
  }
  if (action.type === PageLoadTimerActions.START_TIMER) {
    if (timeout) { window.clearTimeout(timeout); }
    timeout = window.setTimeout(() => {
      dispatch(onCancelPageLoadTimer());
      submitStringMetric(
        MetricNames.NO_PAGE_TRANSITION_RECORDED,
        {
          location: MetricLocations.TIMER_MIDDLEWARE,
          type: MetricTypes.LOG,
        },
      );
    }, TIMEOUT);
  }
  if (action.type === PageLoadTimerActions.COMPLETE_TIMER) {
    if (timeout) { window.clearTimeout(timeout); }
    const state = getState();
    const { PageLoadTimer: { timer } } = state;
    const { pageName } = action.payload;
    if (!timer) {
      submitStringMetric(
        MetricNames.PAGE_TRANSITION_RECORDED_WITHOUT_START,
        {
          location: MetricLocations.TIMER_MIDDLEWARE,
          type: MetricTypes.LOG,
        },
      );
      if (pageName) {
        submitStringMetric(
          `${MetricNames.PAGE_TRANSITION_RECORDED_WITHOUT_START}_${pageName}`,
          {
            location: MetricLocations.TIMER_MIDDLEWARE,
            type: MetricTypes.LOG,
          },
        );
      }
      return next(action);
    }
    const timerDiff = window.performance.now() - timer;
    submitTimerMetric(MetricNames.INTERNAL_PAGE_CHANGE, timerDiff);
    if (pageName) {
      submitTimerMetric(`${MetricNames.INTERNAL_PAGE_CHANGE}_${pageName}`, timerDiff);
    }
  }
  return next(action);
};
