import { getCurrentPathName, isPromotionWeekRoute } from "../../helpers/router";
import { hasSelectedRows } from "../../selectors/promotions";
import copyPromotionRows from "./copyPromotionRows";

export default (dispatch, getState, action, next) => {
  const state = getState();
  const currentPathName = getCurrentPathName(state);
  if (!currentPathName) {
    return next(action);
  }

  if (isPromotionWeekRoute(currentPathName) && hasSelectedRows(state)) {
    return copyPromotionRows(dispatch, getState, action, next);
  }

  return next(action);
}