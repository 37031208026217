import { connect } from 'react-redux';
import PromoWeekActionBar from './PromoWeekActionBar';
import { Language } from '../../../constants';
import {
  onDeletePromotion,
  onResetPromotion,
  onSoftDeletePromotion,
  onOpenPromotionStatusModal,
  onSelectRow,
} from '../../../actionCreators';

const mapDispatchToProps = (dispatch) => {
  return {
    onDelete(id) {
      if (window.confirm(Language.GENERIC_ON_DELETE_CONFIRMATION)) {
        dispatch(onDeletePromotion({ id }));
      }
    },
    onReset(id) {
      if (window.confirm(Language.GENERIC_ON_DELETE_CONFIRMATION)) {
        dispatch(onResetPromotion(id));
      }
    },
    onUpdateStatus: (id, status) => dispatch(onOpenPromotionStatusModal(id, status)),
    onSoftDelete: (id) => dispatch(onSoftDeletePromotion(id)),
    onSelect: (id) => dispatch(onSelectRow(id)),
  };
};

export default connect(null, mapDispatchToProps)(PromoWeekActionBar);
