import {
  PromoActions,
  PromoWeekActions,
  PublishedPromotionActions,
} from '../../constants';
import loadPublishedPromotions from './loadPublishedPromotions';
import loadPublishedPromotionAuditLog from './loadPublishedPromotionAuditLog';
import createPromoWeek from './createPromoWeek';
import updatePromoWeek from './updatePromoWeek';
import deletePromoWeek from './deletePromoWeek';
import createPromotions from './createPromotions';
import resetPromotion from './resetPromotion';
import loadWeeksForYearQuarter from './loadWeeksForYearQuarter';
import updatePromotionStatus from './updatePromotionStatus';
import approveRejectPromotion from './approveRejectPromotion';
import approvePromotionImage from './approvePromotionImage';
import softDeletePromotion from './softDeletePromotion';
import addRowToPromoWeek from './addRowToPromoWeek';
import { onResetSelectedRows } from "../../actionCreators";

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if (type === PromoActions.RESET_PROMOTION) {
    return resetPromotion(dispatch, getState, action, next);
  }

  if (type === PromoActions.CREATE_PROMOTIONS) {
    return createPromotions(dispatch, getState, action, next);
  }

  if (type === PromoActions.SOFT_DELETE_PROMOTION) {
    return softDeletePromotion(dispatch, getState, action, next);
  }

  if (type === PromoActions.UPDATE_PROMOTION_STATUS) {
    return updatePromotionStatus(dispatch, getState, action, next);
  }

  if (type === PromoActions.SET_DATES_RANGE) {
    dispatch(onResetSelectedRows());

    return next(action);
  }

  if (type === PromoActions.APPROVE_PROMOTION_IMAGE) {
    return approvePromotionImage(dispatch, getState, action, next);
  }

  if (type === PromoActions.APPROVE_PROMOTION) {
    return approveRejectPromotion(dispatch, getState, action, next);
  }

  if (type === PromoActions.REJECT_PROMOTION) {
    return approveRejectPromotion(dispatch, getState, action, next);
  }

  if (type === PromoWeekActions.ADD_PROMOTION_WEEK) {
    return createPromoWeek(dispatch, getState, action, next);
  }

  if (type === PromoWeekActions.UPDATE_PROMOTION_WEEK) {
    return updatePromoWeek(dispatch, getState, action, next);
  }

  if (type === PromoWeekActions.DELETE_PROMOTION_WEEK) {
    return deletePromoWeek(dispatch, getState, action, next);
  }

  if (type === PromoWeekActions.LOAD_WEEKS_FOR_YEAR_QUARTER) {
    return loadWeeksForYearQuarter(dispatch, getState, action, next);
  }

  if (type === PublishedPromotionActions.LOAD) {
    return loadPublishedPromotions(dispatch, getState, action, next);
  }

  if (type === PublishedPromotionActions.LOAD_AUDIT_LOG) {
    return loadPublishedPromotionAuditLog(dispatch, getState, action, next);
  }

  if (type === PromoWeekActions.ADD_ROW) {
    return addRowToPromoWeek(dispatch, getState, action, next);
  }

  return next(action);
};
