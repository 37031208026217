import { connect } from 'react-redux';

import {
  onMetadataEditorSetDependentOption,
  onMetadataEditorAddOptionForField,
  onMetadataEditorDeleteOptionForField,
  onPerformMetadataUpdate,
} from '../../../actionCreators';
import getAllMetadataOptionsForColumn from '../../../helpers/getAllMetadataOptionsForColumn';

import Column from './column';

export const mapStateToProps = (state, ownProps) => {
  const {
    Meta: { metadata: { fields } },
  } = state;
  const { dependencies, dependency: { name: selectedField } } = ownProps;
  const getOptionsForDependency = (options, selection) => {
    if (options && options[selection]) {
      return options[selection];
    }
    return [];
  };

  const getOptions = () => {
    if (!selectedField) {
      return { options: [], ableToAdd: false };
    }
    if (dependencies.length === 0) {
      return { options: getAllMetadataOptionsForColumn(state, selectedField), ableToAdd: true };
    }
    const selectedFieldData = fields[selectedField];
    if (!selectedFieldData) {
      return { options: [], ableToAdd: false };
    }
    let { options } = selectedFieldData;
    let hasAllDependencies = true;
    selectedFieldData.dependsOn.forEach((dependency) => {
      const dependencyData = dependencies.find((thisDependency) => {
        return thisDependency.name === dependency;
      });
      if (!dependencyData || !dependencyData.selection) {
        hasAllDependencies = false;
      }
      if (hasAllDependencies) {
        options = getOptionsForDependency(options, dependencyData.selection);
      }
    });

    if (hasAllDependencies) {
      return { options: options || [], ableToAdd: true };
    }
    return { options: [], ableToAdd: false };
  };
  const { options, ableToAdd } = getOptions();
  return {
    options,
    ableToAdd,
    selectedField,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSelectOption(name, selection) {
      dispatch(onMetadataEditorSetDependentOption({ name, selection }));
    },
    onAddOption(value, selectedField, dependencies) {
      dispatch(onMetadataEditorAddOptionForField({ dependencies, selectedField, value }));
      dispatch(onPerformMetadataUpdate());
    },
    onDeleteOption(value, selectedField, dependencies) {
      dispatch(onMetadataEditorDeleteOptionForField({ dependencies, selectedField, value }));
      dispatch(onMetadataEditorSetDependentOption({ name: selectedField, selection: null }));
      dispatch(onPerformMetadataUpdate());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Column);
