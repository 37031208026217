import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian/modal';
import PromoWeekForm from '../PromoWeekForm';

const PromoWeekModal = (props) => {
  const {
    isWeekModalOpen,
  } = props;

  if (!isWeekModalOpen) {
    return null;
  }

  return (
    <Modal
      open={isWeekModalOpen}
      scrollContainer="modal"
    >
      <PromoWeekForm isNew />
    </Modal>
  );
};

PromoWeekModal.propTypes = {
  isWeekModalOpen: PropTypes.bool.isRequired,
};

export default PromoWeekModal;
