import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Tooltip from '@amzn/meridian/tooltip';
import closeMediumTokens from '@amzn/meridian-tokens/base/icon/close-medium';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import WrapTextIcon from '@material-ui/icons/WrapText';
import HighlightIcon from '@material-ui/icons/Highlight';
import { campaignColumnProps } from '../../proptypes';
import { GridViewHeaderButtons } from '../../constants/ui';
import GridViewHeaderCell from '../GridViewHeaderCell';
import CustomViewModal from '../CustomViewModal';
import rowStyles from '../GridViewRow/GridViewRow.module.scss';
import cellStyles from '../GridViewCell/GridViewCell.module.scss';
import { MustFillColumns } from '../../constants';

const GridViewHeader = ({
  columns,
  showActionBar,
  onClearSearchButtonClick,
  showSearch,
  onContentWrapButtonClick,
  isContentWrappingEnabled,
  onHighlightMustFillButtonClick,
  isMustFillHighlightEnabled,
  isSticky,
  isAdmin,
}) => {
  const [width, setWidth] = useState(0);
  const [columnModalOpen, setColumnModalOpen] = useState(false);
  const firstColumn = useRef(null);

  useEffect(() => {
    setWidth(firstColumn.current.offsetWidth);
  }, [firstColumn.current]);

  const onCloseColumnModal = () => setColumnModalOpen(false);
  const onOpenColumnModal = () => setColumnModalOpen(true);
  const toggleContentWrapping = () => onContentWrapButtonClick(!isContentWrappingEnabled);
  const toggleMustFillHighlighting = () => onHighlightMustFillButtonClick(!isMustFillHighlightEnabled);

  if (!columns) {
    return null;
  }

  const cells = columns.map((column, index) => {
    const gridViewHeaderCellProperty = {
      columnName: column.name,
      key: column.name,
      name: column.name,
      isSticky: index <= 1,
      applyMustFillHighlight: isMustFillHighlightEnabled && MustFillColumns.includes(column.name),
      showSearch,
    };
    if (index === 0) {
      gridViewHeaderCellProperty.ref = firstColumn;
    }
    if (index === 1) {
      gridViewHeaderCellProperty.left = width;
    }
    return (
      <GridViewHeaderCell {...gridViewHeaderCellProperty}>
        {column.display}
      </GridViewHeaderCell>
    );
  });

  const clearSearchButton = (
    <Tooltip position="top" title={GridViewHeaderButtons.CLEAR_SEARCH}>
      <Button
        size="small"
        onClick={onClearSearchButtonClick}
        type="secondary"
      >
        <Icon tokens={closeMediumTokens} />
      </Button>
    </Tooltip>
  );

  const manageColumnsButton = (
    isAdmin ? 
    (<Tooltip position="top" title={GridViewHeaderButtons.MANAGE_COLUMNS}>
      <Button
        size="small"
        onClick={onOpenColumnModal}
        type="secondary"
      >
        <Icon tokens={viewTokens} />
      </Button>
    </Tooltip>) 
    : null
  );

  const toggleContentWrapButton = (
    <Tooltip position="top" title={GridViewHeaderButtons.WRAP_CONTENT}>
      <Button
        size="small"
        onClick={toggleContentWrapping}
        type={isContentWrappingEnabled ? 'primary' : 'secondary'}
      >
        <WrapTextIcon />
      </Button>
    </Tooltip>
  );

  const highlightMustFillColumnsButton = (
    <Tooltip position="top" title={GridViewHeaderButtons.HIGHLIGHT_MUST_FILL_COLUMNS}>
      <Button
        size="small"
        onClick={toggleMustFillHighlighting}
        type={isMustFillHighlightEnabled ? 'primary' : 'secondary'}
      >
        <HighlightIcon />
      </Button>
    </Tooltip>
  );

  const headerActionButtons = showSearch
    ? (
      <Row alignmentHorizontal="end" spacing="200">
        {highlightMustFillColumnsButton}
        {toggleContentWrapButton}
        {manageColumnsButton}
        {clearSearchButton}
      </Row>
    )
    : null;

  if (showActionBar) {
    cells.unshift((
      <th
        key="actionSpacer"
        className={cellStyles.cellHeaderContainer}
      >
        <div className={classnames({
          [cellStyles.cell]: true,
          [cellStyles.cellFilters]: true,
        })}
        >
          {headerActionButtons}
        </div>
      </th>
    ));
  }

  return (
    <tr className={classnames({
      [rowStyles.isSticky]: isSticky,
    })}
    >
      {cells}
      <CustomViewModal
        showModal={columnModalOpen}
        onCloseModal={onCloseColumnModal}
      />
    </tr>
  );
};

GridViewHeader.propTypes = {
  columns: campaignColumnProps.isRequired,
  onClearSearchButtonClick: PropTypes.func,
  onContentWrapButtonClick: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
  isContentWrappingEnabled: PropTypes.bool,
  isMustFillHighlightEnabled: PropTypes.bool,
  showActionBar: PropTypes.bool,
  showSearch: PropTypes.bool,
  isSticky: PropTypes.bool,
};

GridViewHeader.defaultProps = {
  showActionBar: false,
  showSearch: false,
  isContentWrappingEnabled: false,
  isMustFillHighlightEnabled: false,
  onContentWrapButtonClick: _.noop,
  onClearSearchButtonClick: _.noop,
  isSticky: false,
};

export default GridViewHeader;
