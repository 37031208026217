import React from 'react';
import PropTypes from 'prop-types';
import styles from './PromotionWeekContainer.module.scss';
import AuditLogPage from '../AuditLogPage';
import PromoWeekForm from '../PromoWeekForm';
import Tabs from '../Tabs';
import {
  PromotionWeekTabsList,
  AuditLogTypes,
} from '../../constants';

const PromotionWeekContainer = (props) => {
  const {
    week,
    currentTab,
    onSwitchTab,
  } = props;

  const getAuditLog = () => {
    if (!week) {
      return null;
    }

    return (
      <AuditLogPage
        entity={week}
        logType={AuditLogTypes.PROMOTION_WEEK}
        showRollback={false}
      />
    );
  };

  const getPromotionWeekForm = () => {
    return (
      <PromoWeekForm
        isNew={!week}
        isClone={false}
        loadedPromotionWeek={week}
      />
    );
  };

  const getComponentByTab = () => {
    switch (currentTab) {
      case PromotionWeekTabsList.WEEK.name:
        return getPromotionWeekForm();
      case PromotionWeekTabsList.AUDIT_LOG.name:
        return getAuditLog();
      default:
        return getPromotionWeekForm();
    }
  };

  const getTabs = () => {
    if (!week) {
      return null;
    }
    const tabsList = Object.values(PromotionWeekTabsList);

    return (
      <Tabs
        onSwitchTab={onSwitchTab}
        currentTab={currentTab}
        tabs={tabsList}
      />
    );
  };

  const tabs = getTabs();
  const component = getComponentByTab();

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          {tabs}
          {component}
        </div>
      </div>
    </>
  );
};

PromotionWeekContainer.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
};

export default PromotionWeekContainer;
