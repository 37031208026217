import { BannerActions } from '../constants/banner';

export const onLoadBanners = () => ({
  type: BannerActions.LOAD_BANNERS,
});

export const onSetBanners = (banners) => ({
  type: BannerActions.SET_BANNERS,
  payload: {
    banners,
  }
});

export const onSaveBanners = (banners) => ({
  type: BannerActions.SAVE_BANNERS,
  payload: {
    banners,
  }
});

export const onResetBanners = () => ({
  type: BannerActions.RESET_BANNERS,
});

export const onSetBannerModalOpen = (isModalOpen) => ({
  type: BannerActions.OPEN_MODAL,
  payload: {
    isModalOpen,
  }
});