import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusKnockoutTokens from '@amzn/meridian-tokens/base/icon/plus-knockout';
import bulbTokens from '@amzn/meridian-tokens/base/icon/bulb';
import ExportButton from '../ExportButton';
import PreviewInstructions from '../PreviewInstructions';
import PromoWeekList from '../PromoWeekList';
import BulkImporterModal from '../BulkImporterModal';
import AddRowModal from '../AddRowModal';
import ColorLegendModal from '../ColorLegendModal';
import BannerModal from '../BannerModal';
import { PromotionExportViewType } from '../../constants/export';
import styles from './Promotions.module.scss';
import PublishPromotionsModal from '../PublishPromotionsModal';

const Promotions = ({
  initialMetadataLoaded,
  isAdmin,
  startDate,
  endDate,
  weekName,
  onOpenBulkImport,
  openPublishPromotionModal,
  onAddRow,
  onShowColorLegend,
  onShowBanner,
}) => {
  useEffect(() => {
    const originalPageTitle = document.title;

    document.title = weekName;

    return () => {
      document.title = originalPageTitle;
    };
  }, [weekName]);

  const getImportButton = () => (
    <Button
      size="small"
      type="secondary"
      onClick={onOpenBulkImport}
    >
      Import
    </Button>
  );

  const getAddRowButton = () => (
    <Button
      size="small"
      onClick={onAddRow}
    >
      <Icon tokens={plusKnockoutTokens} />
      Add Row
    </Button>
  );

  const getColorLegendButton = () => (
    <Button
      size="small"
      type="tertiary"
      onClick={onShowColorLegend}
    >
      <Icon tokens={bulbTokens} />
      Color Legend
    </Button>
  );

  const getBannerButton = () => (
    <Button
      size="small"
      type="tertiary"
      onClick={onShowBanner}
    >
      Open banner
    </Button>
  );

  const getPublishPromotionsButton = () => (
    <Button
      size="small"
      type="primary"
      onClick={openPublishPromotionModal}
    >
      Publish Digital Circular
    </Button>
  );

  const getPromoWeek = () => {
    const importButton = getImportButton();
    const addRowButton = getAddRowButton();
    const colorLegendButton = getColorLegendButton();
    const bannerButton = isAdmin ? getBannerButton() : null;
    const publishPromotionsButton = isAdmin ? getPublishPromotionsButton() : null;

    return (
      <>
        <div className={styles.pageHeaderContainer}>
          <h2 className={styles.pageHeader}>{weekName}</h2>
          <span className={styles.dateRange}>({startDate} - {endDate})</span>
        </div>
        <PromoWeekList showActionBar />
        <div className={styles.buttonsContainer}>
          <div className={styles.leftSideButtonContainer}>
            {addRowButton}
            {colorLegendButton}
            {bannerButton}
          </div>
          <div className={styles.rightSideButtonContainer}>
            {importButton}
            <ExportButton viewType={PromotionExportViewType.PREVIEW} />
            {publishPromotionsButton}
            <PreviewInstructions />
          </div>
        </div>
        <AddRowModal />
      </>
    );
  };

  const getContent = () => {
    if (!initialMetadataLoaded) {
      return null;
    }

    const promoWeek = getPromoWeek();

    return (
      <div>
        <BulkImporterModal />
        <ColorLegendModal />
        <BannerModal />
        <PublishPromotionsModal />
        {promoWeek}
      </div>
    );
  };

  return getContent();
};

Promotions.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  weekName: PropTypes.string.isRequired,
  onOpenBulkImport: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onShowColorLegend: PropTypes.func.isRequired,
  onShowBanner: PropTypes.func.isRequired,
};

export default Promotions;
