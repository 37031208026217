import createColumn from '../helpers/createColumn';
import { InputTypes } from './index';

export const BannerActions = Object.freeze({
  LOAD_BANNERS: 'BannerActions/LOAD_BANNERS',
  SET_BANNERS: 'BannerActions/SET_BANNERS',
  SAVE_BANNERS: 'BannerActions/SAVE_BANNERS',
  OPEN_MODAL: 'BannerActions/OPEN_MODAL',
  RESET_BANNERS: 'BannerActions/RESET_BANNERS',
});

export const BannerColumns = Object.freeze({
  DESKTOP_IMAGE: createColumn('desktopImage', 'Desktop Image', InputTypes.INPUT),
  DESKTOP_IMAGE_ALT: createColumn('desktopImageAlt', 'Desktop Alt', InputTypes.INPUT),
  DESKTOP_LINK: createColumn('desktopLink', 'Desktop Link', InputTypes.INPUT),
  MOBILE_IMAGE: createColumn('mobileImage', 'Mobile Image', InputTypes.INPUT),
  MOBILE_IMAGE_ALT: createColumn('mobileImageAlt', 'Mobile Alt', InputTypes.INPUT),
  MOBILE_LINK: createColumn('mobileLink', 'Mobile Link', InputTypes.INPUT),
  SLOT: createColumn('slot', 'Slot', InputTypes.INPUT),
  STORE_IDS: createColumn('storeIds', 'Store Ids', InputTypes.INPUT),
  STORE_REGIONS: createColumn('storeRegions', 'Store Regions', InputTypes.INPUT),
});

export const BannerConfig = Object.freeze({
  FORM_STORAGE_VAR_NAME: 'banners', // This is a name of a virtual field on the form for all nested banners
});