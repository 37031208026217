import { connect } from 'react-redux';

import {
  onBusinessSelect,
  onSetNewUserExperienceShown,
} from '../../actionCreators';
import {
  BusinessTypes,
} from '../../constants';

import NewUserExperience from './NewUserExperience';

export const mapStateToProps = (state) => {
  const { Sitewide: { showNewUserExperience: isOpen } } = state;
  const businesses = Object.keys(BusinessTypes).map((key) => BusinessTypes[key]);

  return {
    isOpen,
    businesses,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onBusinessSelect(business) {
      dispatch(onBusinessSelect({ business, forceReload: true }));
      dispatch(onSetNewUserExperienceShown(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserExperience);
