import React, { useEffect } from 'react';
import Input from '@amzn/meridian/input';
import Column from '@amzn/meridian/column';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import styles from './TemplateBuilder.module.scss';
import GridView from '../GridView';
import { GridViewSource } from '../../constants';
import TemplateActionBar from './TemplateActionBar';

const TemplateBuilder = (props) => {
  const {
    columnOrder,
    promotions,
    onAddRow,
    onChangeGridViewType,
    onSetTemplateName,
    onSave,
    name,
  } = props;

  useEffect(() => {
    onChangeGridViewType(GridViewSource.PREVIEW_ROWS);

    return () => {
      onChangeGridViewType(GridViewSource.ROWS);
    };
  }, []);

  const getAddButton = () => {
    return (
      <Button
        size="small"
        onClick={onAddRow}
      >
        +
      </Button>
    );
  };

  const addButton = getAddButton();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Column spacing="200">
          <Input
            id="my-input"
            value={name}
            onChange={onSetTemplateName}
            type="text"
            placeholder="Enter template name..."
            size="small"
          />
        </Column>
        <div className={styles.gridContainer}>
          <GridView
            promotions={promotions}
            columnOrder={columnOrder}
            customActionBar={TemplateActionBar}
            enableContextMenu={false}
            isStickyHeader
          />
        </div>
        <div>
          <div className={styles.buttonContainer}>
            {addButton}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              size="small"
              onClick={onSave}
              disabled={!promotions.length}
            >
              Save Template
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

TemplateBuilder.propTypes = {
  name: PropTypes.string.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onChangeGridViewType: PropTypes.func.isRequired,
  onSetTemplateName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TemplateBuilder;
