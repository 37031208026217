const getPromotionById = (state, id) => {
  const { source } = state;

  for (let i = 0; i < state[source].length; i += 1) {
    if (state[source][i].id === id) {
      return state[source][i];
    }
  }

  return null;
};

export default getPromotionById;
