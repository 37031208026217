import { connect } from 'react-redux';
import TemplateBuilder from './TemplateBuilder';
import {
  onSetSource,
  onAddRow,
  onRemoveRow,
  onSetTemplateName,
  onCommitRow,
} from '../../actionCreators';
import { GridViewCommitTypes, TemplateBuilderColumnsOrder } from '../../constants';
import { createPromotionShell } from '../../helpers/promotions';

const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      previewRows,
    },
    Templates: {
      name,
    },
  } = state;

  return {
    columnOrder: TemplateBuilderColumnsOrder,
    promotions: previewRows,
    name,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    onAddRow() {
      dispatch(onAddRow(createPromotionShell()));
    },
    onRemoveRow(id) {
      dispatch(onRemoveRow(id));
    },
    onSave() {
      dispatch(onCommitRow(GridViewCommitTypes.TEMPLATE_BUILDER));
    },
    onChangeGridViewType(sourceType) {
      dispatch(onSetSource(sourceType));
    },
    onSetTemplateName(name) {
      dispatch(onSetTemplateName(name));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateBuilder);
