import { connect } from 'react-redux';
import { defaultStateToProps, defaultDispatchToProps } from '../helpers';
import { getDependentOptionsForFieldOnCampaign } from '../../../helpers/getMetadataOptions';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import getPromotionById from '../../../reducers/gridViewPage/getPromotionById';
import TreeSelect from '../../StyledInputs/TreeSelect';
import { COMMA_DELIMETER } from '../../../constants';

const getTranslatedOptions = (options = []) => {
  const parsedOptions = Array.isArray(options)
    ? options
    : options.split(COMMA_DELIMETER).map((option) => option.trim());
  return parsedOptions.map((option) => {
    return {
      value: option,
      label: option,
    };
  });
};

export const mapStateToProps = (state, ownProps) => {
  const {
    GridViewPage: { activeCell: { rowId, columnName } },
    Meta: { metadata: { fields } },
  } = state;
  const { overrideOptions } = ownProps;

  const columnData = fields[columnName];
  let options = [];
  if (columnData && columnData.options && Array.isArray(columnData.options)) {
    options = columnData.options;
  }
  if (columnData && columnData.dependsOn) {
    const campaign = getPromotionById(state.GridViewPage, rowId);
    options = getDependentOptionsForFieldOnCampaign(
      columnData.options,
      campaign,
      columnData.dependsOn,
    );
  }

  if (overrideOptions) {
    options = overrideOptions;
  }

  return {
    ...defaultStateToProps(state),
    options: getTranslatedOptions(options),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
    },
    onCommitChanges() {
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeSelect);
