import { connect } from 'react-redux';
import { onAuditLogSetEntity } from '../../actionCreators';
import AuditLogPage from './AuditLogPage';
import {
  AuditLogTypes,
  Columns,
  PromotionWeekColumnsOrder,
  TemplateBuilderColumnsOrder,
} from '../../constants';

const getColumnOrderByLogType = (logType, columnOrder) => {
  if (logType === AuditLogTypes.PROMOTION_WEEK) {
    return PromotionWeekColumnsOrder;
  }

  if (logType === AuditLogTypes.PROMOTION) {
    return [
      Columns.STATUS,
      Columns.USERNAME,
      Columns.MODIFICATION_TIMESTAMP,
      Columns.MODIFICATION_TYPE,
      Columns.IS_SOFT_DELETED,
      ...columnOrder,
    ];
  }

  if (logType === AuditLogTypes.TEMPLATE_BUILDER) {
    return TemplateBuilderColumnsOrder;
  }

  return columnOrder;
};

const shouldDisplayVersionHistory = (auditLogType) => [
  AuditLogTypes.TEMPLATE_BUILDER,
].includes(auditLogType);

export const mapStateToProps = (state, { logType }) => {
  const {
    AuditLog: { auditLogType, logs, isLoading },
    Meta: { metadata: { columnOrder } },
  } = state;

  const currentColumnOrder = getColumnOrderByLogType(logType, columnOrder);
  const columnsToCompare = currentColumnOrder.map((column) => column.name);

  return {
    logs,
    isLoading,
    columnsToCompare,
    columnOrder: currentColumnOrder,
    shouldDisplayVersionHistory: shouldDisplayVersionHistory(auditLogType),
  };
};

export const mapDispatchToProps = (dispatch, { entity, logType }) => {
  return {
    onLoadAuditLogs() {
      dispatch(onAuditLogSetEntity(entity, logType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogPage);
