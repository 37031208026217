import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PromoWeekForm from './PromoWeekForm';
import { PromotionWeekColumns, Form } from '../../constants';
import { validatePromoWeek, warn } from '../../helpers/formValidators';
import {
  onAddPromotionWeek,
  onUpdatePromotionWeek,
} from '../../actionCreators';

const translateToForm = (promotionWeek) => promotionWeek;
const translateFromForm = (promotionWeek) => ({ ...promotionWeek });

export const mapStateToProps = (state, ownProps) => {
  const {
    isNew,
    isClone,
    loadedPromotionWeek,
  } = ownProps;

  const {
    GridViewPage: {
      promotionsLoaded,
    },
    Meta: {
      initialMetadataLoaded,
    },
  } = state;

  const isCloneMode = (!isNew && isClone) && loadedPromotionWeek;
  const isEditMode = (!isNew && !isClone) && loadedPromotionWeek;

  let promotionWeek = {};
  if (isCloneMode) {
    promotionWeek = {
      ...loadedPromotionWeek,
      version: 1,
    };
  }
  if (isEditMode) {
    promotionWeek = { ...loadedPromotionWeek };
  }

  const id = !isClone && loadedPromotionWeek ? loadedPromotionWeek.id : null;
  const isLoading = !promotionsLoaded && !initialMetadataLoaded;
  const isNotFound = !isNew && promotionsLoaded && !loadedPromotionWeek;

  return {
    id,
    isNew,
    isClone,
    isLoading,
    isNotFound,
    initialValues: translateToForm(promotionWeek),
    columnsList: PromotionWeekColumns,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onCreate: (data) => {
    dispatch(onAddPromotionWeek(translateFromForm(data)));
  },
  onUpdate: (data) => {
    dispatch(onUpdatePromotionWeek(translateFromForm(data)));
  },
});

const reduxFormConfig = {
  form: Form.PROMO_WEEK,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate: validatePromoWeek,
  warn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(reduxFormConfig)(PromoWeekForm));
