import {
  PromoActions,
} from '../../constants';

export const initialState = {
  id: null,
  status: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { payload } = action;

  switch (action.type) {
    case PromoActions.OPEN_PROMOTION_STATUS_MODAL: {
      const { id, status } = payload;

      return {
        ...state,
        id,
        status,
      };
    }
    case PromoActions.CLOSE_PROMOTION_STATUS_MODAL: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
