import moment from 'moment';
import { connect } from 'react-redux';
import WebsiteNavigationTabs from './WebsiteNavigationTabs';
import history from '../../../history';
import { DateFormats, TabTypes } from '../../../constants';
import { createPromotionWeekRoute } from '../../../helpers/router';

export const mapStateToProps = (state) => {
  const {
    Tabs: {
      [TabTypes.WEBSITE_NAVIGATION]: activeRoute,
    },
    Promo: {
      dates,
    },
  } = state;

  const [startDate, endDate] = dates;

  return {
    activeRoute,
    hasPromotionWeekDatesSelected: dates.length > 0,
    startDate: moment(startDate).format(DateFormats.MM_DD),
    endDate: moment(endDate).format(DateFormats.MM_DD),
    promotionWeekRoute: createPromotionWeekRoute(startDate, endDate),
  };
};

export const mapDispatchToProps = () => {
  return {
    onChangeRoute(route) {
      history.push(route);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteNavigationTabs);
