import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import history from '../history';
import AsinImages from './AsinImages';
import AsinValidator from './AsinValidator';
import AuditLog from './auditLog';
import Banner from './Banner';
import BulkImport from './BulkImport';
import CustomView from './customView';
import Export from './Export';
import FormPage from './formPage';
import GridViewPage from './gridViewPage';
import Meta from './Metadata';
import MetadataAuditLog from './MetadataAuditLog';
import MetadataEditor from './MetadataEditor';
import Promo from './Promo';
import PromoStatusModal from './PromoStatusModal';
import Sitewide from './sitewide';
import Templates from './Templates';
import Tabs from './Tabs';
import User from './user';
import PreviewInstructions from './PreviewInstructions';
import PageLoadTimer from './PageLoadTimer';
import LoadingStatus from './LoadingStatus';
import PerformanceTimer from './PerformanceTimer';
import PublishPromotions from './PublishPromotions';

export default combineReducers({
  router: connectRouter(history),
  form: reduxFormReducer,
  AsinImages,
  AsinValidator,
  AuditLog,
  Banner,
  BulkImport,
  CustomView,
  Export,
  FormPage,
  GridViewPage,
  MetadataAuditLog,
  MetadataEditor,
  Meta,
  PageLoadTimer,
  PerformanceTimer,
  PreviewInstructions,
  Promo,
  PromoStatusModal,
  PublishPromotions,
  Sitewide,
  Tabs,
  Templates,
  User,
  LoadingStatus,
});
