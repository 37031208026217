import { signOut } from '../../helpers/cognito';
import { onSetUser, onSwitchUserViewMode } from '../../actionCreators';
import { UserActions, UserViewMode } from '../../constants/user';

export default ({ dispatch }) => (next) => (action) => {
  if (action.type === UserActions.SET_USER) {
    const { payload: { user } } = action;

    if (!user) {
      return next(action);
    }

    const { isAdmin, isMetadataAdmin } = user;

    const userViewMode = (isAdmin || isMetadataAdmin)
      ? UserViewMode.ADMIN.name
      : UserViewMode.USER.name;

    dispatch(onSwitchUserViewMode(userViewMode));

    return next(action);
  }

  if (action.type === UserActions.LOG_OUT_USER) {
    dispatch(onSetUser(null));
    signOut();

    return next(action);
  }

  return next(action);
};
