import { connect } from 'react-redux';
import {
  onClosePromotionStatusModal,
  onUpdatePromotionStatus,
} from '../../actionCreators';
import PromoStatusModal from './PromoStatusModal';

export const mapStateToProps = (state) => {
  const { PromoStatusModal: { id, status } } = state;

  return {
    isOpen: !!id,
    status,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (status) => dispatch(onUpdatePromotionStatus(status)),
    onClose: () => dispatch(onClosePromotionStatusModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoStatusModal);
