import { connect } from 'react-redux';
import BannerModal from './BannerModal';
import {
  onLoadBanners,
  onSetBannerModalOpen,
} from '../../actionCreators/banner';

const mapStateToProps = (state) => {
  const { Banner: { isModalOpen } } = state;

  return {
    isModalOpen,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => {
      dispatch(onLoadBanners());
    },
    onClose: () => {
      dispatch(onSetBannerModalOpen(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerModal);
