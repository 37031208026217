import { connect } from 'react-redux';
import WebsiteContainer from './WebsiteContainer';

export const mapStateToProps = (state) => {
  const {
    AsinValidator: { isLoading },
  } = state;

  return {
    showLoader: isLoading,
  };
};

export default connect(mapStateToProps)(WebsiteContainer);
