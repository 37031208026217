import React from 'react';
import TreeSelectControl from '../../../Controls/TreeSelectControl';

export default (props) => {
  const { customOnBlur, input } = props;

  const newProps = {
    ...props,
    isFormViewMode: true,
    input: {
      ...input,
      onChange: (name, value) => customOnBlur(name, value),
    },
  };

  return <TreeSelectControl {...newProps} />;
};
