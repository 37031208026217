import {
  AuditLogActions,
  ON_METADATA_RECEIVED,
} from '../../constants';
import { appsyncToLocal } from '../../helpers/translatePromotions';
import {
  onAuditLogClear,
  onAuditLogFetch,
  onAuditLogSetAuditLogType,
} from '../../actionCreators';
import fetchAuditLog from './fetchAuditLog';
import translateRawLogs from './translateRawLogs';
import rollback from './rollback';

export const translateCampaignAuditLogs = (campaignData, metadata) => {
  return campaignData.map((campaign) => appsyncToLocal(campaign, metadata));
};

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === AuditLogActions.SET_ENTITY) {
    const { payload: { entity, auditLogType } } = action;
    dispatch(onAuditLogClear());
    dispatch(onAuditLogSetAuditLogType(auditLogType));
    dispatch(onAuditLogFetch(entity));
  }

  if (action.type === AuditLogActions.FETCH) {
    return fetchAuditLog(dispatch, getState, action, next);
  }

  if (action.type === AuditLogActions.ROLLBACK) {
    return rollback(dispatch, getState, action, next);
  }

  if (action.type === ON_METADATA_RECEIVED) {
    next(action);

    return translateRawLogs(dispatch, getState, action, next);
  }

  return next(action);
};
