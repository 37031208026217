import { PublishPromotionsActions } from '../constants/publishPromotions';

export const sendPublishPromotionsRequest = () => ({
  type: PublishPromotionsActions.SEND_PUBLISH_PROMOTIONS_REQUEST
});

export const setPublishPromotionsIsModalOpen = (isModalOpen) => ({
  type: PublishPromotionsActions.SET_PUBLISH_PROMOTIONS_IS_MODAL_OPEN,
  payload: {
    isModalOpen,
  }
});

export const setPublishPromotionsResponse = (response, requestStatus) => ({
  type: PublishPromotionsActions.SET_PUBLISH_PROMOTIONS_RESPONSE,
  payload: {
    response,
    requestStatus
  }
});