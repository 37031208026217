import _ from 'lodash';
import {
  Columns,
  COMMA_DELIMETER,
  PromotionStatuses,
} from '../constants';
import {
  splitYearQuarterDates,
  joinYearQuarterDates,
} from './dateTime';
import { isFloat } from './common';

const extractDates = (promotion) => {
  const { yearQuarter, startDate, endDate } = splitYearQuarterDates(promotion.yearQuarter_startDate_endDate);

  return {
    yearQuarter,
    startDate,
    endDate,
  };
};

const parseBoolean = (field) => {
  return ((String(field).toLowerCase()) === 'true');
}

export const parsePrice = (field) => {
  if (Number.isNaN(field)
    || typeof field === 'object'
    || typeof field === 'undefined'
  ) {
    return '';
  }
  const parsedValue = String(field).trim();

  if (isFloat(parsedValue)) {
    return String(parseFloat(parsedValue).toFixed(2));
  }

  return parsedValue;
}

const extractPrices = (promotion) => {
  return {
    [Columns.CIRCULAR_PRICE.name]: parsePrice(promotion.circularPrice) || '',
    [Columns.EACH_PRICE_FOR_F3RS.name]: parsePrice(promotion.eachPriceForF3RS) || '',
  }
}

export const appsyncToLocal = (promotion) => {
  const translatedPromotion = {
    ...promotion,
    ...extractDates(promotion),
    ...extractPrices(promotion),
  };
  const nonNulledTranslatedPromotion = {};
  Object.keys(translatedPromotion).forEach((column) => {
    if (_.isBoolean(translatedPromotion[column])) {
      nonNulledTranslatedPromotion[column] = !!translatedPromotion[column];
    } else if (_.isNumber(translatedPromotion[column])) {
      nonNulledTranslatedPromotion[column] = translatedPromotion[column];
    } else if (Array.isArray(translatedPromotion[column])) {
      nonNulledTranslatedPromotion[column] = translatedPromotion[column].filter(v => v.trim() !== '');
    } else {
      nonNulledTranslatedPromotion[column] = translatedPromotion[column] || '';
    }
  });
  return nonNulledTranslatedPromotion;
};

const parseColumns = (promotion) => {
  return {
    [Columns.PAGE.name]: parseInt(promotion.page, 10) || 0,
    [Columns.ZONE_NUMBER.name]: parseFloat(promotion.zoneNumber) || 0,
    [Columns.PRIME_DISCOUNT.name]: parseFloat(promotion.primeDiscount) || null,
    [Columns.IS_APLENTY.name]: parseBoolean(promotion.isAplenty),
    [Columns.IS_CATEGORY_HERO.name]: parseBoolean(promotion.isCategoryHero),
    [Columns.IS_COVER_PAGE_HERO.name]: parseBoolean(promotion.isCoverPageHero),
    [Columns.IS_FRESH_BRAND.name]: parseBoolean(promotion.isFreshBrand),
    [Columns.IS_LOCAL.name]: parseBoolean(promotion.isLocal),
    [Columns.IS_PRIME_BENEFIT.name]: parseBoolean(promotion.isPrimeBenefit),
    [Columns.IS_PRIVATE_BRAND.name]: parseBoolean(promotion.isPrivateBrand),
    [Columns.IS_USDA_CHOICE.name]: parseBoolean(promotion.isUSDAChoice),
    [Columns.IS_USDA_ORGANIC.name]: parseBoolean(promotion.isUSDAOrganic),
  };
};

const parseDates = (promotion) => {
  return {
    yearQuarter_startDate_endDate: promotion.yearQuarter_startDate_endDate
      ? promotion.yearQuarter_startDate_endDate
      : joinYearQuarterDates(promotion),
  };
};

export const parseArrayField = (fieldName, field) => {
  const parsedField = field && !Array.isArray(field)
    ? field.split(COMMA_DELIMETER).map((field) => field.trim())
    : field;

  return {
    [fieldName]: parsedField,
  };
};

export const localToAppsync = (promotion) => {
  const { asins, storeIds, storeRegions } = promotion;
  const translatedPromotion = {
    ...promotion,
    ...parseColumns(promotion),
    ...parseDates(promotion),
    ...parseArrayField(Columns.ASINS.name, asins),
    ...parseArrayField(Columns.STORE_IDS.name, storeIds),
    ...parseArrayField(Columns.STORE_REGIONS.name, storeRegions),
  };

  delete translatedPromotion.yearQuarter;
  delete translatedPromotion.startDate;
  delete translatedPromotion.endDate;
  delete translatedPromotion.errors;
  delete translatedPromotion.tempId;

  return translatedPromotion;
};

export const formatPromotionForMutationInput = (promotion, isStatusUpdate = false) => {
  const { status } = promotion;

  const formattedPromotion = {
    ...promotion,
    [Columns.STATUS.name]: determineNewStatusForMutation(status, isStatusUpdate),
  };

  delete formattedPromotion.id;
  formattedPromotion.version += 1;
  return formattedPromotion;
};

const determineNewStatusForMutation = (status, isStatusUpdate) => {
  if (isStatusUpdate) {
    return status;
  }
  if (PromotionStatuses.EDIT_LOCKED.value === status) {
    return PromotionStatuses.CHANGE_REQUESTED.value;
  }
  if (PromotionStatuses.REVIEW_LOCKED.value === status) {
    return PromotionStatuses.REVIEW_CHANGE_REQUESTED.value;
  }
  return status;
}

export const getExcludedFields = (extraFieldsToExclude = {}) => {
  const baseExcludes = {
    rowId: true,
    errors: true,
    expectedVersion: true,
    // Auto-generated field
    createdAt: true,
    updatedAt: true,
  };

  if (Object.keys(extraFieldsToExclude).length) {
    return {
      ...baseExcludes,
      ...extraFieldsToExclude,
    };
  }

  return baseExcludes;
};

export const getNewPromotionExcludedFields = () => {
  return getExcludedFields();
};

