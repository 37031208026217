import validateRequiredFields from './validateRequiredFields';
import checkMetadata from './checkMetadata';

export const validatePromotion = (state, promotion, partialFieldsValidationList = {}) => {
  const requiredFieldsValidations = validateRequiredFields(promotion, partialFieldsValidationList);
  const metadataValidations = checkMetadata(state, promotion, partialFieldsValidationList);

  return requiredFieldsValidations || metadataValidations;
};

