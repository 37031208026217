import { API, graphqlOperation } from 'aws-amplify';
import {
  onLoadTemplates,
  onOpenToast,
} from '../../actionCreators';
import * as mutations from '../../graphql/mutations';
import { getTemplateById } from '../../helpers/templates';

export default (dispatch, getState, action, next) => {
  const state = getState();

  const { payload: { id } } = action;

  if (!id) {
    const errorMessage = 'Cannot delete a template without an ID specified!';
    dispatch(onOpenToast(errorMessage));
    console.error(errorMessage);

    return next(action);
  }

  const selectedTemplate = getTemplateById(state, id);
  if (!selectedTemplate) {
    dispatch(onOpenToast(`Cannot find a template with ID = '${id}'!`));
    return next(action);
  }

  const {
    User: { currentUser: { username } },
  } = getState();

  const template = {
    ...selectedTemplate,
    promoConfiguration: JSON.stringify(selectedTemplate.promoConfiguration),
    version: selectedTemplate.version + 1,
  };

  delete template.id;

  const params = {
    template,
    username,
    id: selectedTemplate.id,
  };

  API
    .graphql(graphqlOperation(mutations.deleteTemplate, { input: params }))
    .then(() => {
      dispatch(onLoadTemplates());
      dispatch(onOpenToast('Template has been deleted!'));
    })
    .catch((error) => console.error(error));

  return next(action);
};
