import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';

const TemplateActionBar = (props) => {
  const { onRemoveRow } = props;

  return (
    <Button
      size="small"
      onClick={onRemoveRow}
    >
      -
    </Button>
  );
};

TemplateActionBar.propTypes = {
  onRemoveRow: PropTypes.func.isRequired,
};

export default TemplateActionBar;
