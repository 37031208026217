import Modal from '@amzn/meridian/modal';
import Select, { SelectOption } from '@amzn/meridian/select';
import PropTypes from 'prop-types';
import React from 'react';
import { PromotionStatuses } from '../../constants';

const PromoStatusModal = (props) => {
  const {
    isOpen,
    status,
    onSubmit,
    onClose,
  } = props;

  if (!isOpen) {
    return null;
  }

  const statusOptions = Object.keys(PromotionStatuses).map((key ) => {
    const {value, display} = PromotionStatuses[key];
    return <SelectOption key={value} value={value} label={display} />
  });
  return (
    <Modal
      title="Update Status"
      open={isOpen}
      onClose={onClose}
      scrollContainer="modal"
    >
      <Select
        label="Choose a new status"
        value={status}
        onChange={onSubmit}
        width={300}
      >
        {statusOptions}
      </Select>
    </Modal>
  );
};

PromoStatusModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string,
};

PromoStatusModal.defaultProps = {
  isOpen: false,
  status: null,
};

export default PromoStatusModal;
