import { writeClipboardContent } from "../../helpers/clipboardCopyPaste";
import { getCopiedContent } from "../../helpers/digitalCircular";
import { onOpenToast } from "../../actionCreators";
import { Language } from "../../constants";

export default (dispatch, getState, action, next) => {
  const state = getState();
  const {
    PreviewInstructions: { digitalCircularParams },
  } = state;
  const {
    payload: { contentType },
  } = action;

  const copiedContent = getCopiedContent(contentType, digitalCircularParams);

  if (!copiedContent) {
    return next(action);
  }

  writeClipboardContent(copiedContent)
    .then(() => {
      dispatch(onOpenToast(Language.CLIPBOARD_COPY_SUCCESS, 0));
    })
    .catch(() => {
      dispatch(onOpenToast(Language.CLIPBOARD_COPY_ERROR));
    });

  return next(action);
};
