import _ from 'lodash';
import { change, formValueSelector } from 'redux-form';
import { Columns, Form } from '../../constants';
import extractAsins from '../../helpers/extractAsins';
import { onAsinGetProductImages } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const selector = formValueSelector(Form.PROMO);

  const { payload: asinString } = action;
  const picturedAsins = selector(state, Columns.PICTURED_ASINS.name);
  const asins = extractAsins(asinString) || [];

  if (picturedAsins && Array.isArray(picturedAsins)) {
    const newPicturedAsins = asins.filter((asin) => picturedAsins.includes(asin));
    if (!_.isEqual(newPicturedAsins, picturedAsins)) {
      const [firstPicturedAsin = ''] = newPicturedAsins;

      dispatch(onAsinGetProductImages(firstPicturedAsin));
      dispatch(change(Form.PROMO, Columns.PICTURED_ASINS.name, newPicturedAsins));
    }
  }

  return next(action);
}