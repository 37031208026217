import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import sitewideStyles from '../../../styles/sitewide.module.scss';

const Messages = ({ meta }) => {
  if (!meta) {
    return null;
  }
  const { touched, error, warning } = meta;

  const errorMessage = error && (
    <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)}>
      {error}
    </p>
  );
  const warningMessage = warning
    && (
      <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)}>
        {warning}
      </p>
    );

  return (touched && (errorMessage || warningMessage)) || '';
};

Messages.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

Messages.defaultProps = {
  meta: null,
};

export default Messages;
