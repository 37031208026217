import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AsinImagePicker from './AsinImagePicker';
import {
  onAsinGetProductImages,
  onAsinSelectImage,
  onAsinSetProductImages,
} from '../../../../../actionCreators';
import { Form, Columns } from '../../../../../constants';
import extractAsins from '../../../../../helpers/extractAsins';

const selector = formValueSelector(Form.PROMO);

const getSelectedAsin = (state) => {
  const selectedAsins = selector(state, Columns.PICTURED_ASINS.name);
  if (!selectedAsins) {
    return '';
  }

  const extractedAsins = extractAsins(selectedAsins);
  if (!extractedAsins || !extractedAsins.length) {
    return '';
  }

  return extractedAsins[0];
};

const mapStateToProps = (state) => {
  const {
    AsinImages: {
      images,
      isLoading,
    },
  } = state;

  return {
    images,
    isLoading,
    selectedAsin: getSelectedAsin(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadSecondaryImages(asin) {
      dispatch(onAsinGetProductImages(asin));
    },
    onResetSecondaryImages() {
      dispatch(onAsinSetProductImages([]));
    },
    onSecondaryImageSelect(imageIndex) {
      dispatch(onAsinSelectImage(imageIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinImagePicker);
