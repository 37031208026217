import pasteFromClipboard from './pasteFromClipboard';
import copyToClipboard from './copyToClipboard';
import { ClipboardActions } from '../../constants/clipboard';

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === ClipboardActions.ON_COPY) {
    return copyToClipboard(dispatch, getState, action, next);  
  }
  
  if (action.type === ClipboardActions.ON_PASTE) {
    return pasteFromClipboard(dispatch, getState, action, next);
  }
  
  return next(action);
}