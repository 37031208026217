import React from 'react';
import Promotions from '../../Promotions';
import { PageNames } from '../../../constants/metrics';
import WithHistoryTiming from '../../WithHistoryTiming';

const PromotionWeekPage = () => {
  return <Promotions />;
};

export default WithHistoryTiming(PromotionWeekPage, { pageName: PageNames.PROMOTION_WEEK });
