import {
  MetadataEditorActions,
} from '../../constants';

export const initialState = {
  selectedField: null,
  dependencies: [],
  quickCreateModalVisible: false,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case MetadataEditorActions.SELECT_FIELD: {
      return {
        ...state,
        selectedField: action.data,
      };
    }
    case MetadataEditorActions.SET_DEPENDENCIES: {
      return {
        ...state,
        dependencies: action.data,
      };
    }
    case MetadataEditorActions.SET_DEPENDENT_OPTION: {
      const { name, selection } = action.data;
      if (!state.dependencies) {
        return state;
      }
      const updatedDependencies = state.dependencies.map((dependency) => {
        if (name !== dependency.name) { return dependency; }
        return {
          ...dependency,
          selection,
        };
      });
      return {
        ...state,
        dependencies: updatedDependencies,
      };
    }
    default: {
      return state;
    }
  }
};
