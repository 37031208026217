import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Tooltip from '@amzn/meridian/tooltip';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import { DeleteIcon, EditIcon } from '../Icons';
import { Language, Urls } from '../../constants';
import styles from './TemplateList.module.scss';

const TemplateList = (props) => {
  const {
    templates,
    onRemoveTemplate,
  } = props;

  const history = useHistory();

  const onEdit = (id) => () => {
    history.push(`${Urls.TEMPLATE_EDIT}/${id}`);
  };
  const onCreate = () => {
    history.push(Urls.TEMPLATE_NEW);
  };
  const onDelete = (id) => () => {
    if (window.confirm(Language.GENERIC_ON_DELETE_CONFIRMATION)) {
      onRemoveTemplate(id);
    }
  };

  const getNewTemplateButton = () => {
    return (
      <Button onClick={onCreate}>
        <Icon tokens={plusCircleTokens} />Add New Template
      </Button>
    );
  };

  const getButton = (buttonConfig) => {
    const {
      icon: Icon,
      onClick,
      hintName,
      position = 'right',
      testId,
      iconCustomStyle,
    } = buttonConfig;
    const iconBaseStyle = { color: 'black' };

    const iconStyle = {
      ...iconBaseStyle,
      ...iconCustomStyle,
    };

    return (
      <Tooltip
        key={testId}
        position={position}
        title={hintName}
      >
        <Button
          size="small"
          type="link"
          onClick={onClick}
          data-testid={testId}
        >
          <Icon style={iconStyle} />
        </Button>
      </Tooltip>
    );
  };

  const getButtonsList = (id) => {
    const buttonsList = [
      {
        icon: EditIcon,
        onClick: onEdit(id),
        testId: 'edit',
        hintName: Language.ACTION_BUTTON_EDIT,
      },
      {
        icon: DeleteIcon,
        onClick: onDelete(id),
        testId: 'delete',
        hintName: Language.ACTION_BUTTON_DELETE,
      },
    ];

    return buttonsList.map((buttonConfig) => getButton(buttonConfig));
  };

  const rowsList = templates.map(({ id, name }) => {
    const buttonsList = getButtonsList(id);

    return (
      <TableRow key={name}>
        <TableCell>{buttonsList}</TableCell>
        <TableCell>{name}</TableCell>
      </TableRow>
    );
  });

  const addNewTemplateButton = getNewTemplateButton();

  return (
    <div className={styles.container}>
      <p>{addNewTemplateButton}</p>
      <div>
        <Table
          headerRows={1}
          spacing="small"
        >
          <TableRow>
            <TableCell>Actions</TableCell>
            <TableCell>Name</TableCell>
          </TableRow>
          {rowsList}
        </Table>
      </div>
    </div>
  );
};

TemplateList.propTypes = {
  templates: PropTypes.array,
  onRemoveTemplate: PropTypes.func.isRequired,
};

TemplateList.defaultTypes = {
  templates: [],
}

export default TemplateList;
