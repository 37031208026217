import { connect } from 'react-redux';

import getColumnDisplayByColumnName from '../../../helpers/getColumnDisplayByColumnName';

import DependencyDisplay from './DependencyDisplay';

export const mapStateToProps = (state, ownProps) => {
  const { path: [selectedField, ...dependentOptions] } = ownProps;
  const {
    Meta: { metadata: { fields } },
  } = state;

  const { dependsOn } = fields[selectedField] || {};
  const dependencies = dependentOptions.map((option, index) => {
    let field = 'unknown';
    if (dependsOn[index]) {
      field = getColumnDisplayByColumnName(dependsOn[index]);
    }
    return {
      field,
      option,
    };
  });

  return {
    dependencies,
  };
};

export default connect(mapStateToProps)(DependencyDisplay);
