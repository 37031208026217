import { BannerActions } from '../../constants/banner';

export const initialState = {
  banners: [],
  isModalOpen: false,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case BannerActions.SET_BANNERS: {
      const { payload: { banners } } = action;

      return {
        ...state,
        banners,
      };
    }
    case BannerActions.OPEN_MODAL: {
      const { payload: { isModalOpen } } = action;

      return {
        ...state,
        isModalOpen,
      };
    }
    case BannerActions.RESET_BANNERS: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};
