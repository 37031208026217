import {
  SubscriptionActions,
} from '../../constants/subscriptions';
import receivedPromotion from './receivedPromotion';
import deletedPromotion from './deletedPromotion';
import receivedPromotionWeek from './receivedPromotionWeek';
import deletedPromotionWeek from './deletedPromotionWeek';

export default (store) => (next) => (action) => {
  const { getState, dispatch } = store;
  const { type } = action;

  if ([
    SubscriptionActions.PROMOTION_ADDED,
    SubscriptionActions.PROMOTION_UPDATED,
  ].includes(type)) {
    const isNewPromotion = type === SubscriptionActions.PROMOTION_ADDED;

    return receivedPromotion(isNewPromotion)(dispatch, getState, action, next);
  }

  if (type === SubscriptionActions.PROMOTION_DELETED) {
    return deletedPromotion(dispatch, getState, action, next);
  }

  if ([
    SubscriptionActions.PROMOTION_WEEK_ADDED,
    SubscriptionActions.PROMOTION_WEEK_UPDATED,
  ].includes(type)) {
    const isNewPromotion = type === SubscriptionActions.PROMOTION_WEEK_ADDED;

    return receivedPromotionWeek(isNewPromotion)(dispatch, getState, action, next);
  }

  if (type === SubscriptionActions.PROMOTION_WEEK_DELETED) {
    return deletedPromotionWeek(dispatch, getState, action, next);
  }

  return next(action);
};
