import { connect } from 'react-redux';
import moment from 'moment';
import Calendar from './Calendar';
import {
  onSelectTimeFrame,
} from '../../actionCreators';
import { getPromotionWeeks } from '../../selectors/promotions';
import { isCurrentUserAdmin } from '../../selectors/user';

const now = moment().format('YYYY-MM-DD 00:00');

const mapStateToProps = (state) => {
  const {
    Sitewide: { yearQuarter, timeFrame },
  } = state;

  return {
    isAdmin: isCurrentUserAdmin(state),
    yearQuarter,
    timeFrame: timeFrame || now,
    timeNow: now,
    promotionWeeks: getPromotionWeeks(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTimeFrameChange(value) {
      dispatch(onSelectTimeFrame(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
