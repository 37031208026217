import devConfig from './cognito-dev';
import betaConfig from './cognito-beta';
import prodConfig from './cognito-prod';
import { getDeployedLocation } from '../helpers/env';
import { DeployLocations } from '../constants';

const config = () => {
  switch (getDeployedLocation()) {
    case DeployLocations.DEV:
      return devConfig;
    case DeployLocations.BETA:
      return betaConfig;
    case DeployLocations.PROD:
      return prodConfig;
    default:
      return devConfig;
  }
};

export default config();
