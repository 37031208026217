import { connect } from 'react-redux';

import { defaultStateToProps, defaultDispatchToProps } from '../helpers';
import { getDependentOptionsForFieldOnCampaign } from '../../../helpers/getMetadataOptions';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import getPromotionById from '../../../reducers/gridViewPage/getPromotionById';

import MultiSelect from '../../StyledInputs/MultiSelect';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: { activeCell: { rowId, columnName } },
    Meta: { metadata: { fields } },
  } = state;

  const columnData = fields[columnName];
  let options = [];
  if (columnData && columnData.options && Array.isArray(columnData.options)) {
    options = columnData.options;
  }
  if (columnData && columnData.dependsOn) {
    const campaign = getPromotionById(state.GridViewPage, rowId);
    options = getDependentOptionsForFieldOnCampaign(
      columnData.options,
      campaign,
      columnData.dependsOn,
    );
  }

  return {
    ...defaultStateToProps(state),
    options,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
