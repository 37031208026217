import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import styles from './AsinImagePicker.module.scss';

const AsinImagePicker = (props) => {
  const {
    selectedAsin,
    images,
    onSecondaryImageSelect,
    onLoadSecondaryImages,
    onResetSecondaryImages,
    selectFirstAsinOnFirstLoad = false,
    isLoading,
  } = props;

  const [activeImageIndex, setActiveImageIndex] = useState(-1);

  useEffect(() => {
    if (selectFirstAsinOnFirstLoad) setActiveImageIndex(0);
    onLoadSecondaryImages(selectedAsin);

    return () => {
      onResetSecondaryImages();
    };
  }, []);

  const onImageClick = (index) => () => {
    setActiveImageIndex(index);
    onSecondaryImageSelect(index);
  };

  const getImages = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }
    if (!images) {
      return <p>No images to display</p>;
    }

    return images.map((image, index) => (
      <img
        className={classnames({
          [styles.activeImage]: activeImageIndex === index,
        })}
        src={image.smallSize}
        key={index}
        onClick={onImageClick(index)}
        alt={`#${index}`}
      />
    ));
  };

  const imagesList = getImages();

  return (
    <div className={styles.imagePickerContainer}>
      {imagesList}
    </div>
  );
};

export default AsinImagePicker;
