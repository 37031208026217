import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian/modal';
import Heading from '@amzn/meridian/heading';

import styles from './NewUserExperience.module.scss';

const NewUserExperience = ({
  isOpen,
  onBusinessSelect,
  businesses,
}) => {
  const header = (
    <div className={styles.header}>
      <Heading level={5}>Hey, it looks like you&apos;re new here.</Heading>
      Please select a business to get started.
    </div>
  );

  const contents = businesses.map((business) => {
    const onClick = () => { onBusinessSelect(business.name); };
    return (
      <div
        key={business.name}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
        className={styles.selection}
        data-testid="selection"
      >
        {business.label}
      </div>
    );
  });

  return (
    <Modal open={isOpen}>
      {header}
      {contents}
    </Modal>
  );
};

NewUserExperience.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBusinessSelect: PropTypes.func.isRequired,
  businesses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};

export default NewUserExperience;
