import React from 'react';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import signOutTokens from '@amzn/meridian-tokens/base/icon/sign-out';
import UserAvatar from '../UserAvatar';
import { userPanelProps } from '../../proptypes';
import styles from './UserPanel.module.scss';

const UserPanel = ({
  username, realName, onSignOut,
}) => {
  const userAvatarBlock = (
    <section className={styles.avatarSection}>
      <UserAvatar username={username} />
    </section>
  );

  const welcomeBlock = (
    <section className={styles.userSection}>
      <div className={styles.welcomeMessage}>
        Hello,
        {' '}
        {realName}
      </div>
    </section>
  );

  const signOutBlock = (
    <section className={styles.signOutSection}>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <Link
        onClick={onSignOut}
        type="secondary"
      >
        <Icon tokens={signOutTokens} />
      </Link>
    </section>
  );

  return (
    <article className={styles.userPanel}>
      {userAvatarBlock}
      {welcomeBlock}
      {signOutBlock}
    </article>
  );
};

UserPanel.propTypes = userPanelProps;

export default UserPanel;
