import { API, graphqlOperation } from 'aws-amplify';
import {
  onOpenToast,
  onCreatePromotions,
} from '../../actionCreators';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { getTemplateById } from '../../helpers/templates';
import { localToAppsync } from '../../helpers/translatePromotionWeek';
import { getYearQuarter } from '../../helpers/common';

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }

  const {
    User: { currentUser: { username } },
  } = state;

  const {
    payload: {
      week,
    },
  } = action;

  const {
    templateId,
    startDate,
    endDate,
  } = week;

  const yearQuarter = getYearQuarter(startDate);
  const template = getTemplateById(state, templateId);
  const translatedPromotionWeek = localToAppsync(week);

  const promotionWeek = {
    ...translatedPromotionWeek,
    templateName: template ? template.name : 'N/A',
    template: JSON.stringify(template) || '',
    yearQuarter,
  };

  const onPromoWeekCreated = (response) => {
    if (!response || !response.data || !response.data.createPromotionWeek) {
      dispatch(onOpenToast('Error while creating a promotion week!'));
      return;
    }
    const { data: { createPromotionWeek: { name } } } = response;

    dispatch(onOpenToast(`Promo week '${name}' has been created!`));

    dispatch(onCreatePromotions({
      templateId,
      startDate,
      endDate,
      yearQuarter,
    }));
  };

  const onCreatePromoWeek = (isPromoWeekExist) => {
    if (isPromoWeekExist) {
      return false;
    }

    const params = {
      promotionWeek,
      username,
    };

    return API.graphql(graphqlOperation(mutations.createPromotionWeek, { input: params }));
  };

  const onCheckPromoWeekExist = ({ data: { getPromotionWeek } }) => {
    if (getPromotionWeek && getPromotionWeek.length > 0) {
      dispatch(onOpenToast('Error! Promotion week with the selected date range already exists!'));

      return true;
    }

    return false;
  };

  API
    .graphql(graphqlOperation(queries.getPromotionWeek, {
      yearQuarter,
      startDate_endDate: `${startDate}_${endDate}`,
    }))
    .then(onCheckPromoWeekExist)
    .then(onCreatePromoWeek)
    .then(onPromoWeekCreated)
    .catch((error) => console.error(error));

  return next(action);
};
