import React from 'react';
import TemplateList from '../TemplateList';
import { PageNames } from '../../constants/metrics';
import WithHistoryTiming from '../WithHistoryTiming';

const TemplatesPage = (props) => {
  return <TemplateList {...props} />;
};

export default WithHistoryTiming(TemplatesPage, { pageName: PageNames.TEMPLATES });
