import { connect } from 'react-redux';
import EditTemplatePage from './edit-template';
import { getTemplateById } from '../../../../helpers/templates';

export const mapStateToProps = (state, props) => {
  const { match: { params: { id } } } = props;

  return {
    template: getTemplateById(state, id),
  };
};

export default connect(mapStateToProps)(EditTemplatePage);
