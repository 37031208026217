/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/meridian/alert';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import { HashLink as Link } from 'react-router-hash-link';
import { promotionColumnProps } from '../../../proptypes';
import GridView from '../../GridView';
import styles from '../BulkImporter.module.scss';
import { COMMA_DELIMETER } from '../../../constants';

const getErrors = (rejected) => {
  if (!rejected || !rejected.length) {
    return null;
  }

  const errorList = rejected.map((rejectedRow) => {
    const {
      errors,
      page,
      zoneNumber,
      storeRegions,
    } = rejectedRow;

    const formattedStoreRegions = Array.isArray(storeRegions)
      ? storeRegions.join(`${COMMA_DELIMETER}`)
      : storeRegions;

    return Object
      .entries(errors)
      .map(([columnName, { text }]) => {
        return (
          <li className={styles.errorItem} key={text}>
            <div>
              Page | Zone | Store Regions: <strong>{page}</strong> | <strong>{zoneNumber}</strong> | <strong>{formattedStoreRegions || '<empty>'}</strong>
              <br />
              Column(s): <strong>{columnName}</strong>
              <br />
              Error message: <strong>{text}</strong>
            </div>
          </li>
        );
      });
  });

  return (
    <ul className={styles.errorList}>{errorList}</ul>
  );
};

const BulkImportPreview = ({
  create,
  update,
  rejected,
  formatIssues,
  columnOrder,
  isImporting,
  onPerformImport,
  onCancel,
}) => {

  const getErrorDetailsInfo = () => {
    if (!rejected || !rejected.length) {
      return null;
    }

    return (
      <div className={styles.errorDetailsInfo}>
        <Alert type="error">
          <p className={styles.errorDetailsTitle}>The following error(s) have occurred:</p>
          {getErrors(rejected)}
        </Alert>
      </div>
    );
  };

  const createView = create.length ? (
    <a id="create">
      <div className={styles.spacer}>
        <Heading level={3}>Promotions to be created</Heading>
      </div>
      <div className={styles.gridContainer}>
        <GridView
          promotions={create}
          columnOrder={columnOrder}
          showActionBar={false}
          isReadOnly
          isStickyHeader
        />
      </div>
    </a>
  ) : null;

  const updateView = update.length ? (
    <a id="update">
      <div className={styles.spacer}>
        <Heading level={3}>Promotions to be updated</Heading>
        <small>
          Note: existing promotions will be completely overwritten with the values below
        </small>
      </div>
      <div className={styles.gridContainer}>
        <GridView
          promotions={update}
          columnOrder={columnOrder}
          showActionBar={false}
          isReadOnly
          isStickyHeader
        />
      </div>
    </a>
  ) : null;

  const rejectedView = rejected.length ? (
    <a id="rejected">
      <div className={styles.spacer}>
        <Heading level={3}>Rejected rows</Heading>
      </div>
      {getErrorDetailsInfo()}
      <div className={styles.gridContainer}>
        <GridView
          promotions={rejected}
          columnOrder={columnOrder}
          showActionBar={false}
          isReadOnly
          isStickyHeader
        />
      </div>
    </a>
  ) : null;

  const formattingIssues = formatIssues > 0 ? (
    <a id="formatting">
      <div className={styles.spacer}>
        <Heading level={3}>Formatting Issues</Heading>
      </div>
      <div className={styles.messaging}>
        {`${formatIssues} rows were rejected due to formatting issues. This may be because the file has the wrong number of columns or the file is in the wrong format`}
      </div>
    </a>
  ) : null;

  const getLink = (to, name, count) => {
    const content = `${name} (${count})`;
    return count > 0
      ? <Tab value={name}><Link smooth to={`#${to}`} className={styles.tabLink}>{content}</Link></Tab>
      : null;
  };

  const getTitleBar = () => {
    const createLink = getLink('create', 'Create', create.length);
    const updateLink = getLink('update', 'Update', update.length);
    const rejectedLink = getLink('rejected', 'Rejected', rejected.length);
    const formatLink = getLink('formatting', 'Formatting Issues', formatIssues);

    return (
      <div className={styles.linkBar}>
        <TabGroup value="" onChange={() => {}}>
          {rejectedLink}
          {formatLink}
          {createLink}
          {updateLink}
        </TabGroup>
      </div>
    );
  };

  const header = (
    <>
      {getTitleBar()}
      <div className={styles.noticeWrapper}>
        <div className={styles.notice}>
          Note, this is a preview only! Press import button to create and update the promotions.
          <div className={styles.actions}>
            <Button onClick={onPerformImport} className={styles.button} disabled={isImporting}>
              Import
            </Button>
            <Button onClick={onCancel} className={styles.button} disabled={isImporting}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {header}
      {rejectedView}
      {formattingIssues}
      {createView}
      {updateView}
    </>
  );
};

BulkImportPreview.propTypes = {
  onPerformImport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isImporting: PropTypes.bool.isRequired,
  formatIssues: PropTypes.number.isRequired,
  columnOrder: promotionColumnProps.isRequired,
};

export default BulkImportPreview;
