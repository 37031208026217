import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { onSetPublishedPromotions } from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotions';

const translatePublishedPromotions = (publishedPromotions) => {
  try {
    return publishedPromotions.map((publishedPromotion) => {
      const parsedPromotions = JSON.parse(publishedPromotion.promotions);
      const translatedPromotions = parsedPromotions.map((promotion) => appsyncToLocal(promotion));

      return {
        ...publishedPromotion,
        promotions: translatedPromotions,
      };
    });
  } catch (error) {
    console.error(error);
  }
  return {};
};

export default (dispatch, getState, action, next) => {
  const {
    Sitewide: { yearQuarter },
  } = getState();

  const params = {
    yearQuarter,
  };

  const onResponse = ({ data: { getPublishedPromotionsForYearQuarter } }) => {
    if (getPublishedPromotionsForYearQuarter.length) {
      const translatedPromotions = translatePublishedPromotions(getPublishedPromotionsForYearQuarter);

      dispatch(onSetPublishedPromotions(translatedPromotions));
    }
  };

  API
    .graphql(graphqlOperation(queries.getPublishedPromotionsForYearQuarter, params))
    .then(onResponse)
    .catch((error) => console.error(error));

  return next(action);
};
