import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import {
  onOpenToast,
  onPopFromEditHistory,
  onUpdatePromotion,
} from '../../actionCreators';
import { ToastMessages } from '../../constants/message';
import { getLastEditedPromotionInCurrentWeek } from '../../selectors/promotions';
import { ModificationTypes } from '../../constants/auditLog';
import { getAuditLogEntryAtVersion } from '../../helpers/auditlog';
import * as queries from '../../graphql/queries';

/* eslint-disable consistent-return */
const handleUndo = async (dispatch, getState, action, next) => {
  const state = getState();
  const {
    Promo: {
      dates: [startDate, endDate],
    },
    GridViewPage: { activeCell },
    User: {
      editHistory: {
        [startDate + endDate]: currentWeekHistory,
      },
      currentUser: { username: currentUsername },
    },
  } = state;
  if (!currentWeekHistory) {
    return next(action);
  }
  if (currentWeekHistory.length === 0) {
    dispatch(onPopFromEditHistory(startDate, endDate));
    if (!activeCell) {
      dispatch(onOpenToast(ToastMessages.NO_MORE_UNDO));
    }
    return next(action);
  }
  const { id, versionBeforeUpdate } = [...currentWeekHistory].pop();

  const auditLogResponse = await API.graphql(
    graphqlOperation(queries.getPromotionAuditLog, { id }),
  );
  const auditLog = auditLogResponse.data.getPromotionAuditLog;
  if (!auditLog || auditLog.length === 0) {
    return next(action);
  }
  const currentPromotion = getLastEditedPromotionInCurrentWeek(state);
  const {
    promotion: prevPromo,
  } = getAuditLogEntryAtVersion(auditLog, versionBeforeUpdate);
  const {
    promotion: updatedPromo,
    auditLogMetadata: { modificationType, username },
  } = getAuditLogEntryAtVersion(auditLog, versionBeforeUpdate + 1);
  if (modificationType !== ModificationTypes.UPDATE || username !== currentUsername) {
    return next(action);
  }
  Object.keys(prevPromo).forEach((field) => {
    if (field !== 'version' && !_.isEqual(prevPromo[field], updatedPromo[field])) {
      currentPromotion[field] = prevPromo[field];
    }
  });
  dispatch(onPopFromEditHistory(startDate, endDate));
  dispatch(onUpdatePromotion(currentPromotion, { isUndo: true }));
};
/* eslint-enable consistent-return */

export default handleUndo;
