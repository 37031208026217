import { connect } from 'react-redux';
import BulkImporterModal from './BulkImporterModal';
import { onCloseBulkImportModal } from '../../actionCreators/bulkImport';

export const mapStateToProps = (state) => {
  const {
    BulkImport: {
      isModalOpen,
    },
  } = state;

  return {
    isOpen: isModalOpen,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClose() {
      dispatch(onCloseBulkImportModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImporterModal);
