import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';
import styles from './PublishedPromotions.module.scss';
import HorizontalLoader from '../HorizontalLoader';
import { UNDERSCORE_DELIMETER, Urls } from '../../constants';

const PublishedPromotions = (props) => {
  const history = useHistory();
  const {
    isLoading,
    loadPublishedPromotions,
    onDateRangeChange,
    publishedPromotions,
    yearQuarter,
  } = props;

  useEffect(() => {
    loadPublishedPromotions();
  }, [loadPublishedPromotions]);

  const onWeekClick = (start, end) => () => {
    onDateRangeChange(start, end);
    history.push(`${Urls.WEEK_PUBLISHED}/${start}/${end}`);
  };

  const getWeeksList = () => {
    const weeksList = publishedPromotions.map((promoWeek) => {
      const { startDate_endDate } = promoWeek;

      const [start, end] = startDate_endDate.split(UNDERSCORE_DELIMETER);

      const formattedStartDate = moment(start).format('MMM Do');
      const formattedEndDate = moment(end).format('MMM Do');

      return (
        <li key={startDate_endDate} className={styles.listItem}>
          <Link
            onClick={onWeekClick(start, end)}
            type="secondary"
          >
            {formattedStartDate} - {formattedEndDate}
          </Link>
        </li>
      );
    });

    return (
      <div>
        <p>{publishedPromotions.length} promotion weeks are published for <strong>{yearQuarter}</strong>:</p>
        <ul className={styles.list}>
          {weeksList}
        </ul>
      </div>
    );
  };

  const loader = isLoading ? <HorizontalLoader /> : null;
  const content = getWeeksList();

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <Box type="outline" spacingInset="400">
          <Text type="b300">Each week's published promotions can be viewed in this tab. This is a read-only page.</Text>
          <Text type="b300">Showing published promotion weeks for the selected quarter.</Text>
        </Box>
      </div>
      {loader}
      {content}
    </div>
  );
};

PublishedPromotions.propTypes = {
  isLoading: PropTypes.bool,
  loadPublishedPromotions: PropTypes.func.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  yearQuarter: PropTypes.string.isRequired,
  publishedPromotions: PropTypes.arrayOf(PropTypes.shape({
    activeWeek: PropTypes.string,
  })),
};

PublishedPromotions.defaultProps = {
  isLoading: false,
  publishedPromotions: [],
};

export default PublishedPromotions;
