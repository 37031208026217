import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import RadioButton from '@amzn/meridian/radio-button';
import styles from './ExportModal.module.scss';
import { PromotionExportTypes } from '../../constants/export';
import HorizontalLoader from '../HorizontalLoader';
import { isPublishedViewType, isViabilityExportType } from '../../helpers/export';

const ExportModal = ({
  isLoading,
  isExportModalOpen,
  onExport,
  onCloseModal,
  onOptionChange,
  exportType,
  exportViewType,
}) => {
  const onClickExport = () => {
    onExport();
    onCloseModal();
  };
  const getOptionsList = () => {
    const options = [];
    Object.values(PromotionExportTypes).forEach((option) => {
      const { value, display } = option;
      if (isPublishedViewType(exportViewType) && isViabilityExportType(value)) {
        return;
      }
      options.push((
        <div key={value}>
          <RadioButton
            checked={exportType === value}
            onChange={onOptionChange}
            name="exportType"
            value={value}
          >
            {display}
          </RadioButton>
        </div>
      ));
    });

    return options;
  };

  const getFooter = () => {
    return (
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={onCloseModal}>Cancel</Button>
          <Button
            type="secondary"
            size="small"
            onClick={onClickExport}
          >
            Export
          </Button>
        </Row>
      </ModalFooter>
    );
  };

  const optionsList = getOptionsList();
  const footer = getFooter();

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <HorizontalLoader />
      </div>
    );
  }

  return (
    <Modal open={isExportModalOpen}>
      <div className={styles.header}>Export Type</div>
      {optionsList}
      {footer}
    </Modal>
  );
};

ExportModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isExportModalOpen: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  exportType: PropTypes.string.isRequired,
  exportViewType: PropTypes.string.isRequired,
};

export default ExportModal;
