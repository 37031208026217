import { goBack } from 'connected-react-router';
import { API, graphqlOperation } from 'aws-amplify';
import {
  onLoadTemplates,
  onResetActiveTemplate,
  onOpenToast,
  onResetPreviewRows,
} from '../../actionCreators';
import * as mutations from '../../graphql/mutations';

export default (dispatch, getState, action, next) => {
  const {
    GridViewPage: { previewRows },
    Templates: { name, activeTemplate },
  } = getState();

  const isNew = !activeTemplate;

  if (isNew && !name) {
    dispatch(onOpenToast('You have to specify a name for your template!'));
    return next(action);
  }
  if (!isNew && !activeTemplate) {
    dispatch(onOpenToast(`Cannot update template '${name}'!`));
    return next(action);
  }

  if (!previewRows || !previewRows.length) {
    return next(action);
  }

  const rows = [...previewRows];

  rows.forEach((item) => {
    delete item.id;
  });

  const content = {
    name,
    rows,
  };

  const {
    User: { currentUser: { username } },
  } = getState();

  const template = {
    name,
    promoConfiguration: JSON.stringify(content),
    version: isNew ? 1 : activeTemplate.version + 1,
  };

  const params = {
    template,
    username,
  };

  if (!isNew) {
    params.id = activeTemplate.id;
  }

  const onResponse = () => {
    const actionName = isNew ? 'created' : 'updated';
    dispatch(onOpenToast(`Template has been ${actionName}!`));
    dispatch(onLoadTemplates());
  };

  const query = isNew ? mutations.createTemplate : mutations.updateTemplate;

  API
    .graphql(graphqlOperation(query, { input: params }))
    .then(onResponse)
    .finally(() => {
      dispatch(onResetActiveTemplate());
      dispatch(onResetPreviewRows());
      dispatch(goBack());
    })
    .catch((error) => console.error(error));

  return next(action);
};
