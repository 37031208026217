import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../StyledInputs/Input';

import styles from '../MetadataAuditLog.module.scss';

const FilterDisplay = ({
  onMetadataAuditLogSetFilterString,
  filterString,
}) => {
  return (
    <div className={styles.filterContainer}>
      <div className={styles.titleWrapper}>
        Filter Audit Logs
      </div>
      <div className={styles.inputWrapper}>
        <Input
          data-testid="input"
          inputValue={filterString}
          onInputChange={onMetadataAuditLogSetFilterString}
        />
      </div>
    </div>
  );
};

FilterDisplay.propTypes = {
  onMetadataAuditLogSetFilterString: PropTypes.func.isRequired,
  filterString: PropTypes.string.isRequired,
};

export default FilterDisplay;
