import { connect } from 'react-redux';
import { defaultDispatchToProps, defaultStateToProps } from '../helpers';
import Input from '../Input/Input';

export const mapStateToProps = (state) => ({
  ...defaultStateToProps(state),
});

export const mapDispatchToProps = (dispatch) => ({
  ...defaultDispatchToProps(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)((props) => Input({
  ...props,
  type: 'number',
}));
