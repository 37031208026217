import { change } from 'redux-form';
import { Columns, Form } from '../../constants';
import { getAsinImageByIndex } from '../../helpers/images';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { imageIndex } } = action;

  const selectedImage = getAsinImageByIndex(state, imageIndex);
  if (!selectedImage) {
    return next(action);
  }

  dispatch(change(Form.PROMO, Columns.ASIN_IMAGE.name, selectedImage.bigSize, true));

  return next(action);
};
