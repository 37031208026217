import {
  onAddedPromotionWeekReceived,
  onUpdatedPromotionWeekReceived,
} from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotionWeek';

export default (isNewPromotion) => (dispatch, getState, action, next) => {
  const { payload: { week } } = action;

  const actionName = isNewPromotion
    ? onAddedPromotionWeekReceived
    : onUpdatedPromotionWeekReceived;

  dispatch(actionName(appsyncToLocal(week)));

  return next(action);
};
