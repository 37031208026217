import {
  onAddPromotion,
  onOpenToast,
  onLoadWeeksForYearQuarters,
} from '../../actionCreators';
import { getTemplateById } from '../../helpers/templates';
import { Columns } from '../../constants';
import { createPromotionShell } from '../../helpers/promotions';
import { onBulkImportSetForImport } from '../../actionCreators/bulkImport';

const hasAllRequiredValues = (obj) => [
  [Columns.TEMPLATE_ID.name],
  [Columns.START_DATE.name],
  [Columns.END_DATE.name],
].every((prop) => obj[prop]);

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (!action.payload) {
    return next(action);
  }
  const {
    payload: {
      templateId,
      startDate,
      endDate,
      yearQuarter,
    },
  } = action;

  if (!hasAllRequiredValues(action.payload)) {
    dispatch(onOpenToast('Error! Some template params are missing!'));
    return next(action);
  }

  const selectedTemplate = getTemplateById(state, templateId);
  if (!selectedTemplate) {
    dispatch(onOpenToast('Cannot find selected template!'));
    return next(action);
  }

  const { promoConfiguration: { rows } } = selectedTemplate;

  const yearQuarter_startDate_endDate = `${yearQuarter}_${startDate}_${endDate}`;

  let count = 0;
  rows.forEach((promoTemplate) => {
    count++;
    const promotion = createPromotionShell({
      ...promoTemplate,
      yearQuarter_startDate_endDate,
    });

    dispatch(onAddPromotion(promotion, { isBulkAction: true }));
  });

  if (count === rows.length) {
    dispatch(onBulkImportSetForImport(rows.length));
    dispatch(onLoadWeeksForYearQuarters([yearQuarter], false));
  }

  return next(action);
};
