import { connect } from 'react-redux';
import PublishPromotionsModal from './PublishPromotionsModal';
import {
  sendPublishPromotionsRequest,
  setPublishPromotionsIsModalOpen,
} from '../../actionCreators/publishPromotions';
import {
  getPublishPromotionsRequestStatus,
  getPublishPromotionsResponse,
  isPublishPromotionsModalOpen
} from '../../selectors/publishPromotions';

export const mapStateToProps = (state) => {
  return {
    isModalOpen: isPublishPromotionsModalOpen(state),
    response: getPublishPromotionsResponse(state),
    requestStatus: getPublishPromotionsRequestStatus(state)
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    sendRequest() {
      dispatch(sendPublishPromotionsRequest());
    },
    setIsModalOpen(isModalOpen) {
      dispatch(setPublishPromotionsIsModalOpen(isModalOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishPromotionsModal);
