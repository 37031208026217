import { connect } from 'react-redux';

import { onCloseToast } from '../../actionCreators';

import ToastManager from './ToastManager';

const mapStateToProps = (state) => {
  const { Sitewide: { toasts } } = state;
  return {
    toasts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseToast: (id) => { dispatch(onCloseToast(id)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastManager);
