import { connect } from 'react-redux';
import AsinViabilityPanel from './AsinViabilityPanel';
import {
  onAsinValidatorSendAsins,
  onAsinValidatorSetDate,
} from '../../../actionCreators';

export const mapStateToProps = (state) => {
  const { AsinValidator: { isLoading, targetDate } } = state;

  return {
    isLoading,
    targetDate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSend(asins, startDate) {
      dispatch(onAsinValidatorSendAsins(asins, startDate));
    },
    onSetDate(date) {
      dispatch(onAsinValidatorSetDate(date));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinViabilityPanel);
