import {
  onCustomViewSetColumnOrder,
  onRemoveColumnsFromSearch,
} from '../../actionCreators';
import {
  ON_METADATA_RECEIVED,
  CustomViewActions,
} from '../../constants';
import { getHiddenColumnNames } from '../../helpers/customView';

const getCustomViewColumns = () => {
  try {
    return JSON.parse(window.localStorage.getItem(CustomViewActions.LOCAL_STORAGE_COLUMNS)) || null;
  } catch {
    return null;
  }
};

const setCustomViewColumns = (columns) => {
  const stringColumns = JSON.stringify(columns);
  return window.localStorage.setItem(CustomViewActions.LOCAL_STORAGE_COLUMNS, stringColumns);
};
const mergeNewColumns = (columnSettings, metadataColumnOrder) => {
  const knownColumns = columnSettings.map((column) => column.display);

  metadataColumnOrder.forEach((column) => { // add new columns from metadata to custom view
    if (!knownColumns.includes(column.display)) {
      columnSettings.push({ ...column, isVisible: true });
    }
  });
  columnSettings = columnSettings.filter(customColumn => { // remove deprecated columns that don't exist in metadata
    return metadataColumnOrder.some(metadataColumn => metadataColumn.display === customColumn.display)
  })

  return columnSettings;
};

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === ON_METADATA_RECEIVED) {
    next(action);
    const columnOrder = getCustomViewColumns();

    if (columnOrder) {
      const { Meta: { metadata: { columnOrder: metadataColumnOrder } } } = getState();
      const updatedColumnOrder = mergeNewColumns(columnOrder, metadataColumnOrder);
      dispatch(onCustomViewSetColumnOrder(updatedColumnOrder));
    }
    return;
  }

  if (action.type === CustomViewActions.SET_COLUMN_ORDER) {
    setCustomViewColumns(action.payload);
    dispatch(onRemoveColumnsFromSearch(getHiddenColumnNames(action.payload)));
  }

  next(action);
};
