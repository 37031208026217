import _ from 'lodash';
import {
  formValueSelector,
  change,
  autofill,
} from 'redux-form';
import {
  Columns,
  Form,
} from '../../constants';
import { applyPercentageDiscount } from '../../helpers/common';
import updatePicturedAsins from './updatePicturedAsins';
import updatePriceDisplay from './updatePriceDisplay';

export default (dispatch, getState, action, next) => {
  const { meta } = action;
  const state = getState();
  const selector = formValueSelector(Form.PROMO);

  // Whenever PROMOTION_TYPE gets changed to a new type, we need to also reset CIRCULAR_PRICE field
  // as it should be string/decimal:
  // PROMOTION_TYPE = POS -> CIRCULAR_PRICE is a string
  // PROMOTION_TYPE = Fixed price -> CIRCULAR_PRICE is a decimal
  if (meta.field === Columns.PROMOTION_TYPE.name) {
    const currentPromotionType = selector(state, Columns.PROMOTION_TYPE.name);
    const { payload: nextPromotionType } = action;

    if (nextPromotionType !== currentPromotionType) {
      dispatch(change(Form.PROMO, Columns.PRIME_MEMBER_LABEL.name, null));
      dispatch(change(Form.PROMO, Columns.CIRCULAR_PRICE.name, null));
    }
  }

  if (meta.field === Columns.ASINS.name) {
    updatePicturedAsins(dispatch, getState, action, next);
  }

  const {
    circularPrice,
    isPrimeBenefit,
    primeDiscount,
    primeMemberLabel,
  } = selector(
    state,
    Columns.CIRCULAR_PRICE.name,
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRIME_MEMBER_LABEL.name,
    Columns.PRIME_DISCOUNT.name,
  );

  if([
    Columns.CIRCULAR_PRICE.name,
    Columns.IS_PRIME_BENEFIT.name,
    Columns.PRICE_CONDITIONS.name,
    Columns.PRIME_MEMBER_LABEL.name,
    Columns.PROMOTION_TYPE.name,
    Columns.UNIT_OF_MEASURE.name
  ].includes(meta.field)) {
    updatePriceDisplay(dispatch, getState, action, next);
  }

  if (isPrimeBenefit) {
    // Using _.isFinite instead of _.isNumber because NaN is considered a number
    if (meta.field === Columns.PRIME_DISCOUNT.name && circularPrice && _.isFinite(Number(circularPrice))) {
      const { payload: newPrimeDiscount } = action;
      const discountedPrice = applyPercentageDiscount(
        circularPrice,
        newPrimeDiscount,
      ).toFixed(2);
      if (discountedPrice !== primeMemberLabel) {
        dispatch(
          autofill(Form.PROMO, Columns.PRIME_MEMBER_LABEL.name, discountedPrice),
        );
      }
    }
    if (meta.field === Columns.CIRCULAR_PRICE.name && primeDiscount) {
      const { payload: newCircularPrice } = action;
      if (_.isFinite(Number(newCircularPrice))) {
        const newDiscountedPrice = applyPercentageDiscount(
          newCircularPrice,
          primeDiscount,
        ).toFixed(2);
        if (newDiscountedPrice !== primeMemberLabel) {
          dispatch(
            autofill(Form.PROMO, Columns.PRIME_MEMBER_LABEL.name, newDiscountedPrice),
          );
        }
      }
    }
    if (meta.field === Columns.PRIME_MEMBER_LABEL.name && primeDiscount) {
      if (primeDiscount !== 0) {
        dispatch(autofill(Form.PROMO, Columns.PRIME_DISCOUNT.name, null));
      }
    }
  }
  if (meta.field === Columns.IS_PRIME_BENEFIT.name
    && circularPrice
    && _.isFinite(Number(circularPrice))
    && primeDiscount
    && !primeMemberLabel) {
    const { payload: newIsPrimeBenefit } = action;
    const discountedPrice = applyPercentageDiscount(
      circularPrice,
      primeDiscount,
    ).toFixed(2);
    if (newIsPrimeBenefit && discountedPrice !== primeMemberLabel) {
      dispatch(
        autofill(Form.PROMO, Columns.PRIME_MEMBER_LABEL.name, discountedPrice),
      );
    }
  }

  return next(action);
};
