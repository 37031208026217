import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { getYearQuarter, getSelectedDates } from '../../selectors/promotions';
import { onSetBanners } from '../../actionCreators/banner';
import { parseGraphQLResponse } from '../../helpers/api';
import { BannerColumns } from '../../constants/banner';
import { COMMA_DELIMETER } from '../../constants';
import { createBannerShell } from '../../helpers/banner';

const sortBySlotAndRegions = (banners) => {
  return banners.sort((a, b) => {
    const formattedStoreRegionsA = a[BannerColumns.STORE_REGIONS.name] ? a[BannerColumns.STORE_REGIONS.name].join(COMMA_DELIMETER) : '';
    const formattedStoreRegionsB = b[BannerColumns.STORE_REGIONS.name] ? b[BannerColumns.STORE_REGIONS.name].join(COMMA_DELIMETER) : '';

    return (
      a[BannerColumns.SLOT.name] - b[BannerColumns.SLOT.name]
      || formattedStoreRegionsA.localeCompare(formattedStoreRegionsB)
    );
  });
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  const yearQuarter = getYearQuarter(state);
  const [startDate, endDate] = getSelectedDates(state);

  if (!yearQuarter || !startDate || !endDate) {
    return next(action);
  }

  const params = {
    yearQuarter_startDate_endDate: `${yearQuarter}_${startDate}_${endDate}`,
  };

  const onResponse = (response) => {
    const existingBanners = parseGraphQLResponse(response);

    const banners = existingBanners && existingBanners.length
      ? sortBySlotAndRegions(existingBanners)
      : [createBannerShell({ slot: 0 })];

    dispatch(onSetBanners(banners));
  };

  API
    .graphql(graphqlOperation(queries.getBanners, params))
    .then(onResponse)
    .catch((error) => console.error(error));

  return next(action);
};
