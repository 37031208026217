import getPromotionById from './getPromotionById';
import selectCell from './selectCell';
import updateCellValue from './updateCellValue';
import addErrorToPromotion from './addErrorToPromotion';

import {
  GridViewActions,
  PromoActions,
  SET_PROMOTIONS,
  SET_PROMOTIONS_LOADING,
  SET_PROMOTIONS_LOADED,
  CLEAR_PROMOTIONS,
  ADD_ERROR_TO_PROMOTION,
  GridViewSource,
  Columns,
} from '../../constants';
import { PASTE_HERE_ROW_ID } from '../../helpers/gridView';

export const initialState = {
  selectedRowsIds: {},
  promotions: [],
  promotionIdHash: {},
  promotionsLoaded: false,
  promotionsLoading: false,
  translatedLogsForHighlight: {},
  paginationPage: 0,
  paginationItemsPerPage: 30,
  isExportModalOpen: false,
  isAddRowModalOpen: false,
  isColorLegendModalOpen: false,
  isEditReviewPromotionTableOpen: false,
  isImageReviewPromotionTableOpen: false,
  isContentWrappingEnabled: false,
  isMustFillHighlightEnabled: false,
  activeCell: null,
  activeCellValue: null,
  previewRows: [],
  source: GridViewSource.ROWS,
  searchTerms: {},
};

export default (state = initialState, action) => {
  const { source } = state;

  switch (action.type) {
    case GridViewActions.SELECT_CELL: {
      return selectCell(state, action);
    }
    case GridViewActions.UNSELECT_CELL: {
      return {
        ...state,
        activeCell: null,
        activeCellValue: null,
      };
    }
    case GridViewActions.SET_ACTIVE_CELL_EDIT_MODE: {
      const { payload: { isEditMode } } = action;

      return {
        ...state,
        activeCell: {
          ...state.activeCell,
          isEditMode,
        },
      }
    }
    case SET_PROMOTIONS: {
      const promotionsIdHash = {};
      const { payload: { promotions }} = action;
      const promotionsToAdd = promotions.filter((promotion) => {
        promotionsIdHash[promotion.id] = true;
        return !state.promotionIdHash[promotion.id];
      });
      return {
        ...state,
        promotions: [...state.promotions, ...promotionsToAdd],
        promotionIdHash: { ...state.promotionIdHash, ...promotionsIdHash },
      };
    }
    case SET_PROMOTIONS_LOADING: {
      return {
        ...state,
        promotionsLoading: action.data,
      };
    }
    case SET_PROMOTIONS_LOADED: {
      const { payload: { isLoaded } } = action;
      return {
        ...state,
        promotionsLoaded: isLoaded,
      };
    }
    case CLEAR_PROMOTIONS: {
      return {
        ...state,
        promotions: [],
        promotionsLoaded: false,
        promotionsLoading: false,
        promotionIdHash: [],
      };
    }
    case PromoActions.ADD_PROMOTION: {
      return state;
    }
    case GridViewActions.SET_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT: {
      const { payload: { translatedLogsForHighlight } } = action;

      return {
        ...state,
        translatedLogsForHighlight: {
          ...state.translatedLogsForHighlight,
          ...translatedLogsForHighlight,
        },
      };
    }
    case GridViewActions.CLEAR_TRANSLATED_AUDIT_LOGS_FOR_HIGHLIGHT: {
      return {
        ...state,
        translatedLogsForHighlight: {},
      };
    }
    case PromoActions.UPDATE_PROMOTION:
    case PromoActions.UPDATED_PROMOTION_RECEIVED: {
      const { payload: { promotion: currentPromotion } } = action;

      return {
        ...state,
        [source]: state[source].map((promotion) => {
          if (promotion.id !== currentPromotion.id) {
            return promotion;
          }

          return currentPromotion;
        }),
      };
    }
    case PromoActions.UPDATE_PROMOTION_STATUS: {
      const { payload: { id, status } } = action;
      return updateCellValue(state, id, Columns.STATUS.name, status);
    }
    case PromoActions.DELETE_PROMOTION:
    case PromoActions.DELETED_PROMOTION_RECEIVED: {
      const { payload: { promotion: { id } } } = action;

      return {
        ...state,
        [source]: state[source].filter((promotion) => promotion.id !== id),
      };
    }
    case GridViewActions.ACTIVE_CELL_INPUT_UPDATE: {
      return { ...state, activeCellValue: action.data };
    }
    case GridViewActions.CELL_INPUT_UPDATE: {
      const { payload: { promotionId, columnName, value } } = action;
      return updateCellValue(state, promotionId, columnName, value);
    }
    case GridViewActions.ACTIVE_CELL_INPUT_SUBMIT: {
      const { activeCellValue, activeCell: { rowId, columnName } } = state;
      return updateCellValue(state, rowId, columnName, activeCellValue);
    }
    case GridViewActions.RESET_ACTIVE_CELL: {
      const { activeCell } = state;
      if (!activeCell) { return state; }
      const activeCellValue = getPromotionById(state, activeCell.rowId)[activeCell.columnName];
      return { ...state, activeCellValue };
    }
    case GridViewActions.SET_PAGE: {
      return {
        ...state,
        paginationPage: action.data,
      };
    }
    case PromoActions.ADDED_PROMOTION_RECEIVED: {
      const { payload: { promotion: newPromotion } } = action;

      if (getPromotionById(state, newPromotion.id)) {
        return state;
      }

      return {
        ...state,
        [source]: [...state[source]].concat(newPromotion),
      };
    }
    case ADD_ERROR_TO_PROMOTION: {
      return addErrorToPromotion(state, action.data);
    }
    case GridViewActions.ADD_ROW: {
      const { payload: { row } } = action;

      return {
        ...state,
        [source]: [...state[source]].concat(row),
      };
    }
    case GridViewActions.REMOVE_ROW: {
      const { payload: { id } } = action;

      return {
        ...state,
        [source]: state[source].filter((item) => item.id !== id),
      };
    }
    case GridViewActions.SET_ROWS: {
      const { payload: { rows } } = action;

      return {
        ...state,
        [source]: rows,
      };
    }
    case GridViewActions.RESET_PREVIEW_ROWS: {
      return {
        ...state,
        previewRows: [],
      };
    }
    case GridViewActions.SET_SOURCE: {
      // eslint-disable-next-line no-shadow
      const { payload: { source } } = action;

      return {
        ...state,
        source,
      };
    }
    case GridViewActions.OPEN_REVIEW_PROMOTION_TABLE: {
      return {
        ...state,
        isEditReviewPromotionTableOpen: true,
      };
    }
    case GridViewActions.CLOSE_REVIEW_PROMOTION_TABLE: {
      return {
        ...state,
        isEditReviewPromotionTableOpen: false,
      };
    }
    case GridViewActions.OPEN_IMAGE_REVIEW_PROMOTION_TABLE: {
      return {
        ...state,
        isImageReviewPromotionTableOpen: true,
      };
    }
    case GridViewActions.CLOSE_IMAGE_REVIEW_PROMOTION_TABLE: {
      return {
        ...state,
        isImageReviewPromotionTableOpen: false,
      };
    }
    case GridViewActions.ADD_SEARCH_COLUMN: {
      const { payload: { search, columnName } } = action;
      return {
        ...state,
        searchTerms: {
          ...state.searchTerms,
          [columnName]: search === '' ? null : search,
        },
      };
    }
    case GridViewActions.ADD_SEARCH_COLUMNS: {
      const { payload: { columns } } = action;

      return {
        ...state,
        searchTerms: columns,
      };
    }
    case GridViewActions.CLEAR_SEARCH: {
      return {
        ...state,
        searchTerms: {},
      };
    }
    case GridViewActions.REMOVE_COLUMNS_FROM_SEARCH: {
      const { payload: { columns } } = action;
      const searchTermsToRemove = {};
      columns.forEach((column) => { searchTermsToRemove[column] = null; });
      return {
        ...state,
        searchTerms: {
          ...state.searchTerms,
          ...searchTermsToRemove,
        },
      };
    }
    case GridViewActions.OPEN_ADD_ROW_MODAL: {
      return {
        ...state,
        isAddRowModalOpen: true,
      };
    }
    case GridViewActions.CLOSE_ADD_ROW_MODAL: {
      return {
        ...state,
        isAddRowModalOpen: false,
      };
    }
    case GridViewActions.SET_COLOR_LEGEND_MODAL_OPEN: {
      const { payload: { isOpen } } = action;

      return {
        ...state,
        isColorLegendModalOpen: isOpen,
      };
    }
    case GridViewActions.ON_SELECT_ROW: {
      const { payload: { rowId } } = action;

      const newSelectedRowsIds = rowId === PASTE_HERE_ROW_ID
        ? {
          [rowId]: !state.selectedRowsIds[rowId],
        }
        : {
          ...state.selectedRowsIds,
          [rowId]: !state.selectedRowsIds[rowId],
        };

      return {
        ...state,
        selectedRowsIds: newSelectedRowsIds,
      };
    }
    case GridViewActions.ON_RESET_SELECTED_ROWS: {
      return {
        ...state,
        selectedRowsIds: {},
      };
    }
    case GridViewActions.SET_CONTENT_WRAPPING: {
      const { payload: { isEnabled } } = action;
      return {
        ...state,
        isContentWrappingEnabled: isEnabled,
      };
    }
    case GridViewActions.SET_MUST_FILL_HIGHLIGHT: {
      const { payload: { isEnabled } } = action;
      return {
        ...state,
        isMustFillHighlightEnabled: isEnabled,
      };
    }
    default: {
      return state;
    }
  }
};
