import {
  onAddPromotion,
  onCellInputUpdate,
  onOpenToast,
  onSendPromotionEdit,
} from '../../actionCreators';
import {
  onBulkImportActionCompleted,
  onBulkImportSetForImport,
  onBulkImportStartImporting,
} from '../../actionCreators/bulkImport';
import { getPromotionsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';
import { BulkImportActions } from '../../constants/bulkImport';
import { Columns } from '../../constants';
import { storeRegionsMatch } from '../../helpers/bulkImport';

export default (dispatch, state) => {
  const { BulkImport: { accepted: { create, update } } } = state;
  const isBulkAction = true;
  const expectedPromotionCount = create.length + update.length;

  dispatch(onBulkImportSetForImport(expectedPromotionCount));
  dispatch(onBulkImportStartImporting());
  create.forEach((campaignToCreate) => {
    dispatch(onAddPromotion(campaignToCreate, { isBulkAction }));
  });

  const promotions = getPromotionsForCurrentWeek(state);

  update.forEach((promotionToUpdate) => {
    const promotion = promotions.find((currentPromotion) => {
      return (
        String(currentPromotion.page) === String(promotionToUpdate.page)
        && String(currentPromotion.zoneNumber) === String(promotionToUpdate.zoneNumber)
        && storeRegionsMatch(currentPromotion.storeRegions, promotionToUpdate.storeRegions)
      );
    });

    if (!promotion) {
      dispatch(onOpenToast(`Update failed: Promotion "${promotionToUpdate.id}" not found.`, 0));
      dispatch(onBulkImportActionCompleted(
        promotionToUpdate.id,
        BulkImportActions.ERROR,
      ));
      return;
    }
    const rowId = promotion.id;
    const editedColumns = Object.keys(promotionToUpdate);

    editedColumns.forEach((column) => {
      if (promotion[column] !== promotionToUpdate[column]) {
        if (column === Columns.ID.name) {
          return;
        }
        dispatch(onCellInputUpdate(rowId, column, promotionToUpdate[column]));
      }
    });

    dispatch(onSendPromotionEdit({ rowId }, { isBulkAction }));
  });
};
