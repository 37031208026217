import { connect } from 'react-redux';
import PromotionWeekContainer from './PromotionWeekContainer';
import {
  onSetTab,
} from '../../actionCreators';
import { getWeekByStartEndDate } from '../../helpers/promotions';
import { TabTypes } from '../../constants';

export const mapStateToProps = (state, { startDate, endDate }) => {
  const {
    Tabs: {
      [TabTypes.PROMOTION_WEEK]: currentTab,
    },
  } = state;

  const week = getWeekByStartEndDate(state, {
    startDate,
    endDate,
  });

  return {
    currentTab,
    week,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchTab(tabName) {
      dispatch(onSetTab(TabTypes.PROMOTION_WEEK, tabName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionWeekContainer);
