import { getClipboardContent} from '../../helpers/clipboardCopyPaste';
import {
  getCurrentPromotionWeek,
  getCurrentWeekPromotions,
  getSelectedRowsIds
} from '../../selectors/promotions';
import { localToAppsync } from '../../helpers/translatePromotions';
import { Columns, COMMA_DELIMETER, Language, PromotionStatuses } from '../../constants';
import { onAddPromotion, onOpenToast, onUpdatePromotion } from '../../actionCreators';
import { getCustomColumnOrder } from '../../helpers/promotionRowsCopyPaste';
import {
  createPromotionShell,
  isAllowedPageZoneNumberCombination,
  isPromotionActive,
} from '../../helpers/promotions';
import { PASTE_HERE_ROW_ID } from '../../helpers/gridView';
import { getTemplateString } from '../../helpers/common';
import { getAllowedPageZoneCombinationsForCurrentWeek } from '../../selectors/promotionFiltersSelectors';
import { isCurrentUserAdmin } from '../../selectors/user';

const parseLines = (data) => {
  const lines = data.trim().split(/[\n]/gm);
  if (!lines) {
    return [];
  }

  return lines.map((line) => line.split(/[\t]/gm));
};

const getParsedRowsFromClipboard = (state, clipboardContent) => {
  const parsedLinesFromClipboard = parseLines(clipboardContent);
  const columnOrder = getCustomColumnOrder(state);

  return parsedLinesFromClipboard.map(parsedPromotion => {
    const promotion = {};
    columnOrder.forEach((column, index) => {
      promotion[column] = parsedPromotion[index];
    });

    return promotion;
  });
};

// If there is only one row copied on the clipboard, paste the single row into all selected rows,
// otherwise paste all copied on the clipboard rows into all selected rows.
const getParsedPromotionRows = (parsedRowsFromClipboard, selectedPromotions) => {
  if (parsedRowsFromClipboard.length === 1) {
    const [firstParsedRowFromClipboard] = parsedRowsFromClipboard;

    return selectedPromotions.map(({ id, version, status, isSoftDeleted }) => {
      return {
        ...createPromotionShell(firstParsedRowFromClipboard),
        id,
        version,
        status,
        isSoftDeleted,
      }
    });
  }

  return selectedPromotions
    .map(({ id, version, status, isSoftDeleted }, index) => {
      const parsedRowFromClipboard = parsedRowsFromClipboard[index];
      if (!parsedRowFromClipboard) {
        return null;
      }

      return {
        ...createPromotionShell(parsedRowFromClipboard),
        id,
        version,
        status,
        isSoftDeleted,
      }
    })
    .filter(promotion => promotion);
};

const getPromotionsToUpdate = (state, parsedRowsFromClipboard) => {
  if (!parsedRowsFromClipboard || !parsedRowsFromClipboard.length) {
    return [];
  }

  const selectedRowsIds = getSelectedRowsIds(state);
  if (!selectedRowsIds) {
    return [];
  }

  const selectedPromotions = getCurrentWeekPromotions(state)
    .filter(currentWeekPromotion => selectedRowsIds.includes(currentWeekPromotion.id));

  return getParsedPromotionRows(parsedRowsFromClipboard, selectedPromotions);
};

const convertPromotionsForAppSync = (state, promotions) => {
  const promotionWeek = getCurrentPromotionWeek(state);
  if (!promotionWeek) {
    return;
  }
  const { yearQuarter, startDate, endDate } = promotionWeek;

  return promotions.map(promotion => {
    return localToAppsync({
      ...promotion,
      [Columns.YEAR_QUARTER.name]: yearQuarter,
      [Columns.START_DATE.name]: startDate,
      [Columns.END_DATE.name]: endDate,
    });
  });
};

const throwInvalidPasteException = (errorText, placeholders) => {
  throw ({ pastePromotionError: getTemplateString(errorText, placeholders) });
};

const getUneditablePromotions = (state, promotions) => {
  const uneditablePromotions = [];

  promotions.forEach((promotionsToUpdate) => {
    if (!isPromotionActive(promotionsToUpdate, isCurrentUserAdmin(state))) {
      uneditablePromotions.push(promotionsToUpdate);
    }
  });

  return uneditablePromotions;
};

const getPageZoneEligibility = (state, promotions) => {
  const allowedPageZoneCombinations = getAllowedPageZoneCombinationsForCurrentWeek(state);
  const promotionsWithForbiddenPageZoneCombinations = [];

  promotions.forEach((promotion) => {
    if (!isAllowedPageZoneNumberCombination(promotion, allowedPageZoneCombinations)) {
      promotionsWithForbiddenPageZoneCombinations.push(promotion);
    }
  });

  return promotionsWithForbiddenPageZoneCombinations;
};

const getForbiddenPageZoneNumberCombinationList = (promotions) => {
  if (!promotions || !promotions.length) {
    return [];
  }

  return Array
    .from(new Set(promotions.map(({ page, zoneNumber }) => `${page}/${zoneNumber}`)))
    .join(COMMA_DELIMETER);
};

const getCreatePromotions = (state, parsedRowsFromClipboard) => {
  const promotionsToCreate = parsedRowsFromClipboard.map(row => createPromotionShell(row));

  const pageZoneEligibility = getPageZoneEligibility(
    state,
    promotionsToCreate,
  );

  if (pageZoneEligibility.length > 0) {
    throwInvalidPasteException(
      Language.PROMOTION_PASTE_ERROR_NOT_ALLOWED_PAGE_ZONE_NUMBER_COMBINATIONS,
      {
        count: pageZoneEligibility.length,
        invalidCombinations: getForbiddenPageZoneNumberCombinationList(
          pageZoneEligibility,
        ),
      }
    );
  }

  return promotionsToCreate;
};

const getUpdatePromotions = (state, parsedRowsFromClipboard) => {
  const promotionsToUpdate = getPromotionsToUpdate(state, parsedRowsFromClipboard);

  const uneditablePromotions = getUneditablePromotions(state, promotionsToUpdate);
  if (uneditablePromotions.length > 0) {
    throwInvalidPasteException(
      Language.PROMOTION_PASTE_ERROR_UNEDITABLE,
      {
        count: uneditablePromotions.length,
        status: PromotionStatuses.PRINT_LOCKED.display,
      }
    );
  }

  const pageZoneEligibility = getPageZoneEligibility(
    state,
    promotionsToUpdate,
  );

  if (pageZoneEligibility.length > 0) {
    throwInvalidPasteException(
      Language.PROMOTION_PASTE_ERROR_NOT_ALLOWED_PAGE_ZONE_NUMBER_COMBINATIONS,
      {
        count: pageZoneEligibility.length,
        invalidCombinations: getForbiddenPageZoneNumberCombinationList(
          pageZoneEligibility,
        ),
      }
    );
  }

  return promotionsToUpdate;
};

const processPromotions = (dispatch, promotions, isCreateMode) => {
  if (!promotions || !promotions.length) {
    return [];
  }
  const promotionActionType = isCreateMode ? onAddPromotion : onUpdatePromotion;

  promotions.forEach(promotion => {
    dispatch(promotionActionType(promotion));
  });
};

const handleExceptions = (dispatch, error) => {
  if (error.pastePromotionError) {
    dispatch(onOpenToast(error.pastePromotionError));

    return;
  }
  console.error('Error: ', error);
}

export default (dispatch, getState, action, next) => {
  const state = getState();
  const isCreateMode = !!getSelectedRowsIds(state).includes(PASTE_HERE_ROW_ID);

  getClipboardContent()
    .then((clipboardContent) => getParsedRowsFromClipboard(state, clipboardContent))
    .then((parsedRowsFromClipboard) => (isCreateMode
      ? getCreatePromotions(state, parsedRowsFromClipboard)
      : getUpdatePromotions(state, parsedRowsFromClipboard)))
    .then((promotions) => convertPromotionsForAppSync(state, promotions))
    .then((promotions) => processPromotions(dispatch, promotions, isCreateMode))
    .catch((error) => handleExceptions(dispatch, error));

  return next(action);
}