import { connect } from 'react-redux';
import AsinRecommendationModal from './AsinRecommendationModal';
import { onSetAsinRecommendationModalOpen } from '../../../../actionCreators';

const mapStateToProps = (state) => {
  const { AsinValidator: { isAsinRecommendationModalOpen, regionFilters, } } = state;

  return {
    isAsinRecommendationModalOpen,
    regionFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(onSetAsinRecommendationModalOpen(false));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AsinRecommendationModal);
