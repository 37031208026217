import {
  AsinValidatorActions,
  AsinValidatorViewMode,
} from '../../constants';

export const initialState = {
  isOpen: false,
  isLoading: false,
  targetDate: '',
  asinViabilities: [],
  errors: [],
  viewMode: AsinValidatorViewMode.COMPACT.name,
  selectedRegions: {},
  regionFilters: {},
  isAsinRecommendationModalOpen: false,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { payload } = action;

  switch (action.type) {
    case AsinValidatorActions.SET_OPEN: {
      const { isOpen } = payload;

      return {
        ...state,
        isOpen,
      };
    }
    case AsinValidatorActions.SET_LOADING: {
      const { isLoading } = payload;

      return {
        ...state,
        isLoading,
      };
    }
    case AsinValidatorActions.SET_DATE: {
      const { targetDate } = payload;

      return {
        ...state,
        targetDate,
      };
    }
    case AsinValidatorActions.SET_ASIN_VIABILITIES: {
      const { asinViabilities } = payload;

      return {
        ...state,
        asinViabilities,
      };
    }
    case AsinValidatorActions.SET_VIEW_MODE: {
      const { viewMode } = payload;

      return {
        ...state,
        viewMode,
      };
    }
    case AsinValidatorActions.SET_ERRORS: {
      const { errors } = payload;

      return {
        ...state,
        errors,
      };
    }
    case AsinValidatorActions.SET_ASIN_REGIONS: {
      const { asin, region } = payload;

      const currentRegions = state.selectedRegions[asin];
      let newRegions;
      if (currentRegions) {
        if (!currentRegions.includes(region)) {
          newRegions = [...currentRegions, region];
        } else {
          newRegions = currentRegions.filter((currentRegion) => currentRegion !== region);
        }
      } else {
        newRegions = [region];
      }

      const newSelectedRegions = {
        ...state.selectedRegions,
        [asin]: newRegions,
      };

      return {
        ...state,
        selectedRegions: newSelectedRegions,
      };
    }
    case AsinValidatorActions.INIT_REGION_FILTERS: {
      const { storeRegionList } = payload;

      const newRegionFilters = {};
      storeRegionList.forEach(({ storeRegionName }) => {
        newRegionFilters[storeRegionName] = false;
      });
    
      return {
        ...state,
        regionFilters: newRegionFilters,
      }
    }
    case AsinValidatorActions.SET_REGION_FILTER: {
      const { region } = payload;
      const { regionFilters } = state

      const selectedRegion = !regionFilters[region];

      const newSelectedRegions = {
        ...state.regionFilters,
        [region]: selectedRegion
      }
      return {
        ...state,
        regionFilters: newSelectedRegions
      };

    }
    case AsinValidatorActions.CLEAR_REGION_FILTERS: {
      return {
        ...state,
        regionFilters: {}
      };
    }

    case AsinValidatorActions.RESET: {
      return {
        ...initialState,
      };
    }
    case AsinValidatorActions.SET_ASIN_RECOMMENDATION_MODAL_OPEN: {
      const { payload: { isOpen } } = action;

      return {
        ...state,
        isAsinRecommendationModalOpen: isOpen,
      };
    }
    default: {
      return state;
    }
  }
};
