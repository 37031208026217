/* eslint-disable no-console */
import {
  onAuditLogSetEntityData,
  onAuditLogSetLoading,
  onAuditLogSetRawEntityData,
  onOpenToast,
} from '../../actionCreators';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import {
  getPromotionWeekAuditLog,
  getPromotionAuditLog,
  getTemplateAuditLog,
} from '../../graphql/queries';
import {
  AuditLogTypes,
  GraphQLLabels,
  Language,
  UNDERSCORE_DELIMETER,
} from '../../constants';
import {
  getTranslatedData,
  getEntityWithMergedProps,
} from '../../helpers/auditlog';
import { parseGraphQLResponse } from '../../helpers/api';

export const getDataWithMergedProps = (data) => (data ? data.map(getEntityWithMergedProps) : null);

export const getGraphQlParamsByAuditLogType = (entity, auditLogType) => {
  if (auditLogType === AuditLogTypes.PROMOTION_WEEK) {
    const { yearQuarter, startDate, endDate } = entity;

    return {
      queryParams: {
        yearQuarter_startDate_endDate: [yearQuarter, startDate, endDate].join(UNDERSCORE_DELIMETER),
      },
      graphqlAction: getPromotionWeekAuditLog,
    };
  }

  if (auditLogType === AuditLogTypes.TEMPLATE_BUILDER) {
    const { id } = entity;

    return {
      queryParams: {
        id,
      },
      graphqlAction: getTemplateAuditLog,
    };
  }

  if (auditLogType === AuditLogTypes.PROMOTION) {
    const { id } = entity;

    return {
      queryParams: {
        id,
      },
      graphqlAction: getPromotionAuditLog,
    };
  }

  return {};
};

export const getMetricsTypeByEntityType = (auditLogType) => ({
  [AuditLogTypes.PROMOTION_WEEK]: GraphQLLabels.GET_AUDIT_LOGS_FOR_PROMOTION_WEEK,
  [AuditLogTypes.PROMOTION]: GraphQLLabels.GET_AUDIT_LOGS_FOR_PROMOTION,
  [AuditLogTypes.TEMPLATE_BUILDER]: GraphQLLabels.GET_AUDIT_LOGS_FOR_TEMPLATE_BUILDER,
}[auditLogType]);

export default (dispatch, getState, action, next) => {
  const { AuditLog: { auditLogType } } = getState();
  const { payload: { entity } } = action;

  const { queryParams, graphqlAction } = getGraphQlParamsByAuditLogType(entity, auditLogType);

  if (!queryParams || !graphqlAction) {
    console.error(`Cannot load audit logs for '${auditLogType}'`);

    return next(action);
  }

  dispatch(onAuditLogSetLoading(true));
  dispatch(onGraphQLAction({
    graphqlAction,
    data: {
      ...queryParams,
    },
    label: getMetricsTypeByEntityType(auditLogType),
    onSuccessFunction(response) {
      const data = parseGraphQLResponse(response);
      if (!data) {
        console.error('Cannot parse the response', queryParams);
        return;
      }

      const dataWithMergedProps = getDataWithMergedProps(data);
      if (!dataWithMergedProps) {
        console.error('Cannot parse/merge data', queryParams);
        return;
      }

      dispatch(onAuditLogSetLoading(false));
      const { Meta: { initialMetadataLoaded } } = getState();
      if (!initialMetadataLoaded) {
        dispatch(onAuditLogSetRawEntityData(dataWithMergedProps));
      } else {
        dispatch(onAuditLogSetEntityData(
          getTranslatedData(dataWithMergedProps, auditLogType),
        ));
      }
    },
    onFailureFunction(error) {
      console.error(Language.AUDIT_LOG_ERROR_FETCHING, error);
      dispatch(onAuditLogSetLoading(false));
      dispatch(onOpenToast(Language.AUDIT_LOG_ERROR_FETCHING));
    },
  }));

  return next(action);
};
