import { connect } from 'react-redux';
import {
  onBulkImportPerformImport,
  onBulkImportReset,
} from '../../../actionCreators/bulkImport';
import BulkImportPreview from './BulkImportPreview';

export const mapStateToProps = (state) => {
  const {
    BulkImport: {
      accepted: { create, update },
      rejected,
      formatIssues,
      isImporting,
    },
    Meta: { metadata: { columnOrder } },
  } = state;

  return {
    create,
    update,
    rejected,
    formatIssues,
    columnOrder,
    isImporting,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onPerformImport() { dispatch(onBulkImportPerformImport()); },
    onCancel() { dispatch(onBulkImportReset()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImportPreview);
