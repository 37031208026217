import { connect } from 'react-redux';
import TemplateList from './TemplateList';
import {
  onDeleteTemplate,
} from '../../actionCreators';

const naturalSort = (a, b) => a.localeCompare(b, undefined, {
  numeric: true,
  sensitivity: 'base'
});

const mapStateToProps = (state) => {
  const {
    Templates: { templates },
  } = state;

  const sortedTemplates = templates.sort((a, b) => {
    return naturalSort(a.name, b.name);
  });

  return {
    templates: sortedTemplates,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveTemplate(id) {
      dispatch(onDeleteTemplate(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
