import {
  TemplateActions,
} from '../../constants';

export const initialState = {
  templates: [],
  name: '',
  activeTemplate: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TemplateActions.SET_TEMPLATES: {
      const { payload: { templates } } = action;

      return {
        ...state,
        templates,
      };
    }
    case TemplateActions.SET_ACTIVE_TEMPLATE: {
      const { payload: { template } } = action;

      return {
        ...state,
        activeTemplate: template,
      };
    }
    case TemplateActions.RESET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: null,
        name: '',
      };
    }
    case TemplateActions.SET_TEMPLATE_NAME: {
      const { payload: { name } } = action;

      return {
        ...state,
        name,
      };
    }
    default: {
      return state;
    }
  }
};
