// https://stackoverflow.com/questions/46016990/nodejs-subtract-array-from-array-not-removing-all-duplicates
const arraySubtract = (firstArray, secondArray) => {
  const firstArrayClone = firstArray.slice();
  secondArray.forEach((element) => {
    const firstArrayIndex = firstArrayClone.indexOf(element);
    if (firstArrayIndex !== -1) {
      firstArrayClone.splice(firstArrayIndex, 1);
    }
  });
  return firstArrayClone;
};

export default arraySubtract;
