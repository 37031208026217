import { connect } from 'react-redux';

import getColumnDisplayByColumnName from '../../../helpers/getColumnDisplayByColumnName';

import PathDisplay from './PathDisplay';

export const mapStateToProps = (_, ownProps) => {
  const { path: [slotName] } = ownProps;
  const displayName = getColumnDisplayByColumnName(slotName) || 'Unknown slot';
  return {
    slotName: displayName,
  };
};

export default connect(mapStateToProps)(PathDisplay);
