import React from 'react';
import styles from './AsinImagePreview.module.scss';

const AsinImagePreview = ({ asinImage }) => {
  const image = asinImage
    ? <img className={styles.image} src={asinImage} alt="ASIN" />
    : 'No image';

  return (
    <div className={styles.container}>
      {image}
    </div>
  );
};

export default AsinImagePreview;
