import { connect } from 'react-redux';
import {
  onApprovePromotion,
  onAuditLogClear,
  onAuditLogFetch,
  onAuditLogSetAuditLogType,
  onCellInputUpdate,
  onFlashFill,
  onRejectPromotion,
  onSendPromotionEdit,
} from '../../actionCreators';
import ContextMenu from './ContextMenu';
import { AuditLogTypes } from '../../constants';
import { extractCellHistoryFromLogs } from '../../helpers/auditlog';
import { UserViewMode } from '../../constants/user';
import { isCurrentUserAdmin } from '../../selectors/user';

const mapStateToProps = (state, { columnName }) => {
  const {
    AuditLog: {
      logs,
    },
  } = state;
  return {
    cellHistory: extractCellHistoryFromLogs(logs, columnName),
    isAdmin: isCurrentUserAdmin(state),
  };
};
const mapDispatchToProps = (dispatch, { rowId, columnName, cellValue }) => {
  return {
    onApprovePromotion: () => dispatch(onApprovePromotion(rowId)),
    onRejectPromotion: () => dispatch(onRejectPromotion(rowId)),
    onFlashFill: () => {
      dispatch(onFlashFill(rowId, columnName, cellValue));
    },
    onClickHistoryCell: (value) => {
      dispatch(onCellInputUpdate(rowId, columnName, value));
      dispatch(onSendPromotionEdit({ rowId }, { showToast: true }));
    },
    onFetchHistory: () => {
      dispatch(onAuditLogSetAuditLogType(AuditLogTypes.PROMOTION));
      dispatch(onAuditLogFetch({ id: rowId }));
    },
    onClearHistory: () => dispatch(onAuditLogClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
