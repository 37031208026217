import { API, graphqlOperation } from 'aws-amplify';
import { onOpenToast } from '../../actionCreators';

export const sendRequest = (dispatch, options, onSuccess) => {
  const {
    graphQlApi,
    graphQlParams,
    messages: [successMessage, errorMessage],
  } = options;

  API
    .graphql(graphqlOperation(graphQlApi, { input: graphQlParams }))
    .then((response) => {
      onSuccess(response, successMessage);
    })
    .catch((error) => {
      const errorMessages = [];
      if (!!error && Array.isArray(error.errors)) {
        error.errors.forEach((appsyncError) => {
          if (appsyncError.message) {
            errorMessages.push(appsyncError.message);
          }
        });
      }
      if (errorMessages.length > 0) {
        const longerErrorMessageTimeout = 30000;
        errorMessages.forEach((appsyncErrorMessage) => {
          dispatch(onOpenToast(appsyncErrorMessage, longerErrorMessageTimeout));
        });
        // Toasts gets pushed down, so the last displayed toast is at the top
        dispatch(onOpenToast(errorMessage, longerErrorMessageTimeout));
      } else {
        dispatch(onOpenToast(errorMessage));
      }
    });
};
