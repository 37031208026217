import {
  PromoActions,
  PromoWeekActions,
  SET_PROMOTIONS,
} from '../../constants';
import { areAllQuartersLoaded } from './isLoaded';
import addWeekToYearQuarters from './addWeekToYearQuarters';
import { getYearQuarter } from '../../helpers/common';

const initialState = {
  yearQuarters: {},
  isLoaded: false,
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  if (type === PromoWeekActions.SET_WEEKS_FOR_YEAR_QUARTER) {
    const { yearQuarter, weeks } = payload;
    const weeksForYearQuarter = {};
    for (const week of weeks) {
      const { startDate, endDate } = week;
      const yearQuarter_startDate_endDate = `${yearQuarter}_${startDate}_${endDate}`;
      weeksForYearQuarter[yearQuarter_startDate_endDate] = false;
    }
    return {
      ...state,
      yearQuarters: {
        ...state.yearQuarters,
        [yearQuarter]: {
          ...weeksForYearQuarter,
          ...state.yearQuarters[yearQuarter],
        }
      }
    }
  }
  if (type === SET_PROMOTIONS) {
    const { promotions } = payload;
    if (!promotions || !promotions.length) {
      return state;
    }

    const [{ yearQuarter, yearQuarter_startDate_endDate }] = promotions;
    const newYearQuarters = addWeekToYearQuarters(
        state.yearQuarters,
        yearQuarter,
        yearQuarter_startDate_endDate,
      );
    return {
      ...state,
      isLoaded: areAllQuartersLoaded(newYearQuarters),
      yearQuarters: newYearQuarters,
    }
  }

  if (type === PromoActions.ADD_PROMOTION_WEEK) {
    const {
      week: { startDate, endDate }
    } = payload;
    const yearQuarter = getYearQuarter(startDate);
    const yearQuarter_startDate_endDate = `${yearQuarter}_${startDate}_${endDate}`;
    return {
      ...state,
      yearQuarters: addWeekToYearQuarters(
        state.yearQuarters,
        yearQuarter,
        yearQuarter_startDate_endDate,
      ),
    }
  }
  return state;
}