import {
  onDeletedPromotionWeekReceived,
} from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translatePromotionWeek';

export default (dispatch, getState, action, next) => {
  const { payload: { week } } = action;

  dispatch(onDeletedPromotionWeekReceived(appsyncToLocal(week)));

  return next(action);
};
