import momentTimezone from 'moment-timezone';
import moment from 'moment';
import {
  DateFormats,
  DateTimePatterns,
  DEFAULT_TIMEZONE_ID,
  UNDERSCORE_DELIMETER,
} from '../constants';

const parseTimeString = (string) => {
  if (!string) { return null; }
  const parsedTime = string.match(/\d{1,2}:\d{2}/g);
  if (!parsedTime) { return null; }
  // Add a 0 padding for times < 10
  const formattedTime = parsedTime[0].length === 4 ? `0${parsedTime[0]}` : parsedTime[0];
  return formattedTime;
};

export const getDefaultTimezoneFromState = (state) => {
  const defaultForBusinesses = 'Pacific';
  if (!state.Meta.metadata.fields) { return defaultForBusinesses; }
  const {
    Meta: { metadata: { fields: { timezone: { options: timezoneOptions } } } },
  } = state;
  // Fresh UK has no timezone and defaults to Pacific
  const defaultTimezone = timezoneOptions[0] || defaultForBusinesses;
  return defaultTimezone;
};

export const parseDateAndTimeToISOString = (date, time, timezoneID) => {
  if (!date) {
    return null;
  }
  let dateString = date;
  if (date.includes('/')) {
    dateString = date.split('/').join('-');
  }
  const parsedTime = parseTimeString(time);
  const dateObj = new Date(dateString);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1;
  const year = dateObj.getUTCFullYear();
  const monthString = month < 10 ? `0${month}` : month;
  const dayString = day < 10 ? `0${day}` : day;

  const dateTimeString = `${year}-${monthString}-${dayString}T${parsedTime || '00:00'}:00Z`;
  const timezoneOffset = momentTimezone.tz(dateTimeString, timezoneID).utcOffset();
  return momentTimezone.tz(dateTimeString, timezoneID).subtract(timezoneOffset, 'minutes').utc().format();
};

export const getAllDaysInDateRange = (startDate, endDate, daysInterval = 7) => {
  const currentDate = startDate.clone();

  const daysInRange = [];
  while (currentDate <= endDate) {
    daysInRange.push(currentDate.clone());
    currentDate.add(daysInterval, 'days');
  }

  /*
    Days on the interval (between "start date" and "end date")
    shouldn't inherit any originally time set by a customer. They should be fully included
    in the calculations (from 00:00 to 23:59). Only "start date" and "end date" should
    preserve their originally time set.
   */
  const filteredDaysInRange = [];
  const { length } = daysInRange;
  const lastIndex = length - 1;
  for (let i = 0; i < length; i++) {
    const newDayInRange = daysInRange[i].clone();
    if (i === 0 || i === lastIndex) {
      filteredDaysInRange.push(newDayInRange);
    } else {
      filteredDaysInRange.push(newDayInRange.startOf('day'));
    }
  }

  return filteredDaysInRange;
};

export const getHumanizedDateTime = (dateTime) => {
  if (!dateTime || typeof dateTime !== 'string') {
    return '';
  }

  return moment.utc(dateTime).fromNow();
};

export const subtractDateTimeUnit = (number, unit, formatPattern) => {
  const dateTime = momentTimezone.tz(DEFAULT_TIMEZONE_ID).subtract(number, unit);
  if (formatPattern) {
    return dateTime.format(formatPattern);
  }
  return dateTime.format();
};
export const getFormattedDateTime = (dateTime, options = {}) => {
  const { timeZone, pattern } = options;
  const formatPattern = pattern || DateFormats.MM_DD_YY_hh_mma;

  return (
    timeZone
      ? momentTimezone(dateTime).tz(timeZone)
      : momentTimezone(dateTime)
  ).format(formatPattern);
};

export const joinYearQuarterDates = (campaign) => {
  const { yearQuarter, startDate, endDate } = campaign;

  return `${yearQuarter}${UNDERSCORE_DELIMETER}${startDate}${UNDERSCORE_DELIMETER}${endDate}`;
};

export const splitYearQuarterDates = (str) => {
  if (!str) {
    return {
      yearQuarter: null,
      startDate: null,
      endDate: null
    };
  }
  const [yearQuarter, startDate, endDate] = str.split(UNDERSCORE_DELIMETER);

  return {
    yearQuarter, startDate, endDate,
  };
};

export const getDeadlineFromStartDate = (startDate, { amount, unit }) => {
  return moment(startDate).subtract(amount, unit).format(DateTimePatterns.default);
};
