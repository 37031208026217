import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircleTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import { asinValidatorProps } from '../../../../proptypes';
import styles from './AsinRecommendationModal.module.scss';


const AsinRecommendationModal = (props) => {
  const {
    isAsinRecommendationModalOpen,
    onClose,
    recommendedAsins,
    regionFilters,
    partiallyApprovedAsinViabilities,
  } = props;

  const getHeaders = () => {

    const cells = Object.keys(regionFilters).map((region) => {
      return (
        <TableCell key={region}>
          <div className={styles.toggleCheck}>
            {region}
          </div>
        </TableCell>
      )
    });

    return (
      <TableRow key="headers-row">
        <TableCell>&nbsp;</TableCell>
        {cells}
      </TableRow>
    );
  }

  const getCell = (asin, region, isSuggested, isStoreRegionAvailable) => {
    const checkMarkToken = isStoreRegionAvailable ? checkCircleTokens : closeCircleTokens;
    const highlighting = isSuggested ? styles.available : styles.notAvailable;

    return (
      <TableCell key={`${asin}/${region}`}>
        <div className={highlighting}>
          <Button type="icon">
            <Icon tokens={checkMarkToken}>Status</Icon>
          </Button>
        </div>
      </TableCell>
    );
  };

  const getCells = (asin) => {

    return Object.keys(regionFilters).map((regionFilter) => {
      const isSuggested = recommendedAsins[asin].includes(regionFilter)
      const isAvailable = partiallyApprovedAsinViabilities[asin][regionFilter].unavailableAsinViabilities.length;

      return getCell(asin, regionFilter, isSuggested, !isAvailable);
    });

  };

  const getRows = () => {

    if (_.isEmpty(regionFilters)) {
      return null;
    }

    const rows = [];

    Object.keys(recommendedAsins).forEach((asin) => {
      const cells = getCells(asin);

      if (!cells.length) {
        return;
      }
      rows.push((
        <TableRow key={asin}>
          <TableCell>{asin}</TableCell>
          {cells}
        </TableRow>
      ));
    });

    return rows;
  }
  const getContent = () => {
    if (!recommendedAsins) {
      return null;
    }

    const headers = getHeaders();
    const rows = getRows();
    return (
      <>
        <h2>
          Recommended ASINs for full coverage
        </h2>
        <Table
          headerRows={1}
          spacing="small"
          showDividers
        >
          {headers}
          {rows}
        </Table>
      </>
    )
  }

  const content = getContent();

  return (
    <Modal open={isAsinRecommendationModalOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};

AsinRecommendationModal.propTypes = {
  isAsinRecommendationModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  recommendedAsins: asinValidatorProps.isRequired,
  regionFilters: asinValidatorProps.isRequired,
};

export default AsinRecommendationModal;
