import React from 'react';
import classnames from 'classnames';
import { styledInputProps } from '../../../proptypes';
import styles from '../../GridViewCell/GridViewCell.module.scss';
import { COMMA_DELIMETER } from '../../../constants';

const getFormattedValue = (value) => (Array.isArray(value)
  ? value.join(`${COMMA_DELIMETER} `)
  : value);

const Uneditable = ({ inputValue }) => {
  const formattedValue = getFormattedValue(inputValue);

  return (
    <div className={classnames(styles.cell, styles.disabledInput)}>
      {formattedValue || '\u00a0'}
    </div>
  );
};

Uneditable.propTypes = styledInputProps;

export default Uneditable;
