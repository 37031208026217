import React from 'react';
import PropTypes from 'prop-types';

const PathDisplay = ({ slotName, dependencies }) => {
  return (
    <>
      <div>
        {slotName}
      </div>
      <div>
        {dependencies}
      </div>
    </>
  );
};

PathDisplay.propTypes = {
  dependencies: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    option: PropTypes.string,
  })),
  slotName: PropTypes.string.isRequired,
};

PathDisplay.defaultProps = {
  dependencies: [],
};

export default PathDisplay;
